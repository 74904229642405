import assets from 'assets'

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import ClickAwayListener from 'react-click-away-listener';

import User from '../User/User'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import ConfirmationModel from 'container/Dialogs/ConfirmationModel/ConfirmationModel';

const UserDropdown = () => {
    const userData = useSelector(state => state.auth.user);
    const [logoutModal, setLogoutModal] = useState(false);
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);


    const handleClose = () => {
        setTimeout(() => {
            setOpen(false);
        }, 150);
    };

    const logout = () => {
        localStorage.clear();
        navigate('/sign-in');
        Cookies.remove('chat-model');
        window.location.reload();
    };


    return (
        <Container>
            <div className='user'>
                <img
                    width={44}
                    height={44}
                    onClick={() => setOpen(true)}
                    className='user-image'
                    src={userData?.profile?.profile_image || assets.userAvatar2}
                    alt=''
                />

                {
                    open && <ClickAwayListener onClickAway={handleClose}>
                        <User isMobile={false} setLogoutModal={setLogoutModal} />
                    </ClickAwayListener>
                }

            </div>

            <ConfirmationModel
                open={logoutModal}
                setOpen={() => setLogoutModal(!logoutModal)}
                action={() => logout()}
                descripiton='Are you sure you want to logout?'
                btnText='Logout'
            />


        </Container>
    )
}

export default UserDropdown

const Container = styled.div`

    & .user {
        position: relative;        
    }
    
`;