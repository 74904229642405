import React, { useContext, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { EditorContext } from 'container/context/editorContext';

import DialogLayout from '../DialogLayout';
import CustomButton from 'container/components/CustomButton/CustomButton';
import { modes } from 'app.config';

import assets from 'assets';
import { saveDocument } from 'container/api';
import TextField from 'container/components/TextField/TextField';

const ConvertDocAttention = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const editor = useContext(EditorContext);

  const { items } = useSelector(state => state.outliner);
  const { selected } = useSelector(state => state.documents);
  const { user } = useSelector(state => state.auth);
  const [title, setTitle] = useState('Untitled document');
  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    dispatch.documents.setSelected(null);
    dispatch.utils.setMode(modes.document);
    dispatch.utils.setOpenOutliner(false);
    if (editor && items?.length) {
      let contentStr = '';

      items.forEach((item, index) => {
        const messageStrings = item.split('\n');

        const allString = messageStrings?.map((str, index) => {
          if (str.length > 0) {
            return `<p>${str}</p>`;
          } else if (index !== messageStrings.length - 1 && str.length > 1) {
            return `<br />`;
          } else {
            return '';
          }
        });
        contentStr += allString?.join('');
      });
      editor.commands.setContent(contentStr);
      dispatch.outliner.reset();
    }
  };

  const handleSaveDocument = async () => {
    try {
      setError('');
      if (title.trim().length < 3) {
        return setError('Title should be atleast 3 characters');
      }
      setLoading(true);
      if (!selected && editor.getText().length) {
        const data = {
          user: user.id,
          title: title,
          body: editor.getHTML(),
        };

        await saveDocument(data);
        dispatch.outliner.reset();
        dispatch.documents.setSelected(null);
        editor.commands.setContent('');
        editor.chain().focus().run();
        dispatch.documents.getDocuments();
      } else {
        console.log('here');
        dispatch.documents.updateDocument({
          body: editor.getHTML(),
          showToast: true,
        });
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
      handleContinue();
      setOpen(false);
    }
  };

  return (
    <DialogLayout open={true}>
      <Container>
        <p className='title'>Attention!</p>
        <img src={assets.attention} className='image' alt='' />
        <p className='font-size-16 desc'>
          You have not saved your current document.
          <br />
          <br />
          If you continue you will overwrite your current document and create a
          new document.
        </p>
        <div
          className='input-container'
          style={{ marginBlock: '20px 30px', width: '100%' }}
        >
          <TextField
            label='Document title'
            error={error}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <ButtonContainer>
          <CustomButton
            loading={loading}
            variant='btn-outlined'
            label='Save Doc'
            className='btn'
            onClick={handleSaveDocument}
          />
          <CustomButton label='Continue' onClick={handleContinue} />
        </ButtonContainer>
        <div className='close-btn' role='button' onClick={handleClose}>
          <img src={assets.close} alt='' />
        </div>
      </Container>
    </DialogLayout>
  );
};

export default ConvertDocAttention;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgrey};
  width: 498px;
  border-radius: 16px;
  overflow: hidden;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & .image {
    margin-block: -30px -10px;
  }

  & .desc {
    text-align: center;
    opacity: 0.7;
  }

  & p {
    color: ${({ theme }) => theme.colors.primary};
    &.title {
      font-size: 36px;
      font-weight: 500;
    }
  }

  & .input-container {
    & .label {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  & .close-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    right: 14px;
    top: 14px;
    opacity: 0.5;
    cursor: pointer;

    & img {
      width: 25px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  margin-top: 23px;
  & button {
    flex: 1;

    &.btn {
      background-color: ${({ theme }) => theme.colors.lightgrey};
      border: 1px solid ${({ theme }) => theme.colors.lightgrey};
    }
  }
`;
