import React from 'react';
import styled from 'styled-components';
// CSS
import './SignUp.css';

import CreateAccount from './components/CreateAccount';
import SignupSlider from './components/Slider';
import assets from 'assets';
import { useSelector } from 'react-redux';
import TopBanner from '../TopBanner/TopBanner';

function SignUp() {
  const params = new URLSearchParams(window.location.search);
  const playground_user = params.get('playground_user');
  const { topBanner } = useSelector(state => state.utils);

  return (
    <>
    {topBanner && <TopBanner isBtn={false} />}
    <MainContainer className={` ${playground_user && 'mclass'}`}>
      {
        playground_user ? (
          <div
            style={{
              display: 'block',
              textAlign: 'center',
              marginTop: '40px'
            }}
            className='main-logo'>
            <img src={assets.pgchataiFullLogo} alt='' />
          </div>
        ) : (
          <div className='main-logo'>
            <img src={assets.logoWithText} alt='' />
          </div>
        )
      }

      {
        !playground_user && (
          <div className='left-side'>
            <div className='container'>
              <SignupSlider />
            </div>
          </div>
        )
      }
      <Container className={`mobile ${!playground_user && 'width-40'}`}>
        <FormContainer>
          <CreateAccount />
        </FormContainer>
      </Container>
    </MainContainer>
    </>
    
  );
}

export default SignUp;

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 10px;
  width: 100%;

  & .main-logo {
    display: none;
  }

  & .left-side {
    padding: 30px 0px 30px 30px;
    width: 60%;
    min-height: 100vh;

    & .container {
      background-color: var(--darkgrey);
      display: flex;
      border-radius: 20px;
      justify-content: center;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (max-width: 1000px) {
      display: flex;
    flex-direction: column;
    gap: 0px;
    width: 100%;

    .sign__up-box{
      min-width: 100% !important;
      max-width: 100% !important;
    }

    .left-side {
      width: 100%;
      padding: 30px;
    }

    & .logo {
      display: none;
    }

    & .main-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      width: 100%;
    }

    & .input-box {
      margin-top: 0px;
    }

    & .checkbox-list {
      padding: 10px;
    }

    & .mobile {
      display: flex;
      width: 100%;
    }

    & .container div{
      width: 100%;

      & .tool-text{
        text-align: center;
      }

    .stars {
      display: flex;
      gap: 5px;
      justify-content: center;
    }
      /* text-align: center; */
      /* display: flex; */
      /* flex-direction: column; */
    }

      .width-40 {
    width: 100% !important;
  }
  }

  .width-40 {
    width: 40%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .input-box {
    margin: 0 10px;
    & .input-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--primary);
      opacity: 0.5;
      margin-bottom: 0;
      padding-bottom: 5px;
      float: left;
    }
  }

  .input-box .confirm-details {
    background: var(--red);
    border-radius: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
    opacity: 1;
    border: none;
    height: 45px;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 20px;
  }

  /*  */
  /* @media only screen and (max-width: 520px) {
    margin: 0 10px;
  } */

`;

const FormContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;