import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import assets from 'assets';
import { siteURL } from 'app.config';

const ShareWithLinkedIn = () => {
  const id = useSelector(state => state.chat.selectedConversation?.id || '');
  const handleOnClick = () => {
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${siteURL}/share/${id}&title=${''}&summary=${''}`;
    window.open(url, '_blank');
  };
  return (
    <Container role='button' onClick={handleOnClick}>
      <img src={assets.linkedIn} alt='' />
      <p className='font-size-12 bold'>LinkedIn</p>
    </Container>
  );
};

export default ShareWithLinkedIn;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: ${({ theme }) => theme.colors.primary};
  align-items: center;

  & img {
    width: 60px;
    aspect-ratio: 1/1;
  }
`;
