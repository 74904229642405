import React, { useEffect, useState } from 'react';
// import CustomButton from 'container/components/CustomButton/CustomButton';
import TipTapEditor from 'container/components/TipTapEditor/TipTapEditor';
import { useSelector } from 'react-redux';
// import { EditorContext } from 'container/context/editorContext';

import styled from 'styled-components';
// import classNames from 'classnames';
// import assets from 'assets';
// import { updateDocument } from 'container/api';
// import useMediaQuery from 'container/hooks/useMediaQuery';
// import { debounce } from 'container/utils';
// import SmallScreenMenu from '../Content/SmallScreenMenu';

const DocumentView = () => {
  // const editor = useContext(EditorContext);
  const [title, setTitle] = useState('Untitled document');
  // const isDesktop = useMediaQuery('(max-width: 1000px)');

  // const dispatch = useDispatch();
  const openAnalysis = useSelector(state => state.utils.openAnalysis);
  const { selected } = useSelector(state => state.documents);

  // const handleAnalysis = () => {
    // dispatch.utils.setOpenAnalysis(!openAnalysis);
  // };

  /* eslint-disable react-hooks/exhaustive-deps */
  // const handleSaveTitle = useCallback(
  //   debounce(async title => {
  //     await updateDocument({
  //       title,
  //       body: editor?.getHTML(),
  //       id: selected?.id,
  //     });
  //     await dispatch.documents.getDocuments();
  //     dispatch.documents.setSelected({ ...selected, title });
  //   }, 1500),
  //   [selected]
  // );

  // const handleTitleChange = e => {
  //   setTitle(e.target.value);
  //   if (selected) {
  //     handleSaveTitle(e.target.value);
  //   }
  // };

  // const handleOpenLeftMenu = () => {
  //   dispatch.utils.setOpenMobileChatMenu(true);
  // };

  // const handleOpenRightMenu = () => {
  //   dispatch.utils.setOpenMobileRightMenu(true);
  // };

  useEffect(() => {
    if (selected) {
      setTitle(selected.title);
    } else {
      setTitle('Untitled document');
    }
  }, [selected]);

  return (
    <Container openOutliner={openAnalysis}>
      <div className='content'>
        {/* {isDesktop && (
          <SmallScreenMenu showMainText={true} />
          // <MobileHeader>
          //   <IconButton role='button' onClick={handleOpenLeftMenu}>
          //     <img src={assets.message} alt='' />
          //   </IconButton>
          //   <IconButton role='button' onClick={handleOpenRightMenu}>
          //     <img src={assets.menu} alt='' />
          //   </IconButton>
          // </MobileHeader>
        )} */}
        <Header>
          {/* <DocumentTitle value={title} onChange={handleTitleChange} /> */}
          <div className='flex-row'>
            {/* <MenuContainer>
              <CustomButton
                onClick={handleAnalysis}
                label={isDesktop ? '' : 'Analysis'}
                variant='background-color btn-outlined'
                rightIcon={isDesktop ? assets.outliner : assets.arrowLeft}
                className={classNames(
                  openAnalysis === true ? 'active' : '',
                  isDesktop ? 'btn-sm a' : ''
                )}
                rightIconStyles={{
                  transition: '0.4s ease',
                  ...(!isDesktop && {
                    transform: openAnalysis ? 'rotate(0deg)' : 'rotate(180deg)',
                  }),
                }}
              />
            </MenuContainer> */}
            {/* <CustomButton
              variant='btn-outlined'
              label={isDesktop ? '' : 'Analysis'}
              rightIcon={assets.arrowLeft}
              rightIconStyles={{
                transform: 'rotate(180deg)',
              }}
              className={isDesktop ? 'btn-sm a' : ''}
            /> */}
          </div>
        </Header>
        <Content>
          <TipTapEditor title={title} />
        </Content>
      </div>
    </Container>
  );
};

export default DocumentView;

const Container = styled.div`
  height: 100%;
  padding-bottom: 8px;
  /* padding-right: ${({ openOutliner }) => (openOutliner ? '0px' : '8px')}; */

  & .content {
    background-color: ${({ theme }) => theme.colors.lightBlack};
    /* border-radius: 12px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    border-top-right-radius: ${({ openOutliner }) =>
      openOutliner ? '0px' : '12px'};
    border-bottom-right-radius: ${({ openOutliner }) =>
      openOutliner ? '0px' : '12px'};
  }

  & .background-color {
    background-color: #333438 !important;
  }

  @media only screen and (max-width: 1000px) {
    height: calc(100vh - 103px);
    background-color: rgba(32, 33, 36, 1);
    padding: 0px;
    & .content {
      border-radius: 0px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkbg);
  /* padding: 8px 10px; */
  padding: 4px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  & .title {
    color: ${({ theme }) => theme.colors.primary};
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px;
  }

  & .flex-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & .btn-sm {
    background-color: ${({ theme }) => theme.colors.darkgrey};
    width: 30px;
    height: 30px;
    padding: 7px;
    border: 1px solid transparent;

    &.a {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const Content = styled.div`
  flex-grow: 1;
  color: ${({ theme }) => theme.colors.primary};
  background-color: var(--darkbg);
  display: flex;
  flex-direction: column;
  
`;

// const MenuContainer = styled.div`
//   position: relative;
// `;

// const MobileHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 1px solid ${({ theme }) => theme.colors.border};
//   padding: 10px 20px;
// `;

// const IconButton = styled.div`
//   height: 38px;
//   width: 38px;
//   border-radius: 5px;
//   display: grid;
//   place-items: center;
//   background-color: ${({ theme }) => theme.colors.lightgrey};
//   cursor: pointer;
// `;

// const DocumentTitle = styled.input`
//   background-color: transparent;
//   font-size: 14px;
//   border: none;
//   outline: none;
//   color: ${({ theme }) => theme.colors.primary};
//   padding: 12px 0px;
// `;
