import React, { useState } from 'react';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import Button from 'container/components/Button';
import Spinner from 'container/components/Spinner';
import { updateCardDetails } from 'container/api';
import theme from 'container/theme';
import { toast } from 'react-hot-toast';

const StripeForm = ({ setShowForm }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleCloseForm = () => {
    setShowForm(false);
    setError('');
  };

  const createSubScription = async () => {
    try {
      setError('');
      setLoading(true);
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });
      if (paymentMethod.error) {
        return setError('Card information provided is not valid.');
      }
      const paymentMethodId = paymentMethod?.paymentMethod?.id;
      await updateCardDetails({
        payment_method_id: paymentMethodId,
      });
      toast.success('Billing information updated successfully!.');
      handleCloseForm();
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form>
      <p className='font-size-14'>Card information</p>
      <InputContainer>
        <CardNumberElement
          options={{
            showIcon: true,
            style: {
              base: {
                backgroundColor: 'transparent',
                fontSize: '14px',
                color: 'white',
                lineHeight: '45px',
                padding: 10,
              },
            },
          }}
        />
      </InputContainer>
      <InputContainer className='expire'>
        <div style={{ flex: 1 }}>
          <CardExpiryElement
            options={{
              style: {
                base: {
                  backgroundColor: 'transparent',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '45px',
                  padding: 10,
                },
              },
            }}
          />
        </div>
        <div className='seprator'>
          <div className='line'></div>
        </div>
        <div style={{ flex: 1, paddingLeft: '5px' }}>
          <CardCvcElement
            options={{
              style: {
                base: {
                  backgroundColor: 'transparent',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '45px',
                  padding: 10,
                },
              },
            }}
          />
        </div>
      </InputContainer>
      {error && (
        <p
          className='error font-size-14'
          style={{ color: theme.colors.red, marginTop: '5px' }}
        >
          {error}
        </p>
      )}
      <ButtonsRow>
        <Button
          className='btn'
          text='Cancel'
          style={{
            margin: '0px',
            flex: 1,
            backgroundColor: 'transparent',
            border: '1px solid #ffffff',
          }}
          onClick={handleCloseForm}
        />
        <Button
          className='btn'
          text={loading ? <Spinner /> : 'Update information'}
          onClick={createSubScription}
          disabled={loading}
          style={{ margin: '0px', flex: 3 }}
        />
      </ButtonsRow>
    </Form>
  );
};

export default StripeForm;

const Form = styled.form`
  & .btn {
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    margin-top: 30px;
  }

  & .font-size-18 {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    text-align: left;
  }

  & .font-size-14 {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 21px;
    margin-bottom: 10px;
    text-align: left;
  }

  & .font-size-10 {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 21px;
    text-align: center;
    margin-top: 15px;
    padding-inline: 10px;
  }
`;

const InputContainer = styled.div`
  height: 45px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.darkgray};
  border-radius: 7px;
  padding-inline: 10px;

  & .seprator {
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 10px; */

    & .line {
      width: 1px;
      height: 70%;
      background-color: #494c54;
    }
  }

  /* & .card {
    width: 100%;
    padding: 12px;
    background-color: transparent;
    & .InputElement {
      color: ${({ theme }) => theme.colors.primary}!important;
    }
  } */

  &.expire {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;

  & .btn {
    padding-inline: 10px;
    font-size: 12px;
  }
`;
