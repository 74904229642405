import React from 'react'
import styled from "styled-components"
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import FreeTag from 'container/components/FreeTag/FreeTag'
import Promps from 'container/components/Promps/Promps'
import UpgradeButton from 'container/components/UpgradeButton/UpgradeButton'
import assets from 'assets';


const User = ({ isMobile, setLogoutModal }) => {

    const navigate = useNavigate();
    const userData = useSelector(state => state.auth.user);

    const { isLoggedIn } = useSelector(state => state.auth);
    const handleSettings = () => {
        const params = new URLSearchParams(window.location.search);
        navigate(`/settings/profile/?${params.toString()}`);
    };




    return (
        <Container>
            <div
                // style={{
                //     width: isMobile ? window.outerWidth : '',
                // }}
                className={`${!isMobile && 'desktop '}  dropdown flex flex-column`}>
                <div className='user-detail flex flex-column gap-10 '>
                    <div className='flex gap-2 align-items-center'>
                        <img
                            src={userData?.profile?.profile_image || assets.userAvatar2}
                            alt='user data'
                            className='user-image'
                            width={24}
                            height={24}
                        />
                        <span className='username'>
                            {isLoggedIn == null
                                ? 'New User'
                                : userData?.first_name || userData?.username}
                        </span>
                    </div>

                    <div className='flex align-items-center justify-content-between'>
                        <Promps />
                        <FreeTag />
                    </div>

                    <div >
                        <UpgradeButton />
                    </div>
                </div>

                <Divider />

                <div className='user-detail flex flex-column gap-1'>

                    <div
                        onClick={handleSettings}
                        className='item align-items-center'>
                        <img src={assets.profileCircle} width={20} height={20} alt='' />
                        <span>Profile</span>
                    </div>

                    <div
                        onClick={handleSettings}
                        className='item align-items-center'>
                        <img src={assets.hashTag} width={20} height={20} alt='' />
                        <span>Account Settings</span>
                    </div>

                    <div
                        onClick={() => window.open('https://www.flexoffers.com/affiliate-programs/chatai-affiliate-program/', '_blank')}
                        className='item align-items-center'>
                        <img src={assets.affiliates} width={20} height={20} alt='' />
                        <span>Affiliates</span>
                    </div>

                    <div
                        onClick={() => setLogoutModal(true)}
                        className='item align-items-center'>
                        <svg
                            width='20'
                            height='20'
                            viewBox='0 0 28 28'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M21.8794 4.60583H19.5763C19.2709 4.60583 18.978 4.72716 18.7621 4.94311C18.5461 5.15907 18.4248 5.45197 18.4248 5.75737C18.4248 6.06278 18.5461 6.35568 18.7621 6.57163C18.978 6.78759 19.2709 6.90891 19.5763 6.90891H20.7279V20.7274H19.5763C19.2709 20.7274 18.978 20.8487 18.7621 21.0646C18.5461 21.2806 18.4248 21.5735 18.4248 21.8789C18.4248 22.1843 18.5461 22.4772 18.7621 22.6931C18.978 22.9091 19.2709 23.0304 19.5763 23.0304H21.8794C22.1848 23.0304 22.4777 22.9091 22.6937 22.6931C22.9096 22.4772 23.031 22.1843 23.031 21.8789V5.75737C23.031 5.45197 22.9096 5.15907 22.6937 4.94311C22.4777 4.72716 22.1848 4.60583 21.8794 4.60583Z'
                                fill='white'
                            />
                            <path
                                d='M13.5881 8.52104C13.4049 8.27672 13.1321 8.11519 12.8297 8.072C12.5274 8.02881 12.2203 8.10749 11.976 8.29074C11.7316 8.47398 11.5701 8.74678 11.5269 9.04911C11.4837 9.35145 11.5624 9.65856 11.7457 9.90289L13.8184 12.6666H4.60613C4.30072 12.6666 4.00782 12.7879 3.79187 13.0039C3.57591 13.2198 3.45459 13.5127 3.45459 13.8181C3.45459 14.1235 3.57591 14.4164 3.79187 14.6324C4.00782 14.8483 4.30072 14.9697 4.60613 14.9697H13.8184H13.9221L11.9414 17.7794C11.8531 17.9032 11.7901 18.0432 11.7561 18.1915C11.7222 18.3397 11.7179 18.4932 11.7436 18.6431C11.7693 18.793 11.8245 18.9363 11.9058 19.0647C11.9872 19.1932 12.0932 19.3043 12.2178 19.3916C12.4135 19.5282 12.6469 19.6006 12.8857 19.5988C13.0684 19.598 13.2482 19.5537 13.4104 19.4696C13.5726 19.3855 13.7125 19.264 13.8184 19.1152L17.0658 14.509C17.2099 14.3118 17.2875 14.0739 17.2875 13.8296C17.2875 13.5854 17.2099 13.3474 17.0658 13.1502L13.5881 8.52104Z'
                                fill='white'
                            />
                        </svg>
                        <span>Log out</span>
                    </div>
                </div>
            </div>


        </Container>
    )
}

export default User

const Container = styled.div`

    color: white;
    
    & .desktop {
        position: fixed;
        bottom: 0;
        left: 0;
        transform: translate(10%, -20%);
        width: 267px;
        height: max-content;
        /* height: 267px; */
        /* z-index: 999999 !important; */
    }

    & .dropdown {
            
            border-radius: 8px;
            border: 1px solid #4D4F56;
            background-color: #35363A;
            z-index: 1;

            & .username{
                font-size: 16px;
                line-height: 23.2px;
                font-weight: 600;
                font-family: Poppins;
            }

            & .user-detail{
                padding: 10px;
            }

            & .gap-10{
                gap: 10px;
            }

            & .item{
                display: flex;
                gap: 10px;
                padding: 8px 0px;
            }

            & .item span{
                font-size: 14px;
                line-height: 20.3px;
                font-weight: 400;

            }

        }
`

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightgrey};
  margin-block: -2px;
`;