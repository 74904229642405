import { createModel } from '@rematch/core';
import Cookies from 'js-cookie';
import { generateNewImage, getUserImages, images_history } from 'container/api';
import { templates } from 'container/data/templates';
import { filters } from 'container/data/filters';
import assets from 'assets';
import axios from 'axios';
import { black_friday_code, imageGenerationModels } from 'app.config';

export const imageGeneration = createModel()({
  name: 'imageGeneration',
  state: {
    loading: false,
    templates: templates,
    selectedTemplate: '',
    filters: filters,
    selectedFilter: '',
    selectedImage: '',
    prevSelectedImage: '',
    prompt: '',
    generatedImage: null,
    openModal: false,
    modalData: null,
    imageCount: 1,
    width: 512,
    height: 512,
    model: '37d42ae9-5f5f-4399-b60b-014d35e762a5',
    prompt_strength: 7,
    isNeg_prompt: true,
    neg_prompt: '',
    keepSeed: false,
    seed: '',
    promptEdited: false,
    filterEdited: false,
    grid: 3,
    customPrompt: '',
    finalPrompt: '',
    disable: false,
    prevPrompt: '',
    isPromptDetail: false,
    userImages: [],
    clickedImageGenerationButton: false,
    openImagePopup: false,
    conversations: [],
    filter: 'newest',
     mobileTab: 'Generate'
  },
  reducers: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setMobileTab(state, payload) {
      state.mobileTab = payload;
    },
    setFilter(state, payload) {
      state.filter = payload;
    },
    setOpenImagePopup(state, payload) {
      state.openImagePopup = payload;
    },
    setClickImageGenerationButton(state, payload) {
      state.clickedImageGenerationButton = payload
    },
    setIsPromptDetail(state, payload) {
      state.isPromptDetail = payload
    },
    setTemplates(state, payload) {
      state.templates = payload;
    },
    setSelectedTemplate(state, payload) {
      state.selectedTemplate = payload;
    },
    setSelectedFilter(state, payload) {
      state.selectedFilter = payload;
    },
    setFilters(state, payload) {
      state.filters = payload;
    },
    setSelectedImage(state, payload) {
      state.selectedImage = payload;
    },
    setPrevSelectedImage(state, payload) {
      state.prevSelectedImage = payload
    },
    setPrompt(state, payload) {
      state.prompt = payload;
    },
    setGeneratedImage(state, payload) {
      state.generatedImage = payload;
    },
    setOpenModal(state, payload) {
      state.openModal = payload;
    },
    setModalData(state, payload) {
      state.modalData = payload;
    },
    setImageCount(state, payload) {
      state.imageCount = payload;
    },
    setHeight(state, payload) {
      state.height = payload;
    },
    setWidth(state, payload) {
      state.width = payload;
    },
    setModel(state, payload) {
      state.model = payload;
    },
    setPromptStrength(state, payload) {
      state.prompt_strength = payload;
    },
    setIsNegPrompt(state, payload) {
      state.isNeg_prompt = payload;
    },
    setNegPrompt(state, payload) {
      state.neg_prompt = payload;
    },
    setKeepSeed(state, payload) {
      state.keepSeed = payload;
    },
    setSeed(state, payload) {
      state.seed = payload;
    },
    setPromptEdited(state, payload) {
      state.promptEdited = payload;
    },
    setGrid(state, payload) {
      state.grid = payload;
    },
    setCustomPrompt(state, payload) {
      state.customPrompt = payload;
    },
    setFilterEdited(state, payload) {
      state.filterEdited = payload;
    },
    setFinalPrompt(state, payload) {
      state.finalPrompt = payload;
    },
    setDisable(state, payload) {
      state.disable = payload;
    },
    setPrevPrompt(state, payload) {
      state.prevPrompt = payload;
    },
    setUserImages(state, payload) {
      state.userImages = payload;
    },
    setConversations(state, payload) {
      state.conversations = payload;
    }
  },
  effects: dispatch => ({
    async getTemplates() {
      // try {
      //   dispatch.imageGeneration.setLoading(true);
      //   const { data } = await imageGenerationTemplates();
      //   dispatch.imageGeneration.setTemplates(data.data);
      // } catch (err) {
      //   console.log(err.message);
      // } finally {
      //   dispatch.imageGeneration.setLoading(false);
      // }
    },
    async getFilters() {
      // try {
      //   dispatch.imageGeneration.setLoading(true);
      //   const { data } = await imageGenerationFilters();
      //   dispatch.imageGeneration.setFilters([
      //     {
      //       id: 100,
      //       name: 'No Filter',
      //       descrption: '',
      //       image_url: assets.noFilter,
      //     },
      //     ...data.data.Filters,
      //   ]);
      // } catch (err) {
      //   console.log(err.message);
      // } finally {
      //   dispatch.imageGeneration.setLoading(false);
      // }
    },
    async generateImage(payload, state) {
      const cookie = Cookies.get('chatai-auth');
      const messages = state.chat.messages;
      const prompt = payload.prompt;
      const planData = state.subscription.planData;
      const remainingPrompts = state.chat.remainingPrompts;
      try {
        const keepSeed = state.imageGeneration.keepSeed;
        dispatch.imageGeneration.setLoading(true);
        dispatch.imageGeneration.setPrompt(payload.prompt);
        dispatch.imageGeneration.setPrevPrompt(payload.prompt);
        const { data } = await generateNewImage(payload);
        if (
          data.status === 200 &&
          data.Message ===
            'We hope you have been enjoying ChatAI. You have bumped into your free account limit. To upgrade visit https://ui.chatai.com/plans'
        ) {
          dispatch.subscription.setCouponCode(black_friday_code);
          dispatch.utils.setSubscriptionModal(true);
        }

        dispatch.imageGeneration.setSelectedImage('');
        dispatch.imageGeneration.setSelectedFilter('');

        if (
          data.status === 405 &&
          data.Message === 'Your query contains some banned words'
        ) {
          dispatch.chat.setMessages([
            ...messages,
            {
              avatar: assets.picasso,
              message: 'Your query contains some banned words!',
              type: 'text',
              userMsg: false,
              error: true,
            },
          ]);
        } else {
          dispatch.imageGeneration.setGeneratedImage(data.data);
          dispatch.chat.setMessages([
            ...messages,
            {
              tryleap_res: data.data,
              is_tryleap: true,
              query: payload.prompt,
              created_at: data.data.createdAt,
              response_source: 3,
            },
          ]);
          dispatch.imageGeneration.setPrevPrompt('');
          if (keepSeed) {
            dispatch.imageGeneration.setSeed(data.data.seed);
          }
          if (planData?.plan?.toLowerCase() === 'free') {
            window.dataLayer.push({
              event: 'custom',
              eventName: 'prompt_completion',
              prompt_number: remainingPrompts,
            });
          } else {
            window.dataLayer.push({
              event: 'custom',
              eventName: 'prompt_completion',
              prompt_number: 'Unlimited',
            });
          }
        }

        dispatch.imageGeneration.setPromptEdited(false);
        // dispatch.imageGeneration.setHeight(512);
        // dispatch.imageGeneration.setWidth(512);
        dispatch.imageGeneration.setImageCount(1);
        dispatch.imageGeneration.setSelectedTemplate('');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          if (err.response.status === 405) {
            dispatch.chat.setMessages([
              ...messages,
              {
                avatar: assets.picasso,
                prompt: prompt,
                message: 'Your query contains some banned words!',
                type: 'text',
                userMsg: false,
                isMine: true,
              },
            ]);
          } else if (err.response.status === 400) {
            dispatch.chat.setMessages([
              ...messages,
              {
                avatar: assets.picasso,
                prompt: prompt,
                message: err.response.data.Eror,
                type: 'text',
                userMsg: false,
                isMine: true,
              },
            ]);
          }
        }
      } finally {
        dispatch.imageGeneration.setLoading(false);
        dispatch.chat.handleGetRemainingPromts(cookie);
        dispatch.imageGeneration.get_image_history({ model: payload.model_id, filter:payload.filter });
      }
    },
    async getUserImages(payload) {

      try {
        
        const response = await getUserImages(payload);

        
        if (response.status === 200) {
          dispatch.imageGeneration.setUserImages(response.data);
        }
      } catch (error) {
        console.error(error)
      }
    },
    async get_image_history(payload) {
      try {
        const model = imageGenerationModels.find(x => x.value.trim() === payload.model.trim())
        const res = await images_history(model.label, payload.filter);

        if (res.data.status === 200) {
          dispatch.imageGeneration.setConversations(res.data.data)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }),
});
