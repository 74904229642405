export const generateRandomString = length => {
  const str = 'abcde$fghi@jklm%no&pqrstuvwxyz1234567890';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    randomString += str.charAt(Math.floor(Math.random() * str.length));
  }
  return randomString;
};

export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
