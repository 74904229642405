import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'

const FreeTag = () => {
  const user = useSelector(state => state.auth.user);
  const planData = useSelector(state => state.subscription.planData);

  const renderPlanType = () => {
    if (user?.profile?.status === 5) {
      return 'Paused';
    }
    if (planData?.plan.toLowerCase() === 'month') {
      return 'Monthly';
    } else if (planData?.plan.toLowerCase() === 'year') {
      return 'Annual';
    } else if (planData?.plan.toLowerCase() === 'free') {
      return 'Free';
    } else {
      return planData?.plan;
    }
  };
  return (
    <Container>{renderPlanType()}</Container>
  )
}

export default FreeTag

const Container = styled.div`
    background-color: #494C55;
    width: 46px;
    height: 22px;
    border: 0px 0px 0px 0.5px;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
`