import assets from 'assets';

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import api, { userInvoices } from 'container/api';

import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import theme from 'container/theme';

dayjs.extend(utc);

const Invoices = () => {
  const [loading, setLoaidng] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [count, setCount] = useState('');
  const [next, setNext] = useState('');
  const [prev, setPrev] = useState('');
  const [page, setPage] = useState(1);
  const handleGetInvoices = async () => {
    try {
      setLoaidng(true);
      const { data } = await userInvoices();
      setInvoices(data.results);
      setNext(data.next);
      setPrev(data.previous);
      setCount(data.count);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoaidng(false);
    }
  };

  const handleNext = async () => {
    try {
      setPage(page + 1);
      const { data } = await api.get(next);
      setInvoices(data.results);
      setNext(data.next);
      setPrev(data.previous);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoaidng(false);
    }
  };

  const handlePrev = async () => {
    try {
      setPage(page - 1);
      const { data } = await api.get(prev);
      setInvoices(data.results);
      setNext(data.next);
      setPrev(data.previous);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoaidng(false);
    }
  };

  useEffect(() => {
    handleGetInvoices();
  }, []);
  return (
    <Container>
      <p className='font-size-24'>Invoices</p>
      {loading ? (
        <div
          style={{
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClipLoader size={50} color={theme.colors.primary} />
        </div>
      ) : (
        <TableContainer>
          <Table>
            <thead>
              <tr className='header-row'>
                <th>Invoice #</th>
                <th>Last payment amount</th>
                <th>Last billing date</th>
                <th>Next billing date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, index) => (
                <tr key={index}>
                  <td>{invoice?.invoice_number}</td>
                  <td>$ {invoice?.amount_paid}</td>
                  <td>
                    {dayjs(new Date(invoice?.last_payment_date))
                      .utc('z')
                      .local()
                      .format('MM/DD/YYYY - hh:mm a')}
                  </td>
                  <td>
                    {dayjs(new Date(invoice?.next_payment_date))
                      .utc('z')
                      .local()
                      .format('MM/DD/YYYY - hh:mm a')}
                  </td>
                  <td>
                    <a download href={invoice.pdf_link}>
                      <DownloadInvoiceButton>
                        <img src={assets.arrowDown} alt='' />
                        <p className='font-size-12' style={{ margin: '0px' }}>
                          Download Invoice
                        </p>
                      </DownloadInvoiceButton>
                    </a>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={5} className='table-footer'>
                  <Row>
                    <p className='font-size-10'>
                      Showing {invoices.length}-
                      {invoices.length === 10 ? page * 10 : invoices.length} of{' '}
                      {count}
                    </p>
                    <div className='flex-row'>
                      <IconButton onClick={handlePrev} disabled={!prev}>
                        <img src={assets.arrowLeft} alt='' />
                      </IconButton>
                      <div className='count'>{page}</div>
                      <IconButton onClick={handleNext} disabled={!next}>
                        <img
                          src={assets.arrowLeft}
                          style={{ transform: 'rotate(180deg)' }}
                          alt=''
                        />
                      </IconButton>
                    </div>
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default Invoices;

const Container = styled.div``;

const TableContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  overflow: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;
  table-layout: fixed;

  & tr {
    &.header-row {
      & th {
        background-color: ${({ theme }) => theme.colors.tableHeader};
        padding-block: 12px;
        color: ${({ theme }) => theme.colors.inputLabel};
        font-weight: 400;
        border-bottom: 2px solid ${({ theme }) => theme.colors.darkgrey};
        font-size: 14px;
        line-height: 21px;
        &:first-of-type {
          border-top-left-radius: 12px;
          padding-left: 18px;
        }
        &:last-of-type {
          border-top-right-radius: 12px;
          padding-right: 18px;
        }
      }
    }

    & td {
      background-color: ${({ theme }) => theme.colors.lightgrey};
      padding-block: 14px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.darkgrey};
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      &:first-of-type {
        padding-left: 18px;
      }
      &:last-of-type {
        padding-right: 18px;
      }

      &.table-footer {
        padding-block: 32px 26px;
        padding-inline: 28px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .flex-row {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  & .count {
    height: 19px;
    width: 19px;
    display: grid;
    place-items: center;
    background-color: ${({ theme }) => theme.colors.red};
    font-size: 10px;
    line-height: 19px;
    border-radius: 4px;
  }
`;

const DownloadInvoiceButton = styled.button`
  background-color: ${({ theme }) => theme.colors.red};
  border: none;
  border-radius: 7px;
  padding: 8px 14px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconButton = styled.button`
  background-color: ${({ theme }) => theme.colors.darkbg};
  border: none;
  width: 19px;
  height: 19px;
  border-radius: 4px;
  display: grid;
  place-items: center;

  & img {
    height: 8px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
