import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import assets from 'assets';

//
import MonthlySubscription from './components/MonthlySubscription';
import YearlySubscription from './components/YearlySubscription';
import theme from 'container/theme';
import { welcome_discount_code } from 'app.config';

function Subscription({ containerStyle }) {
  const dispatch = useDispatch();
  const planData = useSelector(state => state.subscription.planData);
  const params = new URLSearchParams(window.location.search);
  const discountCode = params.get('discount_code');

  const handlePlan = async value => {
    dispatch.subscription.setPlanId(value);
    dispatch.utils.setSubscriptionModal(false);
  };

  const handleClose = () => {
    dispatch.utils.setSubscriptionModal(false);
  };

  useEffect(() => {
    if (!planData) {
      dispatch.subscription.getPlanData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container style={containerStyle}>
      <Content>
        <p className='font-size-16 heading'>
          Please upgrade to one of our premium plans.{' '}
          {planData?.plan?.toLowerCase() === 'free' &&
            discountCode === welcome_discount_code && (
              <span style={{ color: theme.colors.red }}>
                Discounts applied at checkout
              </span>
            )}
        </p>
        {/* Close Button */}
        <div className='close-btn' onClick={handleClose}>
          <img src={assets.closeIcon} alt='' />
        </div>
        <div className='subscription_plan-grid'>
          <YearlySubscription handlePlan={handlePlan} />
          <MonthlySubscription handlePlan={handlePlan} />
        </div>
      </Content>
    </Container>
  );
}

export default Subscription;

const Container = styled.div`
  padding-inline: 20px;
  position: relative;
  padding-top: 20px;
  .subscription_plan-grid {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(323px, 2fr));
    margin: 20px 7px 0 7px;
    gap: 28px;
    align-content: stretch;
    @media only screen and (max-width: 560px) {
      display: block;
      margin: 15px;
    }
  }
`;

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.darkbg};
  border-radius: 15px;
  padding: 15px;
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
  & .heading {
    text-align: left;
    margin-bottom: 0;
  }
  & .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;
