import React from 'react'
import styled from 'styled-components'
import ImageGenerate from '../../../MiniSidebar/NewSidebar/Components/CreateImage/Components/ImageGenerate/ImageGenerate'

const ImageGenerationModal = ({ open }) => {
    return (
      <Container
        style={{
          display: open ? '' : 'none'
        }}
      >
            <Modal>
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <ImageGenerate showButton={false} />
                    </div>
                </div>
            </Modal>
      </Container>
    )
}

export default ImageGenerationModal

const Modal = styled.div`
  /* --bs-modal-width: 350px; */
  z-index: 10px;
  color: white;
  width: 100%;
  padding: 10px 20px 20px 20px;
  position: absolute;
  top: 0;
  backdrop-filter: blur(8px);
  .modal-dialog .modal-content {
    background: var(--darkgrey);
    border-radius: 15px;
    height: 88vh;
    width: 100%;
  }
  .modal-dialog .modal-content .heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: var(--primary);
    opacity: 1;
    width: 100%;
    
    /* margin-bottom: 0; */
  }
  
`;

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 10px;
  height: 88vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  background-color: #1e1e1e99;
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
`;