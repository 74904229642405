import React, { useContext, useState } from 'react'
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import assets from 'assets';
import { modes } from 'app.config';
import User from '../../../Components/User/User';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import ConfirmationModel from 'container/Dialogs/ConfirmationModel/ConfirmationModel';
import SaveDocument from 'container/Dialogs/SaveDocument/SaveDocument';
import { saveDocument } from 'container/api';
import { EditorContext } from 'container/context/editorContext';

const MobileSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logoutModal, setLogoutModal] = useState(false)
    const { isShowMobileSidebar } = useSelector(state => state.chat);
    const mode = useSelector(state => state.utils.mode);
    const [shouldSave, setShouldSave] = useState(false);
    const [docLoading, setDocLoading] = useState(false);
    const selectedDoc = useSelector(state => state.documents.selected);
    const user = useSelector(state => state.auth.user);
    const editor = useContext(EditorContext);


    const handleClick = (name) => {

        if (!selectedDoc && editor.getText().length > 0) {
            setShouldSave(true);
        } else {
            dispatch.utils.setMode(modes[name])
            dispatch.chat.setIsShowMobileSidebar(false)
            dispatch.chat.setMobileScreen('sidebar')
        }
    }

    const logout = () => {
        localStorage.clear();
        navigate('/sign-in');
        Cookies.remove('chat-model');
        window.location.reload();
    };

    const handleSaveDocument = async (title) => {
        try {
            setDocLoading(true);
            if (!selectedDoc && editor.getText().length) {
                const data = {
                    user: user.id,
                    title: title || 'Untitled',
                    body: editor.getHTML(),
                };

                await saveDocument(data);
                dispatch.outliner.reset();
                // dispatch.documents.setSelected(selectedconversation);
                editor.commands.setContent('');
                editor.chain().focus().run();
                dispatch.documents.getDocuments();
            } else {
                dispatch.documents.updateDocument({
                    body: editor.getHTML(),
                    showToast: false,
                });
            }
        } catch (err) {
            console.log(err.message);
        } finally {
            setShouldSave(false);
            setDocLoading(false);
        }
    };
    return (
        <Container
            style={{
                opacity: isShowMobileSidebar ? '1' : "0",
                transition: '0.4s ease',
                zIndex: isShowMobileSidebar ? '99' : '-1'
            }}
        >

            <Top>
                <div className='title'>
                    <span>Modes</span>
                    <img
                        className='cursor-pointer'
                        src={assets.close}
                        width={30}
                        height={30}
                        alt='close'
                        onClick={() => {
                            dispatch.chat.setIsShowMobileSidebar(false)
                        }}
                    />
                </div>

                <Menu
                    onClick={() => handleClick('chat')}
                    className={`${mode === modes.chat && 'active '} `}
                >
                    <div className='flex gap-2 align-items-center'>
                        <div className='img'>
                            <img width={20} height={20} src={assets.chatIcon} alt='' />
                        </div>
                        <span>Chat</span>
                    </div>

                    <div className='arrow'>
                        <img src={assets.arrowRightMobile} alt='arrow' />
                    </div>
                </Menu>

                <Menu
                    onClick={() => handleClick('document')}
                    className={`${mode === modes.document && 'active '} `}
                >
                    <div className='flex gap-2 align-items-center'>
                        <div className='img'>
                            <img width={20} height={20} src={assets.docAiIcon} alt='' />
                        </div>
                        <span>Doc AI</span>
                    </div>

                    <div className='arrow'>
                        <img src={assets.arrowRightMobile} alt='arrow' />
                    </div>
                </Menu>

                <Menu
                    onClick={() => handleClick('image')}
                    className={`${mode === modes.image && 'active '} `}
                >
                    <div className='flex gap-2 align-items-center'>
                        <div className='img'>
                            <img width={20} height={20} src={assets.createIcon} alt='' />
                        </div>
                        <span>Create</span>
                    </div>

                    <div className='arrow'>
                        <img src={assets.arrowRightMobile} alt='arrow' />
                    </div>
                </Menu>


            </Top>

            <Bottom>
                <User isMobile={true} setLogoutModal={setLogoutModal} />
            </Bottom>

            <ConfirmationModel
                open={logoutModal}
                setOpen={() => setLogoutModal(!logoutModal)}
                action={() => logout()}
                descripiton='Are you sure you want to logout?'
                btnText='Logout'
            />

            {shouldSave && (
                <SaveDocument
                    open={shouldSave}
                    setOpen={setShouldSave}
                    onSave={handleSaveDocument}
                    docLoading={docLoading}
                // onCancel={OverriteDocument}
                />
            )}
        </Container>
    )
}

export default MobileSidebar

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background-color: #2E2F33;
    top: 0;
    left: 0;
    padding: 20px;
    

    & .cursor-pointer{
        cursor: pointer;
    }

    & .title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & span {
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: white;
            font-family: Poppins;
        }
    }

    & .active {
        background-color: #4D4F56;

        & .img{
            background-color: var(--red);
            width: 34px;
            height: 34px;
            border-radius: 10px;
            box-sizing: content-box;
        }
    }
`

const Top = styled.div`

    display: flex;
    flex-direction: column;
    gap: 10px;
`

const Bottom = styled.div`

    display: flex;
    flex-direction: column;
    cursor: pointer;
`

const Menu = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;

    & .img{
        /* background-color: transparent; */
        width: 34px;
        height: 34px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        }

    & span{
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: var(--primary);
        font-family: Poppins;
    }
`
