import React, { useEffect, useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { giftOptions, stripePublicKey } from 'app.config';

import Select from 'container/components/Select/Select';
import TextField from 'container/components/TextField/TextField';

import styled from 'styled-components';
import StripeForm from './StripeForm/StripeForm';
import theme from 'container/theme';
import { useSelector } from 'react-redux';

const stripePromise = loadStripe(stripePublicKey);

const Gifts = () => {
  const user = useSelector(state => state.auth.user);

  const [data, setData] = useState({
    email: user?.email || '',
    f_email: '',
    first_name: '',
    last_name: '',
    duration: giftOptions[0].value,
    note: '',
  });

  const [dataErrors, setDataErrors] = useState(null);

  const handleChange = e => {
    const { name, value } = e.currentTarget;
    setData(prev => ({ ...prev, [name]: value }));
    setDataErrors(prev => ({ ...prev, [name]: null }));
  };

  useEffect(() => {
    if (user) {
      setData(prev => ({ ...prev, email: user?.email || '' }));
    }
  }, [user]);

  return (
    <Container>
      <div className='flex-row space-between'>
        <div>
          <p className='font-size-24'>Send a Gift</p>
          <p className='font-size-12 desc'>
            Select a package to gift to your friends and family.
          </p>
        </div>
        {/* <Link to='/settings/gifts/history'>
          <CustomButton label='History' />
        </Link> */}
      </div>
      <Form>
        <Grid>
          <TextField
            label='Your Email'
            type='email'
            placeholder='Enter your  email'
            value={data.email}
            name='email'
            onChange={handleChange}
            error={dataErrors?.email}
          />
          <TextField
            label='Receivers’s Email'
            type='email'
            placeholder="Enter your friend's email"
            name='f_email'
            value={data.f_email}
            onChange={handleChange}
            error={dataErrors?.f_email}
          />
        </Grid>
        <Grid>
          <TextField
            label='Receivers’s First Name'
            type='text'
            placeholder="Enter your friend's email"
            name='first_name'
            value={data.first_name}
            onChange={handleChange}
            error={dataErrors?.first_name}
          />
          <TextField
            label='Receivers’s Last Name'
            type='text'
            placeholder="Enter your friend's email"
            name='last_name'
            value={data.last_name}
            onChange={handleChange}
            error={dataErrors?.last_name}
          />
        </Grid>

        <SelectContainer>
          <p className='font-size-14'>Select Duration</p>
          <Select
            value={data.duration}
            name='duration'
            onChange={handleChange}
            error={dataErrors?.duration}
            options={giftOptions}
          />
        </SelectContainer>
        <Elements
          stripe={stripePromise}
          options={{
            mode: 'payment',
            currency: 'usd',
            amount: 90,
            appearance: {
              theme: 'stripe',
              variables: {
                colorText: theme.colors.primary,
                colorBackground: theme.colors.darkgray,
                borderRadius: '7px',
              },
            },
          }}
        >
          <StripeForm
            data={data}
            setData={setData}
            setDataErrors={setDataErrors}
            handleChange={handleChange}
          />
        </Elements>
      </Form>
    </Container>
  );
};

export default Gifts;

const Container = styled.div`
  & p {
    margin: 0px;

    &.desc {
      margin-top: 2px;
    }
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 600px;
  width: 100%;
  margin-inline: auto;
  margin-top: 50px;
  gap: 24px;

  & p.label {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
  }

  & .select-container {
    height: 45px;
    border-radius: 8px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const SelectContainer = styled.div`
  & p {
    margin-bottom: 10px;
  }
`;
