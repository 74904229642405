import { createModel } from '@rematch/core';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getPlan, subscribeUser, unSubscribeUser } from 'container/api';
// import { coupons } from 'app.config';

export const subscription = createModel()({
  name: 'subscription',
  state: {
    isLoggedIn: !!localStorage.getItem('token') || false,
    loading: false,
    planId: '',
    planData: null,
    planLoading: false,
    hasCard: false,
    couponVerified: false,
    couponCode: '',
    card: null,
  },
  reducers: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setPlanId(state, payload) {
      state.planId = payload;
    },
    setPlanData(state, payload) {
      state.planData = payload;
    },
    setPlanLoading(state, payload) {
      state.planLoading = payload;
    },
    setHasCard(state, payload) {
      state.hasCard = payload;
    },
    setCouponVerified(state, payload) {
      state.couponVerified = payload;
    },
    setCouponCode(state, payload) {
      state.couponCode = payload;
    },
    setCard(state, payload) {
      state.card = payload;
    },
  },
  effects: dispatch => ({
    async getPlanData() {
      try {
        dispatch.subscription.setPlanLoading(true);
        const { data } = await getPlan();
        dispatch.subscription.setPlanData(data?.data);
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.subscription.setPlanLoading(false);
      }
    },
    async handleSubscribe(payload) {
      try {
        // dispatch.auth.setLoading(true);
        const { data } = await subscribeUser(payload.data);
        if (data.status === 200) {
          window.location.href = data.redirect_url;
        } else {
          toast.error('Unable to subscribe.');
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          console.log('errorMessage', errorMessage);
          toast.error('Unable to subscribe.');
        }
      } finally {
        dispatch.subscription.setLoading(false);
      }
    },
    async handleUnsubscribeUser(payload) {
      try {
        dispatch.subscription.setLoading(true);
        const { data } = await unSubscribeUser(payload);
        if (data.status === 500) {
          toast.error('Unable to Unsubscribe.');
        } else {
          toast.success('Unsubscribed Successfully');
          window.dataLayer.push({
            event: 'custom',
            eventName: 'successfully_down_grade_plan',
            eventCategory: 'Chat-AI Free',
            planPrice: '0',
            location: 'upgrade_profile',
          });
        }
        dispatch.subscription.getPlanData();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          toast.error('Unable to Un Subscribe.');
          console.log(errorMessage);
        }
      } finally {
        dispatch.subscription.setLoading(false);
        payload.model(false);
      }
    },
  }),
});
