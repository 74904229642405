import assets from 'assets';
import React from 'react';
import styled from 'styled-components';

const ImagePicker = ({ image, setImage }) => {
  const handleChange = e => {
    setImage(e.target.files[0]);
  };
  return (
    <Container>
      <label htmlFor='image-picker' className='label'>
        <input
          type='file'
          name=''
          id='image-picker'
          style={{ display: 'none' }}
          onChange={handleChange}
        />
        <img
          src={
            image && typeof image === 'object'
              ? URL.createObjectURL(image)
              : image
              ? image
              : assets.userAvatar2
          }
          alt=''
        />
        <UploadBtn htmlFor='image-picker' className='btn'>
          <img src={assets.profileUpdate} alt='' className='upload-btn' />
        </UploadBtn>
      </label>
    </Container>
  );
};

export default ImagePicker;

const Container = styled.div`
  display: flex;
  justify-content: center;
  & .label {
    position: relative;
    cursor: pointer;
    & img {
      height: 104px;
      aspect-ratio: 1/1;
      background-color: grey;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

const UploadBtn = styled.label`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 30px;
  width: 30px;
  border: none;
  background-color: #202124;
  border-radius: 50%;
  display: grid;
  place-items: center;
  padding: 0px;
  &:hover {
    background-color: #202124;
  }
  & img {
    width: 11px;
    height: 12px !important;
    mix-blend-mode: hard-light;
    border-radius: 0px !important;
  }
`;
