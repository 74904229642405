import assets from 'assets'
import React from 'react'
import styled from 'styled-components'

const Slide1 = () => {
    return (
        <Container>
            <div className='logo'>
                <img src={assets.logoWithText} alt='' />
            </div>

            <div
                style={{
                    marginTop: '30px',
                    marginBottom: '35px',
                    textAlign: 'center'
                }}
            >
                <img src={assets.laptop} width={284} height={250} alt='laptop' />
            </div>

            <div className='text-body'>
                <span className='tool-text'>#1 All-Purpose AI Tool</span>
                <span className='trail-text'>Get 10 Free Prompts</span>
                <span className='credit-heading'>No credit card required</span>
            </div>

            <div className='checkbox-list'>
                <span className='title'>What’s included in your Free account</span>

                <div className='tick-with-text'>
                    <img src={assets.tickWithGreen} width={24} height={24} alt='' />
                    <span>Full access to Text, Image, and Document generation</span>
                </div>

                <div className='tick-with-text'>
                    <img src={assets.tickWithGreen} width={24} height={24} alt='' />
                    <span>ChatGPT, Stable Diffusion, Cohere, and other LLM's</span>
                </div>

                <div className='tick-with-text'>
                    <img src={assets.tickWithGreen} width={24} height={24} alt='' />
                    <span>⁠Chat on web or WhatsApp</span>
                </div>
            </div>
        </Container>
    )
}

export default Slide1

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1000px) {

    
  }
`