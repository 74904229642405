import React from "react";

function Spinner() {
  return (
    <div className="wrap-btn-spinner">
      <div
        className="spinner-border spinner-border-sm spinner-sizing"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default Spinner;
