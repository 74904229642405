import React, { useState, useContext, useEffect, useRef } from 'react';

import styled from 'styled-components';
import { EditorContext } from 'container/context/editorContext';
import assets from 'assets';
import ClickAwayListener from 'react-click-away-listener';
import classNames from 'classnames';

const FontMenu = () => {
  const editor = useContext(EditorContext);
  const [active, setActive] = useState('Heading 1');
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const menu = useRef(null);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleClick = val => {
    switch (val) {
      case 'Title':
        editor.chain().focus().setHeading({ level: 1 }).run();
        break;
      case 'Subtitle':
        editor.chain().focus().setHeading({ level: 2 }).run();
        break;
      case 'Heading 1':
        editor.chain().focus().setHeading({ level: 3 }).run();
        break;
      case 'Heading 2':
        editor.chain().focus().setHeading({ level: 4 }).run();
        break;
      case 'Body':
        editor.chain().focus().setParagraph().run();
        break;
      default:
        break;
    }
    setActive(val);
  };

  const handleHeadingLevel = level => {
    switch (level) {
      case 1:
        setActive('Title');
        break;
      case 2:
        setActive('Subtitle');
        break;
      case 3:
        setActive('Heading 1');
        break;
      case 4:
        setActive('Heading 2');
        break;
      default:
        setActive('Body');
    }
  };

  useEffect(() => {
    editor.on('selectionUpdate', ({ editor }) => {
      handleHeadingLevel(editor?.getAttributes('heading')?.level);
    });
    // eslint-disable-next-line
  }, [editor]);

  useEffect(() => {
    if (open) {
      const boundries = ref.current.getBoundingClientRect();
      const menuBoundries = menu.current.getBoundingClientRect();
      if (boundries.top + menuBoundries.height + 20 > window.innerHeight) {
        menu.current.style.bottom = `0px`;
        menu.current.style.transform = 'translateY(-40px)';
      }
    }
  }, [open]);

  return (
    <Container ref={ref}>
      <div className={classNames('inner-content', open && 'active')}>
        <div className='flex-row' style={{ gap: '5px' }}>
          <p className='font-size-14' style={{ whiteSpace: 'nowrap' }}>
            {active}
          </p>
          <div className='menu-opener' role='button' onClick={handleToggleMenu}>
            <img
              src={assets.chevronLeft}
              className={classNames('menu-icon', open && 'active')}
              alt=''
            />
          </div>
        </div>
        {open && (
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <Menu ref={menu}>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleClick('Title')}
              >
                <div className='flex-row'>
                  <h1>Title</h1>
                </div>
              </div>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleClick('Subtitle')}
              >
                <div className='flex-row'>
                  <h2>Subtitle</h2>
                </div>
              </div>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleClick('Heading 1')}
              >
                <div className='flex-row'>
                  <h3>Heading 1</h3>
                </div>
              </div>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleClick('Heading 2')}
              >
                <div className='flex-row'>
                  <h4>Heading 2</h4>
                </div>
              </div>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleClick('Body')}
              >
                <div className='flex-row'>
                  <p>body</p>
                </div>
              </div>
            </Menu>
          </ClickAwayListener>
        )}
      </div>
    </Container>
  );
};

export default FontMenu;

const Container = styled.div`
  & .menu-opener {
    cursor: pointer;
    position: relative;
    & img {
      transform: rotate(-90deg);
      padding: 5px;
      transition: 0.4s ease;
      &.active {
        transform: rotate(-270deg);
      }
    }
  }

  & .inner-content {
    position: relative;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    &.active {
      background-color: ${({ theme }) => theme.colors.darkgrey};
      border: 1px solid ${({ theme }) => theme.colors.border};
    }
  }
`;

const Menu = styled.div`
  width: 180px;
  background-color: ${({ theme }) => theme.colors.darkbg};
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateY(calc(100% + 4px));
  box-shadow: 0px 4px 8px 0px #0000001f;
  padding-block: 4px;
  border-radius: 4px;

  & .menu-item {
    padding: 4px 8px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.darkgrey};
    }

    & .flex-row {
      gap: 4px;
    }
  }
`;
