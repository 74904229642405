import React from 'react';
import styled from 'styled-components';
//
import DialogLayout from '../DialogLayout';
import Spinner from 'container/components/Spinner';
import { useSelector } from 'react-redux';

function ConfirmationModel(props) {
  const {
    open,
    setOpen,
    action,
    descripiton,
    image = '',
    btnText = 'Confirm',
    loading,
    title,
  } = props;

  const loadingState = useSelector(state => state.auth.loading);
  const loadingState2 = useSelector(state => state.subscription.loading);

  return (
    <DialogLayout open={open}>
      <Modal>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            {image && <img src={image} alt={descripiton} />}
            <h1 className='heading'> {title || 'Confirmation'} </h1>
            <p className='text'>
              {descripiton ? descripiton : 'Are you sure you want to do this?'}
            </p>
            <div className='confirm-btn'>
              
              <button
                type='button'
                className='cancel-btn'
                onClick={action && action}
                disabled={loading || loadingState || loadingState2}
                id='confirm'
              >
                {loading || loadingState || loadingState2 ? (
                  <Spinner />
                ) : (
                  btnText
                )}
              </button>

              <button
                className='cancel-btn'
                type='button'
                onClick={setOpen && setOpen}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </DialogLayout>
  );
}

export default ConfirmationModel;
const Modal = styled.div`
  --bs-modal-width: 350px;
  .modal-dialog .modal-content {
    background: var(--darkgrey);
    border-radius: 15px;
    /* min-height: 189px; */
    text-align: center;
  }
  .modal-dialog .modal-content .heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: var(--primary);
    opacity: 1;
    padding-top: 23px;
    margin-bottom: 0;
  }
  .modal-dialog .modal-content img {
    width: 80px;
    height: 80px;
    margin: 35px auto 0 auto;
  }
  .modal-dialog .modal-content .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: var(--primary);
    opacity: 1;
    margin-bottom: 0;
    padding: 13px 45px 0 45px;
  }
  .modal-dialog .modal-content .confirm-btn {
    display: flex;
    gap: 14px;
    margin: 20px;
  }
  .modal-dialog .modal-content .red-btn {
    background: var(--red);
    border-radius: 10px;
    width: 100%;
    height: 44px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--primary);
    opacity: 1;
    border: none;
  }
  .modal-dialog .modal-content .confirm-btn .cancel-btn {
    background: var(--lightgrey);
    border-radius: 10px;
    width: 100%;
    height: 44px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--primary);
    opacity: 1;
    border: none;
  }
  .modal-dialog .modal-content .confirm-btn .delete-btn {
    background: var(--lightgrey);
    border-radius: 10px;
    width: 100%;
    height: 44px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--red);
    opacity: 1;
    border: none;
  }
`;
