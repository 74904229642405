import { createModel } from '@rematch/core';
import { chipMessages } from 'container/api';

export const chips = createModel()({
  name: 'chips',
  state: {
    loading: false,
    chips: null,
    selected: '',
  },
  reducers: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setChips(state, payload) {
      state.chips = payload;
    },
    setSelected(state, payload) {
      state.selected = payload;
    },
  },
  effects: dispatch => ({
    async handleGetChips() {
      try {
        dispatch.chips.setLoading(true);
        const { data } = await chipMessages();
        dispatch.chips.setChips(data.data);
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.chips.setLoading(false);
      }
    },
  }),
});
