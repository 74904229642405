import React from 'react';
import { useSelector } from 'react-redux';

import Accordion from 'container/components/Accordion';
import { ClipLoader } from 'react-spinners';

import CheckIcon from 'assets/icons/CheckIcon';

import { Container } from '../Styles';
import theme from 'container/theme';

function FreeSubscription({ handleClick }) {
  const planData = useSelector(state => state.subscription.planData);
  const loading = useSelector(state => state.subscription.planLoading);
  const planText = planData && planData.plan;
  return (
    <Container>
      <div className='subscription__plan-inner'>
        <div>
          <h1 className='heading'>Chat-AI Free</h1>
          <p className='text subtitle'>Limited prompts</p>
          <p className='text'>
            $0 <span className='text-small'>/mo</span>
          </p>
          <div className='divider'></div>
          <div className='subs__details-grid'>
            <div className='subs-inner'>
              <CheckIcon />
              <p>10 free web prompts per month</p>
            </div>

            <Accordion>
              <div className='subs-inner'>
                <CheckIcon />
                <p>10 free WhatsApp prompts / mo</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>
                  10 Different AI image generation models based on Stable
                  Diffusion
                </p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>6 AI engines inclduing ChatGPT, Stable Diffusion and more</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>Full AI capabilities</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>Advanced Image generation</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>Last 5 chat history</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>Image editing, filters and templates</p>
              </div>
            </Accordion>
          </div>
        </div>
        <div className='btn-head'>
          <button
            type='button'
            className='plan-btn white'
            onClick={handleClick}
            disabled={loading}
          >
            {loading ? (
              <ClipLoader size={15} color={theme.colors.red} />
            ) : (
              <>
                {planText === 'Free' ? 'Current Plan' : 'Switch to Free Plan'}
              </>
            )}
          </button>
        </div>
      </div>
    </Container>
  );
}

export default FreeSubscription;
