import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import assets from 'assets';
import { getConversationByIdPublic } from 'container/api';
import CustomButton from 'container/components/CustomButton/CustomButton';
import { siteURL } from 'app.config';
import theme from 'container/theme';
import Message from '../Home/components/Content/Message';

dayjs.extend(utc);

const SharePrompt = () => {
  const { id } = useParams();

  const [convo, setConvo] = useState(null);
  const [messages, setMessages] = useState([]);

  const handleFetchMessageData = async () => {
    try {
      const { data } = await getConversationByIdPublic(id);
      console.log(data);
      setConvo(data.convo);
      setMessages(data.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetchMessageData();
    }
    // eslint-disable-next-line
  }, [id]);
  return (
    <Container>
      <div className='left side-panel'>
        <LogoContainer>
          <a href='https://chatai.com/' target='_blank' rel='noreferrer'>
            <img src={assets.logo} alt='' />
          </a>
        </LogoContainer>
      </div>
      <div className='message-box'>
        <LogoContainer className='mob'>
          <a href='https://chatai.com/' target='_blank' rel='noreferrer'>
            <img src={assets.logo} alt='' />
          </a>
        </LogoContainer>
        <MessageBox>
          <div className='header'>
            <p className='font-size-14'>Shared Response</p>
            <a href={`${siteURL}/sign-up`} target='_blank' rel='noreferrer'>
              <CustomButton label='Try for free' />
            </a>
          </div>
          <div className='message-content'>
            <p className='font-size-24'>{convo?.title}</p>
            <p className='font-size-14' style={{ opacity: 0.4 }}>
              {dayjs(convo?.created_at).format('MMMM DD, YYYY')}
            </p>
            {messages.map(item => {
              if (item.response_source === 3) {
                return (
                  <React.Fragment key={item.id}>
                    <Message
                      message={item.query}
                      avatar={assets.userAvatar}
                      date={item.created_at}
                      userMsg={true}
                    />
                    <Message
                      message={item.response}
                      type='img'
                      avatar={assets.chatGpt}
                      date={item.created_at}
                      userMsg={true}
                      id={item.id}
                    />
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={item.id}>
                  <Message
                    message={item.query}
                    avatar={assets.userAvatar}
                    userMsg={true}
                    date={item.created_at}
                  />
                  <Message
                    message={item.response}
                    avatar={assets.chatGpt}
                    date={item.created_at}
                    userMsg={true}
                    id={item.id}
                    isLikedMsg={item.is_liked}
                    isDislikedMsg={item.is_disliked}
                  />
                </React.Fragment>
              );
            })}
            <Card>
              <div className='top'>
                <p className='font-size-24'>Experience the future of AI</p>
                <p className='font-size-15'>Get started with 10 free prompts</p>
                <p
                  className='font-size-14 subtitle'
                  style={{ marginTop: '4px' }}
                >
                  Chat or create images using 6 Different AI Models including
                  ChatGPT 3.5, ChatGPT 4, Midjourney, Cohere, and more via web,
                  sms, or whatsapp.
                </p>
              </div>
              <div className='divider'></div>
              <div className='top'>
                <a href={`${siteURL}/sign-up`} target='_blank' rel='noreferrer'>
                  <CustomButton label='Join For Free' />
                </a>
                <p className='font-size-14' style={{ marginTop: '10px' }}>
                  Already a member?{' '}
                  <a
                    href={`${siteURL}/sign-in`}
                    rel='noreferrer'
                    target='_blank'
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                      color: theme.colors.red,
                    }}
                  >
                    Log in
                  </a>
                </p>
              </div>
            </Card>
          </div>
        </MessageBox>
      </div>
      <div className='right side-panel'></div>
    </Container>
  );
};

export default SharePrompt;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkbg};
  min-height: 100vh;
  display: flex;
  align-items: stretch;

  & .side-panel {
    max-width: 330px;
    flex: 1;
    padding-top: 11px;
    padding-inline: 10px;
  }

  & .message-box {
    flex-grow: 1;
    flex: 2;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
  }

  & .mob {
    display: none;
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    & .right {
      width: 100%;
      max-width: 100%;

      & .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    padding-bottom: 20px;
    & .message-box {
      padding: 10px;
      width: 100%;
    }

    & .left {
      flex: 0;
    }
  }

  @media only screen and (max-width: 700px) {
    & .left {
      display: none;
    }
    & .message-box {
      width: 100%;

      & .mob {
        display: block;
        margin-inline: auto;
      }
    }
  }
`;

const LogoContainer = styled.div`
  padding: 18px;
`;

const MessageBox = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgrey};
  flex-grow: 1;
  border-radius: 15px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  & p {
    margin: 0px;
  }

  & .header {
    padding: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey};
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      margin: 0px;
      color: ${({ theme }) => theme.colors.inputLabel};
    }

    & button {
      height: 37px;
    }
  }

  & .message-content {
    padding: 20px 30px;
    flex-grow: 1;
    height: 0px;
    overflow: auto;
  }
  @media only screen and (max-width: 420px) {
    & .message-content {
      padding: 10px 15px;
    }
  }
`;

const Card = styled.div`
  padding: 20px 0px;
  margin-inline: 20px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.colors.lightBlack};
  border: 1px solid ${({ theme }) => theme.colors.lightgrey};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};

  & .top {
    padding-inline: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  & p {
    margin: 0px;
    text-align: center;

    &.subtitle {
      max-width: 60ch;
      text-align: center;
      opacity: 0.4;
    }
  }

  & .divider {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.lightgrey};
    margin-block: 16px;
  }

  & button {
    width: 100%;
    font-size: 15px;
    max-width: 256px;
  }
`;
