import React, { useEffect } from 'react';

import styled from 'styled-components';
import TemplateSelector from '../components/TemplateSelector';
import { useDispatch, useSelector } from 'react-redux';
import Image from '../components/Image';

const ImageSelection = () => {
  const dispatch = useDispatch();

  const { selectedTemplate, templates, selectedImage } = useSelector(
    state => state.imageGeneration
  );

  const handleTemplateSelect = val => {
    dispatch.imageGeneration.setSelectedTemplate(val);
  };

  useEffect(() => {
    dispatch.imageGeneration.setSelectedTemplate('Art');
    // eslint-disable-next-line
  }, []);

  return (
    <Content>
      <p className='font-size-16'>1. Select an Image</p>
      <p className='font-size-10 subtitle'>
        Choose from a list of premade prompts to generate images quickly.
      </p>
      <TemplateSelector
        onSelect={handleTemplateSelect}
        options={Object.keys(templates).map(template => ({
          img: templates[template][0].example_image_url,
          label: template,
        }))}
        selected={{
          img: templates[selectedTemplate || 'Art'][0].example_image_url,
          label: selectedTemplate,
        }}
      />
      <Grid>
        {templates[selectedTemplate || 'Art'].map((template, index) => (
          <Image
            selected={selectedImage?.id === template.id}
            onClick={() => {
              dispatch.imageGeneration.setSelectedImage(template);
              dispatch.imageGeneration.setPromptEdited(false);
            }}
            src={template.example_image_url}
            key={index}
          />
        ))}
      </Grid>
    </Content>
  );
};

export default ImageSelection;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary};
  padding: 15px;
  flex-grow: 1;
  height: 0px;
  overflow: auto;

  & p {
    margin: 0px;

    &.subtitle {
      max-width: 45ch;
      text-align: center;
      opacity: 0.5;
      margin-bottom: 10px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  width: 100%;
  gap: 6px;
  margin-block: 20px;

  & .grid-item {
    aspect-ratio: 1/1;
    border-radius: 4px;
    overflow: hidden;

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;
