import assets from 'assets'
import React from 'react'
import styled from 'styled-components'

const Slide2 = () => {
    return (
        <Container>
            <div className='logo'>
                <img src={assets.logoWithText} alt='' />
            </div>

            <div
                style={{
                    paddingTop: '60px'
                }}
                className='text-body'>
                <span className='tool-text'>#1 All-Purpose AI Tool</span>
                <span className='trail-text'>Chat. Create. Collaborate. </span>
            </div>

            <div className='box'>
                <img src={assets.dummy} className='img' alt='dummy' />

                <div className='user-detail'>
                    <span className='username'>Sarah T</span>
                    <span className='designation'>Marketing Manager</span>
                </div>

                <div className='stars'>
                    <img src={assets.stars5} alt='stars' />
                </div>

                <p className='paragraph'>
                    ChatAI has transformed the way we work. The chat interface is
                    intuitive and engaging, making interactions seamless and
                    enjoyable. Plus, the ability to convert chats into
                    documents with DocAI has been a game-changer for how we
                    create content.
                </p>
            </div>
        </Container>
    )
}

export default Slide2

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`