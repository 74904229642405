import React, { useState, useContext } from 'react';

import DialogLayout from '../DialogLayout';
import { useSelector, useDispatch } from 'react-redux';
import { EditorContext } from 'container/context/editorContext';

import styled from 'styled-components';
import assets from 'assets';
import CustomButton from 'container/components/CustomButton/CustomButton';
import ClickAwayListener from 'react-click-away-listener';
import { modes } from 'app.config';

const AppendDocAttention = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const editor = useContext(EditorContext);
  const [selected, setSelected] = useState('');
  const { documents, selected: selectedDoc } = useSelector(
    state => state.documents
  );
  const { items } = useSelector(state => state.outliner);
  const [openMenu, setOpenMenu] = useState(false);
  const mode = useSelector(state => state.utils.mode);

  const handleSelectDocument = doc => {
    dispatch.documents.setSelected(doc);
    editor.commands.setContent(doc.body);
    setSelected(doc.title);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleContinue = () => {
    let html = '';
    if (selectedDoc) {
      html = editor.getHTML();
    } else {
      html = '';
    }
    if (editor && items?.length) {
      let contentStr = '';

      items.forEach((item, index) => {
        const messageStrings = item.split('\n');

        const allString = messageStrings?.map((str, index) => {
          if (str.length > 0) {
            return `<p>${str}</p>`;
          } else if (index !== messageStrings.length - 1 && str.length > 1) {
            return `<br />`;
          } else {
            return '';
          }
        });
        contentStr += allString?.join('');
      });
      const parser = new DOMParser();
      const newContent = parser.parseFromString(html + contentStr, 'text/html');
      editor.commands.setContent(newContent.body.innerHTML);
      dispatch.outliner.reset();
    }
    if (mode === modes.chat) {
      dispatch.utils.setMode(modes.document);
      dispatch.utils.setOpenOutliner(false);
    }
    setOpen(false);
  };

  return (
    <DialogLayout open={true}>
      <Container>
        <p className='title'>Attention!</p>
        <img src={assets.attention} className='image' alt='' />
        <p className='font-size-16 desc'>
          You have not saved your current document.
          <br />
          <br />
          If you continue you will overwrite your current document and create a
          new document.
        </p>
        <ButtonContainer>
          <div
            className='opener'
            style={{ flex: '1', position: 'relative' }}
            role='button'
            onClick={toggleMenu}
          >
            <CustomButton
              variant='btn-outlined'
              label={selected || 'Select Document'}
              className='btn'
              rightIcon={assets.chevronLeft}
              rightIconStyles={{
                transform: openMenu ? 'rotate(90deg)' : 'rotate(-90deg)',
              }}
              style={{ width: '100%' }}
            />
            {openMenu && (
              <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
                <Menu>
                  {documents.map(doc => (
                    <div
                      className='menu-item'
                      key={doc.id}
                      onClick={() => handleSelectDocument(doc)}
                    >
                      <p className='font-size-12'>{doc.title}</p>
                    </div>
                  ))}
                </Menu>
              </ClickAwayListener>
            )}
          </div>
          <CustomButton
            label={selected ? 'Continue' : 'Create New'}
            onClick={handleContinue}
          />
        </ButtonContainer>
        <div className='close-btn' role='button' onClick={handleClose}>
          <img src={assets.close} alt='' />
        </div>
      </Container>
    </DialogLayout>
  );
};

export default AppendDocAttention;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgrey};
  width: 498px;
  border-radius: 16px;
  overflow: hidden;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & .image {
    margin-block: -30px -10px;
  }

  & .desc {
    text-align: center;
    opacity: 0.7;
  }

  & p {
    color: ${({ theme }) => theme.colors.primary};
    margin: 0px;
    &.title {
      font-size: 36px;
      font-weight: 500;
    }
  }

  & .close-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    right: 14px;
    top: 14px;
    opacity: 0.5;
    cursor: pointer;

    & img {
      width: 25px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  margin-top: 23px;
  & button {
    flex: 1;

    &.btn {
      background-color: ${({ theme }) => theme.colors.lightgrey};
      border: 1px solid ${({ theme }) => theme.colors.lightgrey};
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  transform: translateY(calc(-100% - 10px));
  max-height: 500px;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.darkbg};
  border-radius: 8px;

  & .menu-item {
    padding: 10px 15px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightgrey};
    }
  }
`;
