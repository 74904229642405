import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { EditorContext } from 'container/context/editorContext';

import Accordion from 'container/components/Accordion/Accordion';
import CustomButton from 'container/components/CustomButton/CustomButton';

import assets from 'assets';
import { modes } from 'app.config';
import useMediaQuery from 'container/hooks/useMediaQuery';
import AppendDocAttention from 'container/Dialogs/AppendDocAttention/AppendDocAttention';
import ConvertDocAttention from 'container/Dialogs/ConvertDocAttention/ConvertDocAttention';
import { saveDocument } from 'container/api';

const Outliner = () => {
  const dispatch = useDispatch();
  const { items } = useSelector(state => state.outliner);
  const { user } = useSelector(state => state.auth);
  const isMobile = useMediaQuery('(max-width: 1000px)');
  const mode = useSelector(state => state.utils.mode);
  const [openAppend, setOpenAppend] = useState(false);
  const [openAttention, setOpenAttention] = useState(false);
  const { selected } = useSelector(state => state.documents);

  const editor = useContext(EditorContext);

  const handleCloseOutliner = () => {
    dispatch.utils.setOpenOutliner(false);
  };
  const handleConvertToDoc = () => {
    dispatch.documents.setSelected(null);
    dispatch.utils.setMode(modes.document);
    dispatch.utils.setOpenOutliner(false);
    if (editor && items?.length) {
      let contentStr = '';

      items.forEach((item, index) => {
        const messageStrings = item.split('\n');

        const allString = messageStrings?.map((str, index) => {
          if (str.length > 0) {
            return `<p>${str}</p>`;
          } else if (index !== messageStrings.length - 1 && str.length > 1) {
            return `<br />`;
          } else {
            return '';
          }
        });
        contentStr += allString?.join('');
      });
      editor.commands.setContent(contentStr);
      dispatch.outliner.reset();
    }
  };

  const handleAppendToCurrentDoc = () => {
    if (!selected && editor.getText().length === 0) {
      setOpenAppend(true);
      dispatch.documents.setShouldUpdate(false);
    } else {
      const html = editor.getHTML();
      if (editor && items?.length) {
        let contentStr = '';

        items.forEach((item, index) => {
          const messageStrings = item.split('\n');

          const allString = messageStrings?.map((str, index) => {
            if (str.length > 0) {
              return `<p>${str}</p>`;
            } else if (index !== messageStrings.length - 1 && str.length > 1) {
              return `<br />`;
            } else {
              return '';
            }
          });
          contentStr += allString?.join('');
        });
        dispatch.documents.setShouldUpdate(false);

        editor.commands.setContent(html + contentStr);
        dispatch.outliner.reset();
      }
      if (mode === modes.chat) {
        dispatch.utils.setMode(modes.document);
      }
      dispatch.utils.setOpenOutliner(false);
      dispatch.documents.setSelected(null)
    }
  };

  const handleConvert = () => {
    if (!selected && editor.getText().length > 0) {
      setOpenAttention(true);
    } else {
      handleSaveDocument();
    }
  };

  const handleSaveDocument = async () => {
    try {
      if (!selected && editor.getText().length) {
        const data = {
          user: user.id,
          title: 'Untitled Document',
          body: editor.getHTML(),
        };

        await saveDocument(data);
        dispatch.outliner.reset();
        dispatch.documents.setSelected(null);
        editor.commands.setContent('');
        editor.chain().focus().run();
        dispatch.documents.getDocuments();
      } else {
        dispatch.documents.updateDocument({
          body: editor.getHTML(),
          showToast: false,
        });
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setOpenAttention(false);
      handleConvertToDoc();
    }
  };

  return (
    <Container>
      <Header>
        <div className='flex-row'>
          <img className='img-outliner' src={assets.outliner} alt='' />
          <p className='font-size-20 outliner-title'>Outliner</p>
        </div>
        {isMobile && (
          <div
            className='clickable'
            role='button'
            onClick={handleCloseOutliner}
          >
            <img src={assets.close} alt='' style={{ width: '40px' }} />
          </div>
        )}
      </Header>
      <OutLinesContainer>
        {items.map((item, index) => (
          <Accordion key={index} index={index} message={item} />
        ))}
      </OutLinesContainer>
      {items.length > 0 && (
        <div className='pad'>
          <CustomButton
            label={
              mode === modes.document
                ? 'Add to Existing Document'
                : 'Create New Document'
            }
            onClick={() => {
              if (mode === modes.document) {
                handleAppendToCurrentDoc();
              } else {
                handleConvert();
              }
            }}
            className={'document-btn'}
          />
          {mode === modes.chat && (
            <CustomButton
              label='Add to Document'
              onClick={handleAppendToCurrentDoc}
              className={'add-document-btn'}
              rightIcon={assets.arrowLeft}
              rightIconClassName={'icon-right-side'}
              
            />
          )}
        </div>
      )}
      {openAppend && (
        <AppendDocAttention open={openAppend} setOpen={setOpenAppend} />
      )}
      {openAttention && (
        <ConvertDocAttention open={openAttention} setOpen={setOpenAttention} />
      )}
      {/* {showWarning && (
        <ConfirmationModel
          open={shouldSaveDoc}
          setOpen={handleClose}
          action={handleSaveDocument}
          descripiton="If you don't save your document your changes will be lost."
          title='Save document'
          btnText='Save'
          loading={docLoading}
        />
      )} */}
    </Container>
  );
};

export default Outliner;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.primary};
  }
  & .pad {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    & button {
      width: 100%;
    }
  }

  & .document-btn{
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    width: 100%;
    max-width: 100% !important;
  }

  & .add-document-btn{
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    background-color: transparent;
    border: 1px solid #4D4F56;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 11px;
    height: 50px;
    width: 100%;
    max-width: 100% !important;
  }

  & .icon-right-side{
    transform: rotate(180deg);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14.5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  & .flex-row {
    gap: 10px;
  }

  & .outliner-title{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  @media only screen and (max-width: 1000px) {
    .img-outliner {
      width: 30px !important;
      height: 30px !important;
    }
  }
`;

const OutLinesContainer = styled.div`
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  padding: 12px;
`;
