import React, { useState, useContext, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import DialogLayout from '../DialogLayout';
import TextField from 'container/components/TextField/TextField';
import styled from 'styled-components';
import CustomButton from 'container/components/CustomButton/CustomButton';
import { EditorContext } from 'container/context/editorContext';
import toast from 'react-hot-toast';
import { getAllDocuments, saveDocument } from 'container/api';
import assets from 'assets';
import useMediaQuery from 'container/hooks/useMediaQuery';

const SaveDocument = ({
  open,
  setOpen,
  docTitle,
  onSave,
  docLoading,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const editor = useContext(EditorContext);
  const isDesktop = useMediaQuery('(max-width: 1000px)');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.auth.user);
  const [error, setError] = useState('');

  const handleChange = e => {
    setTitle(e.target.value);
  };

  const handleClose = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSaveDocument = async () => {
    setError('');
    if (title.trim().length < 3) {
      return setError('Title should be atleast 3 characters');
    }
    try {
      setLoading(true);
      if (typeof onSave === 'function') {
        await onSave(title);
      } else {
        const data = {
          user: user.id,
          title: title,
          body: editor.getHTML(),
        };
        debugger
        editor.commands.setContent('');
        dispatch.documents.setSelected(null);
        await saveDocument(data);
        const { data: res } = await getAllDocuments();
        dispatch.documents.setDocuments(res.data);
        dispatch.documents.setSelected(res.data[0]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    console.log({ open, setOpen, docTitle });
    if (docTitle) {
      setTitle(docTitle);
    }
    // eslint-disable-next-line
  }, [docTitle]);

  const width = isDesktop ? '100%' : '498px'

  return (
    <DialogLayout open={open} width={width}>
      <Content>
        <div className='header'>
          <p className='font-size-24'>Save Document</p>
        </div>
        <div className='body'>
          <TextField
            label='Name'
            value={title}
            onChange={handleChange}
            error={error}
            style={{
              width: isDesktop ? '100%' : '450px',
              height: '50px'
            }}
          />
          <div className='btn-container'>
            <CustomButton
              variant='btn-outlined border-none'
              label='Cancel'
              onClick={handleClose}
              className={'cancel-btn'}
            // loading={loading || docLoading}
            />
            <CustomButton
              loading={loading || docLoading}
              label='Save'
              onClick={handleSaveDocument}
              className={'save-btn'}
            />
          </div>
        </div>
        <div className='close-btn' role='button' onClick={handleCloseModal}>
          <img src={assets.close} alt='' />
        </div>
      </Content>
    </DialogLayout>
  );
};

export default SaveDocument;

const Content = styled.div`
  width: 498px;
  height: 276px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.darkgrey};
  border: 1px solid ${({ theme }) => theme.colors.border + '66'};

  @media only screen and (max-width: 1000px) {
    width: 90%;
    margin: 0px 20px;

    & .save-btn{
      width: 100% !important;
  }
  }
  position: relative;

  & .save-btn{
      width: 268px;
      height: 50px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
  }

  & .cancel-btn{
    height: 50px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  & .header {
    padding: 12px 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border + '66'};

    & p {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
  }

  & .border-none{
    border: none !important;
  }

  & .body {
    padding: 24px;

    & .label {
      color: ${({ theme }) => theme.colors.primary};
      margin-bottom: 10px;
    }

    & .btn-container {
      display: grid;
      grid-template-columns: 2fr 3fr;
      margin-top: 32px;
      gap: 10px;
    }
  }

  & p {
    margin: 0px;
  }
  & .close-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: absolute;
    right: 14px;
    top: 14px;
    opacity: 0.5;
    cursor: pointer;

    & img {
      width: 25px;
    }
  }

  @media only screen and (max-width: 1000px) {
    & .header {
      & p {
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        font-family: Poppins;
      }
    } 

    & .save-btn{
        height: 42px;
    }

    & .cancel-btn{
      height: 42px;
      font-weight: Poppins !important;
      font-size: 16px !important;
    }
  }
`;
