import assets from 'assets';
import React from 'react';

import styled from 'styled-components';

const Stepper = ({ step }) => {
  return (
    <Container step={step}>
      <div className='completion'></div>
      <div className='checks-container'>
        {Array.from({ length: 4 }, (_, i) => i + 1).map(i => (
          <div className={`check ${step === i ? 'current' : ''}`} key={i}>
            {step === i && <div className='dot'></div>}
            {step > i && (
              <div className='dot complete'>
                <img src={assets.check} alt='' />
              </div>
            )}
            {step < i && i}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Stepper;

const Container = styled.div`
  width: 70%;
  max-width: 500px;
  height: 7px;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  border-radius: 10px;
  position: relative;

  & .completion {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: ${({ theme }) => theme.colors.red};
    height: 100%;
    width: ${({ step }) => (step - 1) * (100 / 3)}%;
    transition: 0.4s ease;
  }

  & .checks-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;

    & .check {
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => theme.colors.lightgrey};
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: ${({ theme }) => theme.colors.primary};

      & .dot {
        height: 16px;
        width: 16px;
        background-color: ${({ theme }) => theme.colors.red};
        border-radius: 50%;
        &.complete {
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
        }
      }
    }
  }
`;
