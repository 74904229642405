import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import styled from 'styled-components';
//
import ErrorMessage from 'container/components/ErrorMessage';
import Input from 'container/components/Input';
import Spinner from 'container/components/Spinner';
import Button from 'container/components/Button';
import FullLogo from 'container/components/FullLogo';

function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(state => state.auth.loading);
  const signUpData = useSelector(state => state.auth.signUpData);

  const EmailSchema = Yup.object().shape({
    otp: Yup.number()
      .typeError('Please enter a valid number')
      .required('Number is required')
      .test(
        'is-length',
        'Must have 6-digits code.',
        value => value.toString().length === 6
      ),
  });
  const handleSubmit = async (values, { setSubmitting, setErrors }, errors) => {
    if (values.otp.length) {
      dispatch.auth.handleVerifyOTP({
        otp: values.otp,
        navigate,
        setSubmitting,
        setErrors,
      });
    } else {
      setErrors({ otp: 'Invalid OTP' });
    }
  };

  const handleResendOTP = () => {
    const email = localStorage.getItem('email');
    dispatch.auth.handleGenerateOTP(email);
  };

  if (!signUpData) {
    return <Navigate to='/sign-up' replace={true} />;
  }

  return (
    <Container>
      {/* Logo */}
      <div className='sign__up-logo mb-4'>
        <FullLogo />
      </div>
      <div className='sign__up-box pt-4 mb-4 pb-2'>
        <h1 className='account-heading'>Verify Your Email</h1>
        <p
          className='have-account pt-2 m-0'
          style={{ textAlign: 'left', paddingLeft: '10px' }}
        >
          Please check your email and enter your OTP to create your account.
        </p>
        <div className='input-box mt-3'>
          <Formik
            initialValues={{
              email: localStorage.getItem('email') || '',
              otp: '',
            }}
            validationSchema={EmailSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  type='text'
                  name='otp'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.otp}
                  className='input-email mb-3'
                  placeholder='Enter 6 digits OTP'
                  required
                  id='otp'
                />
                <ErrorMessage error={errors.otp && touched.otp && errors.otp} />
                <p className='account-password m-0' onClick={handleResendOTP}>
                  <span>Resend Email</span>
                </p>
                <Button
                  text={loading ? <Spinner /> : 'Create My Account'}
                  className='confirm-details'
                  type='submit'
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  id='otp-btn'
                />
              </form>
            )}
          </Formik>
        </div>
      </div>{' '}
    </Container>
  );
}

export default VerifyEmail;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .input-box {
    margin: 0 10px;
    & .input-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--primary);
      opacity: 0.5;
      margin-bottom: 0;
      padding-bottom: 5px;
      float: left;
    }
  }

  .input-box .confirm-details {
    background: var(--red);
    border-radius: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
    opacity: 1;
    border: none;
    height: 45px;
    width: 100%;
    margin-top: 13px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 520px) {
    margin: 0 10px;
  }
`;
