import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Accordion from 'container/components/Accordion';

import { Container } from '../Styles';
import CheckIcon from 'assets/icons/CheckIcon';
import { ClipLoader } from 'react-spinners';
import theme from 'container/theme';
import {
  LATEST_RANDOM,
  PLAN_20,
  PLAN_30,
  PLAN_40,
  PLAN_50,
  RANDOM_1,
  abandon5,
  abandon7,
  dynamicCoupons,
  welcome_discount_code,
} from 'app.config';
import { useSearchParams } from 'react-router-dom';
import assets from 'assets';

function YearlySubscription({ handlePlan }) {
  const [price, setPrice] = useState(90);
  const [params] = useSearchParams();
  const [description, setDescription] = useState('')
  const discountCode = params.get('discount_code');

  const planData = useSelector(state => state.subscription.planData);
  const loading = useSelector(state => state.subscription.planLoading);
  const planText = planData && planData.plan;

  useEffect(() => {
    setDescription("");
    if (planText?.toLowerCase() === 'free') {
      if (discountCode === welcome_discount_code) {
        setPrice(70);
      } else if (discountCode === abandon5) {
        setPrice(50);
      } else if (discountCode === abandon7) {
        setPrice(70);
      } else if (discountCode === PLAN_20) {
        setPrice(72);
      } else if (discountCode === PLAN_30) {
        setPrice(63);
      } else if (discountCode === PLAN_40) {
        setPrice(54);
      } else if (discountCode === PLAN_50) {
        setPrice(45);
      } else if (discountCode === RANDOM_1) {
        setPrice(12);
      } else if (discountCode === LATEST_RANDOM) {
        setPrice(78);
      } else {
        const findCoupon = dynamicCoupons.find(x => x.uuid.trim() === discountCode?.trim() && x.plan_id === 2);
        if (findCoupon) {
          setPrice(findCoupon.final_price)
          setDescription(findCoupon.description);
        } else
          setPrice(90);
      }
    }
    // eslint-disable-next-line
  }, [discountCode, planData, welcome_discount_code]);

  return (
    <Container>
      <div className='subscription__plan-inner red'>
        <h1 className='heading'>Chat-AI Pro</h1>
        <p className='text subtitle'>More advanced AI capabilities</p>
        {description && <p className='text subtitle'>{description}</p>}
        <p className='text'>
          {price < 90 && planData?.plan?.toLowerCase() === 'free' && (
            <WasPrice className='was'>
              <img src={assets[90]} alt='' />
            </WasPrice>
          )}{' '}
          ${price}
          <span className='text-small'>/yr</span>
        </p>
        <div className='divider' style={{ borderTop: '1px solid #FF666F' }} />
        <div className='subs__details-grid'>
          <div className='subs-inner'>
            <CheckIcon />
            <p>Unlimited prompts ( Web )</p>
          </div>
          <Accordion>
            <div className='subs-inner'>
              <CheckIcon />
              <p>6 AI engines including ChatGPT, Stable Diffusion and more</p>
            </div>
            <div className='subs-inner'>
              <CheckIcon />
              <p>
                10 Different AI image generation models based on Stable
                Diffusion
              </p>
            </div>
            
            
            <div className='subs-inner'>
              <CheckIcon />
              <p>New DocAI: AI powered content creation and collaboration tool</p>
            </div>
            <div className='subs-inner'>
              <CheckIcon />
              <p>Full AI Capabilities</p>
            </div>
            <div className='subs-inner'>
              <CheckIcon />
              <p>Advanced Image generation</p>
            </div>
            <div className='subs-inner'>
              <CheckIcon />
              <p>Unlimited chat history</p>
            </div>
            <div className='subs-inner'>
              <CheckIcon />
              <p>Image editing, filters and templates</p>
            </div>
            <div className='subs-inner'>
              <CheckIcon />
              <p>First access to new AI models and ChatAI product upgrades</p>
            </div>
            
            <br />
            <br />
            <br />
          </Accordion>
        </div>
        <div className='btn-head'>
          <button
            onClick={() => {
              // if (planData?.plan?.toLowerCase() === 'free') {
                window.dataLayer.push({
                  'event': 'custom',
                  'eventName': 'upgrade_select_plan',
                  'eventCategory': 'Chat-AI Pro', //dynamic value of plan name eg. Chat-AI Free
                  'planPrice': price, //dynamic plan value eg. 0
                  'Location': 'upgrade_profile', //eg. upgrade_ui, upgrade_profile
                });
              // }
              
              if (planText === 'year') {
                return;
              } else {
                handlePlan('2');
                let item = {
                  plan_name: 'pro_plan',
                  Price: '$90',
                };
                window?.klaviyo.push(['track', 'Viewed Product', item]);
              }
            }}
            id='buy-annual-plan'
            type='submit'
            className='plan-btn'
            disabled={loading}
          >
            {loading ? (
              <ClipLoader size={15} color={theme.colors.red} />
            ) : (
              <>
                {planText === 'year'
                  ? 'Your Current Plan'
                  : planText === 'month'
                  ? 'Upgrade Plan'
                  : 'Select Plan'}
              </>
            )}
          </button>
        </div>
      </div>
    </Container>
  );
}

export default YearlySubscription;

const WasPrice = styled.span`
  opacity: 0.4;
  position: relative;
  font-weight: 400;

  & img {
    width: 75px;
  }
`;
