import assets from 'assets';
import React, { useState } from 'react'
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import dayjs from 'dayjs';
import theme from 'container/theme';
import { get_image } from 'container/api';
import { useDispatch, useSelector } from 'react-redux';
import { delete_image } from '../../../../../../../../../api';
import ConfirmationModel from 'container/Dialogs/ConfirmationModel/ConfirmationModel';

const ConversationList = ({ conversation, imageHistory }) => {
    const dispatch = useDispatch()
    // const [edit, setEdit] = useState(false);
    const [selectedConvo, setSelectedConvo] = useState(null);
    const loadingState = useSelector(state => state.chat.loading);
    const edit = false;
    const { selectedImage } = useSelector(state => state.chat)
    const [clickedOnDelete, setClickedOnDelete] = useState(false)
    const { model, filter } = useSelector(state => state.imageGeneration);
    // const [editTitle, setEditTitle] = useState('');
    // const [title, setTitle] = useState('');

    const handleOnUpdateTitle = () => { }

    const handleCancel = () => {
        // setEditTitle(title);
        // setEdit(false);
    };

    // const handleOnEdit = () => {
    //     setEdit(!edit);
    // };

    const handleGetImage = async(id) => {

        const res = await get_image(id);

        if (res.data.status === 200) {
            dispatch.chat.setMessages([res.data.data])
        }
    }

    const handleDeleteImage = async (id) => {
        
        const res = await delete_image(id);

        if (res.data.status === 200) {
            dispatch.imageGeneration.get_image_history({ model, filter });

            if (selectedImage.id === id) {
                dispatch.chat.setMessages([])
                dispatch.chat.setSelectedImage(null)
                dispatch.chat.setSelectedConversation(null)

            }
        }
    }

    // useEffect(() => {
    //     let label = '';
    //     if (conversation.label?.includes('/imagine')) {
    //         label = conversation.label?.replace('/imagine', '');
    //     } else {
    //         label = conversation.prompt;
    //     }
    //     // setTitle(label);
    //     // setEditTitle(label);
    //     // eslint-disable-next-line
    // }, [conversation]);


    return (
        <Container
            // className='active'
            className={`cursor-pointer mobile-conversation ${selectedImage?.id === conversation.id && 'active'}`}
            onClick={(event) => {

                if (!clickedOnDelete) {
                    handleGetImage(conversation.id)
                    dispatch.chat.setSelectedImage(conversation)
                    dispatch.chat.setSelectedConversation(null)
                    dispatch.chat.setMobileScreen('chat')
                } else {
                    setClickedOnDelete(false)
                }
                

            }}
        >
            <div>
                <img
                    src={conversation.image}
                    width={48}
                    height={48}
                    alt=''
                />
            </div>

            <div className='flex-grow-1 text-area'>
                <div className='flex-row'>
                    {/* {edit ? (
                        <TextBox
                            rows={2}
                            value={editTitle}
                            onChange={e => setEditTitle(e.currentTarget.value)}
                            onKeyDown={e => e.key === 'Enter' && handleOnUpdateTitle(e)}
                        ></TextBox>
                    ) : ( */}
                    <p className='q-title font-size-14'>{conversation.prompt}</p>
                    {/* // )} */}

                    <div className='flex-row second' style={{ gap: edit ? '12px' : '6px' }}>
                        {edit && (
                            <>
                                <div
                                    className='icon-button'
                                    onClick={handleOnUpdateTitle}
                                    role='button'
                                >
                                    <img src={assets.correct} alt='' />
                                </div>
                                <div className='icon-button' onClick={handleCancel} role='button'>
                                    <img src={assets.false} alt='' />
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className='flex-row'>
                    <p className='font-size-10' style={{ opacity: '0.5' }}>
                        {dayjs(conversation.created_at).fromNow()}
                    </p>
                    <div className='flex-row second' style={{ marginTop: '10px' }}>
                        {/* {
                            !edit && (<>
                                <div
                                    className='icon-button delete_icon'
                                    id='edit-btn'
                                    onClick={handleOnEdit}
                                    role='button'
                                >
                                    <img src={assets.magicpen} width={12} height={12} alt='' />
                                </div>
                                <Tooltip
                                    anchorSelect='#edit-btn'
                                    content='Edit'
                                    style={{ zIndex: '100', background: theme.colors.lightgrey }}
                                />
                            </>)
                        } */}


                        {/* <div
                            className='icon-button delete_icon'
                            id='save-btn'
                            onClick={handleOnEdit}
                            role='button'
                        >
                            <img src={assets.Element} width={12} height={12} alt='' />
                        </div>
                        <Tooltip
                            anchorSelect='#save-btn'
                            content='Save'
                            style={{ zIndex: '100', background: theme.colors.lightgrey }}
                        /> */}

                        <div className='icon-button' role='button'>
                            <span id='delete-btn'>
                                <div
                                    className='delete_icon'
                                    // onClick={e => handleDelete(e, conversation.id)}
                                    onClick={e => {
                                        e.stopPropagation();
                                        // handleDeleteImage(conversation.id)
                                        setSelectedConvo(conversation.id);
                                        setClickedOnDelete(true)
                                    }}
                                >
                                    <img src={assets.delete} alt='' />
                                </div>
                            </span>
                            <Tooltip
                                anchorSelect='#delete-btn'
                                content='Delete'
                                place='left'
                                style={{ background: theme.colors.lightgrey }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {selectedConvo && (
                <ConfirmationModel
                    open={selectedConvo}
                    setOpen={() => setSelectedConvo(null)}
                    action={() => handleDeleteImage(selectedConvo)}
                    descripiton='Are you sure you want to delete this Image?'
                    loading={loadingState}
                />
            )}
        </Container>
    )
}

export default ConversationList

const Container = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding: 10px;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #414349;
  width: 100%;

  & .img-div{
    width: 33%;
  }

  @media only screen and (max-width: 1000px) {
    & .active {
        background-color: rgba(46, 47, 51, 1) !important;
        border-radius: 8px;
    }

    & .mobile-conversation{
        padding: 20px;
        border-bottom: 1px solid rgba(46, 47, 51, 1);
    }
  }


  & .text-area{
    width: calc(100% - 33%);
  }

  &.active {
    background: var(--lightgrey);
    cursor: pointer;

    & .delete_icon {
        background-color: #35363A !important;
    }
  }

  & .q-title {
    width: 22ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
  }

  & .delete_icon {
    height: 20px !important;
    width: 20px !important;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    margin: 0px !important;
    border-radius: 5px !important;
    display: grid;
    place-items: center;
  }

  & .flex-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;

    & p {
      margin: 0px;
    }

    &.second {
      gap: 7px;
    }
  }
`;

// const TextBox = styled.textarea`
//   flex-grow: 1;
//   background-color: ${({ theme }) => theme.colors.lightgrey};
//   resize: none;
//   padding: 4px;
//   border-radius: 5px;
//   outline: none;
//   color: ${({ theme }) => theme.colors.primary};
//   font-size: 12px;
// `;