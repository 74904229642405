/* eslint-disable react-hooks/exhaustive-deps */
import assets from 'assets'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

const TopBanner = ({ isBtn = true }) => {
    const dispatch = useDispatch();
    const [, setParams] = useSearchParams();

    return (
        <Container>
            <div className='banner-container'>
                <span style={{ width: !isBtn && '100%' }}>
                    {/* Beat the Heat with ChatAI | Mid-Summer Sale 40% off Monthly and Annual Subscribtions  */}
                    {/* Mid-summer sale - 40% OFF All Plans */}
                    Beat the Heat MidSummer Sale! 40% Off All Plans
                </span>
                {
                    isBtn && (<button
                        onClick={() => {
                            setParams({ discount_code: '77ae6432-c283-45f5-b20c-39038b881382' });
                            dispatch.utils.setSubscriptionModal(true);
                            dispatch.chat.setMobileScreen('chat')
                        }}
                    >
                        Upgrade Now
                    </button>)
                }


                <img
                    onClick={() => {
                        dispatch.utils.setTopBanner(false)
                    }}
                    className='close-btn' src={assets.grayClose} alt='' />
            </div>
        </Container>
    )
}

export default TopBanner

const Container = styled.div`
    padding: 12px;
    width: 100%;
    & .banner-container{
        height: 60px;
        position: relative;
        background-color: #35363A;
        border-radius: 12px;
        border: 2px solid #494C54;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
        
        
        span {
            font-family: Poppins;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            text-align: center;
        }

        button {
            background-color: #FF434E;
            height: 42px;
            width: 120px;
            border: 1px solid #71262A;
            border-radius: 8px;
            font-family: Poppins;
            font-weight: 500;
            font-size: 15px;
            line-height: 22.5px;
            color: #FFFFFF;
            outline: none;
        }

        & .close-btn{
            position: absolute;
            top: 0px;
            right: 30px;
            transform: translate(15px, 60%);
            cursor: pointer;
        }
    }

   @media only screen and (max-width: 1000px) {
      & .banner-container{
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 8px 35px 8px 12px;

        span {
            font-family: Poppins;
            font-weight: 400;
            font-size: 15px;
            line-height: 22.5px;
            color: #FFFFFF;
            width: 60%;
            padding: 5px 0px;
        }

        button {
            height: 42px;
            width: 130px;
            padding: 10px;
            border-radius: 8px;
            font-family: Poppins;
            font-weight: 500;
            font-size: 15px;
            line-height: 22.5px;
            color: #FFFFFF;
            outline: none;
            display: inline;
        }
      }  

      & .close-btn{
        transform: translate(25px, 60%) !important;
      }
}
`;