import React from 'react';
import { ClipLoader } from 'react-spinners';

import styled from 'styled-components';

const CustomButton = ({
  label,
  loading,
  disabled,
  variant,
  className,
  leftIcon,
  rightIcon,
  rightIconStyles,
  rightIconClassName,
  leftIconStyles,
  loaderColor,
  ...rest
}) => {
  return (
    <Button
      disabled={disabled || loading}
      {...rest}
      className={` font-size-12 ${variant} ${className}`}
    >
      {loading ? (
        <ClipLoader size={15} color={loaderColor || '#ffffff'} />
      ) : (
        <>
          {leftIcon && <img style={leftIconStyles} src={leftIcon} alt='' />}
          {label && (
            <span
              style={{
                font: 'inherit',
                // fontSize: '12px',
                color: loaderColor || '#fffffff',
              }}
            >
              {label}
            </span>
          )}
            {rightIcon && <img className={rightIconClassName} src={rightIcon} style={rightIconStyles} alt='' />}
        </>
      )}
    </Button>
  );
};

export default CustomButton;

const Button = styled.button`
  max-width: 600px;
  padding-inline: 20px;
  border: none;
  height: 45px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 12px;
  transition: 0.4s ease;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }

  &.btn-blue {
      background-color: var(--blue-dark) !important;
      border: 1px solid var(--blue-dark);
      &:hover {
        background-color: var(--blue-dark);
      }
     
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.red};
  }

  &.btn-grey {
    background-color: ${({ theme }) => theme.colors.lightgrey};
    &:hover {
      background-color: ${({ theme }) => theme.colors.lightgrey};
    }
  }

  &.btn-outlined {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.inputLabel};
    color: ${({ theme }) => theme.colors.primary};

    &.active {
      background-color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
    }
  }
`;
