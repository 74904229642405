import React, { useRef, useState, useEffect } from 'react';

import styled from 'styled-components';
// import Header from './components/Header';
// import SearchInput from '../Content/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../Content/Message';
import Avatar from 'assets/images/user-avatar-1.png';
// import { createConvesation } from 'container/api';
import ImageLoading from './components/ImageLoading';
import Subscription from 'container/components/Subscription/Subscription';
import PaymentForm from '../Content/PaymentForm';
// import useMediaQuery from 'container/hooks/useMediaQuery';
import dayjs from 'dayjs';
import assets from 'assets';
import { modes } from 'app.config';
// import PromptDetail from './PromptDetail/PromptDetail';

const Templates = ({ handleClick, setStep, isMobile }) => {
  // const inputRef = useRef(null);
  const dispatch = useDispatch();
  const subRef = useRef(null);
  // const isMobile = useMediaQuery('(max-width: 1000px)');

  const scroller = useRef(null);
  const { messages, selectedConversation
    // timeFilter
  } = useSelector(
    state => state.chat
  );
  const subscriptionModal = useSelector(state => state.utils.subscriptionModal);
  const selectedPlan = useSelector(state => state.subscription.planId);

  const {
    selectedFilter,
    imageCount,
    selectedImage,
    // templates,
    prompt,
    generatedImage,
    loading,
    // height,
    // width,
    // prompt_strength,
    // model,
    // isNeg_prompt,
    // neg_prompt,
    // seed,
    // grid,
    customPrompt,
    // disable,
  } = useSelector(state => state.imageGeneration);

  const user = useSelector(state => state.auth.user);
  const { mode } = useSelector(state => state.utils);
  // const selectedModel = useSelector(state => state.chat.model);

  // const {timeFilter}

  const [chat, setChat] = useState([]);

  // const [predict, setPredict] = useState('');

  // const handleTemplateClick = val => {
  //   dispatch.imageGeneration.setSelectedTemplate(val);
  //   if (!isMobile) {
  //     dispatch.utils.setOpenControls(true);
  //   }
  //   handleClick(2);
  // };

  // const handlePreMadeTemplatesClick = () => {
  //   if (!isMobile) {
  //     dispatch.utils.setOpenControls(true);
  //   }
  //   handleClick(2);
  // };

  // const handlePredict = async () => {
  //   try {
  //     if (!predict || loading || disable) {
  //       return;
  //     }
  //     if (predict) {
  //       dispatch.imageGeneration.setLoading(true);
  //       let conversationId = selectedConversation?.id;
  //       if (!selectedConversation) {
  //         const { data } = await createConvesation({
  //           label: predict,
  //           model: 'picasso',
  //         });
  //         conversationId = data?.payload?.id;
  //         dispatch.chat.setSelectedConversation(data.payload);

  //         let query = `?model=${selectedModel.value}`;
  //         if (timeFilter === 'recent') {
  //           query += `&most_recent=true`;
  //         } else if (timeFilter === 'favourite') {
  //           query += `&favourite=true`;
  //         }

  //         dispatch.chat.getAllConversations(query);
  //       }

  //       dispatch.imageGeneration.generateImage({
  //         prompt: predict,
  //         conversation_id: conversationId,
  //         steps: 50,
  //         width: width,
  //         height: height,
  //         images: imageCount,
  //         prompt_strength: prompt_strength,
  //         enhance_prompt: 0,
  //         restore_faces: 1,
  //         model_id: model,
  //         negative_prompt: isNeg_prompt ? neg_prompt : '',
  //         seed: seed || '',
  //       });
  //       setPredict('');
  //       dispatch.imageGeneration.setSeed('');
  //       dispatch.imageGeneration.setSelectedFilter(null);
  //       dispatch.imageGeneration.setCustomPrompt('');
  //       dispatch.imageGeneration.setSelectedImage('');
  //     }
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // const handleAfterChange = val => {
  //   dispatch.imageGeneration.setCustomPrompt(val);
  //   dispatch.imageGeneration.setPromptEdited(true);
  // };

  useEffect(() => {
    if (scroller.current) {
      scroller.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loading, generatedImage, prompt, chat]);

  useEffect(() => {
    setChat(messages);
  }, [messages]);

  useEffect(() => {
    if (selectedConversation) {
      dispatch.chat.getConversationMessages(selectedConversation.id);
    }
    // eslint-disable-next-line
  }, [selectedConversation]);

  useEffect(() => {
    let query = '';
    if (customPrompt) {
      query = customPrompt;
    } else {
      if (selectedImage) {
        query = selectedImage.prompt;
      }
    }

    if (selectedFilter?.descrption?.length) {
      query = query + ' and ' + selectedFilter.descrption;
    } else {
      // eslint-disable-next-line
      query = query;
    }
    // setPredict(query);
    // eslint-disable-next-line
  }, [selectedImage, selectedFilter]);

  useEffect(() => {
    if (subRef.current) {
      subRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [subscriptionModal]);

  return (
    <>
      {/* <Header setStep={setStep} /> */}
      <Content>

        {/* <PromptDetail /> */}
        {messages.length === 0 && !loading && (
          <TemplatesGrid>
            {/* {Object?.keys(templates || {})?.map(template => (
            <Template
              key={template}
              templateName={template}
              images={templates[template]}
              onClick={() => handleTemplateClick(template)}
            />
          ))} */}
            <p className='font-size-36 bold title'>
              Generate images with ChatAI
            </p>
            <p className='content-text'>Describe your image in the prompt box </p>
            {/* <p className='font-size-16 description'>
              Describe your image in the prompt box or use a{' '}
              <span
                className='clickable'
                role='button'
                onClick={handlePreMadeTemplatesClick}
              >
                template
              </span>
            </p> */}
          </TemplatesGrid>
        )}

        {chat.map(item => {
          if (item.response_source === 3) {
            return (
              <MessageContainer key={item.id}>
                <Message
                  message={item.query}
                  avatar={user?.profile?.profile_image || Avatar}
                  date={item.created_at}
                  userMsg={true}
                  noMargin={true}
                  fromPicasso={true}
                />
                <Message
                  message={item.response || item.message}
                  type='img'
                  avatar={assets.picasso}
                  date={item.created_at}
                  userMsg={true}
                  picasso={true}
                  id={item.id}
                  tryleap={item?.is_tryleap}
                  tryleapRes={item?.tryleap_res}
                  noMargin={true}
                  isMine={item?.isMine}
                  isMobile={isMobile}
                />
              </MessageContainer>
            );
          } else if (mode === modes.chat)
          return (
            <React.Fragment key={item.id}>
              <Message
                message={item.query || item?.prompt}
                avatar={user?.profile?.profile_image || Avatar}
                userMsg={true}
                date={item.created_at}
              />
              <Message
                message={item.response || item.message}
                avatar={assets.picasso}
                date={item.created_at}
                userMsg={false}
                response={true}
                id={item.id}
                isLikedMsg={item.is_liked}
                isDislikedMsg={item.is_disliked}
                isMine={item?.isMine}
              />
            </React.Fragment>
            ) 
          else return null
        })}
        <div style={{ minHeight: '20px' }}></div>

        {prompt && loading && (
          <>
            <MessagePrompt>
              <div className='user-image'>
                <img
                  src={user?.profile?.profile_image || assets.userAvatar2}
                  alt=''
                />
              </div>
              <div className='content'>
                <p className='font-size-10 date'>{dayjs().format('DD MMM')}</p>
                <p className='font-size-14'>{prompt}</p>
              </div>
            </MessagePrompt>

            <ImageGrid
              className='grid'
              style={{
                marginTop: '20px',
                gridTemplateColumns: `repeat(${2}, 1fr)`,
              }}
            >
              {Array.from({ length: imageCount }, (_, i) => i + 1).map(i => (
                <ImageLoading key={i} />
              ))}
            </ImageGrid>
          </>
        )}
        {subscriptionModal && (
          <div ref={subRef}>
            <Subscription containerStyle={{ padding: '0px' }} />
          </div>
        )}
        {selectedPlan && (
          <PaymentForm
            plan={selectedPlan}
            containerStyle={{ padding: '0px' }}
          />
        )}
        <div className='scroll-view' ref={scroller}></div>
      </Content>



      {/* <SearchInput
        predict={predict}
        setPredict={setPredict}
        handlePredict={handlePredict}
        ref={inputRef}
        afterChange={handleAfterChange}
        disableButton={disable || loading || !predict.length}
      /> */}
    </>
  );
};

export default Templates;

const Content = styled.div`
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  padding: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  position: relative;

  & .content-text{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #9A9A9C;
  }

  color: ${({ theme }) => theme.colors.primary};

  & p {
    margin: 0px;
  }

  @media only screen and (max-width: 1000px) {
    & .title{
      text-align: center;
      font-family: Poppins;
      font-size: 24px !important;
      line-height: 36px !important;
      font-weight: 700 !important;
      /* width: 279px !important; */
    }
  }
`;

const TemplatesGrid = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .title {
    font-size: 36px;
    line-height: 54px;
  }

  & .description {
    color: #9a9a9c;

    & .clickable {
      cursor: pointer;
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  /*
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-block: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr;
  } */
`;

const MessagePrompt = styled.div`
  width: calc(100%);
  background: rgba(32, 33, 36, 0.6);
  padding: 15px;
  border-radius: 12px;
  margin-top: auto;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 0px 100px;

  & .user-image {
    & img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  p.date {
    opacity: 0.5;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 1000px) {
    padding: 0px 10px;
  }
`;

const ImageGrid = styled.div`
  display: inline-grid;
  gap: 20px;
  padding: 0px 100px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr !important;
    padding: 0px !important;
  }
  @media screen and (max-width: 420px) {
    grid-template-columns: 1fr !important;
    padding: 0px !important;
  }

   @media only screen and (max-width: 1000px) {
    padding: 0px !important;
  }
`;

const MessageContainer = styled.div`
   padding: 0px 100px;

   @media only screen and (max-width: 1000px) {
    padding: 0px !important;
  }
`;


