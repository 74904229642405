import React, { useState } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import assets from 'assets';
import useMediaQuery from 'container/hooks/useMediaQuery';

const TemplateSelector = ({ options, selected, onSelect }) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery('(max-width: 1000px)');

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleSelect = val => {
    onSelect(val);
  };

  return (
    <Container>
      <div className='wrapper' onClick={handleToggleMenu}>
        <div className='inner-row'>
          <img className='image' src={selected?.img} alt='' />
          <p style={{ color: isDesktop ? 'rgba(255, 255, 255, 1)' : '#6C6F7A'}} className='font-size-12'>{selected?.label || 'Art'}</p>
        </div>
        <div className='arrow-icon'>
          <img style={{ transform: open ? 'rotate(180deg)' : ''}} src={assets.triangleArrow} alt='' />
        </div>
      </div>

      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Menu>
            {options.map((option, index) => (
              <div
                key={index}
                className={`${selected?.label === option.label ? " active-menu-item" : ''}  menu-item` }
                onClick={() => {
                  handleSelect(option.label);
                  handleToggleMenu();
                }}
              >
                <div className='menu-item-inner'>
                  <img src={option.img} className='image' alt='' />
                  <p className='font-size-14'>{option.label}</p>
                </div>

                <div className='arrow-dropdown'>
                  <img
                    width={16}
                    height={16}
                    src={assets.dropdownArrow}
                    alt=""
                    className='arrow'
                  />
                </div>
              </div>
            ))}
          </Menu>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default TemplateSelector;

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  /* border: 1px solid ${({ theme }) => theme.colors.lightgrey}; */
  padding: 4px;
  background-color: #3F4046;
  border-radius: 4px;
  position: relative;

  & .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  & .inner-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & .image {
    width: 24px;
    height: 24px;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0px;
  right: 0px;
  width: 100%;
  background-color: #35363A;
  transform: translateY(100%);
  border-radius: 4px;
  padding-inline: 6px;
  padding-top: 5px;
  z-index: 10;
  max-height: 300px;
  overflow: auto;
  border: 1px solid #4D4F56;
  box-shadow: 1px 1px 5px #40434A;

  & .arrow-dropdown{
    display: none;
  }

  & .active-menu-item{
      background-color: #4D4F56;
      border-radius: 4px;

      & .arrow-dropdown{
        display: block;
      }
  }

  & .menu-item {
    padding-block: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #40434A; */

    &:hover {
        background-color: #4D4F56;
        border-radius: 4px;
    }
    &:last-of-type {
      border-bottom: none;
    }
    
    & .arrow{
      transform: rotate(270deg);
    }

    & .menu-item-inner {
      padding: 4px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      border-radius: 4px;

      
    }
  }
`;
