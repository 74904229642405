import React from 'react';

import styled from 'styled-components';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';

import loaderData from '../../../assets/animations/loader.json';
import DialogLayout from '../DialogLayout';

const Loader = () => {
  const { loader } = useSelector(state => state.utils);
  return (
    <DialogLayout open={loader}>
      <Container>
        <div className='animation'>
          <Lottie
            options={{ animationData: loaderData }}
            isClickToPauseDisabled
          />
        </div>
        <p className='font-size-18 message'>
          Please wait while we are processing your request.
        </p>
      </Container>
    </DialogLayout>
  );
};

export default Loader;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgrey};
  border-radius: 16px;
  width: 300px;
  overflow: hidden;
  position: relative;
  padding-inline: 30px;
  height: 220px;

  & .message {
    position: absolute;
    padding-inline: 30px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
  }
  & .animation {
    position: absolute;
    width: 100%;
    left: 0;
  }
`;
