const icons = {
  bold: require('./bold.svg').default,
  italic: require('./italic.svg').default,
  underline: require('./underline.svg').default,
  alignLeft: require('./align-left.svg').default,
  alignCenter: require('./align-center.svg').default,
  orderList: require('./order-list.svg').default,
  link: require('./link.svg').default,
  undo: require('./undo.svg').default,
  redo: require('./redo.svg').default,
  unorderList: require('./unorder-list.svg').default,
  superscript: require('./superscript.svg').default,
  subscript: require('./subscript.svg').default,
  strike: require('./strike.svg').default,
  highlight: require('./highligh.svg').default,
  boldLight: require('./boldLight.svg').default,
  italicLight: require('./italic-light.svg').default,
  underlineLight: require('./underline-light.svg').default,
  strikeLight: require('./strike-light.svg').default,
  highlightLight: require('./highlight-light.svg').default,
  codeblockLight: require('./codeblock-light.svg').default,
  unorderListLight: require('./unorder-list-light.svg').default,
  orderListLight: require('./order-list-light.svg').default,
  linkLight: require('./link-light.svg').default,
  undoLight: require('./undo-light.svg').default,
  redoLight: require('./redo-light.svg').default,
  code: require('./code.svg').default,
  alignLeftLight: require('./alignLeft.svg').default,
  alignCenterLight: require('./alignCenterLight.svg').default,
  alignRightLight: require('./alignRightLight.svg').default,
  alignJustifyLight: require('./alignJustifyLight.svg').default,
  arrowUp: require('./arrow-up.svg').default,
  magicpen: require('./magicpen.svg').default,
  tickCircle: require('./tick-circle.svg').default,
  shorter: require('./shorter.svg').default,
  longer: require('./longer.svg').default,
  starzLight: require('./starz-light.svg').default,
  summary: require('./summary.svg').default,
  translate: require('./translate.svg').default,
  edit: require('./edit.svg').default,
  gallery: require('./gallery.svg').default,
  askAi: require('./ask-ai.svg').default,
  uploadBtn: require('./upload-btn.svg').default,
  uploadIcon: require('./upload-icon.svg').default,
  spellCheck: require('./spell-check.svg').default,
  translation: require('./translation.svg').default,
};

export default icons;
