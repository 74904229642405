import { createContext } from 'react';
import { useEditor } from '@tiptap/react';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import OrderedList from '@tiptap/extension-ordered-list';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Strike from '@tiptap/extension-strike';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import CodeBlock from '@tiptap/extension-code-block';
import Code from '@tiptap/extension-code';
import Link from '@tiptap/extension-link';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import BubbleMenu from '@tiptap/extension-bubble-menu'
export const EditorContext = createContext(null);

const EditorProvider = ({ children }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      OrderedList,
      Superscript,
      Subscript,
      Strike,
      Highlight,
      Image.configure({
        HTMLAttributes: {
          class: 'editor-image',
          // crossOrigin: 'anonymous',
        },
      }),
      BubbleMenu.configure({
        element: document.querySelector('.menu'),
      }),
      CodeBlock.configure({
        HTMLAttributes: {
          class: 'editor-code-block',
        },
      }),
      Code.configure({
        HTMLAttributes: {
          class: 'editor-code',
        },
      }),
      Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }),
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Link.configure({
        openOnClick: true,
        HTMLAttributes: {
          rel: 'noopener noreferrer',
          target: '_blank',
          class: 'editor-link',
        },
        protocols: [
          {
            scheme: 'tel',
            optionalSlashes: true,
          },
        ],
      }),
      Color,
      TextStyle,
    ],
  });

  return (
    <EditorContext.Provider value={editor}>{children}</EditorContext.Provider>
  );
};

export default EditorProvider;
