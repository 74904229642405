import React, { useState, useContext, useEffect, useRef } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { EditorContext } from 'container/context/editorContext';

import assets from 'assets';
import icons from 'assets/editor-icons';

import styled from 'styled-components';
import classNames from 'classnames';

const config = [
  {
    label: 'left',
    icon: icons.alignLeftLight,
  },
  {
    label: 'center',
    icon: icons.alignCenterLight,
  },
  {
    label: 'right',
    icon: icons.alignRightLight,
  },
  {
    label: 'justify',
    icon: icons.alignJustifyLight,
  },
];

const TextAlignMenu = () => {
  const editor = useContext(EditorContext);
  const ref = useRef(null);
  const menu = useRef(null);

  const [active, setActive] = useState('left');
  const [open, setOpen] = useState(false);

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleMenuClick = val => {
    switch (val) {
      case 'left':
        editor.commands.setTextAlign('left');
        break;
      case 'center':
        editor.commands.setTextAlign('center');
        break;
      case 'right':
        editor.commands.setTextAlign('right');
        break;
      case 'justify':
        editor.commands.setTextAlign('justify');
        break;
      default:
        break;
    }

    editor.chain().focus();
    setActive(val);
  };

  useEffect(() => {
    if (editor) {
      editor.on('selectionUpdate', ({ editor }) => {
        if (editor.isActive({ textAlign: 'left' })) {
          setActive('left');
        } else if (editor.isActive({ textAlign: 'center' })) {
          setActive('center');
        } else if (editor.isActive({ textAlign: 'right' })) {
          setActive('right');
        } else if (editor.isActive({ textAlign: 'justify' })) {
          setActive('justify');
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (open) {
      const boundries = ref.current.getBoundingClientRect();
      const menuBoundries = menu.current.getBoundingClientRect();
      if (boundries.top + menuBoundries.height + 40 > window.innerHeight) {
        menu.current.style.bottom = `0px`;
        menu.current.style.transform = 'translateY(-40px)';
      }
    }
  }, [open]);

  return (
    <Container ref={ref}>
      <div className={classNames('inner-content', open && 'active')}>
        <div className='flex-row' style={{ gap: '5px' }}>
          <img
            src={config.find(d => d.label === active).icon}
            style={{ width: '20px' }}
            alt=''
          />
          <div className='menu-opener' role='button' onClick={handleToggleMenu}>
            <img
              src={assets.chevronLeft}
              className={classNames('menu-icon', open && 'active')}
              alt=''
            />
          </div>
        </div>
        {open && (
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <Menu ref={menu}>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleMenuClick('left')}
              >
                <div className='flex-row'>
                  <img src={icons.alignLeftLight} alt='' />
                  <p className='font-size-14'>left</p>
                </div>
              </div>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleMenuClick('center')}
              >
                <div className='flex-row'>
                  <img src={icons.alignCenterLight} alt='' />
                  <p className='font-size-14'>center</p>
                </div>
              </div>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleMenuClick('right')}
              >
                <div className='flex-row'>
                  <img src={icons.alignRightLight} alt='' />
                  <p className='font-size-14'>right</p>
                </div>
              </div>
              <div
                className='menu-item'
                role='button'
                onClick={() => handleMenuClick('justify')}
              >
                <div className='flex-row'>
                  <img src={icons.alignJustifyLight} alt='' />
                  <p className='font-size-14'>justify</p>
                </div>
              </div>
            </Menu>
          </ClickAwayListener>
        )}
      </div>
    </Container>
  );
};

export default TextAlignMenu;

const Container = styled.div`
  & .menu-opener {
    cursor: pointer;
    position: relative;
    & img {
      transform: rotate(-90deg);
      padding: 5px;
      transition: 0.4s ease;
      &.active {
        transform: rotate(-270deg);
      }
    }
  }

  & .inner-content {
    position: relative;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    &.active {
      background-color: ${({ theme }) => theme.colors.darkgrey};
      border: 1px solid ${({ theme }) => theme.colors.border};
    }
  }
`;

const Menu = styled.div`
  width: 140px;
  background-color: ${({ theme }) => theme.colors.darkbg};
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateY(calc(100% + 4px));
  box-shadow: 0px 4px 8px 0px #0000001f;
  padding-block: 4px;
  border-radius: 4px;

  & .menu-item {
    padding: 4px 8px;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.darkgrey};
    }

    & .flex-row {
      gap: 4px;
    }
  }
`;
