import React, { useContext, useState } from 'react';

import { BubbleMenu } from '@tiptap/react';

import { EditorContext } from 'container/context/editorContext';
import styled from 'styled-components';
import icons from 'assets/editor-icons';
import assets from 'assets';
import theme from 'container/theme';
import ColorMenu from './ColorMenu';
import TextAlignMenu from './TextAlignMenu';
import FontMenu from './FontMenu';
import toast from 'react-hot-toast';
import { askAiOperations, documentImageGeneration } from 'container/api';
import { useDispatch, useSelector } from 'react-redux';
// import { ClipLoader } from 'react-spinners';
import { translationLanguages } from 'app.config';
import { ClipLoader } from 'react-spinners';
import Cookies from 'js-cookie';

const PopupMenu = ({ handleClick, setLink, selectionRange }) => {
  const editor = useContext(EditorContext);
  const [showAi, setShowAi] = useState(false);
  const [placement, setPlacement] = useState('bottom');
  const [loading, setLoading] = useState('');
  const cookie = Cookies.get('chatai-auth');
  const dispatch = useDispatch();
  const remainingPrompts = useSelector(state => state.chat.remainingPrompts);
  const planData = useSelector(state => state.subscription.planData);

  // eslint-disable-next-line
  const afterClick = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStart(selectionRange.startContainer, selectionRange.startOffset);
    range.setEnd(selectionRange.endContainer, selectionRange.endOffset);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const toggleAI = () => {
    setShowAi(!showAi);
    setPlacement(showAi ? 'auto' : 'bottom');
  };

  const handleAiOperation = async task => {
    if (planData?.plan?.toLowerCase() === 'free' && !remainingPrompts) {
      return dispatch.utils.setSubscriptionModal(true);
    }
    try {
      setLoading(true);

      const text = selectionRange.toString();
      const { data } = await askAiOperations({
        option: task,
        text,
      });
      const messageStrings = data.data?.split('\n');

      let htmlstr = '';

      messageStrings?.forEach(str => (htmlstr += `<p>${str}</p>`));
      const pos = editor.state.selection.$anchor.pos;
      editor.commands.insertContentAt(pos, htmlstr);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
      dispatch.chat.handleGetRemainingPromts(cookie);
    }
  };

  const handleAddImage = async () => {
    const value = selectionRange.toString();
    if (planData?.plan?.toLowerCase() === 'free' && !remainingPrompts) {
      return dispatch.utils.setSubscriptionModal(true);
    }
    if (!value) {
      return toast.error(
        'Text input is required for Ask AI and Image generation operations!'
      );
    }
    try {
      setLoading(true);
      const { data } = await documentImageGeneration({
        option: 'generate_image',
        payload: {
          prompt: value,
          steps: 50,
          width: 1080,
          height: 720,
          images: 1,
          prompt_strength: 7,
          enhance_prompt: 0,
          restore_faces: 1,
          model_id: '1285ded4-b11b-4993-a491-d87cdfe6310c',
          upscaleBy: 'x1',
        },
      });
      const pos = editor.state.selection.$anchor.pos;
      editor.commands.insertContentAt(pos, ` `);

      editor
        .chain()
        .setImage({
          src: data.data,
        })
        .run();
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
      if (planData?.plan?.toLowerCase() === 'free') {
        if (remainingPrompts) {
          window.dataLayer.push({
            event: 'custom',
            eventName: 'prompt_completion',
            prompt_number: remainingPrompts,
          });
        }
      } else {
        window.dataLayer.push({
          event: 'custom',
          eventName: 'prompt_completion',
          prompt_number: 'Unlimited',
        });
      }
      dispatch.chat.handleGetRemainingPromts(cookie);
    }
  };

  const handleAskAiOperations = async (event, task, lng) => {
    event.preventDefault();
    event.stopPropagation();
    if (planData?.plan?.toLowerCase() === 'free' && !remainingPrompts) {
      return dispatch.utils.setSubscriptionModal(true);
    }
    try {
      setLoading(true);
      let text = selectionRange.toString();
      if (task === 'translate') {
        text = `Convert this text into ${lng.toLowerCase()}: ${text}`;
      }
      const { data } = await askAiOperations({
        option: task,
        text,
      });
      if (data?.status === 200) {
        // const range = selectionRange;
        // range.deleteContents();
        let text = data.data;
        if (task === 'fix_spelling_grammar') {
          text = text?.split(':')[1] || '';
          // if (text.charAt(0) === '"' && text.charAt(text.length - 1) === '"') {
          //   text = text?.split('')[1];
          // }
          // if (text.charAt(text.length - 1) === '.') {
          //   text = text.slice(0, -1);
          // }
        }

        let contentStr = '';
        // text.forEach((item, index) => {
          const messageStrings = text.split('\n');

          const allString = messageStrings?.map((str, index) => {
            if (str.length > 0) {
              return `<p>${str}</p>`;
            } else if (index !== messageStrings.length - 1 && str.length > 1) {
              return `<br />`;
            } else {
              return '';
            }
          });
          contentStr += allString?.join('');
        // });
        editor.commands.setContent(contentStr);
        // const transaction = editor.state.tr.insertText(text);
        // editor.view.dispatch(transaction);
        // range.insertNode(document.createTextNode(text));
        // afterClick();
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    } finally {
      setLoading(false);
      dispatch.chat.handleGetRemainingPromts(cookie);
    }
  };

  return (
    <>
      {editor && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            onHide: () => setShowAi(false),
            placement: placement,
          }}
          className='background-transparent'
        >
          {showAi ? (
            <AiMode>
              <div className='ai-container'>
                <div
                  className='icon flex-row'
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={toggleAI}
                  role='button'
                >
                  <img src={assets.stars} alt='' />
                  <p
                    className='font-size-14'
                    style={{
                      color: theme.colors.red,
                      margin: '0px',
                      marginLeft: '5px',
                    }}
                  >
                    Back
                  </p>
                </div>
                {loading && (
                  <p
                    className='font-size-12'
                    style={{ margin: '0px', flexGrow: 1 }}
                  >
                    Generating your response...
                  </p>
                )}
                {loading && (
                  <ClipLoader size={15} color={theme.colors.primary} />
                )}
              </div>
              <AIControls>
                {!loading && (
                  <Menu>
                    {/* <Accordion title='Generate with AI'>
                      <div className='menu-item'>
                        <div className='flex-row'>
                          <img src={icons.askAi} alt='' />
                          <p className='font-size-14'>Ask AI (Generate Text)</p>
                        </div>
                      </div>
                      <div className='menu-item'>
                        <div className='flex-row'>
                          <img src={icons.gallery} alt='' />
                          <p className='font-size-14'>
                            Imagine (Generate Image)
                          </p>
                        </div>
                      </div>
                    </Accordion> */}
                    <div
                      className='menu-item'
                      role='button'
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={() => handleAiOperation('ask_ai')}
                    >
                      <div className='flex-row'>
                        <img src={icons.askAi} alt='' />
                        <p className='font-size-14' focusable='false'>
                          Ask AI (Generate Text)
                        </p>
                      </div>
                    </div>
                    <div
                      className='menu-item'
                      role='button'
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={handleAddImage}
                    >
                      <div className='flex-row'>
                        <img src={icons.gallery} alt='' />
                        <p className='font-size-14' focusable='false'>
                          Imagine (Generate Image)
                        </p>
                      </div>
                    </div>
                    <div
                      className='menu-item'
                      role='button'
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={e => handleAskAiOperations(e, 'improve_writing')}
                    >
                      <div className='flex-row'>
                        <img src={icons.magicpen} alt='' />
                        <p className='font-size-14' focusable='false'>
                          Improve writing
                        </p>
                      </div>
                    </div>
                    <div
                      className='menu-item'
                      role='button'
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={e =>
                        handleAskAiOperations(e, 'fix_spelling_grammar')
                      }
                    >
                      <div className='flex-row'>
                        <img src={icons.tickCircle} alt='' />
                        <p className='font-size-14'>Fix spelling & grammar</p>
                      </div>
                    </div>
                    <div
                      className='menu-item'
                      role='button'
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={e => handleAskAiOperations(e, 'make_shorter')}
                    >
                      <div className='flex-row'>
                        <img src={icons.shorter} alt='' />
                        <p className='font-size-14'>Make shorter</p>
                      </div>
                    </div>
                    <div
                      className='menu-item'
                      role='button'
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={e => handleAskAiOperations(e, 'make_longer')}
                    >
                      <div className='flex-row'>
                        <img src={icons.longer} alt='' />
                        <p className='font-size-14'>Make longer</p>
                      </div>
                    </div>
                    <div
                      className='menu-item'
                      role='button'
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={() => handleAiOperation('summarize')}
                    >
                      <div className='flex-row'>
                        <img src={icons.summary} alt='' />
                        <p className='font-size-14'>Summarize</p>
                      </div>
                    </div>
                    <div
                      className='menu-item'
                      role='button'
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={e =>
                        handleAskAiOperations(e, 'simplify_language')
                      }
                    >
                      <div className='flex-row'>
                        <img src={icons.starzLight} alt='' />
                        <p className='font-size-14'>Simplify language</p>
                      </div>
                    </div>

                    {/* <div className='menu-item'>
                        <div className='flex-row'>
                          <img src={icons.summary} alt='' />
                          <p className='font-size-14'>Summarize</p>
                        </div>
                      </div> */}
                    <div
                      className='menu-item translate '
                      style={{ position: 'relative' }}
                    >
                      <div className='flex-row'>
                        <img src={icons.translate} alt='' />
                        <p className='font-size-14'>Translate</p>
                      </div>
                      <LanguageMenu>
                        {translationLanguages.map(lang => (
                          <li
                            key={lang}
                            role='button'
                            onMouseDown={e => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onClick={e =>
                              handleAskAiOperations(e, 'translate', lang)
                            }
                          >
                            <p className='font-size-12'>{lang}</p>
                          </li>
                        ))}
                      </LanguageMenu>
                    </div>
                    {/* <div className='menu-item'>
                        <div className='flex-row'>
                          <img src={icons.edit} alt='' />
                          <p className='font-size-14'>Continue writing</p>
                        </div>
                      </div> */}
                  </Menu>
                )}
              </AIControls>
            </AiMode>
          ) : (
            <Content>
              <Row
                style={{
                  gap: '10px',
                  marginBottom: '14px',
                  justifyContent: 'space-between',
                }}
              >
                <Row
                  style={{ gap: '1ch', color: theme.colors.red }}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={toggleAI}
                  role='button'
                >
                  <img src={assets.stars} alt='' />
                  <p
                    className='font-size-14'
                    role='button'
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Ask AI
                  </p>
                </Row>
                <Row>
                  <Button onClick={() => handleClick('undo')}>
                    <img src={icons.undoLight} alt='' />
                  </Button>
                  <Button onClick={() => handleClick('redo')}>
                    <img src={icons.redoLight} alt='' />
                  </Button>
                </Row>
                <FontMenu />
                <TextAlignMenu />
                <ColorMenu />
              </Row>
              <Row style={{ justifyContent: 'space-between' }}>
                <Button onClick={() => handleClick('bold')}>
                  <img src={icons.boldLight} alt='' />
                </Button>
                <Button onClick={() => handleClick('italic')}>
                  <img src={icons.italicLight} alt='' />
                </Button>
                <Button onClick={() => handleClick('underline')}>
                  <img src={icons.underlineLight} alt='' />
                </Button>
                <Button onClick={() => handleClick('strike')}>
                  <img src={icons.strikeLight} alt='' />
                </Button>
                <Button onClick={() => handleClick('code')}>
                  <img src={icons.codeblockLight} alt='' />
                </Button>
                <Button onClick={() => handleClick('highlight')}>
                  <img src={icons.highlightLight} alt='' />
                </Button>
                <Button onClick={() => handleClick('unorder-list')}>
                  <img src={icons.unorderListLight} alt='' />
                </Button>
                <Button onClick={() => handleClick('order-list')}>
                  <img src={icons.orderListLight} alt='' />
                </Button>
                <Button onClick={setLink}>
                  <img src={icons.linkLight} alt='' />
                </Button>
                <Button onClick={() => handleClick('codeBlock')}>
                  <img src={icons.code} alt='' />
                </Button>
              </Row>
            </Content>
          )}
        </BubbleMenu>
      )}
    </>
  );
};

export default PopupMenu;

const Content = styled.div`
  min-width: 400px;
  border-radius: 8px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.darkbg};
  box-shadow: ${({ theme }) => theme.colors.darkbg} 0px 7px 29px 0px;

  @media only screen and (max-width: 1000px) {
    background-color: rgba(46, 47, 51, 1) !important;
  }
`;

const Button = styled.button`
  all: unset;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  & p {
    margin: 0px;
  }
`;

const AiMode = styled.div`
  width: 350px;
  position: relative;
  .ai-container {
    width: 100%;
    background-color: #26282B;
    border: 1px solid #4D4F56;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;

    & input {
      flex-grow: 1;
      font-size: 12px;
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.primary};

      &::placeholder {
        color: ${({ theme }) => theme.colors.border};
      }
    }
  }
`;

const AIControls = styled.div`
  margin-top: 10px;

  /* pointer-events: none; */
`;

const Menu = styled.div`
  background-color: #26282B;
  width: 268px;
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px #0000001f;
  border: 1px solid #4D4F56;
  height: 300px;
  overflow-y: auto;

  & .menu-title {
    color: ${({ theme }) => theme.colors.border};
  }

  & .menu-item {
    padding: 10px 4px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    /* border-bottom: 1px solid ${({ theme }) => theme.colors.border}; */
    margin-inline: 5px;
    &:last-of-type {
      border: none;
    }
    & p {
      margin: 0px;
    }

    & .flex-row {
      gap: 10px;
    }

    &.translate {
      &:hover {
        & ul {
          display: flex;
        }
      }
    }
  }
`;

const LanguageMenu = styled.ul`
  position: absolute;
  bottom: -20px;
  right: 0px;
  width: 200px;
  height: 300px;
  background-color: rgba(41, 42, 46, 1);
  border-radius: 8px;
  transform: translateX(100%);
  z-index: 300;
  display: none;
  flex-direction: column;
  padding-inline: 10px;
  list-style: none;
  overflow: auto;
  box-shadow: 0px 4px 8px 0px #0000001f;

  & li {
    padding-block: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    & p {
      padding-inline: 5px;
    }

    &:last-of-type {
      border: none;
    }
  }
`;
