/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './style.css';

const P1Website = () => {
  var settings = {
    autoplay: true, // Enable autoplay
    autoplaySpeed: 4000, // Set autoplaySpeed to 0 to control it manually
    arrows: false,
    dots: true,
    infinite: true, // Enable infinite loop
    speed: 500, // Transition speed between slides
    fade: true,
  };

  useEffect(() => {
    var faqHeaders = document.querySelectorAll('.home_faq_main h4');
    faqHeaders.forEach(function (header) {
      header.addEventListener('click', function () {
        var nextDiv = this.nextElementSibling;
        if (nextDiv.style.display === 'none' || nextDiv.style.display === '') {
          nextDiv.style.display = 'block';
        } else {
          nextDiv.style.display = 'none';
        }

        this.parentNode.classList.toggle('toggle');
      });
    });
  }, []);

  return (
    <>
      {/* <section className="error_banner">
                <div className="container">
                    <p2>Notice: Our image generation tool is under maintenance. Image generation will be back online shortly. Thank you!</p2>
                </div>
            </section> */}

      <div>
        <div className='header_main'>
          <div className='wrapper'>
            <div className='header_iner'>
              <a href='<?php echo home_url(); ?>'>
                <img
                  src='https://chatai.com/wp-content/uploads/2023/04/logo.png'
                  alt=''
                />
              </a>
              <div className='header_right'>
                <ul>
                  <li>
                    <a href='#'>Blog</a>
                  </li>
                  <li>
                    <a href='#'>Pricing</a>
                  </li>
                  <li>
                    <a href='#'>Support</a>
                  </li>
                </ul>

                <div className='header_cta'>
                  <a
                    href='https://ui.chatai.com/sign-in'
                    className='header-login-btn'
                    rel='noreferrer'
                  >
                    Login
                  </a>

                  <a
                    href='https://ui.chatai.com/sign-up'
                    class='header-signup-btn'
                    rel='noreferrer'
                  >
                    Try for free
                  </a>
                </div>
                <div className='header_mode'>
                  <label className='switch'>
                    {/* <input type="checkbox" name="mode" <?php echo (isset($_cookie['dark-mode']))?'checked="checked':'';" ? />&gt; */}
                    <span className='slider round' />
                  </label>
                </div>
              </div>
              <span className='mob_menu'>
                <span />
              </span>
            </div>
          </div>
        </div>
        {/*?php } ?*/}
      </div>

      <section className='home-banner'>
        <div className='container'>
          <div className='home-banner-inner'>
            <div className='home-banner-column'>
              <img
                src='https://chatai.com/wp-content/uploads/2023/11/favicon.png'
                alt=''
                width='51px'
                height='75px'
              />
              <h2>Chat AI</h2>
              <h3>Your Do Everything AI Companion.</h3>
              <p>
                Chat with 6 different AI language models and create AI art via{' '}
                <br />
                WhatsApp or web for one low monthly price.
              </p>
              <span className='btn-info'>
                Get Started with 10 FREE Prompts!
              </span>
              <button
                className='sign-up-btn'
                onClick={() =>
                  window.open('https://ui.chatai.com/sign-up', '_self')
                }
              >
                Signup for Free
              </button>
            </div>
            <div className='home-banner-column'>
              <img
                src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame-9976.png'
                alt=''
              />
            </div>
          </div>
        </div>
      </section>

      <section className='creativity'>
        <div className='container'>
          <div className='creativity-inner'>
            <div className='creativity-column'>
              <img
                src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame-9979.png'
                alt=''
              />
            </div>
            <div className='creativity-column'>
              <span>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Slider.png'
                  alt=''
                />
                AI Image Generation
              </span>
              <h3>Unleash Your Creativity with AI Images and Art</h3>
              <p>
                Designed for creators, ChatAI gives you the power and tools to
                create stunning content.
              </p>
              <div className='diffusion-grid'>
                <div className='diffusion-grid-wrapper'>
                  <div className='diffusion-grid-column'>
                    <img
                      src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame-9862.png'
                      alt=''
                    />
                    <p2>Stable Diffusion</p2>
                  </div>
                  <div className='diffusion-grid-column'>
                    <div className='diffusion-grid-column-wrapper'>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988.png'
                          alt=''
                        />
                        <p3>SDXL</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-1.png'
                          alt=''
                        />
                        <p3>Stable Diffusion 2.1</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-2.png'
                          alt=''
                        />
                        <p3>Stable Diffusion 1.5</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-3.png'
                          alt=''
                        />
                        <p3>Realistic Vision v4.0</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-4.png'
                          alt=''
                        />
                        <p3>Realistic Vision v2.0</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-5.png'
                          alt=''
                        />
                        <p3>Open Journey V4</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-6.png'
                          alt=''
                        />
                        <p3>Open Journey V2</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-7.png'
                          alt=''
                        />
                        <p3>Open Journey V1</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-8.png'
                          alt=''
                        />
                        <p3>Modern Disney</p3>
                      </div>
                      <div className='diffusion-grid-column-wrapper-column'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame 9988-9.png'
                          alt=''
                        />
                        <p3>Future Diffusion</p3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='creativity'>
        <div className='container'>
          <div className='creativity-inner'>
            <div className='creativity-column'>
              <h3>Collaborate with the Ultimate AI Writing Tool.</h3>
              <p
                style={{
                  fontSize: '20px',
                  fontWeight: '400',
                  lineHeight: '34px',
                }}
              >
                Easily convert chats into editable documents and collaborate
                with ChatAI to write, translate, summarize, and simplify
                content, create images and much more.
              </p>
            </div>
            <div className='creativity-column'>
              <img
                src='https://chatai.com/wp-content/themes/chat-ai/assets/images/MainFlow-ForWebsite.gif'
                alt=''
              />
            </div>
          </div>
        </div>
      </section>

      <section className='features'>
        <div className='container'>
          <span>
            <img
              src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Slider.png'
              alt=''
            />
            AI Image Generation
          </span>
          <h2>Get Help With Any Task</h2>
          <div className='features-inner'>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/magicpen.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Learn a New Skill</h3>
                <p>
                  Ask ChatAI how to lose weight, train your dog, or run a
                  5-minute mile.
                </p>
              </div>
            </div>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/voice-square.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Summarize Books or Movies</h3>
                <p>
                  ChatAI can summarize any book, movie, or complex topic on
                  demand.
                </p>
              </div>
            </div>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/gallery.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Create Images and Art</h3>
                <p>
                  Input a text description to generate images of product ideas,
                  funny memes, captivating images for marketing, or images just
                  for fun.
                </p>
              </div>
            </div>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/airplane.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Plan Your Vacation</h3>
                <p>
                  Ask ChatAI to create your travel itinerary, find your hotels
                  and flights, learn local customs, and plan activities and
                  tours.
                </p>
              </div>
            </div>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/code-circle.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Get Coding Help</h3>
                <p>
                  Review and debug code, get help solving a coding problem,
                  complete code, generate code documentation, and more.
                </p>
              </div>
            </div>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/edit-2.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Write Like a Pro</h3>
                <p>
                  Get help with punctuation and editing or have ChatAI enhance
                  your copy. ChatAI can even write for you in a desired style or
                  tone of voice.
                </p>
              </div>
            </div>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/magicpen.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Get Advice</h3>
                <p>
                  Get useful tips for managing relationships, finances, and your
                  health.
                </p>
              </div>
            </div>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/music.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Plan Your Workouts</h3>
                <p>Write music in almost any genre.</p>
              </div>
            </div>
            <div className='features-column'>
              <div className='image-wrapper'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/translate.png'
                  alt=''
                />
              </div>
              <div className='para-wrapper'>
                <h3>Translate Like a Boss</h3>
                <p>
                  ¿Usaste ChatAi para traducir esto? Traduce como un
                  jefe.Translate in any language instantly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='access'>
        <div className='container'>
          <h2>Access the Best AI's All In One Place</h2>
          <p>
            Switch between AI models with one click. Easily compare answers. Use
            the best AI for the job.
          </p>
          <div className='access-image-content-wrapper'>
            <div className='access-image-wrapper'>
              <img
                src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Mask-group.png'
                alt=''
              />
              <div className='circle-icons'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Frame-10010.png'
                  alt=''
                />
              </div>
              <div className='circle-icons'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/ChatGPT 4.png'
                  alt=''
                />
              </div>
              <div className='circle-icons'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Cohere.png'
                  alt=''
                />
              </div>
              <div className='circle-icons'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/ChatGPT 5.png'
                  alt=''
                />
              </div>
              <div className='circle-icons'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/Group 29.png'
                  alt=''
                />
              </div>
              <div className='circle-icons'>
                <img
                  src='https://chatai.com/wp-content/themes/chat-ai/assets/images/A121.png'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id='pricing_section'></div>
      <div className='price_section home-v2'>
        <div className='wrapper'>
          <div className='headings_top'>
            <span />
            <h3>
              Start with 10 free prompts, no credit card <br />
              required. Upgrade for unlimited use.
            </h3>
            <p>Check our pricing plans.</p>
          </div>
          <div className='home_pricing_outer'>
            <div className='home_pricing_main'>
              <div className='home_pricing_main_top'>
                <h4>Free Plan</h4>
                <p>Full AI Functionality. Limited prompts</p>
              </div>
              <div className='home_pricing_pricing'>
                <h4>
                  {' '}
                  $0 <span>/ yearly</span>
                </h4>
              </div>
              <div className='home_pricing_main_btm'>
                <ul>
                  <li>10 free web prompts per month</li>
                  <li>10 free WhatsApp prompts / mo</li>
                  <li>
                    10 Different AI image generation models based on Stable
                    Diffusion
                  </li>
                  <li>
                    6 AI engines including ChatGPT, Stable Diffusion, and more
                  </li>
                  <li>Full AI Capabilities</li>
                  <li>Advanced Image generation</li>
                  <li>
                    New DocAI: AI powered content creation and collaboration
                    tool
                  </li>
                  <li>Last 5 chat history</li>
                  <li>Image editing, filters and templates</li>
                </ul>
                <a
                  href='http://ui.chatai.com/sign-up'
                  className='pricing-free-btn tableone'
                >
                  Try for free
                </a>
              </div>
            </div>
            <div className='home_pricing_main'>
              <div className='home_pricing_main_top'>
                <h4>Pro Plan</h4>
                <p>Unlimited web prompts</p>
              </div>
              <div className='home_pricing_pricing'>
                <h4>
                  {' '}
                  $90 <span>/ yearly</span>
                </h4>
              </div>
              <div className='home_pricing_main_btm'>
                <ul>
                  <li>Unlimited web prompts</li>
                  <li>Unlimited WhatsApp prompts</li>
                  <li>
                    10 Different AI image generation models based on Stable
                    Diffusion
                  </li>
                  <li>
                    6 AI engines including ChatGPT, Stable Diffusion, and more
                  </li>
                  <li>Full AI capabilities</li>
                  <li>Advanced Image generation</li>
                  <li>
                    New DocAI: AI powered content creation and collaboration
                    tool
                  </li>
                  <li>Unlimited chat history</li>
                  <li>Image editing, filters and templates</li>
                  <li>
                    First access to new AI models and ChatAI product upgrades
                  </li>
                </ul>
                <a
                  href='http://ui.chatai.com/sign-up'
                  className='pricing-pro-btn'
                >
                  Get Started
                </a>
              </div>
            </div>
            <div className='home_pricing_main'>
              <div className='home_pricing_main_top'>
                <h4>Plus Plan</h4>
                <p>Our Most Popular Plan</p>
              </div>
              <div className='home_pricing_pricing'>
                <h4>
                  {' '}
                  $9 <span>/ monthly</span>
                </h4>
              </div>
              <div className='home_pricing_main_btm'>
                <ul>
                  <li>Unlimited web prompts</li>
                  <li>Unlimited WhatsApp prompts</li>
                  <li>
                    10 Different AI image generation models based on Stable
                    Diffusion
                  </li>
                  <li>
                    6 AI engines including ChatGPT, Stable Diffusion and more
                  </li>
                  <li>Full AI capabilities</li>
                  <li>Advanced Image generation</li>
                  <li>
                    New DocAI: AI powered content creation and collaboration
                    tool
                  </li>
                  <li>Unlimited chat history</li>
                  <li>Image editing, filters and templates</li>
                </ul>
                <a
                  href='http://ui.chatai.com/sign-up'
                  className='pricing-plus-btn tablethree'
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='testimonial_section'>
        <div className='wrapper'>
          <div className='headings_top'>
            {/* <span>It's a match</span> */}
            <h3>What Our Users Say About ChatAI</h3>
            <p>Hear it from them, not us.</p>
          </div>
          <div className='testimonail_main'>
            <div className='testimonail_carousel'>
              <Slider {...settings}>
                <div>
                  <div className='testimonail_iner'>
                    <div className='testimonail_iner_left'>
                      <div className='testimonail_iner_rating'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                      </div>
                      <p>
                        Being able to chat and make art in the same place is
                        exactly what I needed for my event business.
                      </p>
                      <div className='testimonial_iner_author'>
                        <h4>Callum Smith</h4>
                        <span>Event Planner</span>
                      </div>
                    </div>
                    <div className='testimonail_iner_img'>
                      <img
                        src='https://chatai.com/wp-content/themes/chat-ai/assets/images/client-1.png'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='testimonail_iner'>
                    <div className='testimonail_iner_left'>
                      <div className='testimonail_iner_rating'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                      </div>
                      <p>
                        “I'm in love with ChatAI! You guys are doing a great
                        job. Well appreciated!”
                      </p>
                      <div className='testimonial_iner_author'>
                        <h4>Paul B.</h4>
                        <span>NYC </span>
                      </div>
                    </div>
                    <div className='testimonail_iner_img'>
                      {/*    <img src="<?php echo get_template_directory_uri(); ?>/assets/images/chatai-logo.png" alt="">*/}
                      <img
                        src='https://chatai.com/wp-content/uploads/2023/05/Avatar-1.png'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className='testimonail_iner'>
                    <div className='testimonail_iner_left'>
                      <div className='testimonail_iner_rating'>
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                        <img
                          src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/star-rating.svg'
                          alt=''
                        />
                      </div>
                      <p>
                        “It's more or less like speaking with a real person. I
                        like that I can compare answers from different AI's for
                        brainstorming and research! I use ChatAI to help me be
                        more productive tackling day-to-day tasks.”
                      </p>
                      <div className='testimonial_iner_author'>
                        <h4>Brandon E.</h4>
                        <span>CMO, Generosity Water</span>
                      </div>
                    </div>
                    <div className='testimonail_iner_img'>
                      <img
                        src='https://chatai.com/wp-content/uploads/2023/05/Brandon-Bio.jpeg'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className='home_started_section home-v2'>
        <div className='wrapper'>
          <div className='home_started_iner'>
            <div className='home_started_img'>
              <img
                src='https://chatai.com/wp-content/themes/chat-ai/assets/images/getting-started.gif'
                alt=''
              />
            </div>
            <div className='home_started_right'>
              <h3>Getting Started is Easy</h3>
              <p>
                Getting started with ChatAI is a breeze. Chat now with 10 FREE
                prompts on web &amp; WhatsApp
              </p>
              <div className='home_steps_main'>
                <h4>
                  <span>01</span> Start with the ChatAI Web App
                </h4>
                <p>
                  Access the full power of ChatAI with 10 Free Prompts to start.
                </p>
                <a
                  href='https://ui.chatai.com/sign-up'
                  className='get-started-web-link'
                >
                  <img
                    src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/laptop.svg'
                    alt=''
                  />{' '}
                  Get Started Now
                </a>
              </div>
              <div className='home_steps_main'>
                <h4>
                  <span>02</span> Setup ChatAI on WhatsApp.
                </h4>
                <p>
                  Start a WhatsApp conversation with ChatAI by sending a text to
                  +1&nbsp;(832)&nbsp;412‑1561. Get 10 free prompts per month.
                  Upgrade for more.
                </p>
                <a
                  href='https://wa.me/18324121561'
                  className='get-started-web-link'
                  rel='noreferrer'
                >
                  <img
                    src='https://chatai.com/wp-content/themes/chat-ai/assets/images/icons/whatsapp-new.svg'
                    alt=''
                  />{' '}
                  Chat on WhatsApp
                </a>
              </div>
              {/* <div class="home_steps_main">
          <h4><span>03</span> SMS text ChatAI with your phone.</h4>
          <p>Chatting with AI is as simple as sending a text. Get 10 free prompts per month. Upgrade for more.</p>
          <a href="https://ui.chatai.com/sign-up" class="get-started-web-link"><img src="<?php echo get_template_directory_uri(); ?>/assets/images/icons/text.svg"> Text ChatAI</a>
        </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className='home_faq_section'>
        <div className='wrapper'>
          <div className='home_features_top'>
            <h3>FAQs</h3>
            <p>Frequently asked questions about Chat-AI</p>
          </div>
          <div className='home_faq_wrap'>
            <div className='home_faq_main'>
              <h4>
                <span>01</span> - What is ChatAI?
              </h4>
              <div>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The Chatbot is an artificial intelligence program designed
                    to interact with you through a text-based
                    conversation.&nbsp;
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The chatbot uses artificial intelligence (AI) techniques
                    such as NLP to understand and respond to your questions or
                    commands in real-time. This technology has evolved
                    significantly, leading to the development of specialized
                    applications.
                  </span>
                </p>
              </div>
            </div>
            <div className='home_faq_main'>
              <h4>
                <span>02</span> - How does ChatAI work?
              </h4>
              <div>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    ChatAI was designed to be an all-in-one platform to provide
                    easy access to the best AI models available today.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You will be able to ask questions to the AI in a seamless
                    way as it's available 24/7, so you can message anytime.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The product will give access to 4 AI providers such as:
                  </span>
                </p>
                <ul>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>ChatGPT 3.5</span>
                  </li>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>ChatGPT 4</span>
                  </li>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Stable Diffusion</span>
                  </li>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Ai21</span>
                  </li>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>Cohere</span>
                  </li>
                  <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>HuggingFace</span>
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    Another key feature of ChatAi is the ability to connect in
                    multiple channels such as Whatsapp &amp; desktop.
                  </span>
                </p>
              </div>
            </div>
            <div className='home_faq_main'>
              <h4>
                <span>03</span> - How do I create a document with Doc AI?
              </h4>
              <div>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    ChatAI is designed to be private and secure, and any
                    information you share with it is kept confidential.&nbsp;
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The AI developers take privacy and security very seriously
                    and have implemented appropriate measures to protect your
                    information.
                  </span>
                </p>
              </div>
            </div>
            <div className='home_faq_main'>
              <h4>
                <span>04</span> - Is ChatAI safe and secure?
              </h4>
              <div>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    ChatAI is designed to be private and secure, and any
                    information you share with it is kept confidential.&nbsp;
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    The AI developers take privacy and security very seriously
                    and have implemented appropriate measures to protect your
                    information.
                  </span>
                </p>
              </div>
            </div>
            <div className='home_faq_main'>
              <h4>
                <span>05</span> - Is it free?
              </h4>
              <div>
                <p>
                  <a href='http://chat.ai/'>
                    <span style={{ fontWeight: 400 }}>Chatai.com</span>
                  </a>{' '}
                  <span style={{ fontWeight: 400 }}>
                    subscription currently cost $9/ month on the monthly plan.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    You can also select an annual subscription of $90, which
                    will give a $18 dollars discount.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    With a paid subscription you will have access to unlimited
                    prompts, priority support, and 6 different AI models to
                    experience the best of AI chat.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{ fontWeight: 400 }}>
                    NOTE: ChatAI's prices increased on July 1st, 2023.&nbsp;
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='footer_main'>
        <div className='wrapper'>
          <div className='footer_iner'>
            <div>
              <p>© 2023 ChatAI</p>
              <p>
                1413 Ponce de Leon Ave, 4th Floor San Juan,
                <br />
                Puerto Rico 00907 Puerto Rico
              </p>
            </div>
            <ul>
              <li>
                <a href='/terms-of-service/'>Terms of Service</a>
              </li>
              <li>
                <a href='/privacy-policy/'>Privacy Policy</a>
              </li>
              <li>
                <a href='https://forms.gle/UwHuv4xC2J4W2M6Z9'>Affiliate</a>
              </li>
            </ul>
            <a href='https://www.facebook.com/profile.php?id=61555443810411'>
              <i className='fa-brands fa-facebook-f' />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default P1Website;
