import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import Templates from './Templates';
import StarterPrompt from './StarterPrompt/StarterPrompt';
// import Controls from './Controls/Controls';
import { useSelector, useDispatch } from 'react-redux';
// import useMediaQuery from 'container/hooks/useMediaQuery';
import assets from 'assets';
import ImageGenerationModal from './components/ImageGenerationModal/ImageGenerationModal';
// import SmallScreenMenu from '../Content/SmallScreenMenu';
const ImageGeneration = ({ sideBar, isMobile }) => {
  const [step, setStep] = useState(1);

  // const isDesktop = useMediaQuery('(max-width: 1000px)');
  // const isLeftSideOpen = useSelector(state => state.utils.isLeftSideBarOpen);
  const isRightSideOpen = useSelector(state => state.utils.isRightSideBarOpen);
  const { openControls } = useSelector(state => state.utils);
  const planData = useSelector(state => state.subscription.planData);

  const { openImagePopup } = useSelector(state => state.imageGeneration);

  const dispatch = useDispatch();

  // const open = useSelector(state => state.utils.openControls);
  const handleClick = s => {
    setStep(s);
  };

  useEffect(() => {
    dispatch.imageGeneration.setPromptEdited(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (openControls && !isRightSideOpen) {
      dispatch.utils.setRightSideBar(true);
    }
    // eslint-disable-next-line
  }, [openControls, isRightSideOpen]);

  return (
    <Container
      className={planData?.plan?.toLowerCase() === 'free' ? 'banner' : ''}
    >
      {/* {
        isMobile &&
        <SmallScreenMenu
          showMainText={true}
        />
      } */}

      <div className='container' >


        {(step === 1 || step === 3) && (
          <Templates step={step} setStep={setStep} handleClick={handleClick} isMobile={isMobile} />
        )}
        {step === 2 && <StarterPrompt handleClick={handleClick} />}
      </div>
      {/* <Controls sideBar={sideBar} /> */}
      <CreateImageContainer className={`${openImagePopup && 'image-generation-button-container'}`}>
        <button onClick={() => {
          dispatch.imageGeneration.setMobileTab('Generate')
          dispatch.chat.setMobileScreen('sidebar')        
  // if (!openImagePopup) dispatch.imageGeneration.setOpenImagePopup(true)
          // else {
          //   dispatch.imageGeneration.setClickImageGenerationButton(true)
            
          // }
        }}>
          Generate Image
        </button>
      </CreateImageContainer>

      <ImageGenerationModal open={openImagePopup} />
    </Container>
  );
};

export default ImageGeneration;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  //padding-block: 0px 7px;
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  & .container {
    height: 100%;
    /* background-color: ${({ theme }) => theme.colors.darkgrey}; */
    background-image: url(${assets.imageBg});
    display: flex;
    flex-direction: column;
    padding: 0px;
    z-index: 20;
    transition: 0.4s ease;
    width: 100%;
    max-width: 100%;
  }

  @media only screen and (max-width: 1000px) {
    height: calc(-110px + 100vh);
    &.banner {
      height: calc(100vh - 103px);
    }

    & .container {
      background-color: #202124 !important;
      background-image: none;
    }

    & .image-generation-button-container {
      height: 77px;
      background-color: rgba(46, 47, 51, 1);
      z-index: 1000;
    }
  }
  & .left-side-icon,
  .right-side-icon {
    background-color: #74aa9c;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 40px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
  }

  & .left-side-icon {
    transform: translate(-50%, -50%);
  }
  & .right-side-icon {
    transform: translate(50%, -50%);
    right: 0px;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

const CreateImageContainer = styled.div`
  display: none;

  @media only screen and (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    
  }

  & button {
    border-radius: 8px;
    height: 44px;
    background: rgba(34, 146, 249, 1);
    border-radius: 7px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    width: 100%;
    outline: none;
    border: none;
  }
`
