import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import Image from '../components/Image';

const FilterSelection = () => {
  const disptach = useDispatch();
  const {
    filters,
    selectedFilter,
    selectedImage,
    selectedTemplate,
    templates,
  } = useSelector(state => state.imageGeneration);

  const handleSelectFilter = filter => {
    disptach.imageGeneration.setSelectedFilter(filter);
  };

  return (
    <Container>
      <p className='font-size-16'>1. Starter Prompt</p>
      <p className='font-size-10 subtitle'>
        Choose from a list of premade prompts to get better understanding of how
        to generate images.
      </p>

      <SelectedTemplate>
        <div className='inner'>
          <img
            src={templates[selectedTemplate][0].example_image_url}
            className='image'
            alt=''
          />
          <p className='font-size-12'>{selectedTemplate}</p>
        </div>
      </SelectedTemplate>

      <div className='selected-image'>
        <Image src={selectedImage?.example_image_url} />
      </div>
      <Divider />
      <p className='font-size-16'>2. Filter</p>
      <p className='font-size-10 subtitle'>
        Experiment with different styles that can be applied to your image.
      </p>
      <Grid>
        {filters.map((filter, index) => (
          <Image
            onClick={() => handleSelectFilter(filter)}
            src={filter.image_url}
            key={index}
            label={filter.name}
            selected={selectedFilter?.id === filter.id}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default FilterSelection;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary};
  padding: 15px;
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  & p {
    margin: 0px;

    &.subtitle {
      max-width: 45ch;
      text-align: center;
      opacity: 0.5;
      margin-bottom: 10px;
    }
  }

  & .selected-image {
    width: 124px;
    height: 124px !important;
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightgrey};
  width: 100%;
  max-width: 320px;
  margin-block: 20px;
`;

const SelectedTemplate = styled.div`
  width: 100%;
  max-width: 265px;
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  margin-bottom: 10px;

  & .inner {
    display: flex;
    align-items: center;
    gap: 10px;

    & img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  width: 100%;
  gap: 6px;
  margin-block: 20px;

  & .grid-item {
    aspect-ratio: 1/1;
    border-radius: 4px;
    overflow: hidden;

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;
