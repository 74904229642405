import assets from 'assets';
import React, { useState } from 'react';

function InputPassword(props) {
  const [showPassword, setShowPassword] = useState(false);
  const {
    name,
    type,
    onChange,
    onBlur,
    value,
    className,
    placeholder,
    eye,
    eyeStyles,
    ...rest
  } = props;
  return (
    <>
      {/* Password Eye */}
      {type === 'password' && (
        <div className='position-relative'>
          <input
            type={showPassword ? 'text' : 'password'}
            name={name ? name : 'password'}
            className={className ? className : ''}
            placeholder={placeholder ? placeholder : ''}
            value={value ? value : ''}
            onChange={onChange ? onChange : null}
            onBlur={onBlur ? onBlur : null}
            {...rest}
          />
          {eye === 'true' && (
            <>
              <img
                src={showPassword ? assets.eye : assets.eyeSlash}
                alt=''
                className='eye-icon'
                onClick={() => setShowPassword(!showPassword)}
                style={{ width: '20px', ...eyeStyles }}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default InputPassword;
