import React, { useState, useRef, useEffect, useContext } from 'react';
import './content.css';
import SearchTags from './SearchTags';

import SearchInput from './SearchInput';
import {
  BounceLoader,
  PacmanLoader,
  PropagateLoader,
  ClimbingBoxLoader,
} from 'react-spinners';

import ClickAwayListener from 'react-click-away-listener';

// import Prompts from './Prompts';
// import SmallScreenMenu from './SmallScreenMenu';
// import InPromptSignUpForm from './InPromptSignUpForm';
import {
  convertTextToImage,
  createConvesation,
  getAllDocuments,
  getConversationMessage,
  predictMessage,
  saveDocument,
} from 'container/api';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { EditorContext } from 'container/context/editorContext';

import Avatar from 'assets/images/user-avatar-1.png';
import Message from './Message';
import * as S from './Styles';
import Cookies from 'js-cookie';
import useMediaQuery from 'container/hooks/useMediaQuery';
import { config } from 'container/data/models';
// import FlotingWidget from 'container/components/FlotingWidget';
import Subscription from 'container/components/Subscription/Subscription';
import PaymentForm from './PaymentForm';
import classNames from 'classnames';
import { black_friday_code, modes } from 'app.config';
import styled from 'styled-components';
import CustomButton from 'container/components/CustomButton/CustomButton';
import assets from 'assets';
import toast from 'react-hot-toast';
import SaveDocument from 'container/Dialogs/SaveDocument/SaveDocument';

const loaderConfig = {
  1: BounceLoader,
  2: PacmanLoader,
  3: PropagateLoader,
  4: ClimbingBoxLoader,
};

function Content(props) {
  const isDesktop = useMediaQuery('(max-width: 1000px)');
  // const isLeftSideOpen = useSelector(state => state.utils.isLeftSideBarOpen);
  // const isRightSideOpen = useSelector(state => state.utils.isRightSideBarOpen);
  const selectedPlan = useSelector(state => state.subscription.planId);
  const subRef = useRef(null);
  const dispatch = useDispatch();
  const editor = useContext(EditorContext);
  const {
    // mobileSidebar, setMobileSidebar,
    setSignUpModal, signUpModal, isMobile } =
    props;
  const subscriptionModal = useSelector(state => state.utils.subscriptionModal);
  const planData = useSelector(state => state.subscription.planData);
  const remainingPrompts = useSelector(state => state.chat.remainingPrompts);
  const { openOutliner, mode } = useSelector(state => state.utils);
  const [showMainText] = useState(true);
  const [showMessages] = useState(false);

  const [chat, setChat] = useState([]);
  const [newChat, setNewChat] = useState([]);
  const [predict, setPredict] = useState('');
  const [loading, setLoading] = useState(false);
  const [loaderNumber, setLoaderNumber] = useState(0);
  const [icon, setIcon] = useState('');
  const [imagine, setImagine] = useState(false);
  const Loader = loaderConfig[loaderNumber];
  const model = useSelector(state => state.chat.model);
  const user = useSelector(state => state.auth.user);
  const docLoading = useSelector(state => state.documents.loading);
  const [saveDoc, setSaveDoc] = useState(false);

  const [mobileMenu, setMobileMenu] = useState('content');
  const scroller = useRef(null);
  const hisRef = useRef(null);

  const topBanner = useSelector(state => state.utils.topBanner);
  
  const { selectedConversation, messages } = useSelector(state => state.chat);
  // const user = useSelector(state => state.auth.user);
  const { isLoggedIn } = useSelector(state => state.auth);
  const timeFilter = useSelector(state => state.chat.timeFilter);
  const { selected } = useSelector(state => state.documents);
  const searchRef = useRef(null);
  // const { countryBanner } = useSelector(state => state.location);

  const handleOutliner = () => {
    dispatch.utils.setOpenOutliner(!openOutliner);
  };

  // Predict
  const handlePredict = async () => {
    const cookie = Cookies.get('chatai-auth');
    const remaining = localStorage.getItem('remainingPrompts');
    setImagine(false);
    if (loading || signUpModal || predict.trim() === '') {
      if (predict.trim() === '') {
        toast.error('You cannot send a blank message!');
      }
      return;
    }
    dispatch.chat.setResponseLoading(true);
    try {
      let newConversation = null;
      const cookie = Cookies.get('chatai-auth');
      setNewChat(prev => [
        ...prev,
        {
          avatar: user?.profile?.profile_image || Avatar,
          message: predict,
          type: 'text',
          userMsg: true,
        },
      ]);
      setLoading(true);
      setPredict('');
      const payload = {
        query: predict,
        model: model.value,
      };
      if (isLoggedIn) {
        if (!selectedConversation) {
          const { data } = await createConvesation({
            label: predict,
            model: model?.value,
          });
          payload.conversation_id = data?.payload?.id;

          let query = `?model=${model.value}`;
          if (timeFilter === 'recent') {
            query += `&most_recent=true`;
          } else if (timeFilter === 'favourite') {
            query += `&favourite=true`;
          }

          dispatch.chat.getAllConversations(query);
          newConversation = data.payload;
        } else {
          payload.conversation_id = selectedConversation?.id;
        }
      }
      let data = null;
      let type = 'text';
      let message = '';
      let id = null;
      const isImageCall = /\/imagine\s/.exec(predict);
      if (model.label === 'Picasso' || isImageCall) {
        const apiPayload = {
          query: predict,
        };
        if (isImageCall) {
          apiPayload.query = predict.split('/imagine')[1];
        }
        if (isLoggedIn) {
          apiPayload.conversation_id = payload.conversation_id;
        }

        const res = await convertTextToImage(apiPayload, {
          headers: {
            'chatai-auth': cookie,
          },
        });
        data = res.data;
        type = 'img';
        message = res.data?.image;
      } else {
        const res = await predictMessage(payload, {
          headers: {
            'chatai-auth': cookie,
          },
        });
        data = res.data;
        type = 'text';
        message = res.data?.answer;
      }
      if (
        data.status === 200 &&
        data.Message ===
        'We hope you have been enjoying ChatAI. You have bumped into your free account limit. To upgrade visit https://ui.chatai.com/plans'
      ) {
        dispatch.subscription.setCouponCode(black_friday_code);
        dispatch.utils.setSubscriptionModal(true);
        window.dataLayer.push({
          event: 'custom',
          eventName: 'prompt_completion',
          prompt_number: 0,
        });
      } else if (data.status === 200) {
        id = data?.id;
        setNewChat(prev => [
          ...prev,
          {
            avatar: model.image,
            message: message,
            id: id,
            type: type,
            userMsg: false,
          },
        ]);

        if (planData?.plan?.toLowerCase() === 'free') {
          if (remainingPrompts) {
            window.dataLayer.push({
              event: 'custom',
              eventName: 'prompt_completion',
              prompt_number: remainingPrompts,
            });
          }
        } else {
          window.dataLayer.push({
            event: 'custom',
            eventName: 'prompt_completion',
            prompt_number: 'Unlimited',
          });
        }
      } else if (
        data.status === 405 &&
        data.Message ===
        'Limit Reached, Please provide your email to get more prompts'
      ) {
        if (!isLoggedIn && !loading) {
          setNewChat(prev => [
            ...prev,
            {
              avatar: model.image,
              message:
                'Please complete your sign up to continue chatting for free.',
              type: 'text',
              isMine: true,
              userMsg: false,
            },
          ]);
          setSignUpModal(true);
        }
      }
      if (newConversation) {
        dispatch.chat.setSelectedConversation(newConversation);
        dispatch.chat.setSelectedImage(null)
      }
      if (+remaining) {
        localStorage.setItem('remainingPrompts', +remaining - 1);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = err.response?.data.message;
        console.log(errorMessage);
      }
      setNewChat(prev => [
        ...prev,
        {
          avatar: model.image,
          message:
            'Hmm.. 🤔 seems like we hit a snag. Can you try another variation?',
          type: 'text',
          userMsg: false,
        },
      ]);
    } finally {
      setLoading(false);
      dispatch.chat.handleGetRemainingPromts(cookie);
      dispatch.chat.setResponseLoading(false);
    }
  };

  const handleSwitch = () => {
    if (!selected && editor.getText().length > 0) {
      setSaveDoc(true);
    } else {
      handleSwitchDocument();
    }
  };

  const handleSaveDoc = async title => {
    try {
      dispatch.documents.setLoading(true);
      const data = {
        user: user.id,
        title: title,
        body: editor.getHTML(),
      };
      editor.commands.setContent('');
      dispatch.documents.setSelected(null);
      await saveDocument(data);
      await getAllDocuments();
      handleSwitchDocument();
    } catch (error) {
      console.log(error.message);
    } finally {
      dispatch.documents.setLoading(false);
    }
  };

  const handleSwitchDocument = async () => {
    try {
      dispatch.documents.setLoading(true);
      const id = selectedConversation?.id;
      const { data } = await getConversationMessage(id);
      const messages = data.payload.map(msg => msg.response);
      dispatch.documents.setSelected(null);
      dispatch.utils.setMode(modes.document);
      dispatch.utils.setOpenOutliner(false);
      let contentStr = '';
      messages.forEach((item, index) => {
        const messageStrings = item.split('\n');

        const allString = messageStrings?.map((str, index) => {
          if (str.length > 0) {
            return `<p>${str}</p>`;
          } else if (index !== messageStrings.length - 1 && str.length > 1) {
            return `<br />`;
          } else {
            return '';
          }
        });
        contentStr += allString?.join('');
      });
      editor.commands.setContent(contentStr);
    } catch (err) {
      console.log(err.message);
    } finally {
      dispatch.documents.setLoading(false);
    }
  };
  // const handleDeleteConversation = () => {
  //   dispatch.chat.deleteConversation(selectedConversation.id);
  //   dispatch.chat.setSelectedConversation(null);
  //   setChat([]);
  //   setNewChat([]);
  // };

  useEffect(() => {
    if (scroller.current) {
      if (mobileMenu === 'content') {
        scroller.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (hisRef.current) {
      if (mobileMenu === 'chat-history')
        hisRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [chat, newChat, loading, mobileMenu, signUpModal]);

  useEffect(() => {
    const num = Math.floor(Math.random() * 4) + 1;
    setLoaderNumber(num);
    // eslint-disable-next-line
  }, [newChat]);

  useEffect(() => {
    if (selectedConversation) {
      dispatch.chat.getConversationMessages(selectedConversation.id);
    }
    // eslint-disable-next-line
  }, [selectedConversation]);

  useEffect(() => {
    setChat(messages);
    setNewChat([]);
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    if (isDesktop) {
      dispatch.utils.setLeftSideBar(true);
      dispatch.utils.setRightSideBar(true);
    } else {
      setMobileMenu('content');
    }
    // eslint-disable-next-line
  }, [isDesktop]);

  useEffect(() => {
    if (predict.startsWith('/') && predict.length < 8) {
      setImagine(true);
    } else {
      setImagine(false);
    }
  }, [predict]);

  useEffect(() => {
    getIcon();

    // eslint-disable-next-line
  }, [selectedConversation, predict]);

  useEffect(() => {
    if (subRef.current) {
      subRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [subscriptionModal]);

  const getIcon = () => {
    let query = model.value;
    if (selectedConversation !== null) {
      query = selectedConversation?.model_name;
    }
    const data = config.find(item => item.value === query);
    setIcon(data?.image);
  };


  return (
    <div
      // style={{ minHeight: '', height: 'calc(-134px + 100vh)' }}
      className='content_container'>
      {/* {!isDesktop && (
        <div
          className='left-side-icon'
          onClick={() => dispatch.utils.setLeftSideBar(!isLeftSideOpen)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='currentColor'
            className='bi bi-chevron-left left'
            viewBox='0 0 16 16'
            style={{
              transform: !isLeftSideOpen && 'rotate(0deg)',
            }}
          >
            <path
              fillRule='evenodd'
              stroke='#fff'
              strokeWidth='2'
              d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
            />
          </svg>
        </div>
      )} */}
      {/* Small Screens Menu Header */}
      {mobileMenu === 'content' ? (
        <div
          className={
            `${topBanner ? 'banner-added' : ''} content-inner-box`
            // planData?.plan?.toLowerCase() === 'free' ? 'banner' : ''
            // planData?.plan?.toLowerCase() === 'free' ? 'banner' : ''
          }
          style={{
            // // ...(topBanner && {
            //   minHeight: '100% !important',
            //   height: 'calc(-134px + 100vh) !important',
            // // }),
            transition: '0.4s ease',
            borderTopRightRadius: openOutliner ? '0px' : '',
            borderBottomRightRadius: openOutliner ? '0px' : '',
          }}
          // className={
            // `${topBanner ? 'banner-added': '' } content-inner-box`
            // planData?.plan?.toLowerCase() === 'free' ? 'banner' : ''
            // planData?.plan?.toLowerCase() === 'free' ? 'banner' : ''
          // }
          
        >
          {/* <SmallScreenMenu
            mobileSidebar={mobileSidebar}
            setMobileSidebar={setMobileSidebar}
            showMainText={showMainText}
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
            setNewChat={setNewChat}
            setChat={setChat}
          /> */}
          {/* {!isDesktop && (
            <div
              className='right-side-icon'
              onClick={() => dispatch.utils.setRightSideBar(!isRightSideOpen)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                fill='currentColor'
                className='bi bi-chevron-right right'
                viewBox='0 0 16 16'
                style={{
                  transform: !isRightSideOpen && 'rotate(180deg)',
                }}
              >
                <path
                  fillRule='evenodd'
                  stroke='#fff'
                  strokeWidth='2'
                  d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                />
              </svg>
            </div>
          )} */}

          {selectedConversation && (
            <S.Header className={`${isMobile && 'mobile-bg-home-color'}`}>
              <div className={`left_button-container`}>

                {
                  !isMobile && (<>
                    {selectedConversation && (
                      <p
                        className='chat-heading'
                        style={{
                          maxWidth: '28ch',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          margin: '0px',
                          lineHeight: '21px',
                          fontSize: '14px',
                          fontWeight: '400'
                        }}
                      >
                        {selectedConversation.label?.includes('/imagine')
                          ? selectedConversation.label?.replace('/imagine ', '')
                          : selectedConversation.label}
                      </p>
                    )}
                  </>)
                }
              </div>


              {
                !isMobile && (<>
                  {selectedConversation && (
                    <ButtonContainer>
                      <CustomButton
                        label={isDesktop ? '' : 'Convert Chat to Document'}
                        variant='btn-outlined'
                        rightIcon={assets.documentText}
                        onClick={handleSwitch}
                        loading={docLoading}
                        className={isDesktop ? 'btn-sm' : 'btn-lg'}
                      />
                      <CustomButton
                        onClick={handleOutliner}
                        label={isDesktop ? '' : 'Outliner'}
                        variant='btn-outlined'
                        rightIcon={isDesktop ? assets.outliner : assets.arrowLeft}
                        className={classNames(
                          openOutliner === true ? 'active' : '',
                          isDesktop ? 'btn-sm a' : ''
                        )}
                        rightIconStyles={{
                          transition: '0.4s ease',
                          ...(!isDesktop && {
                            transform: openOutliner
                              ? 'rotate(0deg)'
                              : 'rotate(180deg)',
                          }),
                        }}
                      />
                    </ButtonContainer>
                  )}
                </>)
              }
            </S.Header>
          )}

          
          <S.ContentBox>
            <div className='tags-wrapper'>
              {showMainText && (
                <>
                  <div
                    style={{
                      // marginTop: '80px',
                    }}
                    className={classNames('content-heading')}>
                    <p className='content-text'>
                      Unlock the power of AI
                    </p>
                    <h1 className='help-heading'>
                      How can we help you?
                    </h1>
                  </div>


                  {/* <div className='about-model'>
                    <h1 className='about-heading'>About {model?.label}</h1>
                    <p className='about-text'>{model.about}</p>
                  </div> */}
                </>
              )}
              <div className={`chat_bottom ${showMessages && 'show-message'}`}>
                <SearchTags searchRef={searchRef} setPredict={setPredict} />
                {/* Prompts / Messages */}
                {/* {showMessages && <Prompts />} */}
                <p className='chatbot__text'>
                  Chat-AI is a general knowledge Chatbot. Try asking me about
                  anything.
                </p>
              </div>
            </div>
            {chat.map(item => {
              if (item.response_source === 3) {

                if (mode === modes.image)
                  return (
                    <React.Fragment key={item.id}>
                      <Message
                        message={item.query}
                        avatar={user?.profile?.profile_image || Avatar}
                        date={item.created_at}
                        userMsg={true}
                      />
                      <Message
                        message={item.response}
                        type='img'
                        avatar={icon}
                        date={item.created_at}
                        userMsg={true}
                        id={item.id}
                        tryleap={item?.is_tryleap}
                        tryleapRes={item?.tryleap_res}
                      />
                    </React.Fragment>
                  ); else return null
              } else
                return (
                  <React.Fragment key={item.id}>
                    <Message
                      message={item.query}
                      avatar={user?.profile?.profile_image || Avatar}
                      userMsg={true}
                      date={item.created_at}
                    />
                    <Message
                      message={item.response}
                      avatar={icon}
                      date={item.created_at}
                      userMsg={false}
                      response={true}
                      id={item.id}
                      isLikedMsg={item.is_liked}
                      isDislikedMsg={item.is_disliked}
                    />
                  </React.Fragment>
                );
            })}
            {newChat.map((item, index) => {
              return (
                <Message
                  key={index}
                  message={item.message}
                  avatar={item.user ? Avatar : item.avatar}
                  type={item.type}
                  isUser={item?.user || false}
                  isMine={item?.isMine || false}
                  isSuccess={item?.isSuccess || false}
                  isRedirectLink={item?.isRedirectLink || false}
                  userMsg={item?.userMsg}
                  id={item.id}
                  isLikedMsg={false}
                  isDislikedMsg={false}
                />
              );
            })}

            {loading && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100px',
                  alignItems: 'center',
                }}
                className='text-center my-2 text-primary'
              >
                <Loader color='#ffffff' />
              </div>
            )}
            {/* {signUpModal && (
              <InPromptSignUpForm
                setNewChat={setNewChat}
                newChat={newChat}
                open={signUpModal}
                setOpen={setSignUpModal}
              />
            )} */}
            {/* Subscription Component */}
            {subscriptionModal && (
              <div ref={subRef}>
                <Subscription />
              </div>
            )}
            {selectedPlan && <PaymentForm plan={selectedPlan} />}
            <div ref={scroller} />
          </S.ContentBox>
          <ClickAwayListener onClickAway={() => setImagine(false)}>
            <S.InputBox>
              <SearchInput
                ref={searchRef}
                handlePredict={handlePredict}
                predict={predict}
                setPredict={setPredict}
                loading={loading || signUpModal}
              />

              {imagine && (
                <S.ImageTrigger
                  onClick={() => {
                    setPredict('/imagine ');
                    setImagine(false);
                    searchRef.current.focus();
                  }}
                >
                  <p className='image-text'>/imagine</p>
                </S.ImageTrigger>
              )}
            </S.InputBox>
          </ClickAwayListener>
        </div>
      ) : mobileMenu === 'chat-history' ? null : null}
      {/* {user?.profile?.status === 1 && (
        <FlotingWidget setSubscriptionModal={setSubscriptionModal} />
      )} */}
      {saveDoc && (
        <SaveDocument
          open={saveDoc}
          setOpen={setSaveDoc}
          onCancel={handleSwitchDocument}
          docLoading={docLoading}
          onSave={handleSaveDoc}
        />
      )}
    </div>
  );
}

export default Content;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & .btn-sm {
    background-color: ${({ theme }) => theme.colors.darkgray};
    width: 30px;
    height: 30px;
    padding: 7px;
    border: 1px solid transparent;

    &.a {
      background-color: ${({ theme }) => theme.colors.blue};
      & img {
        width: 30px;
      }
    }
  }
`;

