import assets from 'assets';
import React from 'react';

import styled from 'styled-components';

const Select = ({ options, onChange, value, name, error }) => {
  return (
    <Container className='select-container'>
      <SelectContainer>
        <select name={name} id='' value={value} onChange={onChange}>
          {options?.map(option => (
            <>
              {option.value && option.label && (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              )}
            </>
          ))}
        </select>

        <div className='hider'>
          <img src={assets.triangleArrow} alt='' />
        </div>
      </SelectContainer>
      {error && (
        <p className='font-size-12 error' style={{ background: 'red' }}>
          {error}
        </p>
      )}
    </Container>
  );
};

export default Select;

const Container = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
`;

const SelectContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & select {
    width: 100%;
    height: 100%;
    background-color: #3F4046;;
    outline: none;
    border: none;
    color: ${({ theme }) => theme.colors.primary};
    padding-inline: 10px;
    cursor: pointer;
  }

  & .hider {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 30px;
    background-color: #3F4046;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
`;
