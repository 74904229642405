import React, { useState, useRef, useEffect } from 'react';
import { useContext } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import ClickAwayListener from 'react-click-away-listener';

import icons from 'assets/editor-icons';
import { translationLanguages } from 'app.config';
import { EditorContext } from 'container/context/editorContext';
import { askAiOperations } from 'container/api';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';

const Translate = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const menuRef = useRef(null);
  const anchorRef = useRef(null);

  const editor = useContext(EditorContext);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTranslate = async (event, lng) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      setOpen(false);
      const content = editor.getHTML().toString();
      dispatch.utils.setLoader(true);

      const text = `Translate the content of this html into ${lng.toLowerCase()}. Please keep img tag as it is.: ${content}`;

      const { data } = await askAiOperations({
        option: 'translate',
        text,
      });
      if (data?.status === 200) {
        // let htmlString = '';
        // if (data.data.includes(':')) {
        //   htmlString = data.data.split(':')[1].trim();
        // } else {
        //   htmlString = data.data;
        // }
        const parser = new DOMParser();
        const html = parser.parseFromString(data.data, 'text/html');
        editor.commands.setContent(html.body.innerHTML);
      }
    } catch (err) {
      toast.error(
        "We're sorry but ChatAI is limited to analyzing documents of 4000 characters or about 3 pages. Please highlight the section you want to analyze and right click to have ChatAI go to work.",
        { duration: 10000 }
      );
    } finally {
      dispatch.utils.setLoader(false);
    }
  };

  useEffect(() => {
    if (open && menuRef.current) {
      const dimensions = anchorRef.current.getBoundingClientRect();

      menuRef.current.style.top = `${dimensions.bottom + 5}px`;
      menuRef.current.style.left = `${dimensions.left}px`;
    }
  }, [open]);

  return (
    <Container ref={anchorRef} open={open} id='translate'>
      <div className='menu-opener' onClick={toggleMenu} role='button'>
        <img src={icons.translation} alt='' />
        <div className='triangle'></div>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu ref={menuRef}>
            {translationLanguages.map(lang => (
              <div
                className='menu-item'
                key={lang}
                onClick={e => handleTranslate(e, lang)}
              >
                <p className='font-size-12'>{lang}</p>
              </div>
            ))}
          </Menu>
        </ClickAwayListener>
      )}
      <Tooltip anchorSelect='#translate' content='Translation' />
    </Container>
  );
};

export default Translate;

const Container = styled.div`
  & .menu-opener {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2px;

    & .triangle {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000000;

      transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: ${({ theme }) => theme.colors.darkbg};
  max-height: 300px;
  width: 130px;
  border-radius: 6px;
  overflow: auto;
  z-index: 50;
  transform: translateX(calc(-100% + 20px));

  & .menu-item {
    padding-inline: 10px;
    padding-block: 10px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors.darkgrey};
    }

    & p {
      margin-bottom: 0px;
      white-space: nowrap;
    }
  }
`;
