import React from 'react';

import styled from 'styled-components';

const Suggestion = ({ dotColor }) => {
    return (
        <Container dotColor={dotColor}>
            <div className='flex-row'>
                <div className='indicator'></div>
                <p className='font-size-14'>May be passive voice</p>
            </div>
            <div className='number-count'>2</div>

            <div className='content-container'>
                <ul>
                    <li>of creating products</li>
                    <li>we’ll explore what UX design</li>
                </ul>
            </div>
        </Container>
    );
};

export default Suggestion;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.lightgrey};
  border-radius: 8px;
  padding: 21px 14px;
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  & .flex-row {
    gap: 12px;

    & p {
      opacity: 0.8;
    }
  }

  & .indicator {
    height: 13px;
    width: 13px;
    background-color: ${({ dotColor }) => dotColor};
    border-radius: 50%;
    box-shadow: 0 0 10px ${({ dotColor }) => dotColor};
  }

  & .number-count {
    position: absolute;
    right: 14px;
    top: 14px;
    background-color: ${({ theme }) => theme.colors.darkgray};
    width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    border-radius: 4px;
  }

  & .content-container {
    margin-top: 12px;

    & li {
      font-size: 16px;
    }
  }
`;