import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import CustomButton from 'container/components/CustomButton/CustomButton';
import CancelSubscription from './CancelSubscription/CancelSubscription';
import MonthlySubscription from 'container/components/Subscription/components/MonthlySubscription';
import YearlySubscription from 'container/components/Subscription/components/YearlySubscription';
import FreeSubscription from 'container/components/Subscription/components/FreeSubscription';
import ConfirmationModel from 'container/Dialogs/ConfirmationModel/ConfirmationModel';

import { subscriptionPause, subscriptionResume } from 'container/api';

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.auth.user);
  const remainingPrompts = useSelector(state => state.chat.remainingPrompts);
  const planData = useSelector(state => state.subscription.planData);

  const userLoading = useSelector(state => state.auth.loading);
  const planLoading = useSelector(state => state.subscription.loading);
  const remainingReferrals = useSelector(
    state => state.chat.remainingReferrals
  );

  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);

  const [openFreeModel, setOpenFreeModel] = useState(false);

  const handleCancelSubscription = () => {
    setCancelSubscription(true);
  };

  const handlePlan = async value => {
    dispatch.subscription.setPlanId(value);
    navigate('/');
  };

  const unsubscribePlan = async () => {
    dispatch.subscription.handleUnsubscribeUser({ model: setOpenFreeModel });
  };

  const handlePauseSubscription = async () => {
    try {
      setSubLoading(true);
      const { data } = await subscriptionPause();
      toast.success(<>{data?.Message}</>);
      dispatch.auth.handleGetUser();
      dispatch.subscription.getPlanData();
      setSubLoading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleResumeSubscription = async () => {
    try {
      setResumeLoading(true);
      const { data } = await subscriptionResume();
      toast.success(<>{data?.Message}</>);
      dispatch.auth.handleGetUser();
      dispatch.subscription.getPlanData();
      setResumeLoading(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    const cookie = Cookies.get('chatai-auth');
    if (!user) {
      dispatch.auth.handleGetUser();
    }
    if (!planData) {
      dispatch.subscription.getPlanData();
    }
    dispatch.chat.handleGetRemainingReferrals();
    dispatch.chat.handleGetRemainingPromts(cookie);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(user, remainingReferrals);
  }, [user, remainingReferrals]);

  return (
    <Container>
      <Row>
        <p className='font-size-24'>Manage Subscription</p>
        <Row style={{ gap: '20px' }} className='sub-row'>
          {user?.profile?.status === 5 && (
            <CustomButton
              onClick={handleResumeSubscription}
              label='Resume Subscription'
              variant='btn-grey'
              loading={resumeLoading || planLoading || userLoading}
            />
          )}
          {user?.profile?.status === 2 && (
            <CustomButton
              onClick={handlePauseSubscription}
              label='Pause Subscription'
              variant='btn-grey'
              loading={subLoading || planLoading || userLoading}
            />
          )}
          {user?.profile?.status === 2 && (
            <CustomButton
              label='Cancel Subscription'
              onClick={handleCancelSubscription}
              variant='btn-outlined'
            />
          )}
        </Row>
      </Row>
      {/* {user?.profile?.status === 2 && (
        <p className='font-size-12 desc'>Next billing date: 12/5/2023</p>
      )} */}
      {cancelSubscription && (
        <CancelSubscription setCancelSubscription={setCancelSubscription} />
      )}
      <Grid>
        <YearlySubscription handlePlan={handlePlan} />
        <MonthlySubscription handlePlan={handlePlan} />
        <FreeSubscription
          handleClick={() => {
            if (planData?.plan?.toLowerCase() !== 'free') {
              setOpenFreeModel(true);
              window.dataLayer.push({
                event: 'custom',
                eventName: 'down_grade_select_plan',
                eventCategory: 'Chat-AI Free', //dynamic value of plan name eg. Chat-AI Free
                planPrice: '0', //dynamic plan value eg. 0
                location: 'settings/subscription',
              });
            }
          }}
        />
      </Grid>
      <p className='font-size-16' style={{ marginTop: '30px' }}>
        Remaining prompts :{' '}
        {user?.profile?.status === 2 || remainingReferrals > 0
          ? 'Unlimited prompts'
          : remainingPrompts}
      </p>
      <ConfirmationModel
        open={openFreeModel}
        setOpen={() => setOpenFreeModel(!openFreeModel)}
        action={() => unsubscribePlan()}
        descripiton='Are you sure you want to cancel subscription?'
      />
    </Container>
  );
};

export default Subscription;

const Container = styled.div`
  & p {
    margin: 0px;
    &.desc {
      margin-top: 10px;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.sub {
    margin-top: 50px;
  }

  @media only screen and (max-width: 970px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    &.sub-row {
      flex-direction: row;
    }
  }
  @media only screen and (max-width: 490px) {
    &.sub-row {
      flex-direction: column;
      width: 100%;
      gap: 10px !important;
      & button {
        width: 100%;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
  gap: 20px;
  margin-top: 30px;
  @media only screen and (max-width: 970px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    &.sub-row {
      flex-direction: row;
    }
  }
  @media only screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
