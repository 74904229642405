import React from 'react'
import styled from 'styled-components'

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
      <Container>
          <Tab
              onClick={(event) => {
                  if (event.currentTarget.classList.contains('tab-clicked')) {
                      setActiveTab('Generate')
                  }
              }}
              className={`tab-clicked ${activeTab === "Generate" ? 'active' : ''}`}
          >
              Generate
          </Tab>
          
          <Tab
              onClick={(event) => {
                  if (event.currentTarget.classList.contains('tab-clicked')) {
                      setActiveTab('History')
                  }
              }}
              className={`tab-clicked ${activeTab === "History" ? 'active' : ''}`}
          >
              History
          </Tab>
    </Container>
  )
}

export default Tabs

const Container = styled.div`

    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    gap: 10px;
    background-color: #2E2F33;

    & .active {
        background-color: #2292F9;
        color: #FFFFFF;
        opacity: 1;
        border-radius: 8px;
    }

`

const Tab = styled.div`
    width: 100%;
    /* height: 100%; */
    background-color: #2E2F33;
    border-radius: 4px;
    opacity: 0.20;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
    color: #D9DAE0;

     @media only screen and (max-width: 1000px) {
        padding: 5px 31px 5px 31px !important;
        height: 34px !important;
        width: 120.5px;

    }
`
