/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";
import Slide2 from "./Slide2";
import Slide1 from "./Slide1";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";

const SignupSlider = () => {
  return (
    <Container>
      <Splide
        hasTrack={false}
        className="m-splide"
        options={{
          rewind: true,
          autoplay: true,
          speed: 500,
          interval: 3000,
          width: '100%',
          gap: '1rem',
        }}
      >
        <SplideTrack>
          <SplideSlide>
            <Slide1 />
          </SplideSlide>

          <SplideSlide>
            <Slide2 />
          </SplideSlide>
        </SplideTrack>
      </Splide>
    </Container>
  );
};

export default SignupSlider;

const Container = styled.div`
  display: flex;
  position: relative;
  padding: 50px 0px 50px 0px;
  align-items: center;
  flex-direction: column;

  & .splide__arrows {
    display: none;
  }
  & .splide__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-right: 10px;
    padding: 0px;
    margin-top: 20px;

    & li {
      & button {
        background-color: #87909c;
        height: 6px;
        width: 6px;
        border: none;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        &.is-active {
          background-color: white;
        }
      }
    }
  }
  & .logo {
    display: flex;
    justify-content: center;
    & .logo-text {
      color: var(--primary);
      display: flex;
      justify-content: center;
      flex-direction: column;
      line-height: normal;

      & .name {
        font-size: 38px;
        line-height: 33px;
      }

      & .text {
        font-size: 12px;
        letter-spacing: 1px;
        display: flex;
        justify-content: center;
      }
    }
  }

  & .text-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 464px;

    & .tool-text {
      font-size: 36px;
      line-height: 54px;
      font-weight: 600;
      color: var(--primary);
    }

    & .trail-text {
      padding-top: 5px;
      display: flex;
      justify-content: center;
      color: var(--red);
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
    }
  }

  & .box {
    width: 432px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 18px;
    margin-top: 50px;

    & .img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
    }

    & .user-detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      & .username {
        font-size: 24px;
        line-height: 24px;
        font-weight: 500;
        color: var(--primary);
      }

      & .designation {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: var(--bluish-gray);
      }
    }

    & .stars {
      display: flex;
      gap: 5px;
    }

    & .paragraph {
      text-align: center;
      color: var(--primary);
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    }
  }

  & .checkbox-list {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    justify-content: left;
    flex-direction: column;
    margin-top: 40px;
    /* align-items: center; */
    & .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: var(--primary);
      padding-bottom: 10px;
    }

    & .tick-with-text {
      display: flex;
      align-items: center;
      gap: 20px;
      color: white;
      font-size: 16px;
    }
  }

  & .dot-list {
    display: flex;
    position: absolute;
    bottom: 2%;
    gap: 14px;

    & .dot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #87909c;
      cursor: pointer;
    }

    & .active {
      background-color: white;
    }
  }

  @media only screen and (max-width: 1000px) {

    .m-splide{
      width: 100%;
    }
  }
`;
