import React from 'react';
import styled from 'styled-components';

const DialogLayout = ({ open, children, onClose, width }) => {
  return (
    <>
      {open && (
        <Container onClick={onClose}>
          <div className='inner'>
            <div
              className='cotnet'
              style={{
                width: width && '100%',
                maxWidth: width && '500px',
              }}
            >
              {children}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default DialogLayout;

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  background-color: #1e1e1e99;
  backdrop-filter: blur(2px);
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  & .inner {
    max-height: 95vh;
    /* overflow-y: auto; */
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
