import React, { useContext, useState, useEffect } from 'react';

import { EditorContext } from 'container/context/editorContext';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import icons from 'assets/editor-icons';
import ClickAwayListener from 'react-click-away-listener';
import assets from 'assets';
import CustomButton from '../CustomButton/CustomButton';
import SaveDocument from 'container/Dialogs/SaveDocument/SaveDocument';
import toast from 'react-hot-toast';
import FileUpload from 'container/Dialogs/FileUpload/FileUpload';
import Translate from './Translate';
import { askAiOperations } from 'container/api';
import Download from './Download';
import { Tooltip } from 'react-tooltip';

const ToolBar = ({ selected, setSelected, handleClick, setLink, title }) => {
  const editor = useContext(EditorContext);
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [saveDialog, setSaveDialog] = useState(false);
  const [active, setActive] = useState([]);
  const [fetchFile, setFetchFile] = useState(false);
  const { selected: selectedDocument, loading } = useSelector(
    state => state.documents
  );

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  const handleSelect = val => {
    setSelected(val);
    handleCloseMenu();
  };

  // const handleDiscard = () => {
  //   dispatch.outliner.reset();
  //   editor.commands.setContent('');
  //   editor.chain().focus();
  // };

  const handleSave = () => {
    if (editor.getText() === '') {
      toast.error('Cannot save empty document');
    } else if (selectedDocument) {
      dispatch.documents.updateDocument({
        body: editor.getHTML(),
        showToast: true,
      });
    } else {
      setSaveDialog(true);
    }
  };

  const handleHeadingLevel = level => {
    switch (level) {
      case 1:
        setSelected('Title');
        break;
      case 2:
        setSelected('Subtitle');
        break;
      case 3:
        setSelected('Heading 1');
        break;
      case 4:
        setSelected('Heading 2');
        break;
      default:
        setSelected('Body');
    }
  };

  const handleActiveElements = () => {
    const activeElements = [];
    if (editor.isActive('bold')) {
      activeElements.push('bold');
    }
    if (editor.isActive('italic')) {
      activeElements.push('italic');
    }
    if (editor.isActive('underline')) {
      activeElements.push('underline');
    }
    if (editor.isActive('strike')) {
      activeElements.push('strike');
    }
    if (editor.isActive('highlight')) {
      activeElements.push('highlight');
    }
    if (editor.isActive('superscript')) {
      activeElements.push('superscript');
    }
    if (editor.isActive('subscript')) {
      activeElements.push('subscript');
    }
    if (editor.isActive({ textAlign: 'left' })) {
      activeElements.push('align-left');
    }
    if (editor.isActive({ textAlign: 'center' })) {
      activeElements.push('align-center');
    }
    if (editor.isActive('orderedList')) {
      activeElements.push('order-list');
    }
    if (editor.isActive('bulletList')) {
      activeElements.push('unorder-list');
    }
    if (editor.isActive('link')) {
      activeElements.push('link');
    }
    setActive(activeElements);
  };

  const handleActiveList = val => {
    let list = [...active];
    if (list.includes(val)) {
      list = list.filter(v => v !== val);
    } else {
      list.push(val);
    }

    setActive(list);
  };

  const handleFetchFile = async () => {
    setFetchFile(true);
  };

  const handleSpellCheck = async () => {
    try {
      const content = editor.getHTML().toString();
      dispatch.utils.setLoader(true);

      const { data } = await askAiOperations({
        option: 'fix_spelling_grammar',
        text: content,
      });
      if (data?.status === 200) {
        // eslint-disable-next-line
        // let htmlString = '';
        // if (data.data.includes(':')) {
        //   htmlString = data.data.split(':')[1].trim();
        // } else {
        //   htmlString = data.data;
        // }
        const parser = new DOMParser();
        const html = parser.parseFromString(data.data, 'text/html');
        editor.commands.setContent(html.body.innerHTML);
      }
    } catch (err) {
      toast.error(
        "We're sorry but ChatAI is limited to analyzing documents of 4000 characters or about 3 pages. Please highlight the section you want to analyze and right click to have ChatAI go to work.",
        { duration: 10000 }
      );
    } finally {
      dispatch.utils.setLoader(false);
    }
  };

  useEffect(() => {
    if (editor) {
      switch (selected) {
        case 'Title':
          editor.chain().focus().setHeading({ level: 1 }).run();
          break;
        case 'Subtitle':
          editor.chain().focus().setHeading({ level: 2 }).run();
          break;
        case 'Heading 1':
          editor.chain().focus().setHeading({ level: 3 }).run();
          break;
        case 'Heading 2':
          editor.chain().focus().setHeading({ level: 4 }).run();
          break;
        case 'Body':
          editor.chain().focus().setParagraph().run();
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [selected, editor]);

  useEffect(() => {
    editor.on('selectionUpdate', ({ editor }) => {
      handleHeadingLevel(editor?.getAttributes('heading')?.level);
      handleActiveElements();
    });
    // eslint-disable-next-line
  }, [editor]);

  return (
    <FixedMenu>
      <IconButton
        id='undo'
        onClick={() => handleClick('undo')}
        // style={{ marginLeft: 'auto' }}
      >
        <img src={icons.undo} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#undo' content='Undo' />
      <IconButton id='redo' onClick={() => handleClick('redo')}>
        <img src={icons.redo} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#redo' content='Redo' />

      <FontSelector id='adjust-font'>
        <div className='selector' onClick={handleToggleMenu} role='button'>
          <p className='font-size-18'>{selected}</p>
        </div>
        {openMenu && (
          <>
            <ClickAwayListener onClickAway={handleCloseMenu}>
              <div className='menu'>
                <div
                  className='menu-item'
                  role='button'
                  onClick={() => handleSelect('Title')}
                >
                  <h1 className='l-1'>Title</h1>
                </div>
                <div
                  className='menu-item'
                  role='button'
                  onClick={() => handleSelect('Subtitle')}
                >
                  <h2 className='l-1'>Subtitle</h2>
                </div>
                <div
                  className='menu-item'
                  role='button'
                  onClick={() => handleSelect('Heading 1')}
                >
                  <h3 className='l-1'>Heading 1</h3>
                </div>
                <div
                  className='menu-item'
                  role='button'
                  onClick={() => handleSelect('Heading 2')}
                >
                  <h4 className='l-1'>Heading 2</h4>
                </div>
                <div
                  className='menu-item'
                  role='button'
                  onClick={() => handleSelect('Body')}
                >
                  <p className='l-1'>Body</p>
                </div>
              </div>
            </ClickAwayListener>
          </>
        )}
      </FontSelector>
      <Tooltip anchorSelect='#adjust-font' content='Adjust font size' />
      <IconButton
        id='bold'
        onClick={() => {
          handleClick('bold');
          handleActiveList('bold');
        }}
        className={active.includes('bold') ? 'is-active' : ''}
      >
        <img src={icons.bold} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#bold' content='Bold' />
      <IconButton
        id='italic'
        onClick={() => {
          handleClick('italic');
          handleActiveList('italic');
        }}
        className={active.includes('italic') ? 'is-active' : ''}
      >
        <img src={icons.italic} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#italic' content='Italic' />
      <IconButton
        id='underline'
        onClick={() => {
          handleClick('underline');
          handleActiveList('underline');
        }}
        className={active.includes('underline') ? 'is-active' : ''}
      >
        <img src={icons.underline} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#underline' content='Underline' />
      <IconButton
        id='strike'
        onClick={() => {
          handleClick('strike');
          handleActiveList('strike');
        }}
        className={active.includes('strike') ? 'is-active' : ''}
      >
        <img src={icons.strike} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#strike' content='Strike' />
      <IconButton
        id='highlight'
        onClick={() => {
          handleClick('highlight');
          handleActiveList('highlight');
        }}
        className={active.includes('highlight') ? 'is-active' : ''}
      >
        <img src={icons.highlight} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#highlight' content='Highlight' />
      <IconButton
        id='superscript'
        onClick={() => {
          handleClick('superscript');
          handleActiveList('superscript');
        }}
        className={active.includes('superscript') ? 'is-active' : ''}
      >
        <img src={icons.superscript} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#superscript' content='Superscript' />
      <IconButton
        id='subscript'
        onClick={() => {
          handleClick('subscript');
          handleActiveList('subscript');
        }}
        className={active.includes('subscript') ? 'is-active' : ''}
      >
        <img src={icons.subscript} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#subscript' content='Subscript' />
      <IconButton
        id='alt'
        onClick={() => {
          handleClick('align-left');
          handleActiveList('align-left');
        }}
        className={active.includes('align-left') ? 'is-active' : ''}
      >
        <img src={icons.alignLeft} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#alt' content='Text align left' />
      <IconButton
        id='atc'
        onClick={() => {
          handleClick('align-center');
          handleActiveList('align-center');
        }}
        className={active.includes('align-center') ? 'is-active' : ''}
      >
        <img src={icons.alignCenter} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#atc' content='Text align center' />
      <IconButton
        id='num-list'
        onClick={() => {
          handleClick('order-list');
          handleActiveList('order-list');
        }}
        className={active.includes('order-list') ? 'is-active' : ''}
      >
        <img src={icons.orderList} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#num-list' content='Numbered list' />
      <IconButton
        id='unorder-list'
        onClick={() => {
          handleClick('unorder-list');
          handleActiveList('unorder-list');
        }}
        className={active.includes('unorder-list') ? 'is-active' : ''}
      >
        <img src={icons.unorderList} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#unorder-list' content='Bulleted list' />
      <IconButton
        id='link'
        onClick={() => {
          setLink();
          handleActiveList('link');
        }}
        className={active.includes('link') ? 'is-active' : ''}
      >
        <img src={icons.link} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#link' content='Link' />
      <IconButton id='spell-check' onClick={handleSpellCheck}>
        <img src={icons.spellCheck} alt='' />
      </IconButton>
      <Tooltip anchorSelect='#spell-check' content='Spell check' />
      <Translate />
      <CustomButton
        id='upload-file'
        variant='btn-outlined'
        label={''}
        className='btn'
        onClick={handleFetchFile}
        rightIcon={assets.addDoc}
        rightIconStyles={{width: '16px', height: '16px'}}
        loaderColor={'#000000'}
        style={{ marginLeft: 'auto', padding: '5px', width: '30px', height: '30px' }}
      />
      <Tooltip anchorSelect='#upload-file' content='Upload a file' />
      <Download />
      <CustomButton
        id='save'
        variant='btn-outlined'
        label={''}
        className='btn'
        onClick={handleSave}
        rightIcon={assets.saveDoc}
        rightIconStyles={{ width: '16px', height: '16px' }}
        style={{width: '30px', height: '30px' }}
        loading={loading}
        loaderColor={'#000000'}
      />
      <Tooltip anchorSelect='#save' content='Save document' place='left' />
      {saveDialog && (
        <SaveDocument
          docTitle={title}
          open={saveDialog}
          setOpen={setSaveDialog}
        />
      )}
      {fetchFile && <FileUpload open={fetchFile} setOpen={setFetchFile} />}
    </FixedMenu>
  );
};

export default ToolBar;

const FixedMenu = styled.div`
  padding-block: 7px;
  /* padding-block: 10px; */
  background-color: #EDF2FA; //${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  padding-inline: 10px;
  /* gap: 18px; */
  gap: 15px;
  flex-wrap: wrap;
  width: 100% !important;

  & .btn {
    height: unset;
    padding: 5px;
    color: #000000;
    background-color: transparent; //#f3f4f4;
    cursor: pointer;
    &:hover {
      background-color: #f3f4f4;
    }
  }

  @media only screen and (max-width: 1000px) {
    padding-block: 5px;
    gap: 12px;
  }
`;

const IconButton = styled.button`
  all: unset;

  &.is-active {
    background-color: #0000001a;
  }

  @media only screen and (max-width: 600px) {
    & img {
      width: 20px;
    }
  }
`;

const FontSelector = styled.div`
  position: relative;

  & .selector {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-right: 15px;
    & p {
      margin: 0px;
      color: #000000;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 2px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000000;
  }

  & .menu {
    width: 180px;
    background-color: ${({ theme }) => theme.colors.darkgray};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: translateY(calc(100% + 5px));
    z-index: 2;
    border-radius: 8px;

    & .l-1 {
      line-height: 1;
    }

    & .menu-item {
      padding: 10px 15px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border};
      cursor: pointer;
      & p {
        margin: 0px;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`;
