import React, { useState } from 'react';

import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { subscriptionResume } from 'container/api';

import assets from 'assets';

const UpgradeBanner = () => {
  const disaptch = useDispatch();
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await subscriptionResume();
      toast.success(data?.Message);
      disaptch.auth.handleGetUser();
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <img src={assets.info} alt='' />
      <p>
        Your Chat AI Plan has been paused. You now have a free account{' '}
        <b onClick={handleClick} style={{ cursor: 'pointer' }}>
          Reactivate my subscription
        </b>
      </p>
    </Container>
  );
};

export default UpgradeBanner;

const Container = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff434e;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  border-radius: 58px;
  width: 100%;
  max-width: 480px;

  & img {
  }

  & p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media only screen and (max-width: 500px) {
    width: calc(100% - 40px);
    font-size: 12px;
  }
`;
