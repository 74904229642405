import React, { useContext, useEffect, useState } from 'react';

import { EditorContext } from 'container/context/editorContext';
import assets from 'assets';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import classNames from 'classnames';

const colors = [
  'rgba(33, 37, 41)',
  'rgba(132, 94, 247)',
  'rgba(51, 154, 240)',
  'rgba(34, 184, 207)',
  'rgba(81, 207, 102)',
  'rgba(252, 196, 25)',
  'rgba(255, 107, 107)',
  'rgba(240, 101, 149)',
  'rgba(255, 255, 255)',
  'rgba(95, 61, 196)',
  'rgba(24, 100, 171)',
  'rgba(11, 114, 133)',
  'rgba(43, 138, 62)',
  'rgba(230, 119, 0)',
  'rgba(201, 42, 42)',
  'rgba(194, 37, 92)',
];

const ColorMenu = () => {
  const editor = useContext(EditorContext);
  const [active, setActive] = useState('#ffffff');
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = clr => {
    editor.commands.setColor(clr);
  };

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    editor.on('update', ({ editor }) => {
      const color = editor.getAttributes('textStyle').color;
      setActive(color);
    });
    editor.on('selectionUpdate', ({ editor }) => {
      const color = editor.getAttributes('textStyle').color;
      setActive(color);
    });
  }, [editor]);

  return (
    <Container>
      <div className={classNames('menu-container', openMenu && 'active')}>
        <div
          className='selected-color'
          style={{
            background: active,
          }}
        ></div>
        <div className='menu-opener' role='button' onClick={handleToggleMenu}>
          <img
            src={assets.chevronLeft}
            className={openMenu ? 'active' : ''}
            alt=''
          />
        </div>
      </div>
      {openMenu && (
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Menu>
            {colors.map((clr, index) => (
              <div
                key={index}
                className='grid-item'
                style={{ background: clr }}
                onClick={() => handleClick(clr)}
              ></div>
            ))}
          </Menu>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default ColorMenu;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  position: relative;

    @media only screen and (max-width: 1000px) {
    background-color: rgba(46, 47, 51, 1) !important;
  }

  & .menu-container {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid transparent;
    &.active {
      background-color: ${({ theme }) => theme.colors.darkgrey};
      border: 1px solid ${({ theme }) => theme.colors.border};
    }
  }

  & .selected-color {
    height: 20px;
    width: 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    border: 1px solid #000000;
  }

  & .menu-opener {
    cursor: pointer;
    position: relative;
    & img {
      transform: rotate(-90deg);
      padding: 5px;
      transition: 0.4s ease;
      &.active {
        transform: rotate(-270deg);
      }
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 238px;
  height: 70px;
  background-color: ${({ theme }) => theme.colors.darkbg};
  transform: translateY(calc(-100% - 20px));
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.colors.darkbg} 0px 7px 29px 0px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 4px;
  padding: 8px;

  @media only screen and (max-width: 1000px) {
    background-color: rgba(46, 47, 51, 1) !important;
  }

  & .grid-item {
    aspect-ratio: 1/1;
    border-radius: 4px;
    border: 1px solid #000000;
  }
`;
