import { createModel } from '@rematch/core';
import {
  deleteConversation,
  getConversationMessage,
  getConversations,
  getRemainingPrompts,
  remainingReferralBonus,
} from 'container/api';

export const chat = createModel()({
  name: 'chat',
  state: {
    model: {
      id: '22G1',
      label: 'Chat GPT 4o',
      value: 'gpt-4',
      desc: '(Newest)',
      about:
        'Advanced model with enhanced intelligence, perfect for longer conversations, complex tasks, creative writing, and improved problem-solving.',
    },
    // {
    //   id: '2DA1',
    //   label: 'Chat GPT-3.5',
    //   value: 'gpt-3.5-turbo',
    //   about:
    //     'A highly conversational go-to model for explanations, translations, and creative writing.',
    // },
    msg: '',
    conversations: [],
    selectedConversation: null,
    loading: false,
    messages: [],
    responseLoading: false,
    remainingPrompts: 0,
    remainingReferrals: 0,
    timeFilter: '',
    mobileScreen: 'chat',
    isShowMobileSidebar: false,
    selectedImage: null
  },
  reducers: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setMobileScreen(state, payload) {
      state.mobileScreen = payload;
    },
    setSelectedImage(state, payload) {
      state.selectedImage = payload;
    },
    setConversations(state, payload) {
      state.conversations = payload;
    },
    setSelectedConversation(state, payload) {
      state.selectedConversation = payload;
    },
    setMessages(state, payload) {
      state.messages = payload;
    },
    setModel(state, payload) {
      state.model = payload;
    },
    setResponseLoading(state, payload) {
      state.responseLoading = payload;
    },
    setRemainingPrompts(state, payload) {
      state.remainingPrompts = payload;
    },
    setRemainingReferrals(state, payload) {
      state.remainingReferrals = payload;
    },
    setTimeFilter(state, payload) {
      state.timeFilter = payload;
    },
    setIsShowMobileSidebar(state, payload) {
      state.isShowMobileSidebar = payload;
    }
  },
  effects: dispatch => ({
    async getAllConversations(payload) {
      try {
        dispatch.chat.setLoading(true);
        const { data } = await getConversations(payload);
        dispatch.chat.setConversations(data.conversations);
        return data.conversations
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.chat.setLoading(false);
      }
    },
    // payload = conversation_id
    async getConversationMessages(payload) {
      try {
        dispatch.chat.setLoading(true);
        const { data } = await getConversationMessage(payload);
        dispatch.chat.setMessages(data.payload);

        return data.payload;
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.chat.setLoading(false);
      }
    },
    // payload = conversation_id
    async deleteConversation(payload, state) {
      try {
        const { model, timeFilter } = state.chat;
        dispatch.chat.setLoading(true);
        await deleteConversation(payload);
        let query = `?model=${model.value}`;
        if (timeFilter === 'recent') {
          query += `&most_recent=true`;
        } else if (timeFilter === 'favourite') {
          query += `&favourite=true`;
        }
        const { data } = await getConversations(query);
        dispatch.chat.setSelectedConversation(null);
        dispatch.chat.setSelectedImage(null)
        const conversations = data.conversations;
        if (conversations.length) {
          dispatch.chat.setSelectedConversation(conversations[0]);
        }
        dispatch.chat.setConversations(conversations);
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.chat.setLoading(false);
      }
    },
    // cookie in payload
    async handleGetRemainingPromts(payload) {
      try {
        const token = localStorage.getItem('token');
        dispatch.chat.setLoading(true);
        const remaining = localStorage.getItem('remainingPrompts');

        const { data } = await getRemainingPrompts(
          remaining ? { remaining_prompts: +remaining } : {},
          {
            headers: {
              'chatai-auth': payload,
            },
          }
        );
        if (!token) {
          localStorage.setItem('remainingPrompts', data.remaningprompts);
        }
        dispatch.chat.setRemainingPrompts(data.remaningprompts);
      } catch (error) {
        console.log(error.message);
      } finally {
        dispatch.chat.setLoading(false);
      }
    },
    async handleGetRemainingReferrals() {
      try {
        dispatch.chat.setLoading(true);
        const { data } = await remainingReferralBonus();
        dispatch.chat.setRemainingReferrals(data.remainingdays);
      } catch (error) {
        console.log(error.message);
      } finally {
        dispatch.chat.setLoading(false);
      }
    },
  }),
});
