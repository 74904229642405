import { auth } from './auth';
import { subscription } from './subscription';
import { chat } from './chat';
import { utils } from './utils';
import { chips } from './chips';
import { notifications } from './notifications';
import { shareModal } from './shareModal';
import { imageGeneration } from './imageGeneration';
import { location } from './location';
import { outliner } from './outliner';
import { documents } from './documents';

export const models = {
  auth,
  subscription,
  chat,
  utils,
  chips,
  notifications,
  shareModal,
  imageGeneration,
  location,
  outliner,
  documents,
};
