import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import assets from 'assets';
import { siteURL } from 'app.config';

const ShareWithTwitter = () => {
  const id = useSelector(state => state.chat.selectedConversation?.id || '');
  const handleOnClick = () => {
    const url = `https://twitter.com/intent/tweet?&url=${siteURL}/share/${id}`;
    window.open(url, '_blank');
  };
  return (
    <Container role='button' onClick={handleOnClick}>
      <img src={assets.twitter} alt='' />
      <p className='font-size-12 bold'>Twitter</p>
    </Container>
  );
};

export default ShareWithTwitter;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: ${({ theme }) => theme.colors.primary};
  align-items: center;

  & img {
    width: 60px;
    aspect-ratio: 1/1;
  }
`;
