import React from 'react';

import styled from 'styled-components';
import toast from 'react-hot-toast';
import ConfettiExplosion from 'react-confetti-explosion';

import DialogLayout from '../DialogLayout';
import CustomButton from 'container/components/CustomButton/CustomButton';
import ShareWithWhatsapp from 'container/components/ShareButtons/ShareWithWhatsapp';

import assets from 'assets';

const GiftDialog = ({ open, setOpen, link }) => {
  const handleclose = () => {
    setOpen(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    toast.success('Gift link copied successfully!');
  };

  return (
    <DialogLayout open={open}>
      <Content>
        {open && (
          <ConfettiExplosion
            zIndex={201}
            force={0.6}
            duration={3000}
            particleCount={150}
            width={700}
          />
        )}
        <img src={assets.giftIcon} alt='' />
        <p className='font-size-24 desc'>
          Thank you for your purchase! Your Gift is on the way.
        </p>

        <div className='flex-row' style={{ gap: '20px' }}>
          <IconButton onClick={handleCopyLink}>
            <img src={assets.copy} alt='' />
          </IconButton>
          <ShareWithWhatsapp link={link} className='share-link' />
          <a
            href={`mailto:?subject=${'Chat AI subscription gift'}&body=${link}`}
          >
            <IconButton>
              <img src={assets.email} alt='' />
            </IconButton>
          </a>
        </div>
        <CustomButton label='Close' className='btn' onClick={handleclose} />
        <p className='font-size-12' style={{ marginTop: '-20px' }}>
          You will lose the invite link once you close this window
        </p>
      </Content>
    </DialogLayout>
  );
};

export default GiftDialog;

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgrey};
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  border-radius: 14px;
  position: relative;

  & .confetti {
    position: absolute;
    left: 0;
    top: 0;
    background-color: red;
    width: 100%;
    height: 100%;
  }

  & .share-link {
    height: 40px;
    width: 40px;
    & img {
      width: 100%;
    }
  }

  & .desc {
    font-weight: 500;
    text-align: center;
    max-width: 25ch;
  }

  & .btn {
    font-size: 16px;
    min-width: 180px;
  }
`;

const IconButton = styled.button`
  all: unset;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  display: grid;
  place-items: center;

  & img {
    width: 20px;
  }
`;
