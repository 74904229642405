import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
//
import useMediaQuery from 'container/hooks/useMediaQuery';

function Accordion({ children }) {
  const [showContent, setShowContent] = useState(false);
  const isDesktop = useMediaQuery('(max-width: 550px)');

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  useEffect(() => {
    if (!isDesktop) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
    // eslint-disable-next-line
  }, [isDesktop]);

  return (
    <Container>
      {showContent && children}
      {isDesktop && (
        <div onClick={toggleContent} className="button-grid">
          <p className="bt-text">{showContent ? 'Show Less' : 'Show More'}</p>
          <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transform: `rotate(${showContent ? '90deg' : '270deg'})`,
            }}
          >
            <path
              d="M6.60478 0.895085C6.30066 0.590961 5.81034 0.590961 5.50622 0.895085L0.348535 6.05277C0.106478 6.29482 0.106478 6.68584 0.348535 6.9279L5.50622 12.0856C5.81034 12.3897 6.30066 12.3897 6.60478 12.0856C6.90891 11.7815 6.90891 11.2911 6.60478 10.987L2.11121 6.48723L6.61099 1.98745C6.90891 1.68953 6.90891 1.193 6.60478 0.895085Z"
              fill="white"
            />
          </svg>
        </div>
      )}
    </Container>
  );
}

export default Accordion;

const Container = styled.div`
  margin-top: 10px;
  & .button-grid {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & .bt-text {
      font-size: 14px;
      font-weight: 500;
      color: var(--primary);
      margin-bottom: 0;
    }
  }
`;
