import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';

import { stripePublicKey } from 'app.config';
import StripeForm from './StripeForm/StripeForm';
import theme from 'container/theme';
import assets from 'assets';
import { getCardDetails } from 'container/api';
import { Navigate } from 'react-router-dom';

const stripePromise = loadStripe(stripePublicKey);

const Billing = () => {
  const hasCard = useSelector(state => state.subscription.hasCard);
  const [showForm, setShowForm] = useState(false);
  const [cardData, setCardData] = useState(null);

  const handleUpdate = () => {
    setShowForm(true);
  };

  const handleGetCardDetails = async () => {
    try {
      const { data } = await getCardDetails();
      setCardData(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetCardDetails();
  }, [showForm]);

  if (!hasCard) {
    return <Navigate to='/settings/profile' replace={true} />;
  }
  return (
    <Container>
      <div className='inner'>
        <p className='font-size-24'>Billing Details</p>
        <Row>
          <div>
            <p className='font-size-20' style={{ textTransform: 'capitalize' }}>
              {cardData?.cardBrand} Card
            </p>
            <p className='font-size-12'>
              Expiration date {cardData?.cardExpMonth}/{cardData?.cardExpYear}
            </p>
          </div>
          <div className='row-flex'>
            <img src={assets.card} alt='' />
            <p
              className='font-size-12 '
              style={{ textTransform: 'capitalize' }}
            >
              {cardData?.cardBrand} ****{cardData?.cardLast4}
            </p>
            <Button className='btn' disabled={showForm} onClick={handleUpdate}>
              Update
            </Button>
          </div>
        </Row>
        {showForm && <div
          className='divider'
          style={{ borderTop: '1px solid #000000', marginTop: '50px' }}
        ></div>}
        <StripeFormComponent style={{ display: showForm ? '' : 'none' }}>
          <div className='form-inner'>
            <p className='font-size-20 form-title'>Update Billing Details</p>
            <div className='stripe-elements'>
              <Elements
                stripe={stripePromise}
                options={{
                  mode: 'payment',
                  amount: 100,
                  currency: 'usd',
                  appearance: {
                    theme: 'stripe',
                    variables: {
                      colorText: theme.colors.primary,
                      colorBackground: theme.colors.darkgray,
                      borderRadius: '7px',
                    },
                  },
                }}
              >
                <StripeForm setShowForm={setShowForm} />
              </Elements>
            </div>
          </div>
        </StripeFormComponent>
      </div>
    </Container>
  );
};

export default Billing;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & .inner {
    background-color: ${({ theme }) => theme.colors.darkgrey};
    flex-grow: 1;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const StripeFormComponent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  & .form-inner {
    width: 370px;

    & .form-title {
      text-align: center;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;

  & p {
    margin: 0px;
  }

  & .row-flex {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    & .row-flex {
      & .btn {
        padding-inline: 10px;
        font-size: 12px;
        height: 35px;
      }
    }
  }
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.red} !important;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  height: 43px;
  padding: 0px 30px;
  border-radius: 7px;
  cursor: pointer;

  &:disabled {
    background-color: transparent;
    color: #ffffff99;
    border: 1px solid #ffffff99;
    cursor: not-allowed;
  }
`;
