import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { string, object } from 'yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import CustomButton from 'container/components/CustomButton/CustomButton';
import ImagePicker from 'container/components/ImagePicker/ImagePicker';
import TextField from 'container/components/TextField/TextField';
import { validate } from 'container/utils/validations';
import ConfirmationModel from 'container/Dialogs/ConfirmationModel/ConfirmationModel';
import theme from 'container/theme';

dayjs.extend(utc);

const schema = object({
  first_name: string().required().label('First name'),
  last_name: string().required().label('Last name'),
  phone: string()
    // .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Please Enter a valid phone number')
    .min(11)
    .optional()
    .label('Phone'),
  email: string().email().required().label('Email'),
  password: string().min(8).max(50).optional().label('Password'),
  confirm_password: string().optional(),
});

const Profile = () => {
  const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  // new_gift_subscription

  const params = new URLSearchParams(window.location.search);

  const giftSubscription = params.get('new_gift_subscription');

  const [errors, setErrors] = useState(null);

  const { user, loading } = useSelector(state => state.auth);

  const handleChange = e => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleDeleteAccount = () => {
    dispatch.auth.handleDeleteAccount();
  };

  const handleUpdateUser = async () => {
    try {
      setErrors(null);
      const { password, ...data } = userData;
      const validationErrors = await validate(schema, {
        ...data,
        ...(password.length && { password }),
      });
      let error = false;
      if (validationErrors) {
        error = true;
        setErrors(validationErrors);
      }
      if (password && password !== userData.confirm_password) {
        error = true;
        setErrors(prev => ({
          ...prev,
          confirm_password: 'Confirm password must be same as password',
        }));
      }

      if (profileImage && typeof profileImage === 'object') {
        const formData = new FormData();
        formData.append('profile_image', profileImage);
        dispatch.auth.handleUpdateProfileImage(formData);
      }

      if (error) {
        return null;
      }

      dispatch.auth.handleUpdateUser({
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone_number: userData.phone,
      });
      if (
        password.length &&
        userData.confirm_password.length &&
        userData.password === userData.confirm_password
      ) {
        dispatch.auth.handleUpdatePassword({ password: userData.password });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (!user) {
      dispatch.auth.handleGetUser();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      setUserData({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.profile.sms_number || '',
        password: '',
        confirm_password: '',
      });
      setProfileImage(user.profile.profile_image);
    }
  }, [user]);
  return (
    <Container>
      <p className='font-size-24'>Personal information</p>

      {/* Only for Gift users
      TODO: make it dynamic for gift users
      */}

      {false && (
        <div className='gift'>
          <p className='font-size-16 bold' style={{ marginBottom: '6px' }}>
            Gift awarded
          </p>
          <p className='font-size-12'>
            Duration: 3 months $27.00 <br />
            Expiry: 12-04-2024
          </p>
        </div>
      )}

      <div className='profile-info'>
        <ImagePicker image={profileImage} setImage={setProfileImage} />
        <p className='font-size-12 joined'>
          Joined: {dayjs(user?.date_joined).format('DD/MM/YYYY')}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBlock: '10px ',
          }}
        >
          <CustomButton
            label='Delete Account'
            onClick={() => setConfirm(true)}
            loading={loading}
            style={{ display: 'none', pointerEvents: 'none' }}
          />
        </div>
        <Grid>
          <TextField
            label='First name'
            type='text'
            value={userData.first_name}
            name='first_name'
            placeholder='First name'
            onChange={handleChange}
            error={errors?.first_name}
          />
          <TextField
            label='Last name'
            type='text'
            value={userData.last_name}
            name='last_name'
            placeholder='Last name'
            onChange={handleChange}
            error={errors?.last_name}
          />
          <TextField
            label='Email'
            type='email'
            disabled
            readOnly
            value={userData.email}
          />
          <TextField
            label='Phone'
            type='tel'
            value={userData.phone}
            name='phone'
            placeholder='Phone number'
            onChange={handleChange}
            error={errors?.phone}
          />
          <TextField
            label='Password'
            type='password'
            placeholder='Add new password'
            value={userData.password}
            name='password'
            onChange={handleChange}
            error={errors?.password}
          />
          <TextField
            label='Confirm password'
            type='password'
            placeholder='Confirm new password'
            value={userData.confirm_password}
            name='confirm_password'
            onChange={handleChange}
            error={errors?.confirm_password}
          />
        </Grid>

        {giftSubscription && (
          <p
            className='font-size-14'
            style={{
              marginTop: '30px',
              fontWeight: '500',
              color: theme.colors.blue,
            }}
          >
            Your current password is: temp1234@ Please reset your new password.
          </p>
        )}

        <div className='input-container'>
          <CustomButton
            label='Save changes'
            loading={loading}
            onClick={handleUpdateUser}
          />
        </div>
      </div>
      <ConfirmationModel
        open={confirm}
        setOpen={() => setConfirm(false)}
        title='Delete account'
        btnText='Delete'
        action={handleDeleteAccount}
        descripiton='Are you sure you want to delete your account?'
      />
    </Container>
  );
};

export default Profile;

const Container = styled.div`
  & .joined {
    margin: 0px;
    color: ${({ theme }) => theme.colors.inputLabel};
    text-align: center;
    margin-top: 13px;
  }
  & .profile-info {
    margin-top: 20px;
    margin-inline: auto;
    width: 100%;
    max-width: 700px;
  }

  & .input-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    & button {
      max-width: 350px;
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px 20px;
  margin-top: 30px;
  @media only screen and (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`;
