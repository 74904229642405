import { createModel } from '@rematch/core';
import { deleteDocument, getAllDocuments, updateDocument } from 'container/api';
import toast from 'react-hot-toast';

export const documents = createModel()({
  name: 'documents',
  state: {
    loading: '',
    documents: [],
    selected: null,
    shouldUpdate: true,
  },
  reducers: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setDocuments(state, payload) {
      state.documents = payload;
    },
    setSelected(state, payload) {
      state.selected = payload;
    },
    setShouldUpdate(state, payload) {
      state.shouldUpdate = payload;
    },
  },
  effects: dispatch => ({
    async getDocuments() {
      try {
        dispatch.documents.setLoading(true);
        const { data } = await getAllDocuments();
        dispatch.documents.setDocuments(data.data);
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.documents.setLoading(false);
      }
    },
    async updateDocument(payload, state) {
      const { showToast, body } = payload;
      try {
        dispatch.documents.setLoading(true);
        const selectedDocument = state.documents.selected;
        if (selectedDocument) {
          await updateDocument({
            id: selectedDocument.id,
            title: selectedDocument.title,
            body: body,
          });
          showToast && toast.success('Document updated successfully');
        }
        dispatch.documents.getDocuments();
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.documents.setLoading(false);
      }
      return payload;
    },
    async deleteDocument(payload) {
      try {
        dispatch.documents.setLoading(true);
        await deleteDocument(payload.id);
        dispatch.documents.getDocuments();
        if (typeof payload.after === 'function') payload.after();
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.documents.setLoading(false);
      }
    },
  }),
});
