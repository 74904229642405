import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'

const Promps = () => {
    const user = useSelector(state => state.auth.user);
    const remainingPrompts = useSelector(state => state.chat.remainingPrompts);
    const remainingReferrals = useSelector(
        state => state.chat.remainingReferrals
    );
  return (
      <Container>
          {user?.profile?.status === 2 || remainingReferrals > 0
              ? 'Unlimited prompts'
              : remainingPrompts + ' prompts'}
      </Container>
  )
}

export default Promps

const Container = styled.div`
    color: var(--red);
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
`