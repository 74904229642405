import React, { useState, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import styled from 'styled-components';

import {
  LATEST_RANDOM,
  PLAN_20,
  PLAN_30,
  PLAN_40,
  PLAN_50,
  RANDOM_1,
  abandon5,
  abandon7,
  dynamicCoupons,
  stripePublicKey,
  welcome_discount_code,
} from 'app.config';
import theme from 'container/theme';
import FormComponent from './FormComponent';
import assets from 'assets';
import { useDispatch, useSelector } from 'react-redux';

// public key will go here.
const stripePromise = loadStripe(stripePublicKey);

const PaymentForm = ({ plan, containerStyle }) => {
  const dispatch = useDispatch();

  const { planId } = useSelector(state => state.subscription);
  const params = new URLSearchParams(window.location.search);
  const planData = useSelector(state => state.subscription.planData);
  const couponCode = useSelector(state => state.subscription.couponCode);
  const discountCode = params.get('discount_code') || couponCode;
  const [, setShowThanks] = useState(false);

  const [price, setPrice] = useState(+planId === 1 ? '9' : '90');

  const handleClose = () => {
    // if (+planId === 1 || 2) {
    //   if (planData?.plan?.toLowerCase() === 'free') {
    //     if (+planId === 1) {
    //       window.dataLayer.push({
    //         event: 'custom',
    //         eventName: 'upgrade_close_plan',
    //         eventCategory: 'Chat-AI Plus',
    //         planPrice: '$9',
    //         location: '/',
    //       });
    //     }
    //   } else if (planData?.plan?.toLowerCase() === 'month') {
    //     if (+planId === 2) {
    //       window.dataLayer.push({
    //         event: 'custom',
    //         eventName: 'upgrade_close_plan',
    //         eventCategory: 'Chat-AI Pro',
    //         planPrice: '$90',
    //         location: '/',
    //       });
    //     }
    //   }
    // }
    let status = false;
    const mplan = planData?.plan?.toLowerCase()

    if (mplan === 'free' && (+plan === 1 || +plan === 2)) {
      status = true
    } else if (mplan === 'year' && +plan === 2) {
      status = true
    } else if (mplan === 'month' && (+plan === 1 || +plan === 2)) {
      status = true
    }

    if (status) {
      window.dataLayer.push({
        'event': 'custom',
        'eventName': 'upgrade_close_plan',
        'eventCategory': `Chat-AI ${+plan === 1 ? 'Plus' : 'Pro'}`, //dynamic value of plan name eg. Chat-AI Free
        'planPrice': price, //dynamic plan value eg. 0
        'Location': 'upgrade_profile', //eg. upgrade_ui, upgrade_profile
      });
    }
    dispatch.subscription.setPlanId('');
    setShowThanks(false);
  };

  useEffect(() => {
    if (planData?.plan?.toLowerCase() === 'free') {
      if (+planId === 1 && discountCode === welcome_discount_code) {
        setPrice(7);
      } else if (+planId === 2 && discountCode === welcome_discount_code) {
        setPrice(70);
      } else if (+planId === 1 && discountCode === abandon5) {
        setPrice(5);
      } else if (+planId === 2 && discountCode === abandon5) {
        setPrice(50);
      } else if (+planId === 1 && discountCode === abandon7) {
        setPrice(7);
      } else if (+planId === 2 && discountCode === abandon7) {
        setPrice(70);
      } else if (+planId === 1 && discountCode === PLAN_20) {
        setPrice(7.2);
      } else if (+planId === 2 && discountCode === PLAN_20) {
        setPrice(72);
      } else if (+planId === 1 && discountCode === PLAN_30) {
        setPrice(6.3);
      } else if (+planId === 2 && discountCode === PLAN_30) {
        setPrice(63);
      } else if (+planId === 1 && discountCode === PLAN_40) {
        setPrice(5.4);
      } else if (+planId === 2 && discountCode === PLAN_40) {
        setPrice(54);
      } else if (+planId === 1 && discountCode === PLAN_50) {
        setPrice(4.5);
      } else if (+planId === 2 && discountCode === PLAN_50) {
        setPrice(45);
      } else if (+planId === 1 && discountCode === RANDOM_1) {
        setPrice(1);
      } else if (+planId === 2 && discountCode === RANDOM_1) {
        setPrice(12);
      } else if (+planId === 1 && discountCode === LATEST_RANDOM) {
        setPrice(8)
      } else if (+planId === 2 && discountCode === LATEST_RANDOM) {
        setPrice(78)
      } else {

        const findCoupon = dynamicCoupons.find(x => x.uuid.trim() === discountCode.trim() && x.plan_id === +planId);

        if (findCoupon) {
          setPrice(findCoupon.final_price)
        }
      }
    }
    // eslint-disable-next-line
  }, [planData]);

  return (
    <Wrapper style={containerStyle}>
      <Container>
        {/* Close Button */}
        <div className='close-btn' onClick={handleClose}>
          <img src={assets.closeIcon} alt='' />
        </div>
        <div className='left'>
          <p className='font-size-18'>
            Subscribe to ChatAI {+plan === 1 ? 'Plus' : 'Pro'}{' '}
            {+plan === 1 ? '(Monthly)' : '(Annual)'}
          </p>
          <div className='card-row'>
            <p className='price'>{`$${Number(price).toFixed(2)}`}</p>
            <p className='plan-type'>
              Per <br /> {+plan === 1 ? 'month' : 'year'}
            </p>
          </div>
          <p className='font-size-12'>
            Using AI to improve your life and productivity has never been easier! ChatAI, your AI companion, gives you the power to chat, make documents, and generate images in one place.
          </p>
          {/* <Link to='/settings/gifts'>
            <GiftCard>
              <div className='send-icon'>
                <img src={assets.send} alt='' />
              </div>
              <img src={assets.giftIcon} className='gift-icon' alt='' />
              <p className='font-size-14'>
                Send a Gift to your Friends & Family
              </p>
            </GiftCard>
          </Link> */}
        </div>
        <div className='right'>
          <Elements
            stripe={stripePromise}
            options={{
              mode: 'payment',
              amount: 100,
              currency: 'usd',
              appearance: {
                theme: 'stripe',
                variables: {
                  colorText: theme.colors.primary,
                  colorBackground: theme.colors.darkgray,
                  borderRadius: '7px',
                },
              },
            }}
          >
            <FormComponent setPrice={setPrice} price={price} />
          </Elements>
        </div>
      </Container>
    </Wrapper>
  );
};

export default PaymentForm;

const Wrapper = styled.div`
  padding-inline: 20px;
  margin-top: 30px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.darkbg};
  padding: 20px;
  border-radius: 15px;
  position: relative;

  @media only screen and (max-width: 560px) {
    display: block;
  }
  & .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  & .left {
    color: ${({ theme }) => theme.colors.primary};
    text-align: left;
    & .font-size-18 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }
    & .font-size-12 {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
    & .card-row {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: 10px;
      & .price {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
        width: unset;
        padding: 0;
      }
      & .plan-type {
        font-size: 10px;
        line-height: 12.5px;
        color: #d9dae0;
      }
    }
  }
`;
