import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';
//
import Button from 'container/components/Button';
import Input from 'container/components/Input';
import ErrorMessage from 'container/components/ErrorMessage';
import Spinner from 'container/components/Spinner';
import InputPassword from 'container/components/InputPassword';
import { toast } from 'react-hot-toast';

const PasswordSchema = Yup.object().shape({
  otp: Yup.number().required('OPT is required'),
  password: Yup.string()
    .min(5, 'Too Short!')
    .max(14, 'Too Long!')
    .required('Password Required'),
  confirmPassword: Yup.string()
    .min(5, 'Too Short!')
    .max(14, 'Too Long!')
    .required('Password Required'),
});

function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userEmail = localStorage.getItem('forgot-email');
  const loading = useSelector(state => state.auth.loading);

  const sendEmail = () => {
    dispatch.auth.forgotPasswordEmail({
      email: userEmail,
    });
  };
  return (
    <div className='enter-password-container'>
      <Formik
        initialValues={{ otp: '', password: '' }}
        validationSchema={PasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { otp, password, confirmPassword } = values;
          const userEmail = localStorage.getItem('forgot-email');
          if (password !== confirmPassword) {
            toast.error('Both fields should be same');
            setTimeout(() => {
              setSubmitting(false);
            }, 400);
            return;
          }
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
          const data = { email: userEmail, otp, password };
          dispatch.auth.updatePasswordOtp({ values: data, navigate });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Input
              type='number'
              name='otp'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.otp}
              className='input-email mt-4'
              placeholder='Enter 6 digits OTP'
            />
            <ErrorMessage
              error={errors.otp && touched.otp && errors.otp}
              left={true}
            />
            <InputPassword
              type='password'
              name='password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className='input-email mt-4'
              placeholder='Enter New Password'
              eye='true'
            />
            <ErrorMessage
              error={errors.password && touched.password && errors.password}
              left={true}
            />
            <InputPassword
              type='password'
              name='confirmPassword'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              className='input-email mt-4'
              placeholder='Confirm Password'
              eye='true'
            />
            <ErrorMessage
              error={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
              left={true}
            />
            <p className='account-password' onClick={sendEmail}>
              <span>Resend Email</span>
            </p>
            <Button
              text={loading ? <Spinner /> : 'Update'}
              className='account-button mb-4 mt-2'
              type='submit'
              disabled={loading}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePassword;
