import React, { useRef, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import assets from 'assets';
import ImageLoading from './ImageLoading';

const GeneratedImage = ({ data, tryleapData, isMobile }) => {
  const dispatch = useDispatch();
  const mainRef = useRef(null)

  const [loading, setLoading] = useState(true);
  const { grid } = useSelector(state => state.imageGeneration);
  // eslint-disable-next-line
  const handleOpenInModal = () => {
    dispatch.imageGeneration.setModalData(tryleapData);
    dispatch.imageGeneration.setOpenModal(true);
  };
  

  return (
    <ImageContainer ref={mainRef}
      style={{
        ...(isMobile ? {
          width: mainRef.current?.parentElement?.parentElement?.parentElement?.clientWidth+'px',
          height: '100%'
        } : {
            maxWidth: grid === '1' ? '450px' : '',
        })
      }}
    >
      <img
        src={data.result_images}
        onLoad={() => setLoading(false)}
        className='img'
        alt=''
        style={{ display: loading ? 'none' : 'block' }}
      />
      <div className='overly'>
        <a href={data.result_images} rel='noreferrer' download target='_blank'>
          <div className='icon-button' style={{ backgroundColor: '#ffffff' }}>
            <img src={assets.download} alt='' />
          </div>
        </a>
        {/* <div className='icon-button' role='button' onClick={handleOpenInModal}>
          <img src={assets.expand} alt='' />
        </div> */}
      </div>
      {loading && <ImageLoading />}
    </ImageContainer>
  );
};

export default GeneratedImage;

const ImageContainer = styled.div`
  /* width: 100%; */
  aspect-ratio: 1/1;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  width: 335px;
  height: 335px;


  & .img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  & .overly {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    flex-direction: column-reverse;
    align-items: flex-end;

    & .icon-button {
      height: 34px;
      width: 34px;
      border-radius: 50%;
      display: grid;
      place-items: center;

      &:last-of-type {
        background-color: #35363a99;
      }
    }
  }
`;
