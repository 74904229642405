import assets from 'assets'
import React, { useState } from 'react'
import styled from 'styled-components'
import ClickAwayListener from 'react-click-away-listener';
import { imageGenerationModels } from 'app.config';
import { useDispatch, useSelector } from 'react-redux';

const ModelDropdown = ({ isMobile }) => {

    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const { model } = useSelector(state => state.imageGeneration);

    const handleChangeModel = value => {
        dispatch.imageGeneration.setModel(value);
    };

    const handleClose = () => {
        setOpen(false)
    }

    const value = imageGenerationModels.find(x => x.value.trim() === model.trim())
    return (
        <Container>
            <div
                onClick={() => setOpen(true)}
                className={`dropdown-value ${isMobile && 'mobileDropdown-value'}`}>
                <div className='flex gap-10 align-items-center'>
                    <img src={value?.image} alt='' width={24} height={24} />
                    <span className='name' >{value?.label}</span>
                </div>
                <div>
                    <img style={{ transform: open ? 'rotate(180deg)' : '' }} src={assets.dropdownArrow} alt='' />
                </div>
            </div>

            {
                open && <ClickAwayListener onClickAway={handleClose}>
                    <div className='dropdown-options'>
                        {
                            React.Children.toArray(imageGenerationModels.map((item) => (
                                <div
                                    onClick={() => {
                                        handleChangeModel(item.value)
                                        handleClose()
                                    }}
                                    className={`option ${value?.label === item.label ? 'active' : ''} ${item.disabled && 'disabled'}`}>
                                    <img width={24} height={24} style={{ borderRadius: '5px' }} src={item.image} alt='' />
                                    <span className='name'>{item.label}</span>
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            opacity: '0.5',
                                            lineHeight: 'inherit',
                                        }}
                                    >
                                        {item.desc}
                                    </span>
                                </div>
                            )))
                        }
                    </div>
                </ClickAwayListener>
            }
        </Container>
    )
}

export default ModelDropdown

const Container = styled.div`
    position: relative;
    width: 100%;
    & .gap-10{
        gap: 10px;
    }

    & .mobileDropdown-value{
        border: 1px solid rgba(60, 62, 69, 1) !important;
        border-radius: 8px;
        height: 55px;
        width: 100%;
    }

    & .dropdown-value{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 54px;
        border-bottom: 1px solid #4D4F56;
        border-top: 1px solid #4D4F56;
        
        padding: 14px;
        cursor: pointer;

        & .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
        }

        & .dot{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #D9DAE0;
        }
    }

    & .dropdown-options{
        position: absolute;
        z-index: 9999;
        width: 100%;
        height: 400px;
        overflow-y: auto;
        border-radius: 8px;
        border: 1px solid #4D4F56;
        padding: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: #35363A;

        & .option{
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            padding: 8px 10px;
            cursor: pointer;

            & .name{
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: var(--primary);
            }
        }

        & .active{
            background-color: #4D4F56;
        }

        & .disabled{
            opacity: 0.3;
            cursor: not-allowed;
        }

    }

`