import React from 'react';

function Input({ name, id, type, className, placeholder, ...rest }) {
  return (
    <>
      {type === 'email' || 'text' ? (
        <input
          name={name ? name : 'text'}
          id={id ? id : 'id'}
          type={type ? type : 'text'}
          className={className ? className : ''}
          placeholder={placeholder ? placeholder : ''}
          {...rest}
        />
      ) : null}
    </>
  );
}

export default Input;
