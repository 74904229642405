import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ConversationList from '../ConversationList/ConversationList';
import { useSelector } from 'react-redux';
import { config } from 'container/data/models';

const ImageConversionHistory = () => {
    const [sortedConversations, setSortedConversations] = useState([]);
    const { loading, conversations } = useSelector(state => state.imageGeneration);

    const sortArray = async () => {
        const sortedConvoArray = await sortArrayByLatestDate([...conversations]);
        setSortedConversations([...sortedConvoArray]);
        // await sortArrayByLatestDate([...conversations])
        // setSortedConversations([]);
    };

    useEffect(() => {
        sortArray();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversations]);

    const sortArrayByLatestDate = arr => {
        return arr.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);

            return dateB - dateA;
        });
    };

    const getIcon = model => {
        const data = config.find(item => item.value === model);
        return (
            <img
                src={data?.image}
                alt={data?.value}
                style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '5px',
                    marginTop: '-2px',
                }}
            />
        );
    };
    return (
        <ConversationContainer>
            {sortedConversations?.map(conversation => {
                return (
                    <ConversationList
                        key={conversation.id}
                        conversation={conversation}
                        // handleDelete={handleDelete}
                        loading={loading}
                        // handleSelect={handleSelect}
                        // selected={selected}
                        getIcon={getIcon}
                    />
                );

            })}
        </ConversationContainer>
    )
}

export default ImageConversionHistory

const ConversationContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`