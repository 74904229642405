import React from 'react';
import styled from 'styled-components';

function InputEmail(props) {
  const {
    name = 'text',
    id = 'id',
    type = 'text',
    className = '',
    placeholder = '',
    style,
    ...rest
  } = props;

  return (
    <Container style={style}>
      <p className='email-heading' style={{ margin: '0px', paddingTop: '0px' }}>
        Email
      </p>
      <Input
        name={name}
        id={id}
        type={type}
        className={className}
        placeholder={placeholder}
        {...rest}
      />
    </Container>
  );
}

export default InputEmail;

const Container = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.darkgray};
  border-radius: 7px;
  border: none;
  align-items: center;

  & .email-heading {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
    padding: 5px 0 0 14px;
  }
`;
const Input = styled.input`
  background: transparent;
  color: var(--primary);
  padding: 14px 14px 14px 20px;
  border: none;
  width: 100%;
  border-radius: 7px;
  height: 48px;
  opacity: 1;
`;
