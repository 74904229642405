import React, { useContext, useEffect, useState } from 'react';

import styled from 'styled-components';
import GaugeChart from 'react-gauge-chart';
import { EditorContext } from 'container/context/editorContext';
// import FleschKincaid from 'flesch-kincaid-calc';
import { syllable } from 'syllable';

import Suggestion from './Suggestion';

import assets from 'assets';
import useMediaQuery from 'container/hooks/useMediaQuery';
import { useDispatch } from 'react-redux';
const Analysis = () => {

    const dispatch = useDispatch();
    const editor = useContext(EditorContext);
    const isMobile = useMediaQuery('(max-width: 1000px)');

    const [text, setText] = useState('');
    const [readabilityScore, setReadabilityScore] = useState(null);
    // const [gradeLevel, setGradeLevel] = useState(null);

    const calculateFleschKincaid = () => {
        if (text) {
            const words = text.split(/\s+/);
            const syllableCount = words.reduce(
                (count, word) => count + syllable(word),
                0
            );

            const totalWords = words.length;
            const totalSentences = text.split(/[.!?]+/).length;

            const readingEase =
                206.835 -
                1.015 * (totalWords / totalSentences) -
                84.6 * (syllableCount / totalWords);

            setReadabilityScore(readingEase.toFixed(0));

            // const mappedGradeLevel =
                mapToFleschKincaidGradeLevel(
                readingEase.toFixed(0)
            );
            // setGradeLevel(mappedGradeLevel);
        }
    };

    const mapToFleschKincaidGradeLevel = readingEase => {
        if (readingEase >= 90 && readingEase <= 100) {
            return 'Grade 5th';
        } else if (readingEase >= 80 && readingEase <= 89) {
            return 'Grade 6th';
        } else if (readingEase >= 70 && readingEase <= 79) {
            return 'Grade 7th';
        } else if (readingEase >= 60 && readingEase <= 69) {
            return 'Grade 8th and 9th';
        } else if (readingEase >= 50 && readingEase <= 59) {
            return 'High school';
        } else if (readingEase >= 30 && readingEase <= 49) {
            return 'College';
        } else {
            return 'College graduate';
        }
    };

    useEffect(() => {
        if (editor) {
            setText(editor.getText());
        }
        if (text.length > 0) {
            calculateFleschKincaid();
        }
        // eslint-disable-next-line
    }, [text]);

    const handleCloseAnalysis = () => {
        dispatch.utils.setOpenAnalysis(false);
    }

    return (
        <Container>
            <Header>
                <div className='flex-row'>
                    <div className='img-box'>
                        <img
                            src={assets.chart2}
                            alt=''
                            width={20}
                            height={20}
                        />
                    </div>
                    <p className='font-size-20'>Analysis</p> 
                </div>
                {isMobile && (
                    <div
                        className='clickable'
                        role='button'
                        onClick={handleCloseAnalysis}
                    >
                        <img src={assets.close} alt='' style={{ width: '40px' }} />
                    </div>
                )}
            </Header>
            <Content>
                <p className='font-size-16'>Readability</p>
                <GaugeChart
                    nrOfLevels={3}
                    colors={['#C92A2A', '#FCC419', '#00FF87']}
                    arcWidth={0.15}
                    percent={readabilityScore / 100}
                    needleColor='#EDEDED'
                    needleBaseColor='#EDEDED'
                    className='slider'
                    formatTextValue={text => `${text}`}
                    fontSize='50px'
                    textColor='#00FF87'
                />
                <p className='font-size-12 gauge-text'>Easy to understand</p>
                <SeoScore>
                    <p className='font-size-16' style={{ fontWeight: '600' }}>
                        SEO Score
                    </p>
                    <p className='font-size-16'>{readabilityScore || 0} / 100</p>
                </SeoScore>
                <Suggestions>
                    <div className='header'>
                        <p className='font-size-16' style={{ fontWeight: '600' }}>
                            Suggestions
                        </p>
                        <p className='font-size-16 count'>3 Suggestions</p>
                    </div>
                    <div className='inner'>
                        <Suggestion dotColor='#0CB48C' />
                        <Suggestion dotColor=' #D93E3E' />
                        <Suggestion dotColor='#D9C93E' />
                        <Suggestion dotColor='#206CB4' />
                    </div>
                </Suggestions>
            </Content>
        </Container>
    );
};

export default Analysis;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  flex-direction: column;

  & p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.primary};
  }
  & .pad {
    padding: 12px;

    & button {
      width: 100%;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 14.5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  & .img-box{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    padding: 6px;
    background-color: #2292F9;
  }

  & .flex-row {
    gap: 10px;
  }
`;

const Content = styled.div`
  padding: 12px 10px;
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  & .gauge-text {
    opacity: 0.7;
    text-align: center;
    margin-top: -10px;
    margin-bottom: 24px;
  }
`;

const SeoScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const Suggestions = styled.div`
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 24px 12px;
    & .count {
      color: ${({ theme }) => theme.colors.primary + '99'};
    }
  }
`;
