import React, { useEffect, useState } from 'react';
import DialogLayout from '../DialogLayout';
import styled from 'styled-components';
import assets from 'assets';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
const ImageDetailDialog = () => {
  const dispatch = useDispatch();
  const { openModal, modalData: generatedImage } = useSelector(
    state => state.imageGeneration
  );

  const [images, setImages] = useState([
    { img: assets.preview, active: true },
    { img: assets.preview, active: false },
    { img: assets.preview, active: false },
    { img: assets.preview, active: false },
  ]);
  const [selectedImage, setSelectedImage] = useState({
    img: assets.preview,
    active: true,
  });

  const handleClose = () => {
    dispatch.imageGeneration.setModalData(null);
    dispatch.imageGeneration.setOpenModal(false);
  };

  useEffect(() => {
    if (generatedImage) {
      setImages([{
        img: generatedImage?.output.result_images,
        active: true
      }])

      setSelectedImage({ img: generatedImage?.output.result_images, active: true });
    }
    // const imageData = generatedImage?.images.map((image, i) => ({
    //   img: image.uri,
    //   active: i === 0 ? true : false,
    // }));
    // setImages(imageData);

    // setSelectedImage({ img: generatedImage?.images[0].uri, active: true });
    // eslint-disable-next-line
  }, []);

  return (
    <DialogLayout open={openModal}>
      <Container>
        <Row>
          <p className='font-size-20'>Details</p>
          <div
            role='button'
            className='close-button cursor-pointer'
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: '21px',
              right: '40px',
            }}
            onClick={handleClose}
          >
            <img src={assets.closeIcon} alt='' />
          </div>
        </Row>

        <Row className='main-row' style={{ alignItems: 'stretch' }}>
          <Col style={{ marginTop: '30px' }} className='detail'>
            <InnerRow>
              <LeftCol>Job id</LeftCol>
              <RightCol>
                <InputContainer>
                  <input type='text' value={generatedImage?.id} readOnly />
                </InputContainer>
              </RightCol>
            </InnerRow>

            <InnerRow>
              <LeftCol>Status</LeftCol>
              <RightCol>
                <InputContainer>
                  <input type='text' value={generatedImage?.status} readOnly />
                </InputContainer>
              </RightCol>
            </InnerRow>

            <InnerRow>
              <LeftCol>Prompt</LeftCol>
              <RightCol>
                <InputContainer>
                  <textarea rows={3} value={generatedImage?.input?.prompt} readOnly />
                </InputContainer>
              </RightCol>
            </InnerRow>

            <InnerRow>
              <LeftCol>Image Size</LeftCol>
              <RightCol>
                <InputContainer>
                  <input type='text' value={generatedImage?.input.image_size} readOnly />
                </InputContainer>
              </RightCol>
            </InnerRow>

            {/* <InnerRow>
              <LeftCol>Negative Prompt</LeftCol>
              <RightCol>
                <InputContainer>
                  <textarea
                    rows={3}
                    value={generatedImage?.negativePrompt}
                    readOnly
                  />
                </InputContainer>
              </RightCol>
            </InnerRow> */}

            <InnerRow>
              <LeftCol>Started at</LeftCol>
              <RightCol>
                <InputContainer>
                  <input
                    type='text'
                    value={dayjs(generatedImage?.created_at).format('DD-MM-YYYY, HH:mm:ss')}
                    readOnly
                  />
                </InputContainer>
              </RightCol>
            </InnerRow>
            <InnerRow>
              <LeftCol>Created at</LeftCol>
              <RightCol>
                <InputContainer>
                  <input
                    type='text'
                    value={dayjs(generatedImage?.created_at).format('DD-MM-YYYY, HH:mm:ss')}
                    readOnly
                  />
                </InputContainer>
              </RightCol>
            </InnerRow>
            <InnerRow>
              <LeftCol>Ended at</LeftCol>
              <RightCol>
                <InputContainer>
                  <input
                    type='text'
                    value={dayjs(generatedImage?.ended_at).format('DD-MM-YYYY, HH:mm:ss')}
                    readOnly
                  />
                </InputContainer>
              </RightCol>
            </InnerRow>

            {/* <InnerRow className='no-padding'>
              <LeftCol className='width-50'>
                <InnerRow>
                  <LeftCol>Steps</LeftCol>
                  <RightCol>
                    <InputContainer>
                      <input
                        type='text'
                        value={generatedImage?.steps}
                        readOnly
                      />
                    </InputContainer>
                  </RightCol>
                </InnerRow>
              </LeftCol>
              <RightCol className='width-50'>
                <InnerRow>
                  <LeftCol>Strength</LeftCol>
                  <RightCol>
                    <InputContainer>
                      <input
                        type='text'
                        value={generatedImage?.promptStrength}
                        readOnly
                      />
                    </InputContainer>
                  </RightCol>
                </InnerRow>
              </RightCol>
            </InnerRow>

            <InnerRow className='no-padding'>
              <LeftCol className='width-50'>
                <InnerRow>
                  <LeftCol>Height</LeftCol>
                  <RightCol>
                    <InputContainer>
                      <input type='text' value={generatedImage?.height} />
                    </InputContainer>
                  </RightCol>
                </InnerRow>
              </LeftCol>
              <RightCol className='width-50'>
                <InnerRow>
                  <LeftCol>Width</LeftCol>
                  <RightCol>
                    <InputContainer>
                      <input type='text' value={generatedImage?.width} />
                    </InputContainer>
                  </RightCol>
                </InnerRow>
              </RightCol>
            </InnerRow> */}

            {/* <InnerRow className='no-padding'>
              <LeftCol className='width-50'>
                <InnerRow>
                  <LeftCol># Images</LeftCol>
                  <RightCol>
                    <InputContainer>
                      <input
                        type='text'
                        value={generatedImage?.output?.result_images}
                      />
                    </InputContainer>
                  </RightCol>
                </InnerRow>
              </LeftCol>
              <RightCol className='width-50'>
                <InnerRow>
                  <LeftCol>Seed</LeftCol>
                  <RightCol>
                    <InputContainer>
                      <input type='text' value={generatedImage?.seed} />
                    </InputContainer>
                  </RightCol>
                </InnerRow>
              </RightCol>
            </InnerRow> */}
          </Col>
          <Col className='img'>
            <RightSideContainer className='padding-50-20'>
              <InnerRow style={{ paddingInline: '20px', margin: '0px' }}>
                <Col className='width-100'>
                  <div class='img-container'>
                    {selectedImage && (
                      <>
                        <img
                          src={selectedImage.img}
                          width={'100%'}
                          height={'100%'}
                          style={{ borderRadius: '8px', aspectRatio: '1/1' }}
                          alt=''
                        />
                        <a
                          href={selectedImage.img}
                          rel='noreferrer'
                          className='fa-download'
                          download
                          target='_blank'
                        >
                          <div className='img-icon'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='14'
                              height='14'
                              viewBox='0 0 14 14'
                              fill='none'
                            >
                              <g clip-path='url(#clip0_2401_5881)'>
                                <path
                                  d='M7.01894 11.0833C7.24883 11.0837 7.47654 11.0387 7.68897 10.9508C7.9014 10.8629 8.09436 10.7339 8.25677 10.5712L10.5429 8.28333L9.71802 7.46083L7.59877 9.58067L7.58361 0H6.41694L6.43211 9.57133L4.32044 7.45967L3.49561 8.28333L5.78169 10.5694C5.9439 10.7323 6.13671 10.8616 6.34904 10.9498C6.56137 11.038 6.78902 11.0834 7.01894 11.0833Z'
                                  fill='white'
                                />
                                <path
                                  d='M12.8333 9.33325V12.2499C12.8333 12.4046 12.7719 12.553 12.6625 12.6624C12.5531 12.7718 12.4047 12.8332 12.25 12.8332H1.75C1.59529 12.8332 1.44692 12.7718 1.33752 12.6624C1.22812 12.553 1.16667 12.4046 1.16667 12.2499V9.33325H0V12.2499C0 12.714 0.184374 13.1592 0.512563 13.4873C0.840752 13.8155 1.28587 13.9999 1.75 13.9999H12.25C12.7141 13.9999 13.1592 13.8155 13.4874 13.4873C13.8156 13.1592 14 12.714 14 12.2499V9.33325H12.8333Z'
                                  fill='white'
                                />
                              </g>
                              <defs>
                                <clipPath id='clip0_2401_5881'>
                                  <rect width='14' height='14' fill='white' />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </a>
                      </>
                    )}
                  </div>
                </Col>
              </InnerRow>

              <InnerRow>
                <Col className='width-100'>
                  <div className='images-list'>
                    {generatedImage?.output?.result_images &&
                      images.map((image, index) => (
                        <div
                          key={index}
                          className={`image-div ${
                            image.active && 'image-active'
                          }`}
                          onClick={() => {
                            const imglist = [...images];
                            images.map(img => {
                              return (img.active = false);
                            });
                            imglist[index].active = true;
                            setImages(imglist);
                            setSelectedImage(imglist[index]);
                          }}
                        >
                          <img
                            src={image.img}
                            width={'50px'}
                            height={'50px'}
                            alt=''
                          />
                        </div>
                      ))}
                  </div>
                </Col>
              </InnerRow>
            </RightSideContainer>
          </Col>
        </Row>
      </Container>
    </DialogLayout>
  );
};

export default ImageDetailDialog;

const Container = styled.div`
  width: 100%;
  max-width: 1230px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.darkbg};
  z-index: 1000;
  color: ${({ theme }) => theme.colors.primary};
  padding: 25px;
  position: relative;

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.colors.primary + '1a'};
  }

  @media only screen and (max-width: 750px) {
    width: calc(100vw - 20px);
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
  gap: 20px;

  & p {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 750px) {
    padding-inline: 0px;
    &.main-row {
      flex-direction: column-reverse;
      margin-top: 10px;

      & .detail {
        width: 100%;
      }

      & .img {
        width: 100%;
      }
    }
  }
`;

const InnerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-inline: 20px; */
  width: 100%;
  margin-bottom: 30px;
  gap: 20px;

  &:last-of-type {
    margin-bottom: 0px;
  }

  & p {
    margin-bottom: 0px;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 4px;
  width: 50%;

  &:last-of-type {
    max-width: 570px;
  }

  @media only screen and (max-width: 750px) {
    &:last-of-type {
      max-width: 100%;
    }
  }
`;

const LeftCol = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 4px;
  width: 50%;
`;

const RightCol = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 4px;
  width: 70%;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: right;

  & input {
    height: 45px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    border: none;
    border-radius: 8px;
    padding-inline: 14px;
    color: ${({ theme }) => theme.colors.primary};
  }

  & textarea {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    border: none;
    border-radius: 8px;
    padding-inline: 14px;
    color: ${({ theme }) => theme.colors.primary};
    resize: none;
    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.lightgrey};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.darkgrey};
    }
  }

  & .eye-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
`;

const RightSideContainer = styled.div`
  background-color: #18191b;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-inline: auto;
`;
