import React from 'react';

import styled from 'styled-components';

const Switch = ({ ...props }) => {
  return <SwitchButton type='checkbox' {...props} />;
};

export default Switch;

const SwitchButton = styled.input`
  appearance: none;
  -webkit-appearance: none;
  height: 16px;
  width: 28px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-out;
  &::before {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
    top: 2px;
    left: 2px;
    transition: 0.3s ease-out;
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors.red};
  }
  &:checked::before {
    left: 14px;
  }
`;
