import React from "react";
import { Link } from "react-router-dom";

export default function Button(props) {
  const btnText = props.text ? props.text : "Click me";

  return <RenderIconButton {...props}>{btnText}</RenderIconButton>;
}

const RenderIconButton = (props) => {
  const btnClasses = props.className ? props.className : "",
    btnType = props.type ? props.type : "button",
    isBtnAnchor = props.isAnchor ? props.isAnchor : false,
    isBtnLink = props.isLink ? props.isLink : false,
    btnAnchorHref = props.anchorHref ? props.anchorHref : "#",
    disabled = props.disabled ? props.disabled : false;

  return (
    <>
      {isBtnAnchor && (
        <a
          className={`icon_btn text-capitalize ${btnClasses}`}
          style={{ ...props.btnStyle }}
          href={btnAnchorHref}
        >
          {props.children}
        </a>
      )}
      {isBtnLink && (
        <Link
          className={`icon_btn text-capitalize ${btnClasses}`}
          style={{ ...props.btnStyle }}
          to={btnAnchorHref}
        >
          {props.children}
        </Link>
      )}
      {!isBtnAnchor && !isBtnLink && (
        <button
          disabled={disabled}
          className={`icon_btn text-capitalize ${btnClasses}`}
          style={{ ...props.btnStyle }}
          type={btnType}
          {...props}
        >
          {props.children}
          {props.spinner && props.isSubmitting && <BtnSpinner />}
        </button>
      )}
    </>
  );
};

const BtnSpinner = () => {
  return (
    <div className="wrap-btn-spinner">
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
