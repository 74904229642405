import React, { useState } from 'react';
import styled from 'styled-components';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import Button from 'container/components/Button';
import theme from 'container/theme';
import Spinner from 'container/components/Spinner';
import { useSelector } from 'react-redux';
import { string, object } from 'yup';
import assets from 'assets';
// import CustomButton from 'container/components/CustomButton/CustomButton';
import { validate } from 'container/utils/validations';
import {
  createGiftUser,
  klaviyoGiftUser,
  makePayment,
  stripeRecord,
  upgradePlan,
} from 'container/api';
import toast from 'react-hot-toast';
import GiftDialog from 'container/Dialogs/GiftDialog/GiftDialog';
import { giftOptions, siteURL } from 'app.config';

const schema = object({
  email: string().email().required().label('Email'),
  f_email: string().email().required().label("Receiver's email"),
  first_name: string().min(3).required().label('First Name'),
  last_name: string().min(3).required().label('Last Name'),
  duration: string().required().label('Duration'),
});

const StripeForm = ({ data, setDataErrors, handleChange, setData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [otherCard] = useState(true);
  const [showGiftPopup, setShowGiftPopup] = useState(false);
  const [link, setLink] = useState('');

  const card = useSelector(state => state.subscription.card);

  const handleClick = async () => {
    try {
      setLoading(true);
      setDataErrors(null);
      setError('');
      const validationErrors = await validate(schema, data);
      if (validationErrors) {
        return setDataErrors(validationErrors);
      }
      // let paymentMethod = null;
      // if (!card || otherCard) {
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });
      if (paymentMethod?.error) {
        return setError('Card information provided is not valid.');
      }
      // } else {
      //   // paymentMethod = null;
      // }

      const { data: userRes } = await createGiftUser({
        email: data.f_email,
        first_name: data.first_name,
        last_name: data.last_name,
      });

      const accessToken = userRes.access;

      const giftLink = `${siteURL}?gift_subscription=${accessToken}`;

      const res = await makePayment({
        plan_id: data.duration, // monthly => 1 , yearly => 2
        name: `${data.first_name} ${data.last_name}`,
        email: data.f_email,
        ...(paymentMethod && {
          paymentMethod: paymentMethod?.paymentMethod?.id,
        }),
      });

      const confirmPayment = await stripe?.confirmCardPayment(
        res.data.clientSecret
      );

      if (confirmPayment?.error) {
        toast.error('Something went wrong!');
      } else {
        await stripeRecord({
          subscription_id: res.data.subscriptionId,
          plan_description: 'month',
          amount: 9,
        });

        await upgradePlan({ email: data.f_email });

        const sub = giftOptions.find(op => op.value === data.duration).label;

        await klaviyoGiftUser({
          email: data.f_email,
          first_name: data.first_name,
          last_name: data.last_name,
          subscription: sub.split('(')[0],
          note: data.note,
          link: giftLink,
        });

        setLink(giftLink);
        setShowGiftPopup(true);
        setData(prev => ({
          ...prev,
          f_email: '',
          first_name: '',
          last_name: '',
          duration: '3',
          note: '',
        }));
      }
    } catch (err) {
      console.log(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {card && !otherCard ? (
        <>
          <CardDetails>
            <p className='font-size-14'>Card Details</p>
            <div className='flex-row'>
              <div>
                <p
                  className='font-size-20'
                  style={{ textTransform: 'capitalize' }}
                >
                  {card?.cardBrand} Card
                </p>
                <p className='font-size-12'>
                  Expiration date {card?.cardExpMonth}/{card?.cardExpYear}
                </p>
              </div>
              <div className='flex-row'>
                <img src={assets.card} alt='' />
                <p
                  className='font-size-12 '
                  style={{ textTransform: 'capitalize' }}
                >
                  {card?.cardBrand} ****{card?.cardLast4}
                </p>
              </div>
            </div>
          </CardDetails>

          <div>
            <p className='font-size-14'>Add Notes</p>
            <TextArea
              rows={5}
              placeholder='Add your notes here'
              name='note'
              value={data.note}
              onChange={handleChange}
            ></TextArea>
          </div>
          <ButtonsRow>
            {/* <CustomButton
              label='Use another card'
              variant='btn-outlined'
              style={{ color: theme.colors.primary }}
              onClick={() => {
                setOtherCard(true);
              }}
            /> */}
            <Button
              className='btn'
              text={loading ? <Spinner /> : 'Generate Link'}
              onClick={handleClick}
              disabled={loading}
              style={{
                margin: '0px',
                flex: 3,
                height: '45px',
                fontSize: '16px',
                maxWidth: '191px',
              }}
            />
          </ButtonsRow>
        </>
      ) : (
        <Form>
          <p className='font-size-14'>Card information</p>
          <InputContainer>
            <CardNumberElement
              options={{
                showIcon: true,
                style: {
                  base: {
                    backgroundColor: 'transparent',
                    fontSize: '14px',
                    color: 'white',
                    lineHeight: '45px',
                    padding: 10,
                  },
                },
              }}
            />
          </InputContainer>
          <InputContainer className='expire'>
            <div style={{ flex: 1 }}>
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      backgroundColor: 'transparent',
                      fontSize: '14px',
                      color: 'white',
                      lineHeight: '45px',
                      padding: 10,
                    },
                  },
                }}
              />
            </div>
            <div className='seprator'>
              <div className='line'></div>
            </div>
            <div style={{ flex: 1, paddingLeft: '5px' }}>
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      backgroundColor: 'transparent',
                      fontSize: '14px',
                      color: 'white',
                      lineHeight: '45px',
                      padding: 10,
                    },
                  },
                }}
              />
            </div>
          </InputContainer>
          {error && (
            <p
              className='error font-size-14'
              style={{ color: theme.colors.red, marginTop: '5px' }}
            >
              {error}
            </p>
          )}

          <div style={{ marginTop: '30px' }}>
            <p className='font-size-14'>Add Notes</p>
            <TextArea
              rows={5}
              placeholder='Add your notes here'
              name='note'
              value={data.note}
              onChange={handleChange}
            ></TextArea>
          </div>

          <ButtonsRow>
            {/* <CustomButton
              label='Use current card'
              variant='btn-outlined'
              style={{ color: theme.colors.primary }}
              onClick={() => {
                setOtherCard(false);
              }}
            /> */}
            <Button
              className='btn'
              text={loading ? <Spinner /> : 'Generate Link'}
              onClick={handleClick}
              disabled={loading}
              style={{
                margin: '0px',
                flex: 3,
                height: '45px',
                fontSize: '16px',
                maxWidth: '191px',
              }}
            />
          </ButtonsRow>
        </Form>
      )}
      {showGiftPopup && (
        <GiftDialog
          open={showGiftPopup}
          link={link}
          setOpen={setShowGiftPopup}
        />
      )}
    </>
  );
};

export default StripeForm;

const Form = styled.div`
  & .btn {
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    margin-top: 30px;
  }

  & .font-size-18 {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    text-align: left;
  }

  & .font-size-14 {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 21px;
    margin-bottom: 10px;
    text-align: left;
  }

  & .font-size-10 {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
    line-height: 21px;
    text-align: center;
    margin-top: 15px;
    padding-inline: 10px;
  }
`;

const InputContainer = styled.div`
  height: 45px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  border-radius: 7px;
  padding-inline: 10px;

  & .seprator {
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-right: 10px; */

    & .line {
      width: 1px;
      height: 70%;
      background-color: ${({ theme }) => theme.colors.darkgrey};
    }
  }

  /* & .card {
    width: 100%;
    padding: 12px;
    background-color: transparent;
    & .InputElement {
      color: ${({ theme }) => theme.colors.primary}!important;
    }
  } */

  &.expire {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
  flex-wrap: wrap;

  & .btn {
    padding-inline: 10px;
    font-size: 12px;
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.primary};
    margin-top: 30px;
    width: 191px;
  }
`;

const CardDetails = styled.div`
  & .flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
`;

const TextArea = styled.textarea`
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 12px 14px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  resize: none;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.primary};
`;
