import React from 'react';

function ErrorMessage(props) {
  return (
    <p
      className={`error-message mb-1 ${props?.left && 'pt-2'}`}
      {...props}
      style={{
        margin: props?.left && '0',
      }}
    >
      {props.error}
    </p>
  );
}

export default ErrorMessage;
