export const suggestionChips = {
  Recommendations: [
    'What are the best restaurants in [add city name]?',
    'What are the best movies currently on Netflix?',
    'What are the 10 best rap songs of all time?',
    'Recommend 5 books on productivity',
  ],
  'How to’s': [
    'How do you ride a motorcycle?',
    'How can I lose 20 pounds in 3 months?',
    'How do you become a multi-millionaire?',
    'How to potty train a house cat',
    'How do I get my book published?',
  ],
  Advice: [
    'Give me 5 tips for living a happier life',
    'Act as my psychologist. What is the best way to improve my emotional intelligence?',
    'How can I make new friends?',
    'How can I improve my marriage?',
  ],
  Work: [
    'How can I best use AI at work to save time?',
    'How can I get a promotion at my [insert job type]?',
  ],
  Travel: [
    'Create an itinerary for a vacation to Europe from the USA for 2 weeks visiting [insert countries]',
    'What are the 10 best cities to visit in Mexico?',
  ],
  Poetry: [
    'Write a haiku about AI',
    'Write a poem about [insert topic] in the style of Robert Frost',
    'Who are the 5 greatest poets of all time?',
  ],
  'Writing help': [
    'Give me some writing tips for beginners',
    'Create an article outline for [Insert Topic here]. Make it concise yet ensure no information is left out.',
    'What is the correct usage of a comma?',
    'Tell me how to write in the format of the hero’s journey',
    'What are the basic principles of storytelling?',
    'Write a youtube video description and tags on a topic [insert your title]',
    'How do you write a good personal bio?',
  ],
  'Coding help': [
    "What's the shortest way to swap two variables in Python?",
    'How can I find the maximum value in an array using a single line of JavaScript?',
    "What's the most concise way to check if a string is a palindrome in Java?",
    'How can I reverse a list in Python using a single line of code?',
    "What's the quickest way to calculate the sum of all elements in an array in Ruby?",
    'How can I convert a list of integers to a comma-separated string in Python using a one-liner?',
    "What's the most elegant way to remove duplicates from a list in C#?",
    'How can I find the factorial of a number using a single line of code in JavaScript?',
    "What's the shortest way to sort a dictionary by value in Python?",
    'How can I find the length of the longest word in a sentence using a single line of code in Ruby?',
  ],
  Cooking: [
    'What is the best way to cook a steak?',
    'How do you make the perfect scrambled eggs?',
    'What is the best brand of vegan burgers?',
    'What is the best air fryer you can purchase?',
    'Give me 5 keto breakfast recipes',
    'Create a menu for a gourmet summer cookout using: [add list of ingredients]',
  ],
  'Definitions and meanings': [
    'Explain how inflation works',
    'What is the meaning of life?',
    'Define [insert word]',
    'What is the meaning of the slang word no cap?',
  ],
  Summaries: [
    'Summarize this [insert text/ topic/ book/ movie]',
    'Summarize the life of Gandhi',
    'Summarize the book Atomic Habits chapter by chapter',
    'Summarize world war 2',
    'Summarize the theory of relativity in 500 words',
    'Summarize the entire star wars series of movies',
  ],
  'Fun stuff': [
    'Describe a futuristic invention that could change the world.',
    'Give me an idea for a fun game to play outside with my family',
    'Tell me some fun facts about [insert topic]',
    'What are the 5 things I should do to make today fun?',
    'What are the top 5 most fun video games I can play for free?',
  ],
  Parenting: [
    'Why do babies cry?',
    'What should you do if your kids keep demanding things you can’t afford?',
    'My kid is 16 and he is still not mature. What should I do?',
    'Is it ok to argue in front of your kids?',
    'When should you stop breastfeeding?',
  ],
  Crypto: [
    'Add How can cryptocurrency and blockchain make the world a better place?',
    'What is the price of Bitcoin?',
  ],
  'Health tips': [
    'What is better HITT or Bodybuilding?',
    'Give me 10 tips to stay young',
    'What is the ideal number of daily steps?',
    'Create a 5-day-per-week exercise routine for a 32-year-old man',
    'Compare a carnivore diet vs a vegan diet',
    'Give me 5 bodyweight exercises I can do in the morning',
  ],
};
