import { createModel } from '@rematch/core';
import toast from 'react-hot-toast';
import axios from 'axios';
import {
  login,
  signup,
  updateUser,
  updatePassword,
  deleteUserAccount,
  forgotPassEmail,
  updatePassOtp,
  generateOTP,
  verifyOTP,
  updateProfileImage,
  signUpWithGoogle,
  socialLogin,
  userDashboardData,
} from 'container/api';
import Cookies from 'js-cookie';

export const auth = createModel()({
  name: 'auth',
  state: {
    isLoggedIn: !!localStorage.getItem('token') || false,
    loading: false,
    verificationToken: '',
    user: null,
    email: '',
    signUpModel: false,
    signUpData: null,
    dLoading: false,
  },
  reducers: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setDLoading(state, payload) {
      state.dLoading = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setEmail(state, payload) {
      state.email = payload;
      localStorage.setItem('chatai-email', payload);
    },
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
    setSignUpModel(state, payload) {
      state.signUpModel = payload;
    },
    setSignUpData(state, payload) {
      state.signUpData = payload;
    },
  },
  effects: dispatch => ({
    async handleLogin(payload) {
      try {
        dispatch.auth.setLoading(true);
        const params = new URLSearchParams(window.location.search);
        const { data } = await login(payload.data);
        const cookie = Cookies.get('chatai-auth');
        if (!cookie) {
          Cookies.set('chatai-auth', 'chatai-cookie');
        }
        if (data.status === 200) {
          const { access } = data;
          toast.success('Logged In Successfully');
          localStorage.setItem('token', access);
          payload.navigate(`/?${params.toString()}`, { replace: true });

          dispatch.auth.setIsLoggedIn(true);
          dispatch.auth.setUser(data.user);
          dispatch.auth.handleGetUser({ fireEvent: true });
        } else {
          toast.error(data.errors, { duration: 5000 });
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          toast.error(errorMessage);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleSignUp(payload) {
      const params = new URLSearchParams(window.location.search);
      const cookie = Cookies.get('chatai-auth');
      try {
        dispatch.auth.setLoading(true);
        const { data } = await signup(payload.data);
        const { access } = data;
        if (data.status === 500) {
          return toast.error('Unable to Sign Up User');
        } else if (
          data.Error === 403 &&
          data.Errors === 'User already Exist with the provided username'
        ) {
          return payload.setErrors({
            username: 'User already Exist with the provided username',
          });
        } else {
        }
        localStorage.setItem('token', access);
        if (!cookie) {
          Cookies.set('chatai-auth', 'chatai-cookie');
        }
        dispatch.auth.handleGetUser({ fireSinupEvent: true });
        dispatch.auth.setIsLoggedIn(true);
        toast.success('Sign Up Successfully');
        let navigate = `/?${params.toString()}`;
        payload.navigate(navigate);
      } catch (err) {
        console.log('error', err.message);
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          console.log('errorMessage', errorMessage);
          toast.error('Unable to Sign Up User');
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleUpdateUser(payload) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await updateUser(payload);
        if (data.status === 200) {
          toast.success('User Updated Successfully');
        }
      } catch (err) {
        toast.error('Unable to Update User.');
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          console.log(errorMessage);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleUpdatePassword(payload) {
      try {
        // dispatch.auth.setLoading(true);
        const { data } = await updatePassword(payload);
        if (data.status === 500) {
          toast.error('Unable to Update Password');
        } else {
          toast.success('Password Updated Successfully');
        }
        // dispatch.auth.setLoading(false);
      } catch (err) {
        console.log('err', err);
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          toast.error(<>{errorMessage}</>);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleDeleteAccount(payload) {
      try {
        const { data } = await deleteUserAccount(payload);
        if (data.status === 500) {
          toast.error('Unable to Delete User');
        } else {
          toast.success('User Deleted Successfully');
          localStorage.removeItem('token');
          window.location.href = '/sign-in';
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          toast.error('Unable to Delete User');
          console.log(errorMessage);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async forgotPasswordEmail(payload) {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await forgotPassEmail(payload);
        if (data.status === 200) {
          toast.success('Your one time password has been sent', {
            duration: 5000,
          });
          dispatch.utils.setForgetPassword('change-password');
        }
        if (data.Error === 403) {
          toast.error(data.Errors);
        }
      } catch (err) {
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async updatePasswordOtp(payload) {
      const { values, navigate } = payload;
      try {
        dispatch.auth.setLoading(true);
        const { data } = await updatePassOtp(values);
        if (data.status === 200) {
          toast.success(data.Message);
          localStorage.removeItem('forgot-email');
          setTimeout(() => {
            navigate('/sign-in');
            dispatch.utils.setForgetPassword('send-email');
          }, 1000);
        }
        if (data.Error === 404) {
          toast.error('OTP is not valid.');
        }
        console.log('data', data);
      } catch (err) {
        console.log('eeeee', err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleGenerateOTP(payload) {
      try {
        dispatch.auth.setLoading(true);
        await generateOTP(payload);
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleVerifyOTP(payload, state) {
      try {
        dispatch.auth.setLoading(true);
        payload.setSubmitting(true);
        const { data } = await verifyOTP(payload.otp);
        if (data?.Error) {
          if (data.Error === 404 && data.Errors === 'OTP is invalid') {
            return payload.setErrors({ otp: data.Errors });
          } else {
            return payload.setErrors({ otp: 'OTP is invalid' });
          }
        }
        dispatch.auth.handleSignUp({
          data: state.auth.signUpData,
          navigate: payload.navigate,
        });
      } catch (err) {
        console.log(err.message);
        payload.setErrors({ otp: 'OTP is invalid' });
      } finally {
        dispatch.auth.setLoading(false);
        payload.setSubmitting(false);
      }
    },
    async handleUpdateProfileImage(payload) {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await updateProfileImage(payload);
        if (data.status === 200) {
          // toast.success('Profile Image Updated Successfully');
        }
      } catch (err) {
        toast.error('Unable to Update Profile Image');
        if (axios.isAxiosError(err)) {
          const errorMessage = err.response?.data?.message;
          console.log(errorMessage);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleGoogleSignUp(payload) {
      const cookie = Cookies.get('chatai-auth');
      try {
        const params = new URLSearchParams(window.location.search);

        dispatch.auth.setLoading(true);
        const { data } = await signUpWithGoogle(payload.data);
        const { access } = data;
        if (data.status === 500) {
          return toast.error('Unable to Sign Up User');
        } else if (
          data.Error === 403 &&
          data.Errors === 'User already Exist with the provided email'
        ) {
          if (payload.data.is_google_auth) {
            return toast.error('User already Exist with the provided email');
          }
          return payload.setErrors({
            username: 'User already Exist with the provided username',
          });
        }
        localStorage.setItem('token', access);
        if (!cookie) {
          Cookies.set('chatai-auth', 'chatai-cookie');
        }
        dispatch.auth.setIsLoggedIn(true);
        toast.success('Sign Up Successfully');
        dispatch.auth.handleGetUser({
          fireEvent: true,
          google: true,
          signup: true,
        });
        payload.navigate(`/?${params.toString()}`);
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleSocialLogin(payload) {
      try {
        const params = new URLSearchParams(window.location.search);
        // const discountCode = params.get('discount_code');
        // const imageGeneration = params.get('image-generation');
        // const buyPlan = params.get('buy_plan');
        dispatch.auth.setLoading(true);
        const { data } = await socialLogin(payload.data);
        const cookie = Cookies.get('chatai-auth');
        if (!cookie) {
          Cookies.set('chatai-auth', 'chatai-cookie');
        }
        if (data?.status === 200) {
          const { access } = data;
          toast.success('Logged In Successfully');
          localStorage.setItem('token', access);

          payload.navigate(`/?${params.toString()}`, { replace: true });

          dispatch.auth.setIsLoggedIn(true);
          dispatch.auth.setUser(data.user);
          dispatch.auth.handleGetUser({ fireEvent: true, google: true });
        } else {
          toast.error(data.errors, { duration: 5000 });
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async handleGetUser(payload) {
      try {
        dispatch.auth.setDLoading(true);
        const { data } = await userDashboardData();
        dispatch.auth.setUser(data?.user);
        dispatch.chat.setRemainingPrompts(
          data?.remaining_prompts?.remaningprompts
        );
        dispatch.subscription.setPlanData(data?.plan?.data);
        if (payload?.fireEvent) {
          const userId = data?.user?.id;
          window.dataLayer.push({
            event: 'login_success',
            userId: userId,
            login_status: 'logged_in',
            login_type: payload?.google
              ? payload?.signup
                ? 'google_signup'
                : 'google_login'
              : 'standard_login',
          });
        }
        if (payload?.fireSinupEvent) {
          window.dataLayer.push({
            event: 'signup_success',
            userId: data.user.id,
            login_status: 'logged_in',
            login_type: payload?.google ? 'google_login' : 'standard_login',
          });
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.auth.setDLoading(false);
      }
    },
  }),
});
