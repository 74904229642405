import { createModel } from '@rematch/core';

export const shareModal = createModel()({
  name: 'shareModal',
  state: {
    open: false,
    selectedId: '',
  },
  reducers: {
    setOpen(state, payload) {
      state.open = payload;
    },
    setSelectedId(state, payload) {
      state.selectedId = payload;
    },
  },
});
