const theme = {
  colors: {
    primary: '#ffffff',
    darkbg: '#202124',
    darkgrey: '#35363a',
    lightgrey: '#494c54',
    lightgrey2: '#4D4F56',
    red: '#ff434e',
    yellow: '#fcb808',
    darkgray: '#28292c',
    bluishGray: '#5c616d',
    border: '#5C606D',
    inputLabel: '#8E93A4',
    blue: '#5887ff',
    green: '#4bd37b',
    tableHeader: '#404248',
    lightBlack: '#3A3B41',
    accent: '#2292F9',
  },
};

export default theme;
