import assets from 'assets';
import React, { useState } from 'react';

import styled from 'styled-components';

const TextField = ({
  label,
  type,
  value,
  onChange,
  placeholder,
  error,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Container>
      <p className='font-size-14 label'>{label}</p>
      <InputContainer>
        <input
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...rest}
          autoComplete='off'
        />
        {type === 'password' && (
          <img
            className='eye-icon'
            src={!showPassword ? assets.eyeSlash : assets.eye}
            onClick={handleToggleShowPassword}
            alt=''
            role='button'
            style={{ width: '20px' }}
          />
        )}
      </InputContainer>
      {error && <p className='error font-size-14'>{error}</p>}
    </Container>
  );
};

export default TextField;

const Container = styled.div`
  width: 100%;
  max-width: 600px;

  & p {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.inputLabel};
  }

  & .error {
    margin-block: 4px 0px;
    color: ${({ theme }) => theme.colors.red};
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;

  & input {
    height: 45px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    border: none;
    border-radius: 8px;
    padding-inline: 14px;
    color: ${({ theme }) => theme.colors.primary};

    &::placeholder {
      font-size: 14px;
    }
  }

  & .eye-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
`;
