import { createModel } from '@rematch/core';
import { getLocationData } from 'container/api';

const countries = [
  'FI',
  'IT',
  'DK',
  'FR',
  'DE',
  'GR',
  'CZ',
  'HU',
  'IE',
  'NL',
  'NO',
  'HR',
  'IS',
  'MT',
  'CY',
  'PT',
  'GB',
  'LI',
  'UA',
  'MD',
  'MC',
  'BE',
  'BG',
  'AT',
  'US',
  'CA',
];

export const location = createModel()({
  name: 'location',
  state: {
    data: null,
    loading: false,
    countryDiscount: false,
    country: null,
    countryBanner: false,
  },
  reducers: {
    setData(state, payload) {
      state.data = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setCountryDiscount(state, payload) {
      state.countryDiscount = payload;
    },
    setCountry(state, payload) {
      state.country = payload;
    },
    showCountryBanner(state, payload) {
      state.countryBanner = payload;
    },
    closeBanner(state) {
      state.countryBanner = false;
    },
  },
  effects: dispatch => ({
    async getLocationInfo(payload) {
      try {
        dispatch.location.setLoading(true);
        const { data } = await getLocationData();
        if (countries.includes(data.details.country)) {
          dispatch.location.setCountryDiscount(false);
        } else {
          dispatch.location.setCountryDiscount(true);
          dispatch.location.showCountryBanner(true);
        }
        dispatch.location.setCountry(data.details);
      } catch (err) {
        console.log(err.message);
      } finally {
        dispatch.location.setLoading(false);
      }
    },
  }),
});
