import React from 'react';

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

import SuccessImage from 'assets/images/success-icon.png';
import Border from 'assets/images/line.png';
// import { useSearchParams } from 'react-router-dom';

const styles = StyleSheet.create({
  valuesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '14px',
  },
  key: {
    color: '#FFFFFFB8',
    fontSize: '13px',
    fontWeight: '400',
  },
  value: {
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: '400',
  },
});

const StripeReciept = ({ amount, name, date }) => {
  return (
    <Document>
      <Page size={[345, 400]} orientation='portrait'>
        <View
          style={{
            padding: '30px 25px 15px 25px',
            backgroundColor: '#35363A',
            height: '100%',
            width: '100%',
          }}
        >
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Image
              src={SuccessImage}
              style={{ width: '80px', height: '80px' }}
            />
            <Text
              style={{
                fontSize: '18px',
                fontWeight: '700',
                color: '#ffffff',
                marginTop: '16px',
              }}
            >
              Payment successful
            </Text>
            <Text
              style={{
                fontSize: '12px',
                fontWeight: '400',
                color: '#ffffff',
                marginTop: '10px',
              }}
            >
              We’ve processed your{' '}
              <Text style={{ color: '#03D192' }}>${amount} charge.</Text>
            </Text>
          </View>
          <View
            style={{
              marginTop: '50px',
            }}
          >
            <View style={styles.valuesContainer}>
              <Text style={styles.key}>Payment Time</Text>
              <Text style={styles.value}>{date}</Text>
            </View>
            <View style={styles.valuesContainer}>
              <Text style={styles.key}>Payment Method</Text>
              <Text style={styles.value}>Credit card</Text>
            </View>
            <View style={styles.valuesContainer}>
              <Text style={styles.key}>Customer Name</Text>
              <Text style={styles.value}>{name}</Text>
            </View>
          </View>
          <Image
            style={{
              width: '100%',
            }}
            src={Border}
          />
          <View style={{ ...styles.valuesContainer, marginTop: '16px' }}>
            <Text style={styles.key}>Amount</Text>
            <Text style={styles.value}>${amount}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default StripeReciept;
