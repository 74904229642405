import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import assets from 'assets';
import { siteURL } from 'app.config';

const ShareWithSMS = () => {
  const id = useSelector(state => state.chat.selectedConversation?.id || '');
  return (
    <a
      href={`sms:?body=${siteURL}/share/${id}`}
      target='_blank'
      rel='noreferrer'
    >
      <Container>
        <img src={assets.sms} alt='' />
        <p className='font-size-12 bold'>SMS</p>
      </Container>
    </a>
  );
};

export default ShareWithSMS;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: ${({ theme }) => theme.colors.primary};
  align-items: center;

  & img {
    width: 60px;
    aspect-ratio: 1/1;
  }
`;
