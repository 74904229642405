import assets from 'assets'
import React, { useState } from 'react'
import styled from 'styled-components'
import ClickAwayListener from 'react-click-away-listener';
import { useDispatch, useSelector } from 'react-redux';

const StatusDropdown = () => {

    const dispatch = useDispatch();
    const { filter } = useSelector(({imageGeneration}) => imageGeneration)
    const [open, setOpen] = useState(false)
    // const [value, setValue] = useState({
    //     label: 'All',
    //     value: 'all',
    // })

    const handleClose = () => {
        setOpen(false)
    }

    const options = [
        {
            label: 'Newest',
            value: 'newest'
        },
        {
            label: 'Oldest',
            value: 'old'
        }
    ]

    const value = options.find(x => x.value === filter)
    return (
        <Container>
            <div
                onClick={() => setOpen(true)}
                className='dropdown-value'>
                <div className='flex gap-10 align-items-center'>
                    <span className='name' >{value.label}</span>
                </div>
                <div>
                    <img src={assets.dropdownArrow} width={14} height={14} alt='' />
                </div>
            </div>

            {
                open && <ClickAwayListener onClickAway={handleClose}>
                    <div className='dropdown-options'>
                        {
                            React.Children.toArray(options.map((item) => (
                                <div
                                    onClick={() => {
                                        handleClose()
                                        dispatch.imageGeneration.setFilter(item.value);
                                    }}
                                    className={`option ${value?.label === item.label ? 'active' : ''} ${item.disabled && 'disabled'}`}>
                                    <span className='name'>{item.label}</span>

                                </div>
                            )))
                        }
                    </div>
                </ClickAwayListener>
            }
        </Container>
    )
}

export default StatusDropdown

const Container = styled.div`
    position: relative;
    & .gap-10{
        gap: 10px;
    }
    & .dropdown-value{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 54px;
        border-bottom: 1px solid #4D4F56;
        border-top: 1px solid #4D4F56;
        
        padding: 14px;
        cursor: pointer;

        & .name {
            font-weight: 400;
            font-size: 13px;
            line-height: 19.5px;
        }

        & .dot{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #D9DAE0;
        }
    }

    & .dropdown-options{
        position: absolute;
        z-index: 9999;
        width: 100%;
        overflow-y: auto;
        border-radius: 8px;
        border: 1px solid #4D4F56;
        padding: 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: #35363A;

        & .option{
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            padding: 8px 10px;
            cursor: pointer;

            & .name{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary);
            }
        }

        & .active{
            background-color: #4D4F56;
        }

        & .disabled{
            opacity: 0.3;
            cursor: not-allowed;
        }

    }

`