import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px 4px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  & .mobile-clock-wise{
    background-color: rgba(53, 54, 58, 1);
    border: 1px solid rgba(60, 62, 69, 1);
    height: 30px;
    width: 30px;
    border-radius: 6px;
    cursor: pointer;

  }

  & .mobile-chat-to-doc{
    border: 1px solid rgba(77, 79, 86, 1);
    border-radius: 6px;
    width: 30px;
    height: 30px;
    text-align: center;
  }
  & p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.primary};
  }
  & .left_button-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  & .right_button-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  & svg {
    cursor: pointer;
  }
`;

export const ContentBox = styled.div`
  flex-grow: 1;
  height: 0px;
  overflow-y: auto;
  & .tags-wrapper {
    min-height: 100%;
    /* max-height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & .svg-container {
      padding-top: 150px;
    }
    & .about-model {
      margin: 10px 40px;
      border: 1px solid #53555a;
      border-radius: 7px;
      padding: 20px;
      & .about-heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: var(--primary);
      }
      & .about-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary);
        opacity: 0.5;
        margin-bottom: 0;
        text-align: justify;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    & .help-heading{
      font-family: Poppins;
      font-size: 24px !important;
      font-weight: 500 !important;
      line-height: 36px !important;
      
    }
  }
`;

export const InputBox = styled.div`
  position: relative;
`;

export const ImageTrigger = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  transform: translateY(-100%);
  width: calc(100% - 40px);
  border-radius: 12px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-inline: 15px;
  background-color: var(--darkbg);
  & p {
    margin: 0px;
    width: 100%;
    cursor: pointer;
    text-align: left;
    padding: 4px 6px;
    border-radius: 8px;
    transition: 0.4s ease;
    color: #ffffff;
    &:hover {
      background-color: var(--lightgrey);
    }
  }
`;
