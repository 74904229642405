import React, { useState } from 'react';

import styled from 'styled-components';

import { ClipLoader } from 'react-spinners';
import assets from 'assets';

const Image = ({ src, border, label, selected, onClick }) => {
  const [loading, setLoading] = useState(true);

  return (
    <Container border={border} onClick={onClick} selected={selected}>
      {loading && <ClipLoader color={'#fff'} loading={loading} size={30} />}
      <img
        src={src}
        onLoad={() => setLoading(false)}
        style={{ display: loading ? 'none' : 'block' }}
        alt=''
      />
      <div className='overly'>
        <div className='image-container'>
          {selected && <img src={assets.selected} alt='' />}
        </div>
        {label && <div className='label font-size-10'>{label}</div>}
      </div>
    </Container>
  );
};

export default Image;

const Container = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: ${({ border }) => (border ? '0px' : '4px')};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .overly {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: ${({ selected }) =>
      selected ? '#ff434e80' : 'transparent'};
    display: flex;
    padding: 5px;
    flex-direction: column;
    justify-content: space-between;

    & .image-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;

      & img {
        height: 16px;
        width: 16px;
      }
    }
  }
`;
