import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
// Bootstrap
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import App from './container/App';
import { Provider } from 'react-redux';
import store from './container/store';
import { googleClientSecret } from 'app.config';
import EditorProvider from 'container/context/editorContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Router>
      <GoogleOAuthProvider clientId={googleClientSecret}>
        <EditorProvider>
          <App />
        </EditorProvider>
      </GoogleOAuthProvider>
    </Router>
  </Provider>
);
