import React, { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';

import assets from 'assets';

import styled from 'styled-components';

const Accordion = ({ message, index }) => {
  const panel = useRef(null);
  const dispatch = useDispatch();
  const img = useRef(null);

  const handleClick = () => {
    if (panel.current.style.maxHeight) {
      panel.current.style.maxHeight = null;
      img.current.style.transform = 'rotate(-90deg)';
    } else {
      panel.current.style.maxHeight = panel.current.scrollHeight + 10 + 'px';
      img.current.style.transform = 'rotate(90deg)';
    }
  };

  const handleRemoveFromOutliner = e => {
    e.stopPropagation();
    dispatch.outliner.removeItem(message);
  };

  const handleText = () => {
    const messageStrings = message?.split('\n');

    return messageStrings?.map((str, index) => (
      <p className='message' key={index}>
        {str.length > 0 && `${str}`}
      </p>
    ));
  };

  useEffect(() => {
    if (index === 0) {
      handleClick();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='main' onClick={handleClick}>
        <div className='wrapper'>
          <img src={assets.arrowLeft} ref={img} className='arrow-icon' alt='' />
          <p className='font-size-12 title'>{message}</p>
        </div>
        <div className='btn' onClick={handleRemoveFromOutliner} role='button'>
          <img src={assets.crossRed} className='clickable' alt='' />
        </div>
      </div>
      <Panel ref={panel}>
        <div className='panel-wrapper'>{handleText()}</div>
      </Panel>
    </Container>
  );
};

export default Accordion;

const Container = styled.div`
  width: 100%;
  margin-bottom: 12px;
  & .main {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 29px;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    & .title {
      width: 25ch;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .wrapper {
      display: flex;
      align-items: center;
      gap: 6px;
      width: 100%;
    }

    & .arrow-icon {
      /* height: 8px; */
      width: 12px;
      height: 12px;
      transform: rotate(-90deg);
      transition: 0.4s ease;
    }

    & .btn{
      --bs-btn-padding-y: none !important;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 2px;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Panel = styled.div`
  background-color: ${({ theme }) => theme.colors.lightgrey};
  margin-top: 1px;
  padding-bottom: 2px;
  /* padding-left: 5px; */
  /* padding-right: 5px; */

  border-radius: 2px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.primary + 99};
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  & .panel-wrapper {
    padding: 4px;
    font-size: 10px;
    font-weight: 300;
    line-height: 17.85px;
    color: #D9DAE0;
  }
`;
