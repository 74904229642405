import assets from 'assets';
import React from 'react';

import styled from 'styled-components';

const Checkbox = ({ checked, onChange }) => {
  return (
    <Container>
      <label htmlFor='checkbox' style={{ cursor: 'pointer' }}>
        <img src={checked ? assets.checked : assets.uncheck} alt='' />
      </label>
      <input
        type='checkbox'
        name=''
        id='checkbox'
        style={{ display: 'none' }}
        onChange={onChange}
      />
    </Container>
  );
};

export default Checkbox;

const Container = styled.div`
  cursor: pointer;
`;
