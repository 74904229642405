import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { ClipLoader } from 'react-spinners';

import theme from 'container/theme';

import assets from 'assets';
import { imageProcessingData } from 'container/data';

const ImageLoading = () => {
  const [loading, setLoading] = useState(true);
  const [loaderData, setLoaderData] = useState({
    img: '',
    quote: '',
    name: '',
  });

  const { grid } = useSelector(state => state.imageGeneration);

  useEffect(() => {
    const index = Math.floor(Math.random() * 15);
    setLoaderData(imageProcessingData[index]);
  }, []);

  return (
    <Container
      style={{
        maxWidth: grid === '1' ? '450px' : '',
      }}
    >
      <Content>
        <img src={loaderData.img} alt='' />
        <p className='font-size-10 quote'>{loaderData.quote}</p>
        <p className='font-size-10 bold'>{loaderData.name}</p>
      </Content>
      <Loader>
        <p className='font-size-12 bold'>Image Processing</p>

        <ClipLoader size={13} color={theme.colors.primary} />
      </Loader>
      <Image
        src=''
        alt=''
        onLoad={() => setLoading(false)}
        style={{ display: loading ? 'none' : 'block' }}
      />
    </Container>
  );
};

export default ImageLoading;

const Container = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url(${assets.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.darkbg};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightgrey};
  overflow: hidden;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  & img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  p {
    text-align: center;
    &.quote {
      max-width: 25ch;
      text-align: center;
      opacity: 0.5;
    }
  }
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  position: absolute;
  left: 0px;
  bottom: 9px;
  padding-inline: 10px;
  width: 100%;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
