import assets from 'assets'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Image from '../components/Image'

const PromptDetail = () => {
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(false);
    const { isPromptDetail, selectedTemplate, templates, selectedImage } = useSelector(state => state.imageGeneration)

    useEffect(() => {
        if (update) {
            setTimeout(() => {
                setUpdate(false);
            }, 300);
        }
    }, [update]);
    return (
        <Container
            style={{
                width: '500px',
                opacity: isPromptDetail ? '1' : '0',
                display: isPromptDetail ? 'flex' : 'none'
            }}
        >
            <Title>
                <span className='title'>Prompt Details</span>
                <div
                    className='close-btn'
                    onClick={() => dispatch.imageGeneration.setIsPromptDetail(false)}
                > <img width={20} height={20} src={assets.closeIconGrey} alt='' /> </div>
            </Title>

            <ImageContainer>
                <ImageGrid>
                    {!update &&
                        templates[selectedTemplate]?.map(template => (
                            <Image
                                src={template.example_image_url}
                                selected={selectedImage?.id === template.id}
                                onClick={() => {
                                    dispatch.imageGeneration.setPromptEdited(false);
                                    dispatch.imageGeneration.setSelectedImage(template);
                                    dispatch.imageGeneration.setPrevSelectedImage(selectedImage?.prompt || template.prompt)
                                    dispatch.imageGeneration.setCustomPrompt('');
                                }}
                            />
                        ))}
                </ImageGrid>
            </ImageContainer>
        </Container>
    )
}

export default PromptDetail

const Container = styled.div`
    height:  calc(100vh - 70px);
    position: fixed;
    top: 60px;
    left: 26%;
    width: 600px !important;
    /* width: 455px; */
    background-color: #35363A;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    /* transform: translateX(100%); */
    transition: 0.4s ease;
    z-index: 9999;

    @media only screen and (max-width: 1000px) {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100% !important;
        height: 100vh;
    }
`

const Title = styled.div`
    display: flex;
    justify-content: space-between;

    & .title{
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
    }

    & .close-btn{
        cursor: pointer;
    }
`;


const ImageContainer = styled.div`
    flex-grow: 1;
    height: 0px;
    overflow: auto;
    margin-top: 20px;
    padding: 10px;
`;

const ImageGrid = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(3, 1fr);
  border-radius: 8px;
  gap: 10px;

   @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
   }
`;