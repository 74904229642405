import artists from 'assets/artist';

export const imageProcessingData = [
  {
    img: artists[1],
    quote:
      'Every child is an artist. The problem is how to remain an artist once we grow up.',
    name: '~ Pablo Picasso',
  },
  {
    img: artists[2],
    quote: 'I dream my painting and then I paint my dream.',
    name: '~ Vincent van Gogh',
  },
  {
    img: artists[3],
    quote: 'Art is never finished, only abandoned.',
    name: '~ Leonardo da Vinci',
  },
  {
    img: artists[4],
    quote: 'I paint flowers so they will not die.',
    name: '~ Frida Kahlo',
  },
  {
    img: artists[5],
    quote: 'In the future, everyone will be world-famous for 15 minutes.',
    name: '~ Andy Warhol',
  },
  {
    img: artists[6],
    quote:
      "I found I could say things with color and shapes that I couldn't say any other way - things I had no words for.",
    name: "~ Georgia O'Keeffe",
  },
  {
    img: artists[7],
    quote:
      'Surrealism is destructive, but it destroys only what it considers to be shackles limiting our vision.',
    name: '~ Salvador Dalí',
  },
  {
    img: artists[8],
    quote: 'I must have flowers, always, and always.',
    name: '~ Claude Monet',
  },
  {
    img: artists[9],
    quote: 'I saw the angel in the marble and carved until I set him free.',
    name: '~ Michelangelo',
  },
  {
    img: artists[10],
    quote: 'Painting is self-discovery. Every good artist paints what he is.',
    name: '~ Jackson Pollock',
  },
  {
    img: artists[11],
    quote: 'Art is not what you see, but what you make others see.',
    name: '~ Edgar Degas',
  },
  {
    img: artists[12],
    quote: 'Creativity takes courage.',
    name: '~ Henri Matisse',
  },
  {
    img: artists[13],
    quote:
      'Color is the keyboard, the eyes are the harmonies, the soul is the piano with many strings.',
    name: '~ Wassily Kandinsky',
  },
  {
    img: artists[14],
    quote: 'I paint not what I see but what I saw.',
    name: '~ Edvard Munch',
  },
  {
    img: artists[15],
    quote: 'Choose only one master - Nature.',
    name: '~ Rembrandt van Rijn',
  },
];
