import { Toaster } from 'react-hot-toast';
import { useEffect, useState } from 'react';

import Router from './Router/index';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import useNotifications from './hooks/useNotifications';
import 'react-tooltip/dist/react-tooltip.css';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { config } from './data/models';
import { discountEligible } from './api';

function App() {
  useNotifications();
  const params = new URLSearchParams(window.location.search);
  const imageGeneration = params.get('image-generation');
  const giftSubscription = params.get('gift_subscription');
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const planData = useSelector(state => state.subscription.planData);

  const [eventFired, setEventFired] = useState(false);
  const [planEventFired, setPlanEventFired] = useState(false);

  const navigate = useNavigate();

  const handleGTMLoggedInEvent = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      window.dataLayer.push({
        userId: user.id,
        login_status: 'logged_in',
        login_type: 'standard_login',
      });
      setEventFired(true);
    }
  };

  const handleCheckDiscount = async () => {
    try {
      const { data } = await discountEligible(user?.email);
      if (data?.status === 400) {
        dispatch.utils.setDiscountUser(false);
      } else {
        dispatch.utils.setDiscountUser(true);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleTriggerEvent = async () => {
    try {
      const plan = planData?.plan;
      if (planData) {
        if (plan === 'month') {
          return null;
        } else if (plan === 'year') {
          return null;
        } else {
          window.triggerEvent(3);
        }
      } else {
        window.triggerEvent(3);
      }
      setPlanEventFired(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (user) {
      if (!eventFired) {
        handleGTMLoggedInEvent();
      }
      handleCheckDiscount();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (planData && !planEventFired) {
      handleTriggerEvent();
    }
    // eslint-disable-next-line
  }, [planData]);

  useEffect(() => {
    if (imageGeneration) {
      dispatch.chat.setModel(config[2]);
      dispatch.utils.setOpenControls(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (giftSubscription) {
      localStorage.setItem('token', giftSubscription);
      dispatch.auth.handleGetUser();
      navigate('/settings/profile?new_gift_subscription=true');
    }
    // eslint-disable-next-line
  }, [giftSubscription]);

  return (
    <ThemeProvider theme={theme}>
      <Toaster />
      <Router />
    </ThemeProvider>
  );
}

export default App;
