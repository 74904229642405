import assets from 'assets';
import React, { useState, useEffect } from 'react';
import * as dayjs from 'dayjs'

import styled from 'styled-components';
import { favouriteChat, updateChatLable, updateDocument } from 'container/api';
import { Tooltip } from 'react-tooltip';
import theme from 'container/theme';
import ConfirmationModel from 'container/Dialogs/ConfirmationModel/ConfirmationModel';
import { useSelector } from 'react-redux';

const Conversation = ({
  conversation,
  handleDelete,
  loading,
  handleSelect,
  selected,
  getIcon,
  setIsFavClicked,
  handleOnLoad
}) => {
  const [title, setTitle] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [edit, setEdit] = useState(false);
  const [isFavourite, setIsFavourite] = useState(conversation.is_favourite);
  const [selectedConvo, setSelectedConvo] = useState(null);
  const loadingState = useSelector(state => state.chat.loading);
  const mode = useSelector(state => state.utils.mode);

  const handleOnEdit = () => {
    setEdit(!edit);
    clicked = false
  };

  const handleCancel = () => {
    setEditTitle(title);
    setEdit(false);
    clicked = false
  };

  const handleOnUpdateTitle = async e => {
    try {
      e.stopPropagation();
      setTitle(editTitle);
      setEdit(false);
      clicked = false

      
      if (mode === "document") {
        
        await updateDocument({
          "title": editTitle,
          "id": conversation.id
        })

      } else {
        await updateChatLable({
          conversation_id: conversation.id,
          new_label: editTitle || 'Untitled',
        });

        handleOnLoad(conversation.id)
        // const data = await dispatch.chat.getConversationMessages(conversation.id)

        // debugger
        // if(data.length > 0)
        // dispatch.chat.setSelectedConversation(data[0])
        // if (!loading) {
        //  await handleSelect(conversation);
        // }
      }

    } catch (err) {
      console.log(err.message);
    }
  };

  const handleFavourite = async () => {
    try {
      clicked = false
      if (isFavourite) {
        setIsFavClicked(true)
      }
      setIsFavourite(!isFavourite);
      await favouriteChat({
        conversation_id: conversation.id,
        is_favourite: isFavourite ? false : true,
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    let label = '';
    
    if (mode === 'document') {
      label = conversation.title
    } else {
      if (conversation.label?.includes('/imagine')) {
        label = conversation.label?.replace('/imagine', '');
      } else {
        label = conversation.label;
      }
    }

    setTitle(label);
    setEditTitle(label);
    setIsFavourite(conversation.is_favourite);
    // eslint-disable-next-line
  }, [conversation]);

  let clicked = true
  return (
    <Container
      key={conversation.id}
      onClick={(event) => {
        if (!loading && clicked) {
          handleSelect(conversation);
        } else {
          clicked = true
        }
      }}
      className={`editor-link mobile-conversation ${selected?.id === conversation.id && 'active'}`}
    >
      <div className='flex-row'>
        {edit ? (
          <TextBox
            rows={2}
            value={editTitle}
            onChange={e => setEditTitle(e.currentTarget.value)}
            onKeyDown={e => e.key === 'Enter' && handleOnUpdateTitle(e)}
          ></TextBox>
        ) : (
          <p className='q-title font-size-14'>{title}</p>
        )}

        <div className='flex-row second' style={{ gap: edit ? '12px' : '6px' }}>
          {edit ? (
            <>
              <div
                className='icon-button'
                onClick={handleOnUpdateTitle}
                role='button'
              >
                <img src={assets.correct} alt='' />
              </div>
              <div className='icon-button' onClick={handleCancel} role='button'>
                <img src={assets.false} alt='' />
              </div>
            </>
          ) : (
            <>
              <div
                className='icon-button'
                id='edit-btn'
                onClick={handleOnEdit}
                role='button'
              >
                <img src={assets.edit} alt='' />
              </div>
              <Tooltip
                anchorSelect='#edit-btn'
                content='Edit'
                style={{ zIndex: '100', background: theme.colors.lightgrey }}
              />
            </>
          )}
        </div>
      </div>
      <div className='flex-row'>
        <p className='font-size-10' style={{ opacity: '0.5' }}>
          {dayjs(conversation.updated_at).fromNow()}
        </p>
        <div className='flex-row second' style={{ marginTop: '10px' }}>
          {
            mode !== 'document' && <span id='favourite-chat'>
              <div
                className='icon-button favorite'
                onClick={handleFavourite}
                role='button'
              >
                <img
                  style={{ width: '15px', height: '15px' }}
                  src={isFavourite ? assets.starFilled : assets.starOutlined}
                  alt=''
                />
              </div>
            </span>
          }
          <Tooltip
            anchorSelect='#favourite-chat'
            content='Favorite'
            style={{ background: theme.colors.lightgrey }}
          />
          {mode !== 'document' && <div className='icon-button'>{getIcon(conversation.model_name)}</div>}
          <div className='icon-button' role='button'>
            <span id='delete-btn'>
              <div
                className='delete_icon'
                // onClick={e => handleDelete(e, conversation.id)}
                onClick={e => {
                  e.stopPropagation();
                  setSelectedConvo(conversation.id);
                }}
              >
                <img width={11} height={11} src={assets.delete} alt='' />
              </div>
            </span>
            <Tooltip
              anchorSelect='#delete-btn'
              content='Delete conversation'
              place='left'
              style={{ background: theme.colors.lightgrey }}
            />
          </div>
        </div>
      </div>
      {selectedConvo && (
        <ConfirmationModel
          open={selectedConvo}
          setOpen={() => {
            setSelectedConvo(null)
            clicked = false
          }}
          action={() => {
            handleDelete(selectedConvo)
            clicked = false
           }}
          descripiton='Are you sure you want to delete this conversation?'
          loading={loadingState}
        />
      )}
    </Container>
  );
};

export default Conversation;

const Container = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  padding: 10px;
  margin-left: 10px;

  &.active {
    background: var(--lightgrey);
    border-radius: 7px;
    cursor: pointer;
  }

  & .q-title {
    width: 16.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
  }

  & .delete_icon {
    height: 20px !important;
    width: 20px !important;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    margin: 0px !important;
    border-radius: 5px !important;
    display: grid;
    place-items: center;
  }

  & .flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    & p {
      margin: 0px;
    }

    &.second {
      gap: 7px;
    }
  }
`;

const TextBox = styled.textarea`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  resize: none;
  padding: 4px;
  border-radius: 5px;
  outline: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
`;
