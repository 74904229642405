import React from 'react';

import styled from 'styled-components';

const RangeInput = ({ value, onChange, min, max }) => {
  return (
    <Container>
      <input
        type='range'
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        name=''
        id=''
      />
      <div className='count'>{value}</div>
    </Container>
  );
};

export default RangeInput;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  & .count {
    padding: 2px 15px;
    font-size: 10px;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    border-radius: 41px;
  }

  /********** Range Input Styles **********/
  /*Range Reset*/
  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    /* width: 15rem; */
    width: 100%;
  }

  /* Removes default focus */
  input[type='range']:focus {
    outline: none;
  }

  /***** Chrome, Safari, Opera and Edge Chromium styles *****/
  /* slider track */
  input[type='range']::-webkit-slider-runnable-track {
    background-color: ${({ theme }) => theme.colors.red};
    border-radius: 0.5rem;
    height: 4px;
  }

  /* slider thumb */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -4px; /* Centers thumb on the track */

    /*custom styles*/
    background-color: #ffffff;
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  /******** Firefox styles ********/
  /* slider track */
  input[type='range']::-moz-range-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.5rem;
  }

  /* slider thumb */
  input[type='range']::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0; /*Removes default border-radius that FF applies*/

    /*custom styles*/
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
  }

  input[type='range']:focus::-moz-range-thumb {
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem;
  }
`;
