import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const SearchInput = React.forwardRef((props, ref) => {
  const { handlePredict, predict, setPredict, afterChange, disableButton } =
    props;

  const dispatch = useDispatch();

  const handlePromptChange = e => {
    setPredict(e.target.value);
    if (typeof afterChange === 'function') {
      afterChange(e.target.value);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '0px';
      const scroll = ref.current.scrollHeight;
      ref.current.style.height = `${scroll}px`;
    }

    if (predict === '') {
      dispatch.imageGeneration.setPromptEdited(false);
    }
    // eslint-disable-next-line
  }, [predict]);

  useEffect(() => {
    if (ref.current) ref.current.focus();
    // eslint-disable-next-line
  }, []);

  return (
    <Container id='step-1'>
      <textarea
        ref={ref}
        name='search'
        id='search'
        type='text'
        className='search'
        placeholder='Type a message...'
        onChange={handlePromptChange}
        value={predict}
        style={{
          height: '60px',
          maxHeight: '200px',
          overflow: 'auto',
          fontSize: '16px',
        }}
        onKeyDown={e => {
          if (predict !== '')
            if (e.keyCode === 13) {
              e.preventDefault();
              handlePredict();
            }
        }}
      ></textarea>
      <button
        className='search-btn'
        id='search-btn'
        onClick={() => {
          if (!disableButton) {
            handlePredict();
          }
        }}
        disabled={predict === '' || disableButton === true}
        style={{
          cursor: (predict === '' || disableButton === true) && 'not-allowed',
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='17'
          height='17'
          viewBox='0 0 17 17'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.7742 7.74492C16.8917 7.87711 16.9631 8.05105 16.9631 8.24162C16.9631 8.54583 16.7812 8.80768 16.52 8.92494L1.12407 16.3175C0.846622 16.4508 0.515738 16.4016 0.289301 16.1935C0.0628626 15.9854 -0.0132425 15.6605 0.0973001 15.3739L2.848 8.24156L0.0973006 1.10927C-0.0132419 0.822648 0.0628625 0.49777 0.2893 0.289654C0.515738 0.0815384 0.846623 0.0323583 1.12407 0.16558L16.524 7.56013C16.5765 7.58405 16.6257 7.61381 16.6708 7.64859C16.7085 7.67754 16.743 7.70984 16.7742 7.74492ZM12.9211 7.49264L2.17926 2.33478L4.16849 7.49264L12.9211 7.49264ZM4.16845 8.99059H12.9209L2.17926 14.1483L4.16845 8.99059Z'
            fill='white'
          />
        </svg>
      </button>
    </Container>
  );
});

export default SearchInput;

const Container = styled.div`
  margin: 15px 20px 10px 20px;
  position: relative;
  z-index: 10;

  & .search {
    background: var(--darkgrey);
    border: 1px solid #53555a;
    border-radius: 15px;
    padding: 20px 23px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
    opacity: 0.4;
    width: 100%;
    height: 60px;
    opacity: 1;
    padding-right: 50px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  & .search-btn {
    all: unset;
    top: 50%;
    position: absolute;
    right: 13px;
    background: #FF434E;
    border-radius: 7px;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: translateY(-60%);
    transition: ease-in 0.2s;

    &:hover {
      background-color: var(--red);
    }
  }

  @media only screen and (max-width: 1000px) {
      height: 54px !important;
      display: flex;
      align-items: center;

        & .search {
          height: 54px !important;
          padding: 15px 23px;
          font-family: Poppins;
          font-size: 14px !important;
        }

        & .search-btn {
          transform: translateY(-50%);
        }
      }
`;
