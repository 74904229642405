import React from 'react';

import styled from 'styled-components';
import Outliner from './Outliner';
import { useSelector } from 'react-redux';
import Analysis from './Analysis';

const DocumentSidebar = ({isMobile}) => {
  const { openOutliner, openAnalysis } = useSelector(state => state.utils);
  return (
    <Container>
      <div className={`content ${isMobile && 'mobile-bg-home-color'}`}>
        {openOutliner && <Outliner />}
        {openAnalysis && <Analysis />}
      </div>
    </Container>
  );
  // return (
  //   <Container>
  //     <div className='content'>
  //       <Outliner />
  //     </div>
  //   </Container>
  // );
};

export default DocumentSidebar;

const Container = styled.div`
  /* height: calc(100% - 20px); */
  height: 100%;
  /* margin-top: 12px; */
  /* padding-right: 10px; */

  & .content {
    background-color: ${({ theme }) => theme.colors.lightBlack};
    /* border-top-right-radius: 12px; */
    /* border-bottom-right-radius: 12px; */
    height: 100%;
    border-left: 1px solid ${({ theme }) => theme.colors.border};
  }

  @media only screen and (max-width: 1000px) {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 100;
    margin: 0px;
    padding-right: 0px;
    & .content {
      border-radius: 0px;
      border-left: none;
    }
  }
`;
