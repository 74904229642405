import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Accordion from 'container/components/Accordion';
import { ClipLoader } from 'react-spinners';

import CheckIcon from 'assets/icons/CheckIcon';

import { Container } from '../Styles';
import theme from 'container/theme';
import {
  LATEST_RANDOM,
  PLAN_20,
  PLAN_30,
  PLAN_40,
  PLAN_50,
  RANDOM_1,
  abandon5,
  abandon7,
  dynamicCoupons,
  welcome_discount_code,
} from 'app.config';
import { useSearchParams } from 'react-router-dom';
import assets from 'assets';

function MonthlySubscription({ handlePlan }) {
  const [price, setPrice] = useState(9);
  const [description, setDescription] = useState('')
  const [params] = useSearchParams();
  const couponCode = useSelector(state => state.subscription.couponCode);
  const planData = useSelector(state => state.subscription.planData);
  const loading = useSelector(state => state.subscription.planLoading);
  const planText = planData && planData.plan;
  const discountCode = params.get('discount_code') || couponCode;

  useEffect(() => {
    setDescription("")
    if (planText?.toLowerCase() === 'free') {
      if (discountCode === welcome_discount_code) {
        setPrice(7);
      } else if (discountCode === abandon5) {
        setPrice(5);
      } else if (discountCode === abandon7) {
        setPrice(7);
      } else if (discountCode === PLAN_20) {
        setPrice(7.2);
      } else if (discountCode === PLAN_30) {
        setPrice(6.3);
      } else if (discountCode === PLAN_40) {
        setPrice(5.4);
      } else if (discountCode === PLAN_50) {
        setPrice(4.5);
      } else if (discountCode === RANDOM_1) {
        setPrice(1);
      } else if (discountCode === LATEST_RANDOM) {
        setPrice(8);
      } else {

        const findCoupon = dynamicCoupons.find(x => x.uuid.trim() === discountCode?.trim() && x.plan_id === 1);
        if (findCoupon) {
          setPrice(findCoupon.final_price)
          setDescription(findCoupon.description)
        } else
          setPrice(9);
      }
    }

    // eslint-disable-next-line
  }, [discountCode, planData]);
  return (
    <Container>
      <div className='subscription__plan-inner'>
        <div>
          <h1 className='heading'>Chat-AI Plus</h1>
          <p className='text subtitle'>More advanced AI capabilities</p>
          {description && <p className='text subtitle'>{ description }</p>}

          <p className='text'>
            {+price < 9 && planData?.plan?.toLowerCase() === 'free' && (
              <WasPrice className='was'>
                <img src={assets[9]} alt='' />
              </WasPrice>
            )}{' '}
            ${price} <span className='text-small'>/mo</span>
          </p>
          <div className='divider'></div>
          <div className='subs__details-grid'>
            <div className='subs-inner'>
              <CheckIcon />
              <p>Unlimited prompts ( Web )</p>
            </div>
            <Accordion>
              <div className='subs-inner'>
                <CheckIcon />
                <p>6 AI engines including ChatGPT, Stable Diffusion and more</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>
                  10 Different AI image generation models based on Stable
                  Diffusion
                </p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>New DocAI: AI powered content creation and collaboration tool</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>Full AI Capabilities</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>Advanced Image generation</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>Unlimited chat history</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>Image editing, filters and templates</p>
              </div>
              <div className='subs-inner'>
                <CheckIcon />
                <p>First access to new AI models and ChatAI product upgrades</p>
              </div>
              
              
            </Accordion>
          </div>
        </div>
        <div className='btn-head'>
          <button
            type='button'
            className='plan-btn white'
            id='buy-monthly-plan'
            onClick={() => {

              if (planData?.plan?.toLowerCase() !== 'year') {
                window.dataLayer.push({
                  'event': 'custom',
                  'eventName': 'upgrade_select_plan',
                  'eventCategory': 'Chat-AI Plus', //dynamic value of plan name eg. Chat-AI Free
                  'planPrice': price, //dynamic plan value eg. 0
                  'Location': 'upgrade_profile', //eg. upgrade_ui, upgrade_profile
                });
              }

              if (planText === 'month') {
                return;
              } else {
                handlePlan('1');
                let item = {
                  plan_name: 'plus_plan',
                  Price: '$9',
                };
                window?.klaviyo.push(['track', 'Viewed Product', item]);
              }
            }}
            disabled={loading}
          >
            {loading ? (
              <ClipLoader size={15} color={theme.colors.red} />
            ) : (
              <>
                {planText === 'month'
                  ? 'Current Plan'
                  : planText === 'year'
                    ? 'Upgrade Plan'
                    : 'Select Plan'}
              </>
            )}
          </button>
        </div>
      </div>
    </Container>
  );
}

export default MonthlySubscription;

const WasPrice = styled.span`
  opacity: 0.4;
  position: relative;
  font-weight: 400;

  & img {
    width: 60px;
  }
`;
