import React, { useEffect } from 'react';
import CustomButton from 'container/components/CustomButton/CustomButton';

// import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import assets from 'assets';

import styled from 'styled-components';

const ThankYou = () => {
  const navigate = useNavigate();
  // const { user } = useSelector(state => state.auth);

  const state = useLocation().state;

  const show = state?.show;

  const planId = state?.planId;
  const id = state?.id;

  const handleChatNow = () => {
    navigate('/', { replace: 'true' });
  };

  // const handleReferAFriend = () => {
  //   window.RF = {
  //     user: {
  //       first_name: `${user?.first_name}`,
  //       email: `${user?.email}`,
  //     },
  //   };
  // };

  const handleFireEvent = () => {
    try {
      const { id, couponCode, price } = state;
      if (id && price) {
        window.flexEvent({
          order_number: id,
          order_amount: price,
          coupon: couponCode,
        });
      }
    } catch (err) {
      console.log('Script error : ', err.message);
    }
  };

  useEffect(() => {
    if (!show) {
      navigate('/', { replace: 'true' });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleFireEvent();
    // eslint-disable-next-line
  }, [state]);

  return (
    <Container>
      <Content>
        <Logo>
          <img src={assets.logo} alt='' />
        </Logo>
        <img
          alt=''
          src={`https://www.shareasale.com/sale.cfm?tracking=${id}&amount=${
            +planId === 1 ? 9 : 90
          }&merchantID=152126&transtype=sale`}
          width='1'
          height='1'
          style={{ position: 'absolute' }}
        ></img>
        <h1 className='title'>
          Thanks For Going {+planId === 1 ? 'Plus' : 'Pro'}!
        </h1>
        <p className='font-size-24 subtitle'>
          Your upgrade unlocks an unlimited world of AI possibilities!
        </p>
        <p className='font-size-16 description'>
          You are subscribed to ChatAI’s{' '}
          {+planId === 1 ? 'Monthly Plus Plan' : 'Yearly Pro Plan'} . Be on the
          look out for your confirmation email. If you don’t love ChatAI, you
          can cancel within 7 days of your upgrade for your money back by
          emailing us at{' '}
          <a
            href='https://chatai.com/'
            className='link'
            rel='noopenner noreferrer'
            target='_blank'
          >
            billing@chatai.com
          </a>
          . <br />
          <br /> If you have any questions about your order, please contact us
          at{' '}
          <a
            href='https://help.chatai.com/'
            rel='noopenner noreferrer'
            target='_blank'
            className='link'
          >
            {' '}
            help@chatai.com
          </a>
          .
        </p>
        <SocialRow>
          <Social>
            <p className='font-size-24 bold'>Get Started</p>
            <CustomButton
              label='Chat Now'
              leftIcon={assets.chat}
              className='btn'
              onClick={handleChatNow}
            />
          </Social>

          <Social>
            <p className='font-size-24 bold'>Connect </p>
            <a
              href='https://www.facebook.com/profile.php?id=61555443810411'
              rel='noopenner noreferrer'
              target='_blank'
            >
              <CustomButton
                label='Follow us on Facebook'
                leftIcon={assets.facebookIcon}
                className='btn fb'
              />
            </a>
          </Social>
        </SocialRow>
        <p className='font-size-24 posts'>Top Posts for Pro Users </p>
        <BlogPostsRow>
          <BlogPost
            href='https://chatai.com/how-to-write-a-prompt-part-2/'
            rel='noopener noreferrer'
            target='_blank'
          >
            <div className='badge' style={{ background: '#4AA6CD' }}>
              <p className='font-size-14'>Prompt Engineering for AI</p>
            </div>
            <p className='font-size-24 card-title'>
              How to Write a Prompt Part 2: Our 7 Expert Tips for Powerful AI
              Interactions
            </p>
            <p className='font-size-16 desc'>
              Are you ready to take your AI prompt writing skills to the next
              level? Welcome back to Part Two of our series, "How to Write a
              Prompt."
            </p>
          </BlogPost>

          <BlogPost
            href='https://chatai.com/guide-to-writing-ai-prompts-for-art-with-stable-diffusion/'
            rel='noopener noreferrer'
            target='_blank'
            style={{ backgroundImage: `url(${assets.blog})` }}
          >
            <div className='badge' style={{ background: '#CD4A4A' }}>
              <p className='font-size-14'>AI in Art</p>
            </div>
            <p className='font-size-24 card-title'>
              Your Guide to Writing AI Prompts for Art with Stable Diffusion
            </p>
            <p className='font-size-16 desc'>
              The advent of text-to-image generative AI has taken the creative
              world by storm, and one of the most transformative tools in the
              space is Stable Diffusion. Image generators convert written
              prompts into tangible, artistic images. These AI models are
              tremendous assets and time savers for artists, business owners,
              marketers, and creatives worldwide. But what tips […]
            </p>
          </BlogPost>

          <BlogPost
            href=' https://chatai.com/train-your-chatbot-to-write-like-you/'
            rel='noopener noreferrer'
            target='_blank'
          >
            <div className='badge' style={{ background: '#F4812E' }}>
              <p className='font-size-14'>AI for Writing</p>
            </div>
            <p className='font-size-24 card-title'>
              Train Your Chatbot To Write Like You
            </p>
            <p className='font-size-16 desc'>
              Tired of AI-assisted writing that is as bland as plain oatmeal?
              Teaching your ChatAI to write like you can infuse your
              AI-generated content with personality and style that reflects your
              brand and resonates better with your audience.
            </p>
          </BlogPost>
        </BlogPostsRow>
      </Content>
    </Container>
  );
};

export default ThankYou;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBg};
  padding: 14px;
  height: 100lvh;
  display: flex;
  flex-direction: column;

  & p,
  & h1 {
    color: ${({ theme }) => theme.colors.primary};
  }

  & .title {
    font-size: 64px;
    font-weight: 600;
    line-height: 96px;
    letter-spacing: 0px;
    text-align: left;
    text-align: center;
  }

  & .subtitle {
    margin-block: 8px;
    font-weight: 500;
    text-align: center;
  }

  & .description {
    text-align: center;
    max-width: 70ch;
  }

  & a.link {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline !important;
  }

  & .posts {
    font-weight: 500;
  }
`;

const Content = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.darkgrey};
  border-radius: 14px;
  overflow: auto;
  position: relative;
  height: 0px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    padding-top: 80px;
  }
`;

const Logo = styled.div`
  position: absolute;
  left: 28px;
  top: 20px;
`;

const SocialRow = styled.div`
  display: flex;
  align-items: center;
  gap: 29px;
  flex-wrap: wrap;
  margin-block: 80px;
  justify-content: center;
`;

const Social = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  & .btn {
    height: 64px;

    cursor: pointer;
    width: 250px;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }

    &.fb {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #315a9f;
      border: 2px solid #315a9f;
    }

    & span {
      font-size: 16px !important;
    }
  }
`;

const BlogPostsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 24px;
  margin-top: 25px;
  width: 100%;
  flex-wrap: wrap;
`;

const BlogPost = styled.a`
  background-color: ${({ theme }) => theme.colors.darkbg};
  border-radius: 8px;
  padding: 12px;
  flex: 0 0 33.33%;
  max-width: 340px;
  min-width: 280px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & .badge {
    background-color: red;
    height: 30px;
    border-radius: 100vh;
    padding: 6px 16px;
  }

  & .card-title {
    font-weight: 600;
    line-height: 34px;
    margin-block: 10px;
  }

  & .desc {
    opacity: 0.5;
  }

  @media only screen and (max-width: 768px) {
    flex: 0 0 50%;
    max-width: unset;
    min-width: unset;
  }
  @media only screen and (max-width: 600px) {
    flex: 0 0 100%;
    max-width: unset;
  }
`;
