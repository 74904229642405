import assets from 'assets';

export const filters = [
  {
    id: 100,
    name: 'None',
    descrption: '',
    image_url: assets.noFilter,
  },
  {
    id: 1,
    name: 'Vivid Colors',
    descrption:
      "Apply a 'Vivid Colors' filter to saturate the image with intense and lively hues that captivate the eye.",
    image_url: assets.Vivid_Colors,
  },
  {
    id: 2,
    name: 'Monochromatic Palette',
    descrption:
      "Transform the scene using a 'Monochromatic Palette' filter, highlighting the scene's details through a single color tone.",
    image_url: assets.Monochromatic,
  },
  {
    id: 3,
    name: 'Soft Pastels',
    descrption:
      "Infuse a 'Soft Pastels' filter into the image, giving it a gentle and dreamlike quality with delicate, light shades.",
    image_url: assets.Soft_Pastels,
  },
  {
    id: 4,
    name: 'High Contrast',
    descrption:
      "Apply a 'High Contrast' filter to emphasize bold differences between light and shadow for a striking visual impact.",
    image_url: assets.High_Contrast,
  },
  {
    id: 5,
    name: 'Muted Tones',
    descrption:
      "Enhance the tranquility of the image with a 'Muted Tones' filter, creating a calm and serene atmosphere.",
    image_url: assets.Muted_Tones
  },
  {
    id: 6,
    name: 'Dramatic Lighting',
    descrption:
      "Add a 'Dramatic Lighting' filter to create a sense of mystery and tension through contrast.",
    image_url: assets.Dramatic_Icons,
  },
  {
    id: 7,
    name: 'Watercolor Painting',
    descrption:
      'Transform the image into a watercolor painting, where colors bleed and blend, creating a delicate and artistic effect.',
    image_url: assets.WaterColor_Paintings
  },
  {
    id: 8,
    name: 'Rustic Textures',
    descrption:
      "Apply a 'Rustic Textures' filter to add texture and depth, enhancing the cozy and nostalgic ambiance.",
    image_url: assets.Rustic_Textures
  },
  {
    id: 9,
    name: 'Vintage Sepia',
    descrption:
      "Give the image a classic touch with a 'Vintage Sepia' filter, evoking memories of old photographs.",
    image_url: assets.Vintage_Sepia
  },
  {
    id: 10,
    name: 'Dreamy Atmosphere',
    descrption:
      "Infuse a 'Dreamy Atmosphere' filter to make the scene feel soft and ethereal, as if seen through a dream.",
    image_url: assets.Dreamy_Atomosphere,
  },
  {
    id: 11,
    name: 'Impressionistic Strokes',
    descrption:
      "Apply an 'Impressionistic Strokes' filter to capture the energy and emotion of the moment through dynamic brushwork.",
    image_url: assets.Impressionistic_Stokes,
  },
  {
    id: 12,
    name: 'Close-up Details',
    descrption:
      "Use a 'Close-up Details' filter to focus on intricate elements, revealing the beauty in small things.",
    image_url: assets.Close_up,
  },
];
