import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// Formik
import { Formik } from "formik";
import * as Yup from "yup";
//
import Button from "container/components/Button";
import Input from "container/components/Input";
import ErrorMessage from "container/components/ErrorMessage";
import Spinner from "container/components/Spinner";

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .min(6)
    .required("Email is required"),
});

function EmailContainer() {
  const dispatch = useDispatch();

  return (
    <div className="enter-email-container">
      <Formik
        initialValues={{ email: "" }}
        validationSchema={EmailSchema}
        onSubmit={(values, { setSubmitting }, errors) => {
          console.log("values", values);
          localStorage.setItem("forgot-email", values.email);
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
          dispatch.auth.forgotPasswordEmail(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className="input-email mt-4"
              placeholder="Email Address"
            />
            <ErrorMessage
              error={errors.email && touched.email && errors.email}
              left={true}
            />
            <p className="account-password">
              Go back to{" "}
              <Link to="/sign-in">
                <span>Sign In</span>
              </Link>
            </p>
            <Button
              text={isSubmitting ? <Spinner /> : "Send Email"}
              className="account-button mb-4 mt-2"
              type="submit"
              disabled={isSubmitting}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}

export default EmailContainer;
