import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { siteURL } from 'app.config';
import assets from 'assets';

const ShareWithWhatsapp = ({ link, className }) => {
  const id = useSelector(state => state.chat.selectedConversation?.id || '');
  // const messages = useSelector(state => state.chat.messages);

  const whatsappLink = link
    ? `https://api.whatsapp.com/send?text=${link}`
    : `https://api.whatsapp.com/send?text=${siteURL}/share/${id}`;

  return (
    <>
      <a href={whatsappLink} target='_blank' rel='noopener noreferrer'>
        <Container role='button' className={className}>
          <img src={assets.whatsapp} alt='' />
          {!link && <p className='font-size-12 bold'>WhatsApp</p>}
        </Container>
      </a>
    </>
  );
};

export default ShareWithWhatsapp;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: ${({ theme }) => theme.colors.primary};
  align-items: center;

  & img {
    width: 60px;
    aspect-ratio: 1/1;
  }
`;
