const assets = {
  settings: require('./icons/setting2.svg').default,
  chatGpt: require('./icons/chatgpt-icon.png'),
  cohere: require('./icons/Cohere.svg').default,
  gpt4: require('./icons/image-4.png'),
  ai21: require('./icons/A121.svg').default,
  picasso: require('./icons/stableDiffusion.svg').default,
  huggingFace: require('./images/huggingFace.png'),
  tick: require('./icons/tick.png'),
  userAvatar: require('./images/user-avatar.png'),
  userAvatar2: require('./images/user-avatar-1.png'),
  stripeCards: require('./images/stripe-cards.png'),
  closeIcon: require('./icons/close-icon.svg').default,
  closeIconGrey: require('./icons/closeIconGrey.svg').default,
  giftIcon: require('./images/gift.png'),
  info: require('./icons/info.png'),
  card: require('./icons/card.svg').default,
  logo: require('./icons/brand-logo.svg').default,
  chevronLeft: require('./icons/chevronLeft.svg').default,
  user: require('./icons/user.svg').default,
  star: require('./icons/star.svg').default,
  users: require('./icons/users.svg').default,
  invoices: require('./icons/invoices.svg').default,
  book: require('./icons/book.svg').default,
  eye: require('./icons/eye.svg').default,
  eyeslash: require('./icons/eyeslash.svg').default,
  copy: require('./icons/copy.svg').default,
  arrowDown: require('./icons/arrowdown.svg').default,
  arrowLeft: require('./icons/arrowLeft.svg').default,
  close: require('./icons/close.svg').default,
  burger: require('./icons/burger.svg').default,
  profileUpdate: require('./icons/profile-update.svg').default,
  copyButton: require('./icons/clipboardtick.svg').default,
  bell: require('./icons/bell.svg').default,
  arrowRightGreen: require('./icons/arrowRightGreen.svg').default,
  tickGreen: require('./icons/tick.svg').default,
  infoRed: require('./icons/infoRed.svg').default,
  googleIcon: require('./icons/googleIcon.png'),
  whatsapp: require('./icons/whatsapp.svg').default,
  facebook: require('./icons/facebook.svg').default,
  twitter: require('./icons/twitter.svg').default,
  linkedIn: require('./icons/linkedin.svg').default,
  sms: require('./icons/sms.svg').default,
  like: require('./icons/like.svg').default,
  dislike: require('./icons/dislike.svg').default,
  share: require('./icons/share.svg').default,
  starOutlined: require('./icons/starOutlined.svg').default,
  edit: require('./icons/edit.svg').default,
  delete: require('./icons/delete.svg').default,
  correct: require('./icons/correct.svg').default,
  false: require('./icons/xcross.svg').default,
  starFilled: require('./icons/starFilled.svg').default,
  likeFilled: require('./icons/likeFilled.svg').default,
  dislikeFilled: require('./icons/dislikeFilled.svg').default,
  temp1: require('./images/temp1.png'),
  temp2: require('./images/temp2.png'),
  temp3: require('./images/temp3.png'),
  triangleArrow: require('./icons/triangleArrow.svg').default,
  check: require('./icons/check.svg').default,
  download: require('./icons/download.svg').default,
  expand: require('./icons/expand.svg').default,
  selected: require('./icons/selected.svg').default,
  help: require('./icons/help.svg').default,
  bg: require('./images/bg.svg').default,
  discount: require('./images/discount.png'),
  post: require('./images/post.png'),
  signup: require('./icons/signup.svg').default,
  preview: require('./images/preview.png'),
  menu: require('./icons/menu.svg').default,
  message: require('./icons/message.svg').default,
  settingsWhite: require('./icons/settings-white.svg').default,
  checked: require('./icons/checked.svg').default,
  uncheck: require('./icons/uncheck.svg').default,
  plus: require('./icons/plus.svg').default,
  custom: require('./icons/custom.svg').default,
  noFilter: require('./images/noFilter.png'),
  dummy: require('./images/dummy.png'),
  newCopy: require('./icons/newCopy.svg').default,
  newArrowRight: require('./icons/newArrowRight.svg').default,
  newGift: require('./icons/new-gift.svg').default,
  offFourty: require('./icons/40off.svg').default,
  whiteClose: require('./icons/white-close.svg').default,
  anak: require('./icons/anak.svg').default,
  tree: require('./icons/Tree.png'),
  sale: require('./icons/SALE.svg').default,
  grayClose: require('./icons/gray-close.svg').default,
  // stars: require('./images/stars.png'),
  laptop: require('./images/laptop.png'),
  tickWithGreen: require('./images/tick-circle.png'),
  midJourney: require('./icons/midJourney.svg').default,
  eyeSlash: require('./icons/eye-slash.svg').default,
  salesBg: require('./images/sales-bg.png'),
  cyberMondayPrice: require('./icons/cyberMondayPrice.svg').default,
  documentText: require('./icons/document-text.svg').default,
  arrowRight: require('./icons/arrow-right.svg').default,
  outliner: require('./icons/outliner.svg').default,
  crossRed: require('./icons/cross-red.svg').default,
  discard: require('./icons/discard.svg').default,
  save: require('./icons/save.svg').default,
  icon: require('./icons/icon.svg').default,
  backIcon: require('./icons/backIcon.svg').default,
  giftsIcon: require('./icons/gift-icon.svg').default,
  send: require('./icons/send.svg').default,
  email: require('./icons/email.svg').default,
  christmas: require('./images/christmas.png'),
  xmas: require('./images/xmas.png'),
  giftBanner: require('./images/gift-banner.png'),
  9: require('./icons/9.svg').default,
  90: require('./icons/90.svg').default,
  valentine: require('./images/valentine.png'),
  chat: require('./icons/chat.svg').default,
  darkIcon: require('./icons/dark-icon.svg').default,
  facebookIcon: require('./icons/facebook-icon.svg').default,
  blog: require('./images/blog.png'),
  chataiText: require('./images/chatai-text.png'),
  searchIcon: require('./images/search-icon.png'),
  searchLine: require('./images/search-line.png'),
  union: require('./images/Union.png'),
  chatIcon: require('./icons/chatIcon.svg').default,
  chatIconCom: require('./icons/chatIcon.svg').ReactComponent,
  docIconCom: require('./icons/doc-ai-react.svg').ReactComponent,
  craeteIconCom: require('./icons/create-react.svg').ReactComponent,
  docAiIcon: require('./images/doc-ai.png'),
  createIcon: require('./images/create.png'),
  alertIcon: require('./images/alert.png'),
  questionIcon: require('./images/question.png'),
  avatarImage: require('./images/Avatar Image.png'),
  hashTag: require('./images/hashtag.png'),
  dropdownArrow: require('./images/arrow-down.png'),
  plusIcon: require('./images/plus.png'),
  addDoc: require('./images/add_doc.png'),
  saveDoc: require('./images/save_doc.png'),
  emptyState: require('./images/Empty State.png'),
  chart2: require('./images/chart-2.png'),
  imageBg: require('./images/Dot Background.png'),
  downloadDoc: require('./images/download_doc.png'),
  profileCircle: require('./images/profile-circle.png'),
  stars: require('./icons/stars.svg').default,
  stars5: require('./icons/5Stars.svg').default,
  logoWithText: require('./icons/LogoWithText.svg').default,
  arrowRightMobile: require('./icons/arrow-right-mobile.svg').default,
  sourceCode: require('./icons/source-code.svg').default,
  documentDownload: require('./icons/document-download.svg').default,
  affiliates: require('./icons/affliliates.svg').default,
  pdf: require('./icons/pdf.svg').default,
  word: require('./icons/word.svg').default,
  html: require('./icons/html.svg').default,
  DLLM: require('./icons/doc-llm.svg').default,
  cloud: require('./icons/cloud.svg').default,
  checkCircle: require('./icons/check-circle.svg').default,
  attention: require('./icons/attention.svg').default,
  SDXL: require('./images/SDXL.png'),
  Image_all: require('./images/selectAll_image_model.svg').default,
  StableDiffusion21: require('./images/Stable Diffusion 2.1.png'),
  StableDiffusion15: require('./images/Stable Diffusion 1.5.png'),
  RealisticVisionv40: require('./images/Realistic Vision v4.0.png'),
  RealisticVisionv20: require('./images/Realistic Vision v2.0.png'),
  OpenJourneyV4: require('./images/Open Journey V4.png'),
  OpenJourneyV2: require('./images/Open Journey V2.png'),
  OpenJourneyV1: require('./images/Open Journey V1.png'),
  FutureDiffusion: require('./images/Future Diffusion.png'),
  ModernDisney: require('./images/Modern Disney.png'),
  magicpen: require('./icons/magicpen.svg').default,
  clockCounterClockwise: require('./icons/ClockCounterClockwise.svg').default,
  alignLeft: require('./icons/align-left.svg').default,
  Element: require('./images/elements.png'),
  AiWeiwei: require('./artist/Ai Weiwei.jpg'),
  AnselmKeifer: require('./artist/Anselm Kiefer.jpg'),
  MarleneDumas: require('./artist/Marlene Dumas.jpg'),
  YayoiKusama: require('./artist/Yayoi Kusama.jpg'),
  AndyWarhol: require('./artist/Andy Warhol.jpg'),
  GerhardRichter: require('./artist/Gerhard Richter.jpg'),
  
  FridaKahlo: require('./artist/Frida Kahlo.jpeg'),
  HenriMatisse: require('./artist/Henri Matisse.jpeg'),
  JacksonPollock: require('./artist/Jackson Pollock.webp'),
  SalvadorDali: require('./artist/Salvador Dalí.jpeg'),
  WassilyKandinsky: require('./artist/Wassily Kandinsky.webp'),

  Art_01: require('./templates/Art_01.jpeg'),
  Art_02: require('./templates/Art_02.jpeg'),
  Art_03: require('./templates/Art_03.jpeg'),
  Art_04: require('./templates/Art_04.jpeg'),
  Character_01: require('./templates/Character_01.jpeg'),
  Character_02: require('./templates/Character_02.jpeg'),
  Character_03: require('./templates/Character_03.jpeg'),
  Character_04: require('./templates/Character_04.jpeg'),
  Character_05: require('./templates/Character_05.jpeg'),
  Character_06: require('./templates/Character_06.jpeg'),
  Icon: require('./templates/icon.jpeg'),
  Icon_2: require('./templates/Icon_2.jpeg'),
  Wallpaper_01: require('./templates/Wallpaper_01.jpeg'),
  Wallpaper_02: require('./templates/Wallpaper_02.jpeg'),


  Vivid_Colors: require('./Filters/Vivid_colors.jpeg'),
  Monochromatic: require('./Filters/Monochromatic.png'),
  Muted_Tones: require('./Filters/Muted_Tones.png'),
  Rustic_Textures: require('./Filters/Rustic_Textures.jpeg'),
  Soft_Pastels: require('./Filters/Soft_Pastels.png'),
  Vintage_Sepia: require('./Filters/Vintage_Sepia.jpeg'),
  FilterWallpaper_01: require('./Filters/Wallpaper_01.jpeg'),
  FilterWallpaper_02: require('./Filters/Wallpaper_02.jpeg'),
  WaterColor_Paintings: require('./Filters/WaterColor_Paintings.png'),
  High_Contrast: require('./Filters/High_Contrast.png'),
  Dramatic_Icons: require('./Filters/Dramatic_Icons.jpeg'),
  Dreamy_Atomosphere: require('./Filters/Dreamy_Atomosphere.jpeg'),
  Impressionistic_Stokes: require('./Filters/Impressionistic_Stokes.jpeg'),
  Close_up: require('./Filters/Close_up.png'),
  pgNowChatai: require('./icons/pgNowChatai.svg').default,
  chataiFullLogo: require('./icons/full-logo-chatai.svg').default,
  pgchataiFullLogo: require('./icons/pg-chatai-logo.svg').default,
  newUnSelected: require('./icons/not-select.svg').default,

  LeonardodaVinci: require('./artist/Leonardo da Vinci.png'),
  MichelangeloBuonarroti: require('./artist/Michelangelo Buonarroti.png'),
  RembrandtvanRijn: require('./artist/Rembrandt van Rijn.png'),
  VincentvanGogh: require('./artist/Vincent van Gogh.png'),
  PabloPicasso: require('./artist/Pablo Picasso.png'),
  ClaudeMonet: require('./artist/Claude Monet.png'),
  JohannesVermeer: require('./artist/Johannes Vermeer.png'),
  RaphaelSanzio: require('./artist/Raphael Sanzio.png'),
  CaravaggioMichelangeloMerisidaCaravaggio: require('./artist/Caravaggio (Michelangelo Merisi da Caravaggio).png'),
  TitianTizianoVecellio: require('./artist/Titian (Tiziano Vecellio).png'),
  FranciscoGoya: require('./artist/Francisco Goya.png'),
  DiegoVelázquez: require('./artist/Diego Velázquez.png'),
  JanvanEyck: require('./artist/Jan van Eyck.png'),
  PeterPaulRubens: require('./artist/Peter Paul Rubens.png'),
  ElGrecoDomenikosTheotokopoulos: require('./artist/El Greco (Domenikos Theotokopoulos).png'),
  ÉdouardManet: require('./artist/Édouard Manet.png'),
  // HenriMatisse: require('./artist/Henri Matisse.png'),
  PaulCézanne: require('./artist/Paul Cézanne.png'),
  // JacksonPollock: require('./artist/Jackson Pollock.png'),
  // AndyWarhol: require('./artist/Andy Warhol.png'),
  MarcChagall: require('./artist/Marc Chagall.png'),
  // WassilyKandinsky: require('./artist/Wassily Kandinsky.png'),
  GustavKlimt: require('./artist/Gustav Klimt.png'),
  GeorgiaOKeeffe: require(`./artist/Georgia O'Keeffe.png`),
  EdwardHopper: require('./artist/Edward Hopper.png'),
  SalvadorDalí: require('./artist/Salvador Dalí.png'),
  // FridaKahlo: require('./artist/Frida Kahlo.png'),
  EdgarDegas: require('./artist/Edgar Degas.png'),
  PierreAugusteRenoir: require('./artist/Pierre-Auguste Renoir.png'),
  PaulGauguin: require('./artist/Paul Gauguin.png'),
  AlbrechtDürer: require('./artist/Albrecht Dürer.png'),
  CasparDavidFriedrich: require('./artist/Caspar David Friedrich.png'),
  JMWTurner: require('./artist/J.M.W. Turner.png'),
  JeanMichelBasquiat: require('./artist/Jean-Michel Basquiat.png'),
  SandroBotticelli: require('./artist/Sandro Botticelli.png'),
  AmedeoModigliani: require('./artist/Amedeo Modigliani.png'),
  GeorgesSeurat: require('./artist/Georges Seurat.png'),
  HenrideToulouseLautrec: require('./artist/Henri de Toulouse-Lautrec.png'),
  JoanMiró: require('./artist/Joan Miró.png'),
  RoyLichtenstein: require('./artist/Roy Lichtenstein.png'),
  NormanRockwell: require('./artist/Norman Rockwell.png'),
  HieronymusBosch: require('./artist/Hieronymus Bosch.png'),
  DavidHockney: require('./artist/David Hockney.png'),
  WinslowHomer: require('./artist/Winslow Homer.png'),
  GrantWood: require('./artist/Grant Wood.png'),
  ÉlisabethVigéeLeBrun: require('./artist/Élisabeth Vigée Le Brun.png'),
  ThomasGainsborough: require('./artist/Thomas Gainsborough.png'),
  JohnSingerSargent: require('./artist/John Singer Sargent.png'),
  MaryCassatt: require('./artist/Mary Cassatt.png'),
  ArtemisiaGentileschi: require('./artist/Artemisia Gentileschi.png'),

  // grayClose: require('./icons/gray-close.svg').default,
  // tree: require('./icons/Tree.png'),
  // whiteClose: require('./icons/white-close.svg').default,
  // newGift: require('./icons/new-gift.svg').default,
  // sale: require('./icons/SALE.svg').default


};

export default assets;
