import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// CSS
import '../SignUp/SignUp.css';
import '../SignIn/SignIn.css';
import EmailContainer from './components/EmailContainer';
import ChangePassword from './components/ChangePassword';
import FullLogo from 'container/components/FullLogo';
// import SalesBanner from 'container/components/SalesBanner/SalesBanner';
// import { bannerText } from 'app.config';
import styled from 'styled-components';
import TopBanner from '../TopBanner/TopBanner';

function ForgetPassword() {
  const dispatch = useDispatch();

  const forgetPassword = useSelector(state => state.utils.forgetPasswordState);
  const email = useSelector(state => state.auth.email);
  const { topBanner } = useSelector(state => state.utils);

  useEffect(() => {
    if (email)
      dispatch.utils.setForgetPassword('change-password');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {topBanner && <TopBanner isBtn={false} />}
      <Container style={{ height: topBanner ? '85vh' : '100vh' }}>
        <div className='container'>
          {/* Logo */}
          <div className='row sign__in-logo'>
            <FullLogo />
          </div>
          {/* BOX Container */}
          <div className='row'>
            <div className='col-sm-12 col-10 sign__in-box sign__up-box pt-4 mb-4'>
              <h1 className='account-heading' style={{ fontSize: '30px' }}>
                {forgetPassword === 'send-email'
                  ? 'Forget Password'
                  : 'Create New Password'}
              </h1>
              {forgetPassword === 'send-email' ? (
                <EmailContainer />
              ) : forgetPassword === 'change-password' ? (
                <ChangePassword />
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ForgetPassword;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */

  & .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .row {
      width: 100%;
    }
  }

  /*  */
  @media only screen and (max-width: 550px) {
    margin: 0 10px;
  }
`;
