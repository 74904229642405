import React, { useState } from 'react';

import styled from 'styled-components';

import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
//
import ErrorMessage from 'container/components/ErrorMessage';
import InputPassword from 'container/components/InputPassword';
import Input from 'container/components/Input';
import Spinner from 'container/components/Spinner';
import Button from 'container/components/Button';
//
import { checkEmail } from 'container/api';
import GoogleLogin from 'container/components/GoogleLogin/GoogleLogin';

function CreateAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const referral_code = params.get('referral_code');
  const playground_user = params.get('playground_user');

  const loading = useSelector(state => state.auth.loading);
  const [checkingEmail, setCheckingEmail] = useState(false);

  const handleKeyDown = e => {
    if (e.keyCode === 32) {
      e.preventDefault();
      return false;
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }, errors) => {
    try {
      setSubmitting(true);
      setCheckingEmail(true);
      const { data } = await checkEmail(values.email);
      if (data.isEmailDuplicate) {
        setErrors({ email: 'Email already exist!, please try another email.' });
        return toast.error('Email already exist!, please try another email.');
      }

      dispatch.auth.handleSignUp({
        data: referral_code ? { ...values, referral_code } : values,
        navigate,
      });
    } catch (err) {
      console.log(err.message);
    } finally {
      setSubmitting(false);
      setCheckingEmail(false);
    }
  };
  const validate = values => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.first_name) {
      errors.first_name = 'First Name is required';
    } else if (values.first_name.length < 3) {
      errors.first_name = 'First Name is too short';
    }

    if (!values.last_name) {
      errors.last_name = 'Last Name is required';
    } else if (values.last_name.length < 3) {
      errors.last_name = 'Last Name is too short';
    }

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!regex.test(values.email)) {
      errors.email = 'Invalid Email';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 5) {
      errors.password = 'Password too short';
    }

    return errors;
  };
  return (
    <div className='sign__up-box pt-4 mb-4 pb-2'>
      <h1 className='account-heading'>{playground_user ? '' : 'Sign Up'} </h1>
      {
        !playground_user &&
        // (<>
        //   <div className='sign-in-new-text'>
        //     <span className='pg-users'>Playground AI users.</span>
        //     <span className='detail'>
        //       Please login to ChatAI using your existing Playground AI email and password. Your chat history, images, and account settings are all available in ChatAI. Please contact us at help@chatai.com with any questions about your account migration.
        //       Thank you
        //     </span>
        //   </div>
        // </>) :
      (<>
          <h1 className='account-heading2'>Get 10 Free Prompts</h1>
          <span className='credit-heading'>No credit card required</span>
        </>)
      }
      <div className='input-box mt-5'>
        <Formik
          initialValues={{
            first_name: localStorage.getItem('name') || '',
            last_name: '',
            email: localStorage.getItem('email') || '',
            password: '',
          }}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Input
                type='text'
                name='first_name'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
                onKeyDown={handleKeyDown}
                className='input-email mb-2'
                placeholder='Enter First Name'
                required
                id='sign-up-first_name'
              />
              <ErrorMessage
                error={
                  errors.first_name && touched.first_name && errors.first_name
                }
              />
              <Input
                type='text'
                name='last_name'
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                value={values.last_name}
                className='input-email mb-2'
                placeholder='Enter Last Name'
                required
                id='sign-up-last_name'
              />
              <ErrorMessage
                error={
                  errors.last_name && touched.last_name && errors.last_name
                }
              />
              <Input
                type='email'
                name='email'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className='input-email mb-2'
                placeholder='Enter Email Address'
                required
                id='sign-up-email'
              />
              <ErrorMessage
                error={errors.email && touched.email && errors.email}
              />
              <div>
                <InputPassword
                  type='password'
                  name='password'
                  className='input-email mb-2'
                  placeholder='Enter Password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  required
                  eye={'true'}
                  eyeStyles={{
                    top: '50%',
                    transform: 'translateY(-60%)',
                  }}
                  id='sign-up-password'
                />
              </div>
              <ErrorMessage
                error={errors.password && touched.password && errors.password}
              />
              <Button
                text={loading || isSubmitting ? <Spinner /> : ' Get Started for Free '}
                className='confirm-details'
                type='submit'
                disabled={isSubmitting || checkingEmail || loading}
                onClick={handleSubmit}
                id='sign-up-btn'
              />
            </form>
          )}
        </Formik>
        <Divider>
          <span>or</span>
        </Divider>
        <GoogleLogin id='signup-with-google' />
      </div>
      <p className='have-account mt-1' style={{ paddingTop: '20px' }}>
        Already have an account?{' '}
        <Link to={`/sign-in?${params.toString()}`}>
          <span>Log in</span>
        </Link>
      </p>
    </div>
  );
}

export default CreateAccount;

const Divider = styled.div`
  width: 100%;
  margin-block: 30px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ffffff1a;

  & span {
    color: ${({ theme }) => theme.colors.primary};
    line-height: 1;
    background-color: ${({ theme }) => theme.colors.darkbg};
    padding-inline: 10px;
    margin-top: -4px;
  }
`;
