import React, { useState } from 'react';

import CustomButton from 'container/components/CustomButton/CustomButton';
import TextField from 'container/components/TextField/TextField';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import styled from 'styled-components';
import { cancelSubscription } from 'container/api';

const CancelSubscription = ({ setCancelSubscription }) => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState({
    1: '',
    2: '',
    3: '',
  });

  const user = useSelector(state => state.auth.user);

  const handleChange = e => {
    const { value, name } = e.target;
    setQuestions(prev => ({ ...prev, [name]: value }));
  };

  const handleCancelsubscription = async () => {
    setLoading(true);
    try {
      const { data } = await cancelSubscription({
        user_id: user.id,
        'What made you cancel your subscription?': questions[1],
        'What could we have done differently?': questions[2],
        'Which area/feature do you think we can improve on?': questions[3],
      });
      if (data.status === 200) {
        toast.success('Request sent to support team.');
      }
    } catch (err) {
      setLoading(false);
      console.log(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
      setCancelSubscription(false);
    }
  };

  const handleCloseForm = () => {
    setCancelSubscription(false);
  };

  return (
    <Container>
      <p className='font-size-24'>Cancel Subscription</p>
      <p className='font-size-12 desc'>
        Help us understand how can we make our services better for you.
      </p>
      <Grid>
        <TextField
          value={questions[1]}
          onChange={handleChange}
          name='1'
          label='What made you cancel your subscription? (optional)'
        />
        <TextField
          value={questions[2]}
          onChange={handleChange}
          name='2'
          label='What could have we done differently? (optional)'
        />
        <TextField
          value={questions[3]}
          onChange={handleChange}
          name='3'
          label='Which area/feature do you think we can improve? (optional)'
        />
      </Grid>
      <ButtonContainer>
        <CustomButton
          label='Cancel'
          onClick={handleCloseForm}
          variant='btn-outlined'
        />
        <CustomButton
          onClick={handleCancelsubscription}
          label='Submit'
          loading={loading}
        />
      </ButtonContainer>
    </Container>
  );
};

export default CancelSubscription;

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 14px;
  padding: 30px;
  margin-top: 50px;

  & p {
    margin: 0px;

    &.desc {
      margin-top: 4px;
    }
  }

  & .label {
    color: ${({ theme }) => theme.colors.primary}!important;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 500px) {
    padding: 15px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px 20px;
  margin-top: 50px;

  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;

  & button {
    width: 187px;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    width: 100%;
    & button {
      width: 100%;
    }
  }
`;
