import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import ClickAwayListener from 'react-click-away-listener';
import { picassoConfig } from 'app.config';
import { suggestionChips } from 'container/data/suggestionChips';

function SearchTags({ setPredict, searchRef }) {
  const dispatch = useDispatch();
  const active = useSelector(state => state.chips.selected);
  const activeModel = useSelector(state => state.chat.model.label);

  const handleSelectChip = chip => {
    dispatch.chips.setSelected(chip);
  };

  return (
    <ClickAwayListener onClickAway={() => handleSelectChip(null)}>
      <div style={{ paddingInline: '20px' }}>
        <div className='search__tags'>
          {activeModel === 'Picasso' ? (
            <>
              {Object.keys(picassoConfig).length > 0 &&
                Object.keys(picassoConfig).map(chip => (
                  <div
                    key={chip}
                    className={`search__tags-inner ${
                      active === chip && 'selected'
                    }`}
                    onClick={() => handleSelectChip(chip)}
                  >
                    <p>{chip}</p>
                  </div>
                ))}
            </>
          ) : (
            <>
              {Object.keys(suggestionChips).length > 0 &&
                Object.keys(suggestionChips).map(chip => (
                  <div
                    key={chip}
                    className={`search__tags-inner ${
                      active === chip && 'selected'
                    }`}
                    onClick={() => handleSelectChip(chip)}
                  >
                    <p>{chip}</p>
                  </div>
                ))}
            </>
          )}
        </div>
        <div
          className='search_tags'
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            paddingInline: '20px',
            marginBottom: '10px',
          }}
        >
          {active &&
            (activeModel === 'Picasso'
              ? picassoConfig[active]
              : suggestionChips[active]
            )?.map(question => (
              <div
                key={question}
                className='tags-questions'
                style={{ textAlign: 'left' }}
              >
                <div
                  className='question-box'
                  onClick={() => {
                    setPredict(question);
                    searchRef.current.focus();
                  }}
                >
                  {question}
                </div>
              </div>
            ))}
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default SearchTags;
