import React from 'react';

import DialogLayout from '../DialogLayout';
import styled from 'styled-components';
import assets from 'assets';
import CustomButton from 'container/components/CustomButton/CustomButton';

const ErrorModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <DialogLayout open={true}>
      <Content>
        <div className='title-row'>
          <p className='font-size-24'>Error</p>
          <div className='close-button' onClick={handleClose}>
            <img src={assets.closeIcon} alt='' />
          </div>
        </div>
        <p className='font-size-16' style={{ marginTop: '10px' }}>
          You have already used this coupon
        </p>
        <div className='button-container'>
          <CustomButton label='Continue' onClick={handleClose} />
        </div>
      </Content>
    </DialogLayout>
  );
};

export default ErrorModal;

const Content = styled.div`
  width: 420px;
  border-radius: 12px;
  padding: 25px;
  background-color: ${({ theme }) => theme.colors.darkgrey};

  & p {
    margin: 0px;
    /* text-align: left; */
    color: ${({ theme }) => theme.colors.primary};
  }

  & .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .button-container {
    margin-top: 25px;
  }
`;
