import assets from 'assets';

export const config = [
  {
    id: '22G1',
    label: 'Chat GPT 4o',
    image: assets.gpt4,
    value: 'gpt-4',
    desc: '(Newest)',
    about:
      'Advanced model with enhanced intelligence, perfect for longer conversations, complex tasks, creative writing, and improved problem-solving.',
  },
  {
    id: '2DA1',
    label: 'Chat GPT 3.5',
    image: assets.chatGpt,
    value: 'gpt-3.5-turbo',
    desc: '(Faster)',
    about:
      'A highly conversational go-to model for explanations, translations, and creative writing.',
  },
  
  // {
  //   id: 'DLLM',
  //   label: 'Document AI',
  //   image: assets.DLLM,
  //   value: '',
  //   desc: '',
  //   about: '',
  // },
  {
    id: '2A31',
    label: 'Stable Diffusion',
    image: assets.picasso,
    // value: 'picaso',
    value: 'picasso',
    desc: '(Text to image)',
    about: 'Transforms text descriptions into vivid, photorealistic images.',
    new: true,
  },
  {
    id: '2DK1',
    label: 'Ai21',
    image: assets.ai21,
    value: 'ai21',
    about:
      'Guru for natural language understanding, extracting information, summarizing longer articles, and boosting your content and copy ideas.',
  },
  {
    id: '2881',
    label: 'Cohere',
    image: assets.cohere,
    value: 'cohere',
    about:
      'A master for specific tasks and open-ended questions, providing precise answers or assistance in content generation, learning, or translation.',
  },
  {
    id: '2ZQ1',
    label: ' HuggingFace',
    image: assets.huggingFace,
    value: 'huggingface_hub',
    desc: '(Coming soon)',
    disabled: true,
    about:
      'Wizard for code completion, writing drafts, summarizing text, and more.',
  },
  // {
  //   label: 'Forefront',
  //   image: Image2,
  //   // value: 'forefront',
  //   value: 'gpt-3.5-turbo',
  // },
];
