import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useNotifications = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const fetchNotifications = () => {
    if (token) {
      dispatch.notifications.loadNotifications();
    }
  };

  useEffect(() => {
    const interval = setInterval(fetchNotifications, 1000 * 60 * 10);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
};

export default useNotifications;
