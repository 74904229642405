import { Navigate } from 'react-router-dom';

const UserRestriction = ({ comp: Comp }) => {
  const token = localStorage.getItem('token');
  // const path = useLocation().pathname;
  if (token) {
    return Comp;
  } else {
    const params = new URLSearchParams(window.location.search);
    return <Navigate to={`sign-in?${params.toString()}`} />;
  }
};

export default UserRestriction;
