import assets from 'assets'
import React, { useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import UserDropdown from './Components/UserDropdown/UserDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { modes } from 'app.config';
import { ClipLoader } from 'react-spinners'
import ClickAwayListener from 'react-click-away-listener'
import SaveDocument from 'container/Dialogs/SaveDocument/SaveDocument'
import { EditorContext } from 'container/context/editorContext'
import { saveDocument } from 'container/api'

const MiniSidebar = () => {

    const dispatch = useDispatch();
    const mode = useSelector(state => state.utils.mode);
    const topBanner = useSelector(state => state.utils.topBanner);
    const [shouldSave, setShouldSave] = useState(false);
    const [docLoading, setDocLoading] = useState(false);
    const selectedDoc = useSelector(state => state.documents.selected);
    const user = useSelector(state => state.auth.user);
    const editor = useContext(EditorContext);
    // const planData = useSelector(state => state.subscription.planData);

    const [open, setOpen] = useState(false);

    const { notifications, next, unreadCount, loading } = useSelector(
        state => state.notifications
    );

    const menuListRef = useRef(null);


    const handleLoadNotifications = () => {
        dispatch.notifications.loadMoreNotifications();
    };

    const handleMarkAllRead = () => {
        if (unreadCount === 0) {
            return;
        }
        dispatch.notifications.readNotification();
    };

    const handleClickAway = () => {
        setOpen(false)
    }

    const handleSaveDocument = async (title) => {
        try {
            setDocLoading(true);
            if (!selectedDoc && editor.getText().length) {
                const data = {
                    user: user.id,
                    title: title || 'Untitled',
                    body: editor.getHTML(),
                };

                await saveDocument(data);
                dispatch.outliner.reset();
                // dispatch.documents.setSelected(selectedconversation);
                editor.commands.setContent('');
                editor.chain().focus().run();
                dispatch.documents.getDocuments();
            } else {
                dispatch.documents.updateDocument({
                    body: editor.getHTML(),
                    showToast: false,
                });
            }
        } catch (err) {
            console.log(err.message);
        } finally {
            setShouldSave(false);
            setDocLoading(false);
        }
    };

    const handleNavigation = (mode) => {

        if (!selectedDoc && editor.getText().length > 0) {
            setShouldSave(true);
        } else {
            dispatch.utils.setMode(mode)
        }
    }


    return (
        <Container
            className={`${topBanner && 'banner-added '} flex position-relative flex-column justify-content-between align-content-center`}>

            {/* Top Menu  */}
            <div className='menu-list flex flex-column'>
                <div
                    onClick={() => handleNavigation(modes.chat)}
                    className={`${mode === modes.chat && 'active '}  menu flex flex-column align-items-center justify-content-center`}>
                    <img width={24} height={24} src={assets.chatIcon} alt='' />
                    <span>Chat</span>
                </div>

                <div
                    onClick={() => handleNavigation(modes.document)}
                    className={`${mode === modes.document && 'active '} menu flex flex-column align-items-center justify-content-center`}>
                    <img width={24} height={24} src={assets.docAiIcon} alt='' />
                    <span>Doc AI</span>
                </div>

                <div
                    onClick={() => handleNavigation(modes.image)}
                    className={`${mode === modes.image && 'active '} menu flex flex-column align-items-center justify-content-center`}>
                    <img width={24} height={24} src={assets.createIcon} alt='' />
                    <span>Create</span>
                </div>
            </div>

            {/* Bottom Menu */}
            <div className='menu-list flex flex-column'>
                {
                    // planData?.plan?.toLowerCase() === 'free' && (
                    //     <div
                    //         onClick={() => dispatch.utils.setGiftCard(true)}
                    //         className='menu flex flex-column align-items-center justify-content-center'>

                    //         <div className='new-gift'>
                    //             <img width={30} height={30} src={assets.sale} alt='' />
                    //         </div>

                    //     </div>
                    // )
                }
                
                <div
                    onClick={() => setOpen(true)}
                    className='menu flex flex-column align-items-center justify-content-center'>
                    <img width={40} height={40} src={assets.alertIcon} alt='' />
                    <span>Alerts</span>
                </div>
                {
                    open && <ClickAwayListener onClickAway={handleClickAway}>
                        <MenuList
                            ref={menuListRef}
                            style={{ display: !open ? 'none' : 'flex', maxWidth: '309px' }}
                        >
                            <div className='header'>
                                <p className='font-size-20'>Notifications</p>
                                <p
                                    className='font-size-12 success'
                                    role='button'
                                    onClick={handleMarkAllRead}
                                >
                                    Mark all as read
                                </p>
                            </div>
                            <div className='notifcation-list'>
                                {loading && (
                                    <div style={{ textAlign: 'center', marginTop: '100px' }}>
                                        <ClipLoader size={20} color='#ffffff' />
                                    </div>
                                )}
                                {!loading &&
                                    notifications.length > 0 &&
                                    notifications.map(notification => (
                                        <NotificationItem key={notification.id}>
                                            <div className='icon'>
                                                <img
                                                    src={
                                                        notification?.icon ? assets.tickGreen : assets.infoRed
                                                    }
                                                    alt=''
                                                />
                                            </div>
                                            <div className='content'>
                                                <p className='font-size-14'>{notification?.title}</p>
                                                <p className='font-size-10'>
                                                    {notification?.notification}
                                                </p>
                                            </div>
                                        </NotificationItem>
                                    ))}
                                {!loading && !notifications.length && (
                                    <p className='font-size-14'>No notifications found</p>
                                )}
                            </div>
                            {next && (
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <ViewAllButton
                                        role='button'
                                        className='font-size-12 success'
                                        onClick={handleLoadNotifications}
                                    >
                                        Load more <img src={assets.arrowRightGreen} alt='' />
                                    </ViewAllButton>
                                </div>
                            )}
                        </MenuList>
                    </ClickAwayListener>
                }


                <a
                    href='https://help.chatai.com/hc/en-us'
                    target='_blank'
                    rel='noreferrer'
                >
                    <div className='menu flex flex-column align-items-center justify-content-center'>
                        <img width={40} height={40} src={assets.questionIcon} alt='' />
                        <span>Help</span>
                    </div>
                </a>

                <div className='menu flex flex-column align-items-center justify-content-center'>
                    <UserDropdown />
                </div>
            </div>
            

            {shouldSave && (
                <SaveDocument
                    open={shouldSave}
                    setOpen={setShouldSave}
                    onSave={handleSaveDocument}
                    docLoading={docLoading}
                // onCancel={OverriteDocument}
                />
            )}
        </Container>
    )
}

export default MiniSidebar



const Container = styled.div`

    border-right: 1px solid ${({ theme }) => theme.colors.lightgrey2};
    height:  calc(100vh - 50px);
    overflow-y: auto;
    background-color: #35363A;
    /* width: calc(-260px + 360px); */
    width: 60px;
    overflow-x: hidden;

    & .new-gift{
        background-color: rgba(38, 40, 43, 1);
        width: 48px !important;
        height: 48px !important;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .menu-list {
        gap: 24px;
        & .menu {
            /* padding: 12px 0px; */
            gap: 4px;
            cursor: pointer;
            width: 100%;
            height: 70px;
            box-sizing: border-box;

            & span{
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                color: var(--primary);
            }
        }

        & .user-image{
            border-radius: 8px;
        }

        & .active {
            background-color: var(--red);
        }
    }

    /* @media (max-width: 1300px) {
        width: 100px;
    } */

    @media only screen and (max-width: 1000px) {
        display: none;
    }
`;


const MenuList = styled.div`
  padding: 12px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.lightBlack};
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translate(20%, -70%);
  /* transform: translate(0%, calc(100% + 20px)); */
  z-index: 100;
  color: ${({ theme }) => theme.colors.primary};
  height: 309px;
  display: flex;
  width: 600px;
  flex-direction: column;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .success {
    color: ${({ theme }) => theme.colors.green};
  }

  & .notifcation-list {
    height: 0px;
    flex-grow: 1;
    overflow: auto;
  }
`;

const ViewAllButton = styled.div`
  cursor: pointer;
`;

const NotificationItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  & .font-size-10 {
    opacity: 0.5;
  }
`;