import assets from "assets";

export const templates = {
  None: [
    {
      id: 0,
      prompt: '',
      example_image_url: assets.newUnSelected,
      list: 'default',
      priority: 0,
    }
  ],
  Art: [
    {
      id: 71,
      created_at: '2023-07-19T15:27:06.313956+00:00',
      prompt:
        'A steampunk cityscape full of intricate gears and machinery, airships flying in the sky, industrial revolution inspired, ultra-realistic, high detail',
      categories: 'Art,Wallpapers,Comics',
      example_image_url: assets.Art_01,
      list: 'default',
      priority: 0,
    },
    {
      id: 120,
      created_at: '2023-07-19T15:27:06.313956+00:00',
      prompt:
        `8k portrait influenced by Salvador Dalí's 'Surrealism,' featuring unexpected juxtapositions, melting objects, and a dreamlike atmosphere.`,
      categories: 'Wallpapers,Art',
      example_image_url: assets.Art_02 ,
      list: 'default',
      priority: 0,
    },
    {
      id: 140,
      created_at: '2023-07-19T15:27:06.313956+00:00',
      prompt:`8k portrait in the style of Henri Matisse's 'Fauvism,' with bold colors, simplified forms, and expressive brushwork.` ,
      categories: 'Characters,Art',
      example_image_url: assets.Art_03,
      list: 'default',
      priority: 0,
    },
    {
      id: 141,
      created_at: '2023-07-19T15:27:06.313956+00:00',
      prompt: `8k portrait in the style of Leonardo da Vinci's 'Renaissance,' with realistic proportions, sfumato technique, and classical composition.`,
      categories: 'Characters,Art',
      example_image_url: assets.Art_04,
      list: 'default',
      priority: 0,
    },
  ],
  Characters: [
    {
      id: 48,
      created_at: '2023-07-19T04:31:01.885863+00:00',
      prompt: '8k portrait of a model in a turquoise dress',
      categories: '["Characters","Stock Photos"]',
      example_image_url: assets.Character_01,
      list: 'default',
      priority: 5,
    },
    {
      id: 52,
      created_at: '2023-07-19T04:33:06.215451+00:00',
      prompt: `3d fluffy squirrel, closeup cute and adorable, cute big circular reflective eyes, long fuzzy fur, Pixar render, unreal engine cinematic smooth, intricate detail, cinematic`,
      categories: '["Characters","Stock Photos"]',
      example_image_url: assets.Character_02,
      list: 'default',
      priority: 5,
    },
    {
      id: 198,
      created_at: '2023-07-19T17:54:43.564979+00:00',
      prompt: `Glamorous portrait of {KEYWORD} as ultra-realistic barbie, wearing a beautiful headband, pink backdrop, elegant white evening gown with beadwork, styled hair with a headband, sparkling makeup, shot in 8k with an 85mm sony g master lens, capturing modern barbie-inspired elegance`,
      categories: 'Characters,3D',
      example_image_url: assets.Character_03,
      list: 'default',
      priority: 2,
    },
    {
      id: 201,
      created_at: '2023-07-19T17:53:55.680166+00:00',
      prompt: `8k portrait influenced by Salvador Dalí's 'Surrealism,' featuring unexpected juxtapositions, melting objects, and a dreamlike atmosphere.`,
      categories: 'Characters,3D',
      example_image_url: assets.Character_04,
      list: 'default',
      priority: 2,
    },
    {
      id: 202,
      created_at: '2023-07-19T17:53:55.680166+00:00',
      prompt: `8k portrait in the style of Henri Matisse's 'Fauvism,' with bold colors, simplified forms, and expressive brushwork.`,
      categories: 'Characters,3D',
      example_image_url: assets.Character_05,
      list: 'default',
      priority: 2,
    },
    {
      id: 200,
      created_at: '2023-07-19T17:53:55.680166+00:00',
      prompt: `8k portrait in the style of Leonardo da Vinci's 'Renaissance,' with realistic proportions, sfumato technique, and classical composition.`,
      categories: 'Characters,3D',
      example_image_url: assets.Character_06,
      list: 'default',
      priority: 2,
    },
  ],
  Icons: [
    {
      id: 77,
      created_at: '2023-07-19T15:27:06.313956+00:00',
      prompt:
        `A minimalist, flat design icon representing a cloud, with clean lines and neutral colors, ideally suited for a weather app, high resolution, detailed`,
      categories: 'Logos,Icons',
      example_image_url: assets.Icon,
      list: 'default',
      priority: 0,
    },
    {
      id: 79,
      created_at: '2023-07-19T15:27:06.313956+00:00',
      prompt: `A minimalist, flat design icon representing a man, with clean lines and neutral colors, ideally suited for a Users app, high resolution, detailed`,
      categories: 'Logos,Icons',
      example_image_url: assets.Icon_2 ,
      list: 'default',
      priority: 0,
    },
  ],
  // 'Stock photos': [
  //   {
  //     id: 32,
  //     created_at: '2023-07-19T04:15:02.167561+00:00',
  //     prompt:
  //       '8k portrait close up of face of beautiful woman fashion model in white feather clothes, official balmain editorial, dramatic lighting highly detailed, new york city in the background, ultra-realistic',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-1e80a8ea-c909-4066-86ad-b37973033496/generated_images/3.png',
  //     list: 'default',
  //     priority: 5,
  //   },
  //   {
  //     id: 48,
  //     created_at: '2023-07-19T04:31:01.885863+00:00',
  //     prompt: '8k portrait of a model in a turquoise dress',
  //     categories: '["Characters","Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-745600b0-fe35-46ec-a127-9ea7b0b99eda/generated_images/0.png',
  //     list: 'default',
  //     priority: 5,
  //   },
  //   {
  //     id: 87,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a captivating shot of wildlife in their natural habitat, showcasing the beauty and diversity of nature, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-083f1daf-a6a8-4e7a-b165-ebc06b6c0b73/generated_images/2.png',
  //     list: 'default',
  //     priority: 5,
  //   },
  //   {
  //     id: 31,
  //     created_at: '2023-07-19T04:14:12.276377+00:00',
  //     prompt:
  //       '8k portrait up-close and personal view of a roaring lion in the African savannah, sun setting in the background casting a golden light on the scene, high resolution, ultra-realistic',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-fce41119-5fb3-43c8-a892-169b2ff259b8/generated_images/3.png',
  //     list: 'default',
  //     priority: 5,
  //   },
  //   {
  //     id: 52,
  //     created_at: '2023-07-19T04:33:06.215451+00:00',
  //     prompt:
  //       '8k portrait of an enchanting, highly detailed image of a fairy in a magical forest, surrounded by sparkling lights and vibrant flora, captured in soft, dreamy lighting',
  //     categories: '["Characters","Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-8bf0de2c-673a-46e0-8b20-bbd471c71d62/generated_images/3.png',
  //     list: 'default',
  //     priority: 5,
  //   },
  //   {
  //     id: 128,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a stylish and fashionable handbag, featuring premium materials, intricate details, and a trendy design.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-e268416b-1b04-4f9e-9015-4dee9344e3b3/generated_images/1.png',
  //     list: 'default',
  //     priority: 4,
  //   },
  //   {
  //     id: 45,
  //     created_at: '2023-07-19T04:27:10.817986+00:00',
  //     prompt:
  //       '8k linkedin professional profile photo of a black man in a suit with studio lighting, bokeh, corporate portrait headshot photograph best corporate photography photo winner, meticulous detail, hyperrealistic, centered uncropped symmetrical beautiful',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-8c1a0269-e86e-46d6-8c31-6989e7959eac/generated_images/2.png',
  //     list: 'default',
  //     priority: 4,
  //   },
  //   {
  //     id: 126,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a delicious and tempting cupcake, with fluffy cake, creamy frosting, and colorful sprinkles on top.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-cc65ef84-8439-4f87-b1f7-aed9f21de240/generated_images/3.png',
  //     list: 'default',
  //     priority: 4,
  //   },
  //   {
  //     id: 37,
  //     created_at: '2023-07-19T04:19:05.950118+00:00',
  //     prompt:
  //       'A sleek, modern kitchen design featuring stainless steel appliances, marble countertops, and minimalist cabinets, photorealistic, high detail, inspired by Architectural Digest',
  //     categories: '["Stock Photos","Real Estate"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-084c4726-a6b0-4188-a1ef-675cda787fc8/generated_images/0.png',
  //     list: 'default',
  //     priority: 4,
  //   },
  //   {
  //     id: 221,
  //     created_at: '2023-08-18T10:47:38.254067+00:00',
  //     prompt:
  //       'Glamorous portrait of {KEYWORD} as ultra-realistic barbie, wearing a beautiful headband, pink backdrop, elegant white evening gown with beadwork, styled hair with a headband, sparkling makeup, shot in 8k with an 85mm sony g master lens, capturing modern barbie-inspired elegance',
  //     categories: 'Stock Photos, Characters',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-7ba64bdd-c98f-43e9-9542-066055b3e9ce/generated_images/2.png',
  //     list: 'gender_neutral',
  //     priority: 1,
  //   },
  //   {
  //     id: 220,
  //     created_at: '2023-08-18T10:01:54.668273+00:00',
  //     prompt:
  //       '8k high-fashion shot of {KEYWORD} as Ken from Barbie on a beach, blonde hair, wearing pink, vibrant backdrop of Malibu beach, styled in a chic and tailored pastel suit without a tie, iconic windswept hair, flawless dramatic lighting with soft shadows, captured with a 100mm Leica Summilux f/1.4 lens, showcasing intricate details and capturing the essence of modern masculinity and style',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-60707ed3-eb51-4236-841b-572679938c47/generated_images/1.png',
  //     list: 'gender_neutral',
  //     priority: 1,
  //   },
  //   {
  //     id: 219,
  //     created_at: '2023-08-03T00:58:16.192744+00:00',
  //     prompt:
  //       '8k close up linkedin profile picture of {KEYWORD}, professional jack suite, professional headshots, photo-realistic, 4k, high-resolution image, workplace settings, upper body, modern outfit, professional suit, businessman, blurred background, glass building, office window',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-8cb0be6b-bdba-440b-9408-2351421b1b2b/generated_images/1.png',
  //     list: 'gender_neutral',
  //     priority: 1,
  //   },
  //   {
  //     id: 218,
  //     created_at: '2023-08-03T00:26:18.161678+00:00',
  //     prompt:
  //       '8k portrait of {KEYWORD} businesswoman cinematic medium shot, shallow depth of field, studio shoot, professional headshot, professional suit, black background, professional retouched skin',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-e7282e23-89da-430d-9ced-2683daa98ac5/generated_images/2.png',
  //     list: 'female',
  //     priority: 1,
  //   },
  //   {
  //     id: 72,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       'A breathtaking, high resolution image of the Milky Way galaxy stretching across a clear night sky, captured with intricate detail',
  //     categories: 'Stock Photos,Wallpapers',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-09c79906-02fd-4e57-bcf1-f28b8f58d932/generated_images/2.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 74,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       "An underwater scene featuring a colorful coral reef and a variety of tropical fish, sun rays penetrating the water's surface, photorealistic, high detail",
  //     categories: 'Wallpapers,Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-2fb616c5-852b-406d-a72b-3e102c64646e/generated_images/2.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 76,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       "8k cinematic photo of a realistic, highly detailed image of a space station orbiting Earth, Earth's horizon and the vastness of space in the background",
  //     categories: 'Stock Photos,Wallpapers',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-8859eff0-09b5-42c8-9447-e778c8a60df1/generated_images/3.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 83,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a majestic mountain landscape with snow-capped peaks, lush valleys, and a clear blue sky, invoking a sense of grandeur and serenity, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-ea1bab2a-478d-4693-ad44-30a54f1fe5ad/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 84,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a bustling city street filled with pedestrians, vibrant storefronts, and towering buildings, capturing the energy and diversity of urban life, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-b0c6044e-d891-4739-92f7-7dd1f5d04135/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 85,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of an appetizing food platter showcasing a variety of dishes, including savory appetizers, mouthwatering entrees, and delectable desserts, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-4f557480-3914-4b80-b2f9-af935cd11697/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 86,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k close up portrait of a fitness enthusiast engaged in a workout routine, demonstrating strength, agility, and determination, inspiring a healthy and active lifestyle, high resolution, highly detailed',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-eac87305-e56f-41e3-8abc-34cf9e940a78/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 88,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of architectural marvels featuring stunning buildings and structures from different time periods and architectural styles, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-f340ae6a-1fe1-4fbc-8d5e-25460136caf6/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 89,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a vibrant autumn forest with colorful foliage, creating a picturesque scene of natural beauty, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-c46a8d10-c8c9-45e0-aaea-141981dabd92/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 91,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a cup of hot coffee surrounded by coffee beans, evoking a cozy and comforting atmosphere, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-021b7927-fb66-4d23-9cb6-883b7adcf0e0/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 92,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a beautiful sunrise over a misty mountain range, with the golden rays of sunlight peeking through the clouds, creating a sense of awe and inspiration, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-309885b6-e5e6-4d0d-a120-efc7f88f50bf/generated_images/3.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 94,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k close-up portrait of a woman using a virtual reality headset, immersed in a virtual world, exploring the possibilities of technology and innovation, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-54af1296-9d2e-4ab9-af76-b835e26f3fe0/generated_images/2.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 95,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a serene lakeside landscape with calm waters reflecting the surrounding trees and mountains, creating a peaceful and tranquil atmosphere, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-25bd250d-b99d-4c0c-b2ed-1a3fe98fa2d9/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 96,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a colorful hot air balloon festival, with dozens of balloons taking flight against a clear blue sky, creating a vibrant and festive scene, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-c7606415-7125-4a8d-a6a5-3fceab743770/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 97,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a modern smart home with automated devices and appliances, showcasing the convenience and efficiency of home automation, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-ac8aec75-ed59-49f0-963f-c0fd39e0c3ab/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 98,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a captivating starry night sky over a peaceful countryside, with silhouettes of trees and a faint glow from distant villages, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-7dfbf4a2-e371-4dc1-b7e6-3117486807d9/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 99,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a stylish and organized home office setup with a comfortable workspace, modern furniture, and natural lighting, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-96f7227f-9de5-48e5-abe9-a4c735d0225b/generated_images/2.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 100,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a busy office with employees working at their desks, discussing ideas in meeting rooms, and interacting in common areas, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-e9eebee7-a073-4122-a3a6-728504c31da2/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 101,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of an innovative technology concept, showcasing futuristic devices and cutting-edge advancements, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-e61ba1f6-521f-4b55-b3bd-d9529a0830a7/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 102,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a joyful family playing board games together at home, creating fun and memorable moments, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-cde2f01a-a564-476f-9931-8b000935544f/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 103,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a dense and mysterious forest with sunlight streaming through the trees, creating a magical atmosphere, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-33f267f5-3e24-4847-a23c-a47ec89f74f3/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 104,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a tranquil garden pond, with water lilies, koi fish, and lush vegetation, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-aaae7b82-f568-453c-b544-35fcdee027af/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 105,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       "A close-up of a beagle's face, with its ears perked up and its eyes looking inquisitively. The beagle's fur is groomed and its nose is slightly wrinkled, as if it is sniffing the air. The background is blurred, with a hint of a park setting. Realistic, Close-up, Dog Park.",
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-373c751f-b323-4cc7-be92-1a66561562cd/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 106,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       "In this ultra-realistic stock photo, a close-up shot showcases the cutting-edge features of AMD EPYC processors. The image depicts a sleek, high-tech motherboard with the EPYC processor at its center, surrounded by intricate circuitry. The processor is highlighted with vibrant LED lights, emphasizing its power and performance. The image captures the processor's advanced architecture, featuring multiple cores and threads for enhanced multitasking capabilities. The attention to detail is impeccable, with every component and connection meticulously crafted to ensure accuracy. The image sparks curiosity.",
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-081298e7-f58c-4518-8ab3-f31b177f588e/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 123,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a vintage film camera, showcasing its retro design, mechanical dials, and the nostalgia of analog photography.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-dc20bbe7-b76c-4c77-9d3e-2a788dc95ac7/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 124,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a stylish and trendy wristwatch, featuring a sleek design, intricate details, and precise timekeeping.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-6b043633-6e98-496c-9675-372ee58fbabc/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 127,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a refreshing glass of lemonade, with sliced lemons, ice cubes, and a garnish of mint leaves.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-2bb42bca-0d35-443c-a28e-0ba871a5d68d/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 129,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a vintage record player, with a vinyl record spinning, capturing the nostalgia and warmth of analog music.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-e5f4a2c2-7f13-4938-a2d3-ae72d0933eb8/generated_images/3.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 29,
  //     created_at: '2023-07-19T04:09:15.30899+00:00',
  //     prompt:
  //       '8k portrait close up face of bedraggled 40 year old man dark blond hair blue eyes, dirty with mud on his face, a little bloody, just finished a long trail run on the AT, dramatic lighting highly detailed, with shenandoah woods in the background',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-31cfbeb5-771f-4df5-bec0-6be59051d6d0/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 189,
  //     created_at: '2023-07-19T17:28:59.286179+00:00',
  //     prompt:
  //       '8k portrait of close-up high-resolution image of a fierce Viking warrior in battle gear, icy Nordic landscape in the background, captured in dramatic lighting with highly detailed composition',
  //     categories: 'Stock Photos,Characters',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-f793ef02-bd92-431d-a27f-6b73c5777383/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 90,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a busy office workspace with employees collaborating, working on computers, and holding meetings, representing productivity and teamwork, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-8ac45d60-7bd6-4e58-af46-b10cde18d9ad/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 200,
  //     created_at: '2023-07-19T18:06:00.629025+00:00',
  //     prompt:
  //       '8k close-up portrait of a professional basketball player in action, dribbling the ball with intense focus and determination, high resolution, highly detailed.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-cfaee771-b7cf-4e4e-9325-f2d0584dee18/generated_images/2.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 206,
  //     created_at: '2023-07-21T23:14:23.068191+00:00',
  //     prompt:
  //       'realistic half-bust portrait photography of a beautiful {KEYWORD} face illuminated by neon lights, maniac tv series style, cyberpunk background, perfectly drawn eyes, perfectly drawn circles, high resolution, ultra realistic, sharp features, white balance, 32k, super-resolution, sharp focus, soft smooth lighting',
  //     categories: 'Characters, Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-92d1e2d7-521b-4a2c-9187-a318b2dfa261/generated_images/3.png',
  //     list: 'gender_neutral',
  //     priority: 0,
  //   },
  //   {
  //     id: 212,
  //     created_at: '2023-07-21T23:37:06.660284+00:00',
  //     prompt:
  //       "80's portrait of {KEYWORD}, blue background, white pearl neckless, fashion model in oversized white clothes, official balmain editorial, dramatic lighting highly detailed, analog photo, overglaze, 80mm Sigma f/1.4 or any ZEISS lens",
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-e44d9def-523a-4e65-8645-871e2f22da40/generated_images/1.png',
  //     list: 'gender_neutral',
  //     priority: 0,
  //   },
  //   {
  //     id: 125,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       '8k portrait of a classic red wine bottle, with a label showcasing its origin, vintage, and the rich color of the wine.',
  //     categories: 'Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-47a950e6-38dc-4989-82ff-37ca3d656ba4/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 33,
  //     created_at: '2023-07-19T04:16:03.307498+00:00',
  //     prompt:
  //       '8k portrait close-up of a cute and playful Golden Retriever puppy in a green garden, playing with a red ball, sunny day, photorealistic, high detail',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-8defa7ef-8214-4417-a3ad-8bdfd34e7ff7/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 34,
  //     created_at: '2023-07-19T04:16:47.001933+00:00',
  //     prompt:
  //       '8k portrait of an enchanting, well-groomed Persian cat with luxurious white fur and captivating emerald eyes, lounging on an elegant silk cushion in a grandiose living room setting, photorealistic and rich in detail',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-f522cbea-0e12-42bb-a1ce-bacf69f023d1/generated_images/2.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 36,
  //     created_at: '2023-07-19T04:18:14.596601+00:00',
  //     prompt:
  //       'Highly detailed, photorealistic image of a latest luxury sports car with a sleek and shiny black finish, dramatic lighting, positioned in a spacious warehouse, rendered in 4K',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-bf35b2e6-c28c-476c-b651-69baed69f3bf/generated_images/2.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 38,
  //     created_at: '2023-07-19T04:19:46.363124+00:00',
  //     prompt:
  //       'A modern, minimalist office space featuring clean lines, a sleek desk, ergonomic chair, and panoramic windows with a cityscape view, photorealistic, high detail',
  //     categories: '["Stock Photos","Real Estate"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-1e918f40-1614-4af6-8a4e-81592f0c9551/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 39,
  //     created_at: '2023-07-19T04:20:37.017018+00:00',
  //     prompt:
  //       'A vibrant, detailed image of a traditional Indian market (bazaar) with various stalls selling spices, textiles, and handicrafts, people bustling about, high resolution',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-89b1eac2-dfe4-46dd-bd23-718a67458348/generated_images/1.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 40,
  //     created_at: '2023-07-19T04:22:20.298159+00:00',
  //     prompt:
  //       'Delicious, photorealistic gourmet hamburger with fresh lettuce, ripe tomato, juicy patty, and creamy cheese, served on a rustic wooden table, high resolution, highly detailed',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-d0fe28e0-cdd0-46b8-b576-60ea38744a72/generated_images/0.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 41,
  //     created_at: '2023-07-19T04:23:25.09825+00:00',
  //     prompt:
  //       'A luscious, detailed image of a traditional Italian pizza Margherita with melted mozzarella, fresh basil, and ripe tomatoes, served on a rustic wooden table, high resolution',
  //     categories: '["Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-da709827-2705-4785-9718-a92ce956bf1a/generated_images/3.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 44,
  //     created_at: '2023-07-19T04:26:25.956685+00:00',
  //     prompt:
  //       '8k portrait of a classic scene from a spaghetti western movie, featuring a gunslinger in a dusty desert town, high noon, sun directly overhead, photorealistic, highly detailed',
  //     categories: '["Characters","Stock Photos"]',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-ffac2b1d-a011-4eee-9b3a-643dc18f6ff8/generated_images/3.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  //   {
  //     id: 70,
  //     created_at: '2023-07-19T15:27:06.313956+00:00',
  //     prompt:
  //       'A rustic cabin nestled in a snow-covered landscape, with smoke billowing from the chimney and soft, warm light from the windows, high resolution, highly detailed',
  //     categories: 'Wallpapers,Real Estate,Stock Photos',
  //     example_image_url:
  //       'https://static.tryleap.ai/image-gen-f9ef0eaa-1b2a-4c57-831b-71d1eac63b96/generated_images/2.png',
  //     list: 'default',
  //     priority: 0,
  //   },
  // ],
  Wallpapers: [
    {
      id: 131,
      created_at: '2023-07-19T15:27:06.313956+00:00',
      prompt: `8k portrait of a charming cottage in the countryside, surrounded by blooming flowers and lush green fields.`,
      categories: 'Wallpapers',
      example_image_url: assets.Wallpaper_01,
      list: 'default',
      priority: 3,
    },
    {
      id: 130,
      created_at: '2023-07-19T15:27:06.313956+00:00',
      prompt:
        'A steampunk cityscape full of intricate gears and machinery, airships flying in the sky, industrial revolution inspired, ultra-realistic, high detail',
      categories: 'Wallpapers',
      example_image_url: assets.Wallpaper_02 ,
      list: 'default',
      priority: 3,
    },
  ],
};
