import React from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { useGoogleLogin } from '@react-oauth/google';

import assets from 'assets';

const GoogleLogin = ({ isSignIn, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(state => state.auth.loading);
  const login = useGoogleLogin({
    onSuccess: res => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${res.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${res.access_token}`,
              Accept: 'application/json',
            },
          }
        )
        .then(data => {
          if (isSignIn) {
            dispatch.auth.handleSocialLogin({
              data: {
                is_google_auth: true,
                email: data.data.email,
                google_auth_token: res.access_token,
                first_name: data.data.given_name,
                last_name: data.data.family_name,
                profile_image: data.data.picture,
              },
              navigate,
            });
          } else {
            dispatch.auth.handleGoogleSignUp({
              data: {
                first_name: data.data.given_name,
                last_name: data.data.family_name,
                email: data.data.email,
                is_google_auth: true,
                profile_image: data.data.picture,
              },
              navigate,
            });
          }
        })
        .catch(err => console.log(err));
    },
    onError: err => {
      console.log(err);
    },
  });

  return (
    <Button onClick={login} disabled={loading} {...rest}>
      <img src={assets.googleIcon} width='30px' alt='' />
      <p className='font-size-16'>Sign {isSignIn ? 'in' : 'up'} with google</p>
    </Button>
  );
};

export default GoogleLogin;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  color: #000000;
  gap: 10px;
  height: 50px;
  border-radius: 7px;
  border: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  & p {
    margin: 0px;
  }

   @media only screen and (max-width: 1000px) {
      min-width: 100% !important;
      max-width: 100% !important;
  }
`;
