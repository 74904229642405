import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DialogLayout from '../DialogLayout';
import { PDFDownloadLink } from '@react-pdf/renderer';

import ImportImg from 'assets/images/import.png';
import SuccessModel from 'assets/images/success-icon.png';
import Border from 'assets/images/line.png';
import { useNavigate } from 'react-router-dom';
import { getPlan, getUserDetails, upgradePlan } from 'container/api';
import StripeReciept from 'container/components/StripeReciept/StripeReciept';

dayjs.extend(utc);

const StripeSuccess = ({ open, setOpen, userId }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/');
    setOpen(false);
  };
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);

  const [planUpgraded, setPlanUpgraded] = useState(false);
  const [planData, setPlanData] = useState(null);
  const [, setLoading] = useState(false);

  const getUser = async () => {
    try {
      setLoading(true);
      const { data } = await getUserDetails();
      const { data: planDetails } = await getPlan();
      setPlanData(planDetails.data);
      console.log(planDetails.data);
      dispatch.auth.setUser(data.user);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpgradePlan = async () => {
    try {
      await upgradePlan();
      setPlanUpgraded(true);
      getUser();
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (userId && user && !planUpgraded) {
      if (+userId === +user.id) {
        handleUpgradePlan();
      }
    }
    // eslint-disable-next-line
  }, [userId, user]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getUser();
    // eslint-disable-next-line
  }, []);
  return (
    <DialogLayout open={open}>
      <ModalContent>
        <button className='btn'>
          <svg
            width='8'
            height='8'
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleClose}
            // onClick={() => setShowModel(!showModel)}
          >
            <g opacity='0.5'>
              <path
                d='M7.08762 0.912376L1 6.99999M7.08762 7L1.00001 0.912376'
                stroke='white'
                strokeLinecap='round'
              />
            </g>
          </svg>
        </button>
        <SuccessImg src={SuccessModel} alt='Success' />
        <h1 className='model-heading'>Payment successful</h1>
        <p className='model-text'>
          We've processed your <span>${planData?.amount} charge.</span>
        </p>

        <TableBox>
          {/* <TableGrid>
            <h1 className='head'>Ref Number</h1>
            <h1 className='content'>000085752257</h1>
          </TableGrid> */}

          <TableGrid>
            <h1 className='head'>Payment Time</h1>
            <h1 className='content'>
              {dayjs(planData?.start_date).format('DD-MM-YYYY, HH:mm:ss')}
            </h1>
          </TableGrid>

          <TableGrid>
            <h1 className='head'>Payment Method</h1>
            <h1 className='content'>Credit card</h1>
          </TableGrid>

          <TableGrid>
            <h1 className='head'>Customer Name</h1>
            <h1 className='content'>{user?.first_name || user?.username}</h1>
          </TableGrid>

          <BorderImage src={Border} alt='Border Image' />

          <TableGrid className='mb-4'>
            <h1 className='head'>Amount</h1>
            <h1 className='content'>USD {planData?.amount}</h1>
          </TableGrid>
          {/* {!loading && planData && ( */}
          <PDFDownloadLink
            document={
              <StripeReciept
                amount={planData?.amount}
                name={user?.username}
                date={dayjs(planData?.start_date).format(
                  'DD-MM-YYYY, HH:mm:ss'
                )}
              />
            }
            fileName='ChatAi-bill.pdf'
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                'Loading document...'
              ) : (
                <Button>
                  <img src={ImportImg} style={{ zIndex: 110 }} alt='Import' />
                  Get PDF Receipt
                </Button>
              )
            }
          </PDFDownloadLink>
          {/* )} */}
        </TableBox>
      </ModalContent>
    </DialogLayout>
  );
};

export default React.memo(StripeSuccess);

const ModalContent = styled.div`
  background-color: #35363a;
  padding: 30px 25px 25px 15px;
  border-radius: 15px;
  position: relative;
  & .btn {
    position: absolute;
    right: 5px;
    top: 5px;

    & svg {
      width: 15px;
      height: 15px;
    }
  }
  & .model-heading {
    padding-top: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: var(--primary);
    text-align: center;
  }
  & .model-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary);
    text-align: center;
    & span {
      color: #03d192;
    }
  }
`;
const TableBox = styled.div`
  margin-top: 50px;
`;

const TableGrid = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  & .head {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: var(--primary);
    opacity: 0.72;
    margin-bottom: 0;
  }
  & .content {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: var(--primary);
    margin-bottom: 0;
  }
`;
const BorderImage = styled.img`
  width: 100%;
`;
const SuccessImg = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
const Button = styled.button`
  background-color: var(--darkbg);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary);
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  & img {
    margin-right: 10px;
  }
`;
