import React, { useState, useContext } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { useSelector } from 'react-redux';
import html2pdf from 'html2pdf.js';

import { EditorContext } from 'container/context/editorContext';

import CustomButton from '../CustomButton/CustomButton';
import assets from 'assets';
import { convertBase64StringFromURl, convertTODocx } from 'container/api';
import { Tooltip } from 'react-tooltip';

const Download = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const editor = useContext(EditorContext);
  const { selected } = useSelector(state => state.documents);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const getPdf = async () => {
    try {
      if (loading) {
        return null;
      }
      setLoading(true);
      const editorContent = editor?.getHTML();

      const parser = new DOMParser();

      const doc = parser.parseFromString(editorContent, 'text/html');
      const images = doc.querySelectorAll('img');
      const urls = [];
      const myObjs = [];
      images.forEach(image => {
        urls.push(image.src);
      });

      for (let i = 0; i < urls.length; i++) {
        const { data } = await convertBase64StringFromURl(urls[i]);
        const imgSrc = 'data:image/jpeg;base64,' + data.data;
        myObjs.push({ [urls[i]]: imgSrc });
      }

      let newContent = editorContent;
      myObjs.forEach(obj => {
        newContent = newContent.replace(
          Object.keys(obj)[0],
          Object.values(obj)[0]
        );
      });

      if (newContent) {
        const pdfOptions = {
          margin: 10,
          filename: `${selected?.title || 'Untitled document'}.pdf`,
          image: { type: 'png', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf(newContent, pdfOptions);
      }
      handleCloseMenu();
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getWordDocument = async () => {
    try {
      const editorContent = editor?.getText();
      setLoading(true);
      const { data } = await convertTODocx(editorContent);
      const link = document.createElement('a');
      link.href = data?.word_file_url;
      link.download = `${selected?.title}.docx`;
      link.click();
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getHTML = () => {
    const editorContent = editor?.getHTML();
    const blob = new Blob([editorContent], { type: 'text/html' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${selected?.title}.html`;
    link.click();
  };
  return (
    <MenuContainer id='download'>
      <Tooltip anchorSelect='#download' content='Download' />
      <CustomButton
        variant='btn-outlined'
        label={''}
        rightIcon={assets.documentDownload}
        onClick={toggleMenu}
        className={'btn'}
        style={{ width: '30px', height: '30px', background: 'transparent' }}
      />
      {open && (
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Menu>
            <div
              className='menu-item flex-row'
              role='button'
              onClick={getPdf}
              style={{ cursor: loading ? 'not-allowed' : '' }}
            >
              <img src={assets.pdf} alt='' />
              <p className='font-size-14'>{loading ? 'Loading...' : 'PDF'}</p>
            </div>
            <div
              className='menu-item flex-row'
              role='button'
              onClick={getWordDocument}
              style={{ cursor: loading ? 'not-allowed' : '' }}
            >
              <img src={assets.word} alt='' />
              <p className='font-size-14'>{loading ? 'Loading...' : 'Word'}</p>
            </div>
            <div className='menu-item flex-row' onClick={getHTML} role='button'>
              <img src={assets.html} alt='' />
              <p className='font-size-14'>HTML</p>
            </div>
          </Menu>
        </ClickAwayListener>
      )}
    </MenuContainer>
  );
};

export default Download;

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;

  & .btn {
    height: unset;
    color: #000000;
    background-color: #f3f4f4;
    cursor: pointer;
    padding: 9px;
    &:hover {
      background-color: #f3f4f4;
    }

    & img {
      filter: invert(1);
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  width: 180px;
  background-color: ${({ theme }) => theme.colors.darkgrey};
  bottom: 0px;
  right: 0px;
  transform: translateY(calc(100% + 8px));
  border-radius: 8px;
  padding: 3px 16px;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.border};
  z-index: 100;
  & p {
    margin: 0px;
  }

  & .menu-item {
    gap: 10px;
    padding: 10px 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};

    &:last-of-type {
      border: none;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  @media only screen and (max-width: 1000px) {
    right: 0px;
    width: 120px;
  }
`;
