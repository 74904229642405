import React from 'react';

import DialogLayout from '../DialogLayout';
import styled from 'styled-components';

import FailureImg from 'assets/images/failure-img.png';
import { useNavigate } from 'react-router-dom';

const StripeFailure = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    navigate();
  };
  return (
    <DialogLayout open={open}>
      <FailureContent>
        <img src={FailureImg} alt='Failure' />
        <h1 className='heading'>Payment Declined</h1>
        <p className='text'>The credit card information was declined.</p>
        <ButtonContainer>
          <HomeButton onClick={handleClose}>Homepage</HomeButton>
          <NewButton onClick={handleClose}>+ New payment</NewButton>
        </ButtonContainer>
      </FailureContent>
    </DialogLayout>
  );
};

export default StripeFailure;

const FailureContent = styled.div`
  background-color: #35363a;
  padding: 40px 20px 20px 20px;
  border-radius: 15px;
  & img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  & .heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: var(--primary);
    padding-top: 24px;
    text-align: center;
    margin-bottom: 0;
  }
  & .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary);
    padding-top: 6px;
    text-align: center;
    margin-bottom: 0;
  }
`;

const HomeButton = styled.button`
  height: 44px;
  width: 144px;
  background: var(--darkgrey);
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 7px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary);
`;
const NewButton = styled.button`
  height: 44px;
  width: 144px;
  background: var(--darkbg);
  border: none;
  border-radius: 7px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  margin-top: 25px;
`;
