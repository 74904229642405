import React, { useMemo, useState, useContext } from 'react';

import DialogLayout from '../DialogLayout';

import { useDropzone } from 'react-dropzone';
import { EditorContext } from 'container/context/editorContext';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import assets from 'assets';
import CustomButton from 'container/components/CustomButton/CustomButton';
import { convertDocToHtml, saveDocument } from 'container/api';
import SaveDocument from '../SaveDocument/SaveDocument';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const FileUpload = ({ open, setOpen }) => {
  const editor = useContext(EditorContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [responseData, setResponseData] = useState(false);
  const [filename, setFilename] = useState('');
  const [docLoading, setDocLoading] = useState(false);
  const [shouldSaveDoc, setShouldSaveDoc] = useState(false);
  const selectedDoc = useSelector(state => state.documents.selected);
  const user = useSelector(state => state.auth.user);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx', '.doc'],
        'application/pdf': ['.pdf'],
      },
      onDrop: async acceptedFiles => {
        const file = acceptedFiles[0];
        console.log(file);
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append('word_file', file);
          setFilename(file.name);

          const { data } = await convertDocToHtml(formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setPercentage(percentCompleted);
            },
          });
          setResponseData(data);
          setCompleted(true);
        } catch (err) {
          console.log(err.message);
        } finally {
          setLoading(false);
        }
      },
    });

  const handleSaveDocument = async title => {
    try {
      setDocLoading(true);
      if (!selectedDoc && editor.getText().length) {
        const data = {
          user: user.id,
          title: title || 'Untitled Document',
          body: editor.getHTML(),
        };

        await saveDocument(data);
        dispatch.outliner.reset();
        dispatch.documents.setSelected(null);
        editor.commands.setContent('');
        editor.chain().focus().run();
        dispatch.documents.getDocuments();
      } else {
        dispatch.documents.updateDocument({
          body: editor.getHTML(),
          showToast: false,
        });
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setShouldSaveDoc(false);
      setDocLoading(false);
      handleOpenDoc();
      setOpen(false);
    }
  };

  const handleOpenDoc = () => {
    let content = responseData.html_content.split('<body>');
    content = content[1].split('</body>');
    editor.commands.setContent(content[0]);
    setOpen(false);
  };

  const handleClick = () => {
    if (!selectedDoc && editor.getText().length > 0) {
      setShouldSaveDoc(true);
    } else {
      dispatch.documents.updateDocument({
        body: editor.getHTML(),
        showToast: false,
      });
      setShouldSaveDoc(false);
      dispatch.documents.setSelected(null);
      dispatch.chat.setMobileScreen('chat')
      handleOpenDoc();
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleOpenDoc();
  };

  // dispatch.outliner.reset();
  // dispatch.documents.setSelected(null);
  // editor.commands.setContent('');
  // editor.chain().focus().run();
  // setShouldSaveDoc(false);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <DialogLayout open={open}>
      <Container style={{ position: 'relative' }}>
        <div className='content align-items-center gap-10 '>
          <span className='scratch-text'>Start from Scratch</span>
          <img src={assets.emptyState} alt='' width={100} height={100} />

          <CustomButton
            label='Create Document'
            leftIcon={assets.plusIcon}
            leftIconStyles={{ width: '20px', height: '20px' }}
            className={'create-btn'}
            onClick={() => {

              dispatch.documents.setSelected(null)
              editor.commands.setContent("");
              dispatch.chat.setMobileScreen('chat')
              setOpen(false)
            }}
          />
        </div>

        <Divider />

        {!loading && !completed && (
          <div

            {...getRootProps({ style })} className='content gap-10'>
            <>
              <input style={{ border: 'none !important' }} {...getInputProps()} disabled={loading || completed} />
              <div className='icon'>
                <img src={assets.cloud} alt='' />
              </div>
              <p className='font-size-14 text'>
                <span style={{ color: 'var(--blue-dark)' }}>Click to upload </span>
                <span>
                  or drag and drop <br />
                </span>
                <span className='text-faint'>
                  DOCX, PDF, DOC or HTML (max. 20mb)
                </span>
              </p>
              <Ortext>
                <span>OR</span>
              </Ortext>
              <CustomButton label='Browse Files' className={'btn-blue'} />
            </>

            <img
              src={assets.closeIcon}
              role='button'
              className='close'
              alt=''
              onClick={e => {
                e.stopPropagation();
                setOpen(false);
              }}
            />
          </div>
        )}
        {loading && (
          <Uploading>
            <img src={assets.word} style={{ width: '44px' }} alt='' />
            <Progress>
              <p className='font-size-16 percentage'>{percentage}%</p>
              <div className='progress'>
                <div
                  className='filler'
                  style={{ width: `${percentage}%` }}
                ></div>
              </div>
            </Progress>
            <p className='font-size-14 bold title'>Uploading Document...</p>
            <p className='font-size-12'>untitled.docx</p>
          </Uploading>
        )}
        {completed && (
          <Completed>
            <div className='icon-container'>
              <img src={assets.checkCircle} alt='' />
            </div>
            <div style={{ marginBlock: '16px 20px', textAlign: 'center' }}>
              <p className='font-size-14 bold title'>Uploading Document...</p>
              <p className='font-size-12'>{filename}</p>
            </div>
            <CustomButton label='Open Document' onClick={handleClick} />
          </Completed>
        )}
        {shouldSaveDoc && (
          <SaveDocument
            open={shouldSaveDoc}
            setOpen={setShouldSaveDoc}
            onCancel={handleClose}
            onSave={handleSaveDocument}
            docLoading={docLoading}
          />
          // <ConfirmationModel
          //   open={shouldSaveDoc}
          //   setOpen={handleClose}
          //   action={handleSaveDocument}
          //   descripiton="If you don't save your document your changes will be lost."
          //   title='Save document'
          //   btnText='Save'
          //   loading={docLoading}
          // />
        )}
      </Container>
    </DialogLayout>
  );
};

export default FileUpload;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.darkgrey};
  width: 400px;
  border-radius: 16px;
  padding: 28px 0px;
  border: 1.5px dashed #4D4F56;
  overflow: hidden;

  & .scratch-text{
    font-size: 16px;
    font-weight: 400;
    line-height: 23.2px;
  }

  & .gap-10 {
    gap: 10px;
  }

  & .create-btn{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
  }

  & .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: none !important;
    padding: 0px 24px !important;
    border-radius: 16px !important;
    background-color: ${({ theme }) => theme.colors.darkgrey}!important;

    & .icon {
      height: 56px;
      width: 56px;
      background-color: ${({ theme }) => theme.colors.darkbg};
      display: grid;
      place-items: center;
      border-radius: 50%;
    }

    & .text {
      text-align: center;
      max-width: 31ch;
      color: ${({ theme }) => theme.colors.primary};
      margin-block: 16px;

      & .text-faint {
        opacity: 0.7;
        font-size: 12px;
      }
    }
  }
`;

const Ortext = styled.div`
  border-top: 1px solid #4D4F56;
  text-align: center;
  width: 100%;
  position: relative;
  margin-block: 20px 24px;

  & span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.darkgrey};
    padding-inline: 10px 10px;
    font-size: 12px;
    line-height: 17.4px;
    font-weight: 600;
    color: #98A2B3;
  }
`;

const Uploading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2;
  border-radius: 16px;
  border-style: dashed;
  border-color: #4d4f56;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 264px;
  padding: 56px 24px;
  & p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-block: 20px 16px;

  & .percentage {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    margin: 0px;
  }

  & .progress {
    width: 100%;
    height: 6px;
    border-radius: 13px;
    background-color: #ffcad0;
    position: relative;

    & .filler {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 0%;
      height: 100%;
      border-radius: 13px;
      background-color: ${({ theme }) => theme.colors.red};
      transition: width 0.4s ease-in-out;
    }
  }
`;

const Completed = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2;
  border-radius: 16px;
  border-style: dashed;
  margin: 0px 24px;
  border-color: #4d4f56;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 264px;
  padding: 28px 24px;
  & p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.primary};
  }

  & .icon-container {
    height: 56px !important;
    width: 56px !important;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: #e7f6ec;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightgrey};
  margin-block: 18px;
`;