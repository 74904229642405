import React, { useEffect, useState, useRef } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Stepper from '../components/Stepper';
import ImageSelection from './ImageSelection';
import CustomButton from 'container/components/CustomButton/CustomButton';
import FilterSelection from './FilterSelection';
import ImageAndModels from './ImageAndModels';
import assets from 'assets';
import useMediaQuery from 'container/hooks/useMediaQuery';
import SearchInput from '../../Content/SearchInput';
import { createConvesation } from 'container/api';

const StarterPrompt = ({ handleClick }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [disableNext, setDisabledNext] = useState(false);
  const [prompt, setPromt] = useState('');

  const inputRef = useRef(null);

  const isMobile = useMediaQuery('(max-width: 1000px)');

  const {
    selectedFilter,
    imageCount,
    selectedImage,
    height,
    width,
    prompt_strength,
    model,
    isNeg_prompt,
    neg_prompt,
    seed,
    finalPrompt,
    filterEdited,
  } = useSelector(state => state.imageGeneration);

  const { selectedConversation, timeFilter } = useSelector(state => state.chat);
  const selectedModel = useSelector(state => state.chat.model);

  const handlePredict = async () => {
    try {
      dispatch.imageGeneration.setLoading(true);
      let conversationId = selectedConversation?.id;
      if (!selectedConversation) {
        const { data } = await createConvesation({
          label: finalPrompt,
          model: 'picasso',
        });
        conversationId = data?.payload?.id;
        let query = `?model=${selectedModel.value}`;
        if (timeFilter === 'recent') {
          query += `&most_recent=true`;
        } else if (timeFilter === 'favourite') {
          query += `&favourite=true`;
        }

        dispatch.chat.getAllConversations(query);
        dispatch.chat.setSelectedConversation(data.payload);
        dispatch.chat.setSelectedImage(null)
      }

      dispatch.imageGeneration.generateImage({
        prompt: finalPrompt,
        conversation_id: conversationId,
        steps: 50,
        width: width,
        height: height,
        images: imageCount,
        prompt_strength: prompt_strength,
        enhance_prompt: 0,
        restore_faces: 1,
        model_id: model,
        negative_prompt: isNeg_prompt ? neg_prompt : '',
        seed: seed || '',
      });
      dispatch.imageGeneration.setSeed('');
      dispatch.imageGeneration.setSelectedFilter(null);
      dispatch.imageGeneration.setSelectedImage(null);
      dispatch.imageGeneration.setFinalPrompt('');
      dispatch.imageGeneration.setCustomPrompt('');
      setPromt('');
    } catch (err) {
      console.log(err.message);
    } finally {
    }
  };

  const handleGoNext = () => {
    if (step === 4) {
      handleClick(1);
      handlePredict();
      dispatch.utils.setOpenControls(true);
      return null;
    }
    if (step === 1) {
      dispatch.imageGeneration.setSelectedImage({ ...selectedImage, prompt });
    }
    if (step >= 2) {
      dispatch.imageGeneration.setFinalPrompt(prompt);
    }
    setStep(step + 1);
  };

  const handleGoBack = () => {
    if (step === 1) {
      handleClick(1);
      dispatch.imageGeneration.setSelectedImage('');
      dispatch.imageGeneration.setSelectedFilter('');
      dispatch.utils.setOpenControls(false);
      return null;
    }
    setStep(step - 1);
  };

  const handleAfterPromptChange = () => {
    if (step === 1) {
      dispatch.imageGeneration.setPromptEdited(true);
    }
    if (step >= 2) {
      dispatch.imageGeneration.setFilterEdited(true);
      dispatch.imageGeneration.setPromptEdited(true);
    }
  };

  const handleBackToDashboard = () => {
    handleClick(1);
  };

  useEffect(() => {
    if (step === 1) {
      setDisabledNext(!!!selectedImage);
    }
    if (step === 2) {
      setDisabledNext(!!!selectedFilter);
    }
  }, [step, selectedImage, selectedFilter]);

  useEffect(() => {
    let query = '';
    if (selectedImage) {
      query = selectedImage.prompt;
    }
    if (selectedFilter?.descrption?.length) {
      query = query + ' and ' + selectedFilter.descrption;
    }
    if (filterEdited && step > 2) {
      query = finalPrompt;
    }
    setPromt(query);
    // eslint-disable-next-line
  }, [selectedImage, selectedFilter]);

  return (
    <>
      <StepperContainer>
        <div>
          {!isMobile && (
            <BackButton role='button' onClick={handleBackToDashboard}>
              <div className='icon'>
                <img src={assets.chevronLeft} alt='' />
              </div>
              <p className='font-size-12'>Dashboard</p>
            </BackButton>
          )}
        </div>
        <Stepper step={step} />
        <div />
      </StepperContainer>
      {step === 1 && <ImageSelection />}
      {step === 2 && <FilterSelection />}
      {(step === 3 || step === 4) && <ImageAndModels step={step} />}

      <ButtonContainer>
        <CustomButton
          variant='btn-outlined font-size-16'
          label='Back'
          onClick={handleGoBack}
        />
        <CustomButton
          variant='font-size-16'
          label={step === 4 ? 'Generate Prompt' : 'Next'}
          onClick={handleGoNext}
          disabled={disableNext}
        />
      </ButtonContainer>
      <SearchInput
        disableButton
        predict={prompt}
        setPredict={setPromt}
        ref={inputRef}
        afterChange={handleAfterPromptChange}
      />
    </>
  );
};

export default StarterPrompt;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-block: 30px 15px;
  padding-inline: 15px;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  & p {
    margin: 0px;
    color: ${({ theme }) => theme.colors.primary + '99'};
  }

  & .icon {
    background-color: ${({ theme }) => theme.colors.darkgray};
    width: 30px;
    height: 30px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
