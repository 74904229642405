import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { object, number } from 'yup';

import styled from 'styled-components';
import Image from '../components/Image';
import { validate } from 'container/utils/validations';
import Select from 'container/components/Select/Select';
import { imageDimensions } from 'app.config';

const schema = object({
  height: number().min(90).max(2800).required(),
  width: number().min(90).max(2800).required(),
});

const ImageAndModels = ({ step }) => {
  const dispatch = useDispatch();
  const {
    selectedFilter,
    selectedImage,
    selectedTemplate,
    templates,
    imageCount,
    height,
    width,
  } = useSelector(state => state.imageGeneration);

  const [dimensionsError, setDimensionsError] = useState('');
  const [selected, setSelected] = useState('');
  const [customDimension, setCustomDimension] = useState(false);

  const handleImageCount = count => {
    dispatch.imageGeneration.setImageCount(count);
  };

  const handleChange = e => {
    const value = e.target.value;
    if (value === 'custom') {
      setSelected(value);
      return setCustomDimension(true);
    } else {
      setCustomDimension(false);
    }
    const dimensions = value?.split(' x ');
    dispatch.imageGeneration.setWidth(+dimensions[0]);
    dispatch.imageGeneration.setHeight(+dimensions[1]);
    setSelected(value);
  };

  // const handleDimension = (width, height) => {
  //   dispatch.imageGeneration.setHeight(height);
  //   dispatch.imageGeneration.setWidth(width);
  // };

  const handleDimensionsChange = e => {
    const { name, value } = e.currentTarget;
    if (name === 'width') {
      dispatch.imageGeneration.setWidth(+value);
    } else {
      dispatch.imageGeneration.setHeight(+value);
    }
  };

  const validateDimensions = async () => {
    const errors = await validate(schema, { height, width });
    if (errors) {
      const errorMessages = Object?.keys(errors).map(msg => errors[msg]);
      setDimensionsError(errorMessages.join('. '));
      dispatch.imageGeneration.setDisable(true);
    } else {
      setDimensionsError('');
      dispatch.imageGeneration.setDisable(false);
    }
  };

  useEffect(() => {
    if (width === 0) {
      dispatch.imageGeneration.setWidth('');
    }
    if (height === 0) {
      dispatch.imageGeneration.setHeight('');
    }

    validateDimensions();
    // eslint-disable-next-line
  }, [width, height]);

  useEffect(() => {
    setSelected(imageDimensions[1].value);
    const val = imageDimensions[1].value;
    const dimensions = val?.split(' x ');
    dispatch.imageGeneration.setWidth(+dimensions[0]);
    dispatch.imageGeneration.setHeight(+dimensions[1]);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <p className='font-size-16'>1. Starter Prompt</p>
      <p className='font-size-10 subtitle'>
        Choose from a list of premade prompts to get better understanding of how
        to generate images.
      </p>

      <SelectedTemplate>
        <div className='inner'>
          <img
            src={templates[selectedTemplate][0].example_image_url}
            className='image'
            alt=''
          />
          <p className='font-size-12'>{selectedTemplate}</p>
        </div>
      </SelectedTemplate>

      <div className='selected-image'>
        <Image src={selectedImage.example_image_url} />
      </div>
      <Divider />
      <p className='font-size-16'>2. Filter</p>
      <p className='font-size-10 subtitle'>
        Experiment with different styles that can be applied to your image.
      </p>
      <div className='selected-image'>
        <Image src={selectedFilter.image_url} />
      </div>
      <Divider />
      <p className='font-size-16'>3. Image Dimensions</p>
      <p className='font-size-10 subtitle'>Width x Height of the image.</p>
      <DimensionButtons>
        <Select
          options={imageDimensions}
          onChange={handleChange}
          value={selected}
        />
      </DimensionButtons>

      {customDimension && (
        <>
          <CustomDimensions>
            <Input
              placeholder='Add width'
              type='number'
              value={width}
              name='width'
              onChange={handleDimensionsChange}
              min={90}
              max={1024}
              className={dimensionsError ? 'error' : ''}
            />
            <Input
              placeholder='Add height'
              type='number'
              value={height}
              name='height'
              onChange={handleDimensionsChange}
              min={90}
              max={1024}
              className={dimensionsError ? 'error' : ''}
            />
          </CustomDimensions>
          {dimensionsError && (
            <p className='font-size-12 error-message'>
              Dimensions must be between 90 and 2800
            </p>
          )}
        </>
      )}

      <Divider />
      {step === 4 && (
        <>
          <p className='font-size-16'>4. Number of images</p>
          <p className='font-size-10 subtitle'>
            Select the number of images you would like to generate.
          </p>
          <ButtonsContainer>
            <Button
              onClick={() => handleImageCount(1)}
              className={imageCount === 1 ? 'active' : ''}
            >
              1
            </Button>
            <Button
              onClick={() => handleImageCount(2)}
              className={imageCount === 2 ? 'active' : ''}
            >
              2
            </Button>
            {/* <Button
              onClick={() => handleImageCount(3)}
              className={imageCount === 3 ? 'active' : ''}
            >
              3
            </Button>
            <Button
              onClick={() => handleImageCount(4)}
              className={imageCount === 4 ? 'active' : ''}
            >
              4
            </Button> */}
          </ButtonsContainer>
          {/* {' '}
          <Divider />
          <p className='font-size-16'>4. Models selection</p>
          <p className='font-size-10 subtitle'>
            Different AI models can produce different or better results so fee
            free to experiment.
          </p>
          <ButtonsContainer>
            <div className='' style={{ width: '300px' }}>
              <Select
                options={imageGenerationModels}
                value={model}
                onChange={handleChangeModel}
              />
            </div>
          </ButtonsContainer> */}
        </>
      )}
    </Container>
  );
};

export default ImageAndModels;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary};
  padding: 15px;
  flex-grow: 1;
  height: 0px;
  overflow: auto;

  & p {
    margin: 0px;

    &.subtitle {
      max-width: 54ch;
      text-align: center;
      opacity: 0.5;
      margin-bottom: 10px;
    }
  }

  & .selected-image {
    width: 124px;
    height: 124px !important;
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.lightgrey};
  width: 100%;
  max-width: 320px;
  margin-block: 20px;
`;

const SelectedTemplate = styled.div`
  width: 100%;
  max-width: 265px;
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  margin-bottom: 10px;

  & .inner {
    display: flex;
    align-items: center;
    gap: 10px;

    & img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;

const Button = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  height: 26px;
  min-width: 70px;
  border-radius: 4px;
  padding-inline: 10px;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.colors.red};
  }
`;

const DimensionButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 10px 14px;
  max-width: 300px;
`;

const Input = styled.input`
  border: none;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 10px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  min-width: 130px;
  &.error {
    border: 1px solid ${({ theme }) => theme.colors.red};
  }
`;

const CustomDimensions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
