import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import DialogLayout from '../DialogLayout';
import assets from 'assets';

import ShareWithWhatsapp from 'container/components/ShareButtons/ShareWithWhatsapp';
import ShareWithFacebook from 'container/components/ShareButtons/ShareWithFacebook';
import ShareWithTwitter from 'container/components/ShareButtons/ShareWithTwitter';
import ShareWithLinkedIn from 'container/components/ShareButtons/ShareWithLinkedIn';
import ShareWithSMS from 'container/components/ShareButtons/ShareWithSMS';
import { getConversationByIdPublic } from 'container/api';
import { ClipLoader } from 'react-spinners';
import { siteURL } from 'app.config';
import { toast } from 'react-hot-toast';

dayjs.extend(utc);

const ShareDialog = () => {
  const dispatch = useDispatch();
  const { open } = useSelector(state => state.shareModal);
  const selectedId = useSelector(
    state => state.chat.selectedConversation?.id || ''
  );

  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch.shareModal.setOpen(false);
  };

  const handleFetchMessage = async () => {
    try {
      setLoading(true);
      const { data } = await getConversationByIdPublic(selectedId);
      setMessage(data.data[0]);
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRenderMessage = ref => {
    if (message?.response_source === 3) {
      return (
        <img
          src={message?.response}
          style={{
            height: '100px',
            width: '100px',
            marginTop: '8px',
          }}
          alt=''
        />
      );
    }

    const messageStrings = message.response?.split('\n');

    return messageStrings.map((str, index) => (
      <p className='message' key={index}>
        {str.length > 0 && (
          <p style={{ font: 'inherit', margin: '0px' }}>{str}</p>
        )}
        {index !== messageStrings.length - 1 && str.length > 1 && (
          <div style={{ height: '14px' }} />
        )}
      </p>
    ));
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${siteURL}/share/${selectedId}`);
    toast.success('Link copied successfully!');
  };

  useEffect(() => {
    if (selectedId) {
      handleFetchMessage();
    }
    // eslint-disable-next-line
  }, [selectedId]);

  return (
    <DialogLayout open={open}>
      <Container>
        <Row>
          <p className='font-size-20'>Share Chat Response</p>
          <div
            role='button'
            className='close-button cursor-pointer'
            style={{ cursor: 'pointer' }}
            onClick={handleClose}
          >
            <img src={assets.closeIcon} alt='' />
          </div>
        </Row>
        <div className='divider'></div>
        <Content>
          <MessageBox>
            <div className='message'>
              <img src={assets.chatGpt} alt='' />
              {loading ? (
                <div
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ClipLoader size={20} color='#ffffff' />
                </div>
              ) : (
                <div>{message && handleRenderMessage()}</div>
              )}
            </div>
            <div className='divider'></div>
            <div className='message-footer'>
              <Row>
                <p className='font-size-12'>Powered by ChatAI</p>
                <p className='font-size-10'>
                  {!loading &&
                    dayjs(message?.created_at).format('MMMM DD, YYYY')}
                </p>
              </Row>
            </div>
          </MessageBox>
          {/* <InviteBox>
            <input
              type='text'
              name=''
              id=''
              placeholder='Email, comma separated'
            />
            <button>Invite</button>
          </InviteBox> */}
          <FlexBox>
            <ShareWithWhatsapp />
            <ShareWithFacebook />
            <ShareWithTwitter />
            <ShareWithSMS />
            <ShareWithLinkedIn />
          </FlexBox>
          <CopyLink>
            <input
              type='text'
              name=''
              id=''
              value={`${siteURL}/share/${selectedId}`}
              readOnly
              placeholder=''
            />
            <button onClick={handleCopyLink}>
              <img src={assets.copy} alt='' /> <span>Copy link</span>
            </button>
          </CopyLink>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default ShareDialog;

const Container = styled.div`
  width: 615px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.darkbg};
  z-index: 1000;
  color: ${({ theme }) => theme.colors.primary};

  & .divider {
    border-top: 1px solid ${({ theme }) => theme.colors.primary + '1a'};
  }

  @media only screen and (max-width: 650px) {
    width: calc(100vw - 20px);
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 20px 17px;
  padding-inline: 20px;

  & p {
    margin-bottom: 0px;
  }
`;

const Content = styled.div`
  padding: 20px;
`;

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.darkgrey};
  border-radius: 8px;
  max-height: 220px;

  & .message {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 10px;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
`;
// eslint-disable-next-line
const InviteBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightgrey};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-block: 20px;

  & input {
    flex-grow: 1;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
    border: none;
  }

  & button {
    padding: 10px 20px;
    border: none;
    border-radius: 7px;
    background-color: ${({ theme }) => theme.colors.lightgrey};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-inline: 50px;
  margin-block: 30px;
  flex-wrap: wrap;
  @media only screen and (max-width: 500px) {
    justify-content: flex-start;
    gap: 15px;
  }
`;

const CopyLink = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightgrey};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-block: 20px;
  & input {
    flex-grow: 1;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
    border: none;
  }

  & button {
    padding: 10px 20px;
    border: none;
    border-radius: 7px;
    background-color: ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;
