import { createModel } from '@rematch/core';

export const outliner = createModel()({
  name: 'outliner',
  state: {
    items: [],
  },
  reducers: {
    addItem(state, item) {
      const elements = state.items;
      if (!elements.includes(item)) {
        state = state.items.push(item);
      }
    },
    removeItem(state, item) {
      const elements = state.items;
      state.items = elements.filter(i => i !== item);
    },
    reset(state) {
      state.items = [];
    },
    setItems(state, items) {
      state.items = items;
    },
  },
});
