import { createModel } from '@rematch/core';
import api, { markAllNotifications } from 'container/api';
import { allNotifications } from 'container/api';

export const notifications = createModel()({
  name: 'notifications',
  state: {
    loading: false,
    notifications: [],
    next: '',
    unreadCount: 0,
  },
  reducers: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setNext(state, payload) {
      state.next = payload;
    },
    setUnreadCount(state, payload) {
      state.unreadCount = payload;
    },
  },
  effects: dispatch => ({
    async loadNotifications(payload) {
      try {
        dispatch.notifications.setLoading(true);
        const { data } = await allNotifications();
        dispatch.notifications.setNotifications(data.results);
        dispatch.notifications.setNext(data.next);
        dispatch.notifications.setUnreadCount(data?.unread_count || 0);
      } catch (err) {
      } finally {
        dispatch.notifications.setLoading(false);
      }
    },
    async loadMoreNotifications(payload, rootState) {
      try {
        const next = rootState.notifications.next;
        dispatch.notifications.setLoading(true);
        if (next) {
          const { data } = await api.get(next);
          dispatch.notifications.setNotifications([
            ...rootState.notifications.notifications,
            ...data.results,
          ]);

          dispatch.notifications.setNext(data.next);
          dispatch.notifications.setUnreadCount(data?.unread_count || 0);
        }
      } catch (err) {
        console.log(err);
      } finally {
        dispatch.notifications.setLoading(false);
      }
    },
    async readNotification(payload, rootState) {
      try {
        await markAllNotifications();
        dispatch.notifications.setUnreadCount(0);
      } catch (err) {
        console.log(err.message);
      }
    },
  }),
});
